import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCallId, getCurrentUserId, getCurrentUserName } from 'selectors';
import logger from 'utils/logger';

interface AppAggregates {
  'pathname': string,
  'userId': string,
  'displayName': string,
  'callId': string,
}

/**
 * Updates logger with the user's username or userID initially,
 * and then also whenever one of them changes.
 */
export default function useLoggerAggregates() {
  const currentUserId = useSelector(getCurrentUserId);
  const currentUserDisplayName = useSelector(getCurrentUserName);
  const callId = useSelector(getCallId);
  const location = useLocation();

  useEffect(() => {
    const aggregates: AppAggregates = {
      pathname: location.pathname,
      userId: currentUserId || '',
      displayName: currentUserDisplayName || '',
      callId: callId || '',
    };

    const { messageContext } = logger;
    Object.keys(aggregates).forEach((_key) => {
      const key = _key as keyof AppAggregates;
      if (aggregates[key] !== messageContext.aggregates?.[key]) {
        logger.setMessageAggregate(key, aggregates[key]);
      }
    });
  }, [location, callId, currentUserDisplayName, currentUserId]);
}
