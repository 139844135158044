import logger from 'utils/logger';
import request from 'utils/request';
import { handleError } from 'actions/sharedActions';
import { MakeActionType } from 'utils/typeUtils';
import { AppThunkAction, DashboardRoom } from 'store/types';
import {
  CCPaginationResponse, CreateRoomRequestPayload, CreateRoomResponse, FetchDashboardRooms,
} from 'utils/ccService/types';
import { push } from 'connected-react-router';
import { getMessageFromError, getStatusCodeFromError } from 'utils/errorUtils';
import RouteEnum from 'utils/routeEnum';

export const reducerName = 'dashboardState' as const;
export const DELETE_MY_ROOM = `${reducerName}/DELETE_MY_ROOM` as const;
export const DELETE_ALL_ROOMS = `${reducerName}/DELETE_ALL_ROOMS` as const;
export const SET_MY_ROOMS = `${reducerName}/SET_MY_ROOMS` as const;
export const SET_INVITED_ROOMS = `${reducerName}/SET_INVITED_ROOMS` as const;
export const SET_IS_CREATING_ROOM = `${reducerName}/SET_IS_CREATING_ROOM` as const;
export const SET_CREATING_LOADING = `${reducerName}/SET_CREATING_LOADING` as const;
export const TOGGLE_ACCOUNT_MODAL = `${reducerName}/TOGGLE_ACCOUNT_MODAL` as const;
export const SET_NAME_ERROR = `${reducerName}/SET_NAME_ERROR` as const;
export const SET_ROOM_SETTINGS_MODAL = `${reducerName}/SET_ROOM_SETTINGS_MODAL` as const;
export const SET_ROOM_DELETE_MODAL = `${reducerName}/SET_ROOM_DELETE_MODAL` as const;
export const SET_ROOM_NAME = `${reducerName}/SET_ROOM_NAME` as const;
export const SET_ROOM_SETTINGS_ROOM_NAME = `${reducerName}/SET_ROOM_SETTINGS_ROOM_NAME` as const;
export const PREPEND_MY_ROOM = `${reducerName}/PREPEND_MY_ROOM` as const;
export const SET_NEW_ROOM_NAME_ERROR = `${reducerName}/SET_NEW_ROOM_NAME_ERROR` as const;
export const SET_ROOM_DELETE_LOADING = `${reducerName}/SET_ROOM_DELETE_LOADING` as const;
export const TOGGLE_APP_SETTINGS_MODAL_OPEN = `${reducerName}/TOGGLE_APP_SETTINGS_MODAL_OPEN` as const;
export const SET_NEXT_MY_ROOMS_URL = `${reducerName}/SET_NEXT_MY_ROOMS_URL` as const;
export const SET_NEXT_INVITED_ROOMS_URL = `${reducerName}/SET_NEXT_INVITED_ROOMS_URL` as const;
export const APPEND_MY_ROOMS = `${reducerName}/APPEND_MY_ROOMS` as const;
export const APPEND_INVITED_ROOMS = `${reducerName}/APPEND_INVITED_ROOMS` as const;

export const setIsCreatingRoom = (isCreatingRoom: boolean) => ({
  type: SET_IS_CREATING_ROOM,
  payload: { isCreatingRoom },
});

export const setCreatingRoomLoading = (loading: boolean) => ({
  type: SET_CREATING_LOADING,
  payload: { loading },
});

export const setNameError = (error: {
  roomId: string,
  message: string | null
}) => ({
  type: SET_NAME_ERROR,
  payload: error,
});

export const setNewRoomNameError = (error: string, erroneousName: string, roomId: string) => ({
  type: SET_NEW_ROOM_NAME_ERROR,
  payload: { error, erroneousName, roomId },
});

export const setRoomDeleteLoading = (loading: boolean) => ({
  type: SET_ROOM_DELETE_LOADING,
  payload: { loading },
});

export const toggleAccountModal = () => ({
  type: TOGGLE_ACCOUNT_MODAL,
});

export const setRoomForSettings = (roomId: string) => ({
  type: SET_ROOM_SETTINGS_MODAL,
  payload: { roomId },
});

export const setRoomForDelete = (roomId: string) => ({
  type: SET_ROOM_DELETE_MODAL,
  payload: { roomId },
});

export const setRoomName = (roomId: string, roomName: string) => ({
  type: SET_ROOM_NAME,
  payload: {
    roomId,
    roomName,
  },
});

export const setMyRooms = (rooms: DashboardRoom[]) => ({
  type: SET_MY_ROOMS,
  payload: { rooms },
});

export const appendMyRooms = (rooms: DashboardRoom[]) => ({
  type: APPEND_MY_ROOMS,
  payload: { rooms },
});

export const setInvitedRooms = (rooms: DashboardRoom[]) => ({
  type: SET_INVITED_ROOMS,
  payload: { rooms },
});

export const appendInvitedRooms = (rooms: DashboardRoom[]) => ({
  type: APPEND_INVITED_ROOMS,
  payload: { rooms },
});

export const setRoomSettingsRoomName = (roomSettingsRoomName: string) => ({
  type: SET_ROOM_SETTINGS_ROOM_NAME,
  payload: { roomSettingsRoomName },
});

export const deleteMyRoomInState = (roomId: string) => ({
  type: DELETE_MY_ROOM,
  payload: { roomId },
});

export const prependMyRoom = (room: DashboardRoom) => ({
  type: PREPEND_MY_ROOM,
  payload: { room },
});

export const toggleAppSettingsModalOpen = (open?: boolean) => ({
  type: TOGGLE_APP_SETTINGS_MODAL_OPEN,
  payload: { open },
});

export const setNextMyRoomsUrl = (url: string) => ({
  type: SET_NEXT_MY_ROOMS_URL,
  payload: { url },
});

export const setNextInvitedRoomsUrl = (url: string) => ({
  type: SET_NEXT_INVITED_ROOMS_URL,
  payload: { url },
});

export type DashboardAction = MakeActionType<[
  typeof DELETE_ALL_ROOMS,
  typeof setIsCreatingRoom,
  typeof setCreatingRoomLoading,
  typeof setNameError,
  typeof toggleAccountModal,
  typeof setRoomForSettings,
  typeof setRoomForDelete,
  typeof setRoomName,
  typeof setMyRooms,
  typeof setRoomSettingsRoomName,
  typeof deleteMyRoomInState,
  typeof prependMyRoom,
  typeof setNewRoomNameError,
  typeof setRoomDeleteLoading,
  typeof toggleAppSettingsModalOpen,
  typeof appendMyRooms,
  typeof setNextMyRoomsUrl,
  typeof setInvitedRooms,
  typeof appendInvitedRooms,
  typeof setNextInvitedRoomsUrl
]>

const getNextUrl = (data: CCPaginationResponse) => data?._links?.next || '';

const validateAndFixRooms = (rooms: DashboardRoom[], source: 'my-rooms' | 'invited-rooms'): DashboardRoom[] => {
  if (!Array.isArray(rooms)) {
    logger.warn('Malformed rooms response', { reason: 'Response data "rooms" is not array', source, rooms });
    return [];
  }

  const issues: string[] = []; // keep track of issues pertaining to individual rooms
  return rooms.filter((room, i) => {
    if (!room || typeof room !== 'object') {
      issues.push(`Room is bad type at index ${i}`);
      return false;
    }
    if (!room.slug) {
      issues.push(`Room is missing slug at index ${i}`);
      return false;
    }
    if (!room.name) {
      issues.push(`Room has no name at index ${i}`);
      // allowing these rooms to remain since they at least have a slug to be usable
    }

    if (i === rooms.length - 1 && issues.length > 0) {
      logger.warn('Received malformed rooms in response', { source, issues: JSON.stringify(issues), rooms: JSON.stringify(rooms) });
    }
    return true;
  });
};

/**
 * Gets user's rooms
 */
export const fetchMyRooms = (): AppThunkAction => async (dispatch) => {
  try {
    const response = await request<FetchDashboardRooms>({ method: 'GET', url: '/rooms' });

    dispatch(setMyRooms(validateAndFixRooms(response.data?.rooms, 'my-rooms')));
    dispatch(setNextMyRoomsUrl(getNextUrl(response.data)));
  } catch (error) {
    dispatch(handleError('Failed to get user\'s rooms', { error }));
  }
};

/**
 * Handles paging for more user rooms
 */
export const fetchMoreMyRooms = (): AppThunkAction => async (dispatch, getState) => {
  const { dashboardState: { nextMyRoomsUrl } } = getState();
  if (!nextMyRoomsUrl) return;

  try {
    const response = await request<FetchDashboardRooms>({ method: 'GET', url: nextMyRoomsUrl });

    dispatch(appendMyRooms(validateAndFixRooms(response.data?.rooms, 'my-rooms')));
    dispatch(setNextMyRoomsUrl(getNextUrl(response.data)));
  } catch (error) {
    dispatch(handleError('Failed to get user\'s rooms', { error, nextMyRoomsUrl }));
  }
};

/**
 * Gets rooms that the user has been invited to
 */
export const fetchInvitedRooms = (): AppThunkAction => async (dispatch) => {
  try {
    const response = await request<FetchDashboardRooms>({ method: 'GET', url: '/invited-rooms' });

    dispatch(setInvitedRooms(validateAndFixRooms(response.data?.rooms, 'invited-rooms')));
    dispatch(setNextInvitedRoomsUrl(getNextUrl(response.data)));
  } catch (error) {
    dispatch(handleError('Failed to get invited rooms', { error }));
  }
};

/**
 * Handles paging for more invited rooms
 */
export const fetchMoreInvitedRooms = (): AppThunkAction => async (dispatch, getState) => {
  const { dashboardState: { nextInvitedRoomsUrl } } = getState();
  if (!nextInvitedRoomsUrl) return;

  try {
    const response = await request<FetchDashboardRooms>({ method: 'GET', url: nextInvitedRoomsUrl });

    dispatch(appendInvitedRooms(validateAndFixRooms(response.data?.rooms, 'invited-rooms')));
    dispatch(setNextInvitedRoomsUrl(getNextUrl(response.data)));
  } catch (error) {
    dispatch(handleError('Failed to get invited rooms', { error, nextInvitedRoomsUrl }));
  }
};

/**
 * Updates a room's name in CC Service.
 * @param {string} roomId
 */
export const updateName = (roomId: string, name: string, newRoom = false): AppThunkAction => async (dispatch, getState) => {
  const room = getState().dashboardState.myRooms.find((roomObject) => roomObject.slug === roomId);
  const prevRoomName = room?.name || '';
  try {
    // assume it worked, set state immediately
    dispatch(setRoomName(roomId, name));
    await request({
      method: 'PUT',
      url: `/rooms/${roomId}`,
      data: {
        name,
      },
    });

    dispatch(setNameError({ message: null, roomId }));
  } catch (error) {
    if (!newRoom && prevRoomName) {
      dispatch(setRoomName(roomId, prevRoomName));
    }
    if (getStatusCodeFromError(error) === 422) {
      if (newRoom) {
        dispatch(setNewRoomNameError('A room with this name already exists', name, roomId));
      } else {
        dispatch(setNameError({
          message: 'A room with this name already exists',
          roomId,
        }));
      }
    } else {
      dispatch(handleError('Failed to update room name', { error }));
    }
    // revert room name back to previous name on error
    dispatch(setRoomName(roomId, prevRoomName));
  }
};

/**
 * Creates a new room and adds it to the available rooms
 * @param {string} name
 */
export const createNewRoom = (data: CreateRoomRequestPayload): AppThunkAction => async (dispatch) => {
  dispatch(setCreatingRoomLoading(true));
  dispatch(setNewRoomNameError('', '', ''));
  try {
    // create new room
    const response = await request<CreateRoomResponse>({ method: 'POST', url: '/rooms', data });
    const { room } = response.data;

    // Dummy DashboardRoom object based on response data to display recently created room
    // Most of these fields not necessary for dashboard except name and slug
    dispatch(prependMyRoom({
      id: -1,
      slug: room.id,
      description: '',
      ownerId: -1,
      currentSessionId: '',
      inactive: 0,
      name: data.name,
      created: new Date().toISOString(),
      updated: new Date().toISOString(),
      lastSession: new Date().toISOString(),
      isLocked: 0,
      roster: [],
    }));
  } catch (error) {
    dispatch(handleError('Failed to create new room', { error }));
    const statusCode = getStatusCodeFromError(error);
    if (statusCode === 422) {
      // 422 means duplicate name
      dispatch(setIsCreatingRoom(true));
      dispatch(setNewRoomNameError('A room with this name already exists', data.name, ''));
    }
  } finally {
    dispatch(setCreatingRoomLoading(false));
  }
};

/**
 * Deletes a user's room
 * @param {string} roomId
 */
export const deleteRoom = (roomId: string): AppThunkAction => async (dispatch, getState) => {
  const prevRooms = getState().dashboardState.myRooms;
  // assume deletion worked
  dispatch(deleteMyRoomInState(roomId));
  dispatch(setRoomDeleteLoading(true));
  try {
    await request({
      method: 'DELETE',
      url: `/rooms/${roomId}`,
    });
  } catch (error) {
    dispatch(setMyRooms(prevRooms));
    dispatch(handleError('Failed to delete room', { error, roomId }));
  } finally {
    dispatch(push(RouteEnum.DASHBOARD.encodePath()));
    dispatch(setRoomForDelete(''));
    dispatch(setRoomForSettings(''));
    dispatch(setRoomDeleteLoading(false));
  }
};

/**
 *  Deletes all of a user's rooms
 * @param {string} roomId
 */
export const deleteAllRooms = (): AppThunkAction => async (dispatch, getState) => {
  const state = getState();
  const { myRooms: rooms } = state.dashboardState;
  try {
    const requests = rooms.map((room) => request({
      method: 'DELETE',
      url: `/rooms/${room.slug}`,
    }));

    await Promise.all(requests);

    dispatch({
      type: DELETE_ALL_ROOMS,
    });
  } catch (error) {
    const errorMessage = getMessageFromError(error);
    logger.warn('Error deleting all rooms', { errorMessage });
  }
};
