import { getLocalStorageFeatureFlagValue } from 'hooks/useFeatureFlags';
import { createSelector } from 'reselect';
import {
  EncoderRenderIds, EncoderRenderIdsEnum, EncoderRenderIdsEnumAsArray, StoreState,
} from 'store/types';

export const getDevicePermission = (state: StoreState) => state.encoderState.devicePermission;

export const getVideoDeviceId = (state: StoreState) => state.encoderState.videoDeviceId;

export const getAudioDeviceId = (state: StoreState) => state.encoderState.audioDeviceId;

export const getIsVideoPaused = (state: StoreState) => state.encoderState.isVideoPaused;

export const getVideoUiState = (state: StoreState) => state.encoderState.videoUiState;

export const getVideoMediaStreamControllerError = (state: StoreState) => state.encoderState.videoMediaStreamControllerError;

export const getVideoMediaStreamControllerLoading = (state: StoreState) => state.encoderState.videoMediaStreamControllerLoading;

export const getVideoClient = (state: StoreState) => state.encoderState.videoClient;

export const getCall = (state: StoreState) => state.encoderState.call;

export const getIsRetrying = (state: StoreState) => state.encoderState.isRetrying;

export const getVideoMediaStreamController = (state: StoreState) => state.encoderState.videoMediaStreamController;

export const getNumBitrates = (state: StoreState) => state.encoderState.numBitrates;

export const getHasTriedUpshift = (state: StoreState) => state.encoderState.hasTriedUpshift;

export const getUpshiftUnsuccessfulTime = (state: StoreState) => state.encoderState.upshiftUnsuccessfulTime;

export const getBroadcast = (state: StoreState) => state.encoderState.broadcast;

export const getFilterUiState = (state: StoreState) => state.encoderState.filterUiState;

export const getFilterMediaStreamController = (state: StoreState) => state.encoderState.filterMediaStreamController;

export const getFilterMediaStreamControllerLoading = (state: StoreState) => state.encoderState.filterMediaStreamControllerLoading;

export const getFilterMediaStreamControllerError = (state: StoreState) => state.encoderState.filterMediaStreamControllerError;

export const getEncoderRenderIds = (state: StoreState) => state.encoderState.encoderRenderIds;

export const getIsAudioMuted = (state: StoreState) => state.encoderState.isAudioMuted;

export const getFilterMaxBitrate = (state: StoreState) => state.encoderState.filterMaxBitrate;

export const getDominantSpeaker = (state: StoreState) => state.encoderState.dominantSpeaker;

export const getVideoDevicePref = (state: StoreState) => state.encoderState.videoDeviceIdPref;

/** Helper function to get highest priority Enum value that is currently being rendered */
export const getHighestPriorityEnumValue = (encoderPortalIds: EncoderRenderIds) => (
  // since the array is already sorted, the first element that is rendering should be the highest priority one
  EncoderRenderIdsEnumAsArray.find((portalKey) => encoderPortalIds[portalKey]) ?? null
);

/** When filtered video is trying to render in multiple locations, return the highest priority encoderRenderId value (integer) */
export const getEncoderRenderId = createSelector(
  getEncoderRenderIds,
  (encoderPortalIds): EncoderRenderIdsEnum | null => {
    const highestPriorityKeyBeingRendered = getHighestPriorityEnumValue(encoderPortalIds);

    if (!highestPriorityKeyBeingRendered) return null;

    // convert enum string key into an integer
    return EncoderRenderIdsEnum[highestPriorityKeyBeingRendered] ?? null;
  },
);

export const makeGetEncoderRenderIdIsType = (type: EncoderRenderIdsEnum | null) => createSelector(
  getEncoderRenderId,
  (encoderRenderId) => encoderRenderId === type,
);

export const getEncoderRenderIdIsControlBar = makeGetEncoderRenderIdIsType(EncoderRenderIdsEnum.CONTROL_BAR);

export const getMediaStreamControllersLoading = createSelector(
  getVideoMediaStreamControllerLoading,
  getFilterMediaStreamControllerLoading,
  (videoMscLoading, filterMscLoading) => videoMscLoading || filterMscLoading,
);

/** Returns one depending on if the Filters feature flag is enabled */
export const getVideoOrFilterMediaStreamController = createSelector(
  getVideoMediaStreamController,
  getFilterMediaStreamController,
  (videoMsc, filterMsc) => {
    const enableFilters = getLocalStorageFeatureFlagValue('enableFilters');
    return enableFilters ? filterMsc : videoMsc;
  },
);

/** Returns one depending on if the Filters feature flag is enabled */
export const getVideoOrFilterMediaStreamControllerError = createSelector(
  getVideoMediaStreamControllerError,
  getFilterMediaStreamControllerError,
  (videoMscError, filterMscError) => {
    const enableFilters = getLocalStorageFeatureFlagValue('enableFilters');
    return enableFilters ? filterMscError : videoMscError;
  },
);

/** Returns one depending on if the Filters feature flag is enabled */
export const getVideoOrFilterMediaStreamControllerLoading = createSelector(
  getVideoMediaStreamControllerLoading,
  getFilterMediaStreamControllerLoading,
  (videoMscLoading, filterMscLoading) => {
    const enableFilters = getLocalStorageFeatureFlagValue('enableFilters');
    return enableFilters ? (videoMscLoading || filterMscLoading) : videoMscLoading;
  },
);

export const getIsDominantSpeaker = (id:string) => createSelector(
  getDominantSpeaker,
  (speaker) => (id === speaker?.userId),
);
