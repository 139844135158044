/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import { memo, ComponentProps, ReactNode } from 'react';
import { attendanceStatusIconDefaultStyles } from './common';

interface AttendanceStatusIconPlainProps extends ComponentProps<'td'> {
  attendanceIcon: ReactNode;
}
/** Plain icon with no hover / click listeners */
const AttendanceStatusIconPlain = memo(({ attendanceIcon, ...rest }: AttendanceStatusIconPlainProps) => (
  <td sx={attendanceStatusIconDefaultStyles} {...rest}>{attendanceIcon}</td>
));

export default AttendanceStatusIconPlain;
