/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import { CSS } from 'types/css';
import { ReactComponent as OverallAttendanceCircleSVG } from 'assets/images/circle.svg';
import Svg from 'components/ui/Svg';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getUserViewOverallAttendance } from 'selectors';
import { mediaMaxWidth, mediaMinWidth } from 'styles/styles';
import { TEAL_TRUE } from 'theme/ui/colors';
import { attendanceCardBaseStyles } from '../common';

const overallAttendanceCardStyles: CSS = {
  ...attendanceCardBaseStyles,
  display: 'flex',
  [mediaMaxWidth._5]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    minHeight: '5rem',
    padding: '0.8rem 2rem',
  },
  [mediaMinWidth._5]: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '26rem',
    height: '26rem',
    marginRight: '2rem',
    padding: '2rem',
  },
};

const overallAttendanceTextStyles: CSS = {
  variant: 'text.body_semibold',
};

const overallAttendanceNumberContainerStyles: CSS = {
  position: 'relative',
  [mediaMaxWidth._5]: {
    width: 'fit-content',
    height: 'fit-content',
  },
  [mediaMinWidth._5]: {
    width: '17.6rem',
    height: '17.6rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
  },
};

/** Hack that allows animating a path
 * @see https://css-tricks.com/svg-line-animation-works/ */
const STROKE_DASH_ARRAY = 600;
const overallAttendanceCircleSvgStaticStyles: CSS = {
  [mediaMaxWidth._5]: {
    display: 'none',
  },
  [mediaMinWidth._5]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) rotate(-90deg)',
    width: '100%',
    height: '100%',
    transition: 'stroke-dashoffset 0.2s',

    strokeDasharray: STROKE_DASH_ARRAY,
    strokeWidth: 7,
  },
};

const overallAttendanceNumberStyles: CSS = {
  [mediaMaxWidth._5]: {
    variant: 'text.h2_bold',
    color: TEAL_TRUE,
  },
  [mediaMinWidth._5]: {
    variant: 'text.h1_bold',
    color: TEAL_TRUE,
  },
};

const PATH_OFFSET_PATH_VISIBLE = 90;
const PATH_OFFSET_PATH_HIDDEN = STROKE_DASH_ARRAY;
const PATH_OFFSET_RANGE = PATH_OFFSET_PATH_HIDDEN - PATH_OFFSET_PATH_VISIBLE;

/** Calculates the svg stroke offset such that:
0.0 = no path showing
0.5 = half of the circle's path showing
1.0 = the circle's path is fully showing */
const getStrokeDashOffsetFromAttendancePercentage = (percentage: number) => STROKE_DASH_ARRAY - PATH_OFFSET_RANGE * percentage;

interface OverallAttendanceCardProps {
  roomId: string, userId: string,
}

export default function OverallAttendanceCard({ roomId, userId }: OverallAttendanceCardProps) {
  const overallAttendance = useSelector(getUserViewOverallAttendance);

  const overallAttendanceCircleSvgStyles = useMemo(() => ({
    ...overallAttendanceCircleSvgStaticStyles,

    // start with the circle hidden and animate it getting drawn
    strokeDashoffset: PATH_OFFSET_PATH_HIDDEN,

    // ThemeUI (/Emotion) does not remove animation declarations when the component unmounts in
    // development mode, so these animation names must be unique to prevent conflicting animations.
    // @see https://github.com/emotion-js/emotion/issues/488
    [`@keyframes drawCircle-${roomId}-${userId}`]: {
      from: { strokeDashoffset: PATH_OFFSET_PATH_HIDDEN },
      to: { strokeDashoffset: getStrokeDashOffsetFromAttendancePercentage(overallAttendance) },
    },
    animation: ` drawCircle-${roomId}-${userId} 2s 0.5s forwards`,
  }), [overallAttendance, roomId, userId]);

  return (
    <section sx={overallAttendanceCardStyles}>
      <span sx={overallAttendanceTextStyles}>Overall Attendance</span>
      <div sx={overallAttendanceNumberContainerStyles}>
        <Svg
          svg={OverallAttendanceCircleSVG}
          aria-hidden
          sx={overallAttendanceCircleSvgStyles}
        />
        <span sx={overallAttendanceNumberStyles}>
          {Math.round(overallAttendance * 100)}
          %
        </span>
      </div>
    </section>
  );
}
