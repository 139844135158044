/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'components/ui/Modal/Modal';
import ModalTitle from 'components/ui/Modal/ModalTitle';
import ModalBody from 'components/ui/Modal/ModalBody';
import Button from 'components/ui/Button';
import DisplayNameInput from 'components/Input/DisplayNameInput';
import HiddenEmailInput from 'components/Input/HiddenEmailInput';
import { getCurrentUserName, getNewDisplayName, getUserProfile } from 'selectors/index';
import { changeParticipantForm, submitDisplayNameUpdate, toggleRegistrationCompleteModal } from 'actions/loginActions';
import { ReactComponent as HighFive } from 'assets/images/high-five.svg';
import useTypedSelector from 'hooks/useTypedSelector';
import { loginCardPx, mediaMaxWidth, mediaMinWidth } from 'styles/styles';
import { AuthUser } from 'store/types';
import { isGoogleUser } from 'utils/typePredicates';
import { TEAL_DARK } from 'theme/ui/colors';
import { CSS } from 'types/css';

// import mixpanel from 'utils/mixpanel';
import Svg from 'components/ui/Svg';

const modalTitleStyles: CSS = {
  [mediaMaxWidth._1]: {
    variant: 'text.h5',
  },
  [mediaMinWidth._1]: {
    variant: 'text.h4',
  },
};

const modalBodyStyles: CSS = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: loginCardPx,
};

const highFiveStyles: CSS = {
  height: '11.6rem',
  width: '16rem',
  display: 'block',
  m: '1.5rem auto 4rem',
};

const welcomeStyles: CSS = {
  textAlign: 'center',
  mb: '2rem',
  [mediaMaxWidth._1]: {
    variant: 'text.h5_bold',
    color: TEAL_DARK,
  } as CSS,
  [mediaMinWidth._1]: {
    variant: 'text.h4_bold',
    color: TEAL_DARK,
  } as CSS,
};

const displayNameInputStyles: CSS = {
  mt: '2rem',
};

const hiddenEmailInputStyles: CSS = {
  mt: '2rem',
};

function RegistrationCompleteModal() {
  // if they are seeing the registration complete modal, they can only be an AuthUser
  const currentUser = useTypedSelector(getUserProfile) as AuthUser;
  const currentDisplayName = useTypedSelector(getCurrentUserName);
  const newDisplayName = useTypedSelector(getNewDisplayName);
  const registrationCompleteVisible = useTypedSelector((state) => (
    state.loginState.registrationCompleteVisible
  ));
  const currentUserIsGoogleUser = isGoogleUser(currentUser);
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const hiddenEmail = currentUser?.sanitizedEmail || '**********';

  const toggleModal = () => {
    dispatch(toggleRegistrationCompleteModal());
    // send a request to update displayName in database
    if (newDisplayName.length && (currentDisplayName !== newDisplayName)) {
      dispatch(submitDisplayNameUpdate());
    }
  };

  // useEffect(() => {
  //   if (registrationCompleteVisible) mixpanel.track('Registration Complete Screen View');
  // }, [registrationCompleteVisible]);

  return (
    <Modal
      isOpen={registrationCompleteVisible}
      toggle={toggleModal}
      width="narrow"
      minContentHeight
      componentStyles={{ maxHeight: 'calc(100vh - 5rem)' }}
    >
      <ModalTitle toggle={toggleModal}><span sx={modalTitleStyles}>Registration Complete</span></ModalTitle>
      <ModalBody sx={modalBodyStyles}>
        <Svg svg={HighFive} aria-hidden sx={highFiveStyles} />
        <p sx={welcomeStyles}>Welcome to ChalkCast!</p>
        <DisplayNameInput
          value={newDisplayName}
          inputRef={inputRef}
          currentDisplayName={currentUser?.displayName}
          onNameChange={(name) => dispatch(changeParticipantForm(name, 'displayName'))}
          data-selenium="registration-complete-modal__name-input"
          componentStyles={displayNameInputStyles}
          wide
        />
        {currentUserIsGoogleUser && (
          <HiddenEmailInput
            id="email"
            label="Email"
            data-selenium="registration-complete-modal__disabled-email-input"
            value={hiddenEmail}
            componentStyles={hiddenEmailInputStyles}
            wide
          />
        )}
        <Button
          onClick={toggleModal}
          variant="primary"
          sx={{ mt: currentUserIsGoogleUser ? 0 : '4rem' }}
        >
          Continue
        </Button>
      </ModalBody>
    </Modal>
  );
}

export default RegistrationCompleteModal;
