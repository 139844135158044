// !!!! WARNING !!!! only dynamic imports should occur in this file, since this function is imported into index.tsx

/**
 * Sets up service worker to intercept requests to CC-service while developing.
 *
 * Useful for mocking CC-service responses.
 * @see https://mswjs.io/docs/getting-started/integrate/browser
*/
const setupMockServiceWorker = async () => {
  if (process.env.REACT_APP_USE_MSW === 'true') {
    // base service worker
    const { setupWorker } = await import('msw');
    const mswWorker = setupWorker();

    // dynamically import any requests to mock:
    const { mockGetAllViewAttendanceNormal, mockGetUserViewAttendance } = await import('utils/tests/mocks/mockWorkerResponses');

    // use any mocked requests
    mswWorker.use(
      mockGetAllViewAttendanceNormal,
      mockGetUserViewAttendance,
    );

    // register service worker
    return mswWorker.start();
  }
  return null;
};

export default setupMockServiceWorker;
