/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import { useSelector } from 'react-redux';
import { getEncoderRenderId } from 'selectors';
import { EncoderRenderIdsEnum } from 'store/types';
import FilterVideo from './FilterVideo';

interface FilterVideoConditionalProps {
  encoderRenderId: EncoderRenderIdsEnum,
}

/** Renders the Filter encoder video if the current encoderRenderId matches this component's encoderRenderId */
export default function FilterVideoConditional({
  encoderRenderId,
}: FilterVideoConditionalProps) {
  const selectedEncoderRenderId = useSelector(getEncoderRenderId);
  return selectedEncoderRenderId === encoderRenderId ? <FilterVideo /> : null;
}
