import produce from 'immer';
import {
  PreferencesAction,
  SET_SOUND_SETTINGS,
  SET_SOUND_SETTINGS_LOADING,
  SET_TILE_SORT,
  SET_TILE_SORT_INCL_DISABLED_CAM,
} from 'actions/preferencesActions';
import { persistReducer, persistConfig } from 'store/localStoragePersistMiddleware';
import { PreferencesState, tileSortOptions } from 'store/types';
import { CCServiceSoundPreferencesEnum } from 'utils/ccService/types';

export const initialState: PreferencesState = {
  soundPreferencesLoading: true,
  soundPreferences: {
    [CCServiceSoundPreferencesEnum.JOIN]: {
      description: 'Someone joins the room',
      name: CCServiceSoundPreferencesEnum.JOIN,
      soundPreferenceId: 1,
      enabled: 1,
    },
    [CCServiceSoundPreferencesEnum.LEAVE]: {
      description: 'Someone leaves the room',
      name: CCServiceSoundPreferencesEnum.LEAVE,
      soundPreferenceId: 2,
      enabled: 1,
    },
    [CCServiceSoundPreferencesEnum.RAISE_HAND]: {
      description: 'Someone raises their hand',
      name: CCServiceSoundPreferencesEnum.RAISE_HAND,
      soundPreferenceId: 3,
      enabled: 1,
    },
    [CCServiceSoundPreferencesEnum.CALLED_ON]: {
      description: 'I am called on (When I have my hand raised)',
      name: CCServiceSoundPreferencesEnum.CALLED_ON,
      soundPreferenceId: 4,
      enabled: 1,
    },
    [CCServiceSoundPreferencesEnum.ROOM_CHAT_MESSAGE]: {
      description: 'Someone sends a message to the room (and my chat window is closed)',
      name: CCServiceSoundPreferencesEnum.ROOM_CHAT_MESSAGE,
      soundPreferenceId: 5,
      enabled: 1,
    },
    [CCServiceSoundPreferencesEnum.DIRECT_CHAT_MESSAGE]: {
      description: 'Someone sends a direct message to me (and my chat window is closed)',
      name: CCServiceSoundPreferencesEnum.DIRECT_CHAT_MESSAGE,
      soundPreferenceId: 6,
      enabled: 1,
    },
  },
  tileSort: tileSortOptions[0],
  tileSortIncludeDisabledCameras: false,
};

const reducer = produce((draft: PreferencesState, action: PreferencesAction) => {
  switch (action.type) {
    case SET_SOUND_SETTINGS:
      draft.soundPreferences = action.payload.soundPreferences;
      break;
    case SET_SOUND_SETTINGS_LOADING:
      draft.soundPreferencesLoading = action.payload.isLoading;
      break;
    case SET_TILE_SORT:
      draft.tileSort = action.payload.sort;
      break;
    case SET_TILE_SORT_INCL_DISABLED_CAM:
      draft.tileSortIncludeDisabledCameras = action.payload.include;
      break;
    default:
      break;
  }
}, initialState);

export default persistReducer(reducer, persistConfig.preferencesState);
