export enum fbTrackCodesEnum {
  COMPLETE_REGISTRATION = 'CompleteRegistration'
}

/**
 * Currently we only use this to track when a user signs up after clicking a facebook ad
 */
function facebookTrack(code: fbTrackCodesEnum) {
  // if fbq exists on the window object that means the user came to us from a facebook ad
  if (window.fbq) {
    window.fbq('track', code);
  }
}

export default facebookTrack;
