import { EnumBase } from 'utils/enum';
import passThroughFragShaderSource from '../shaders/webgl/pass_through.frag';
import passThroughWebGL2FragShaderSource from '../shaders/webgl2/pass_through_2.frag';
import grayscaleFragShaderSource from '../shaders/webgl/grayscale.frag';
import pixelateFragShaderSource from '../shaders/webgl/pixelate.frag';
import colorifyFragShaderSource from '../shaders/webgl/colorify.frag';
import gaussianBlurFragShaderSource from '../shaders/webgl2/gaussian_blur_2.frag';
import sobelFragShaderSource from '../shaders/webgl2/sobel_2.frag';
import sobelAndNonmaxSuppressionFragShaderSource from '../shaders/webgl2/sobel_with_nonmax_suppression_2.frag';
import doubleThresholdFragShaderSource from '../shaders/webgl2/double_threshold_2.frag';
import hysteresisFragShaderSource from '../shaders/webgl2/hysteresis_2.frag';
import invertFragShaderSource from '../shaders/webgl2/invert_2.frag';

/** String-value keys of Filters enum: i.e. `"PASS_THROUGH"`, `"GRAYSCALE"`, etc. */
export type FiltersKeys = Exclude<keyof typeof Filters, keyof typeof EnumBase | 'keyOf'>;

/** Enum variants as a union type (for literal typing on enum properties: `0 | 1 | 2` instead of `number` for instance) */
export type FiltersUnion = (typeof Filters)[FiltersKeys];

/** Types of filters that may be applied to the user's video
 *
 * Using an inferred number type allows TypeScript to retain the number value for each enum variant.
 */
export class Filters<N extends number = number, S extends string = string> extends EnumBase {
  /** Pass video data through without applying a filter. Since this version uses WebGL1,
   * it should be fine to use in pretty much every browser. */
  public static readonly PASS_THROUGH = new Filters(
    0,
    'Pass Through',
    passThroughFragShaderSource,
    false,
    [],
    true,
  );

  /** Pass video data through without applying a filter. Uses WebGL2,
   * so can only be used in browsers that support WebGL2 */
  public static readonly PASS_THROUGH_2 = new Filters(
    1,
    'Pass Through (WebGL2)',
    passThroughWebGL2FragShaderSource,
    true,
    [],
    false,
  );

  /** Create an 8-bit, pixelated effect */
  public static readonly PIXELATE = new Filters(
    2,
    'Pixelate',
    pixelateFragShaderSource,
    false,
    [],
    true,
  );

  /** Adds some arbitrary color to the video */
  public static readonly COLORIFY = new Filters(
    3,
    'Colorify',
    colorifyFragShaderSource,
    false,
    [],
    true,
  );

  /** Converts video to grayscale */
  public static readonly GRAYSCALE = new Filters(
    4,
    'Grayscale',
    grayscaleFragShaderSource,
    false,
    [],
    true,
  );

  /** Gaussian blur, using WebGL2 */
  public static readonly GAUSSIAN_BLUR_2 = new Filters(
    5,
    'Gaussian Blur',
    gaussianBlurFragShaderSource,
    true,
    [],
    false,
  );

  /** Sobel filter: converts to grayscale and detects edges */
  public static readonly SOBEL_2 = new Filters(
    6,
    'Sobel',
    sobelFragShaderSource,
    true,
    [],
  );

  /** Sobel filter: converts to grayscale and detects edges, using a nonmax suppression algorithm
   * at the end to make all edges only 1px wide (preservers light intensity of edges) */
  public static readonly SOBEL_AND_NONMAX_SUPPRESSION_2 = new Filters(
    7,
    'Sobel (With Nonmaximum Suppression)',
    sobelAndNonmaxSuppressionFragShaderSource,
    true,
    [],
  );

  /** Reduces all edges to either a zero value, a middle (weak) output value, or a high (strong) output value */
  public static readonly DOUBLE_THRESHOLD_2 = new Filters(
    8,
    'Double Threshold',
    doubleThresholdFragShaderSource,
    true,
    [],
  );

  /** Uses the same zero, weak, and strong value as Double Threshold. Converts any weak pixels that
   * touching a strong pixel in to a strong pixel */
  public static readonly HYSTERESIS_2 = new Filters(
    9,
    'Hysteresis',
    hysteresisFragShaderSource,
    true,
    [],
  );

  /** Invert the colors of an image (white -> black, black -> white, etc.) */
  public static readonly INVERT_2 = new Filters(
    10,
    'Invert',
    invertFragShaderSource,
    true,
    [],
  );

  /** Canny edge detection with a black background and white lines */
  public static readonly CANNY_EDGE_DETECTION_DARK_2 = new Filters(
    11,
    'Canny Edge Detection (Dark)',
    passThroughWebGL2FragShaderSource,
    true,
    [
      Filters.GRAYSCALE,
      Filters.GAUSSIAN_BLUR_2,
      Filters.SOBEL_AND_NONMAX_SUPPRESSION_2,
      Filters.DOUBLE_THRESHOLD_2,
      Filters.HYSTERESIS_2,
    ],

    true,
  );

  /** Canny edge detection with a light background and dark lines */
  public static readonly CANNY_EDGE_DETECTION_LIGHT_2 = new Filters(
    12,
    'Canny Edge Detection (Light)',
    passThroughWebGL2FragShaderSource,
    true,

    [
      Filters.GRAYSCALE,
      Filters.GAUSSIAN_BLUR_2,
      Filters.SOBEL_AND_NONMAX_SUPPRESSION_2,
      Filters.DOUBLE_THRESHOLD_2,
      Filters.HYSTERESIS_2,
      Filters.INVERT_2,
    ],

    true,
  );

  protected constructor(
    public integer: N,
    public name: S,
    public source: string,
    public requiresWebGl2: boolean,
    public filterSteps: Filters[],
    public publicFilter = false,
  ) {
    super();
  }

  /** Identical ordering is guaranteed on successive calls */
  public static asArray() {
    const array = super.asArray();
    array.sort(
      (a, b) => (a as Filters).integer - (b as Filters).integer,
    );
    // hacky, but gives exact union values for array
    return super.asArray() as Array<typeof Filters[FiltersKeys]>;
  }

  public static asObject() {
    // hacky, but gives exact object keys/values
    return super.asObject() as { [key in Extract<FiltersKeys, 'keyOf'>]: typeof Filters[key]};
  }

  /** Returns the raw string value key of this enum variant i.e. `"PASS_THROUGH"` for `Filters.PASS_THROUGH.keyOf()` */
  public keyOf() {
    return Object.entries(Filters.asObject()).find((entry) => entry[1] === this)?.[0] as FiltersKeys;
  }
}
