import { useCallback, useEffect, useRef } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import {
  getUserProfile, getLivelyTokenString,
  getLivelyTokenExp, getJWT, getLoginType, getFetchingTokens, getFetchingUser,
} from 'selectors';
import {
  logout, fetchUser, fetchNewTokens, setLoginType,
} from 'actions/loginActions';
import { sendUserToErrorPage, setErrorMessage } from 'actions/sharedActions';
import { LoginTypesEnum } from 'store/types';
import { isAuthUser } from 'utils/typePredicates';
import determineAuthState from 'utils/determineAuthState';
import logger from 'utils/logger';
import RouteEnum from 'utils/routeEnum';

const MAX_TOKEN_RETRIES = 5;
const MAX_USER_RETRIES = 5;

/**
 * Keeps Redux LoginState up to date, based on the user object, JWT, and livelyToken.
 *
 * The JWT is valid for 30 days, while the livelyToken is valid only for 24 hours.
 * In general, we use the JWT to refresh the user's livelyToken regularly to persist their login state.
 */
export default function useSyncAuthState() {
  const user = useSelector(getUserProfile);
  const livelyToken = useSelector(getLivelyTokenString);
  const livelyTokenExp = useSelector(getLivelyTokenExp);
  const token = useSelector(getJWT);
  const loginType = useSelector(getLoginType);
  const dispatch = useDispatch();
  // check auth credentials every time the user moves pages
  const location = useLocation();
  const fetchingUser = useSelector(getFetchingUser);
  const fetchingTokens = useSelector(getFetchingTokens);
  const nTokenAttempts = useRef(0);
  const nUserAttempts = useRef(0);

  const handleAuthFailure = useCallback(() => {
    logger.error('Error determining authentication state', {
      user, token, livelyToken, livelyTokenExp,
    } as any);
    batch(() => {
      dispatch(logout());
      dispatch(setErrorMessage('Error determining user\'s authentication state'));
      dispatch(sendUserToErrorPage());
    });
  }, [dispatch, user, token, livelyToken, livelyTokenExp]);

  // refresh the user's tokens on every page load/refresh
  useEffect(() => {
    if (!fetchingTokens && token) {
      dispatch(fetchNewTokens());
      nTokenAttempts.current += 1;
    }
  }, []);

  useEffect(() => {
    let newLoginType = LoginTypesEnum.UNKNOWN;
    switch (determineAuthState({
      user, token, livelyToken, livelyTokenExp,
    })) {
      // valid authentication states: --------------------------------------------------
      case 'anon':
        // user is anonymous, do nothing except update/verify loginType
        newLoginType = LoginTypesEnum.ANON_USER;
        break;
      case 'valid':
        // user is valid, do nothing except update/verify loginType
        newLoginType = isAuthUser(user) ? LoginTypesEnum.AUTH_USER : LoginTypesEnum.TEMP_USER;
        break;

      // fixable authentication states: ------------------------------------------------
      case 'badLivelyToken':
        // this is fixable by fetching new tokens using the existing JWT
        if (nTokenAttempts.current < MAX_TOKEN_RETRIES && !fetchingTokens) {
          setTimeout(() => {
            dispatch(fetchNewTokens());
            nTokenAttempts.current += 1;
          }, nTokenAttempts.current === 0 ? 0 : 1000);
        } else if (nTokenAttempts.current >= MAX_TOKEN_RETRIES) {
          logger.error('Could not fetch tokens');
          handleAuthFailure();
        }
        break;
      case 'noUser':
        // this is fixable by fetching a new user object for the user
        if (nUserAttempts.current < MAX_USER_RETRIES && !fetchingUser) {
          setTimeout(() => {
            dispatch(fetchUser());
            nUserAttempts.current += 1;
          }, nUserAttempts.current === 0 ? 0 : 1000);
        } else if (nUserAttempts.current >= MAX_USER_RETRIES) {
          logger.error('Could not fetch user');
          handleAuthFailure();
        }
        break;
      // non-fixable authentication states: ---------------------------------------------
      case 'noJWT':
        // no way of re-verifying authentication, clear login data
        dispatch(logout());
        break;
      case 'expired':
        // if JWT is expired, no way of re-verifying authentication, clear login data
        dispatch(logout({ redirect: RouteEnum.SESSION_EXPIRED }));
        break;
      case 'error':
        /* this case should be very unlikely, but logout here just to be on the safe
        side if something goes wrong when trying to verify the user's authentication state */
        handleAuthFailure();
        break;
    }
    // keep loginType in sync with authentication data
    if (newLoginType !== loginType) dispatch(setLoginType(newLoginType));
  }, [fetchingUser, fetchingTokens, location, token,
    livelyToken, livelyTokenExp, loginType, user, dispatch, handleAuthFailure]);
}
