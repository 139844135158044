import { DashboardState } from 'store/types';
import produce from 'immer';
import {
  SET_MY_ROOMS,
  DELETE_MY_ROOM,
  DELETE_ALL_ROOMS,
  SET_CREATING_LOADING,
  TOGGLE_ACCOUNT_MODAL,
  SET_NAME_ERROR,
  SET_ROOM_SETTINGS_MODAL,
  SET_ROOM_DELETE_MODAL,
  SET_ROOM_NAME,
  SET_ROOM_SETTINGS_ROOM_NAME,
  SET_IS_CREATING_ROOM,
  DashboardAction,
  PREPEND_MY_ROOM,
  SET_NEW_ROOM_NAME_ERROR,
  SET_ROOM_DELETE_LOADING,
  TOGGLE_APP_SETTINGS_MODAL_OPEN,
  SET_NEXT_MY_ROOMS_URL,
  APPEND_MY_ROOMS,
  SET_INVITED_ROOMS,
  SET_NEXT_INVITED_ROOMS_URL,
  APPEND_INVITED_ROOMS,
} from 'actions/dashboardActions';
import { LOGOUT, SharedAction } from 'actions/sharedActions';

export const initialState: DashboardState = {
  myRooms: [],
  invitedRooms: [],
  isCreatingRoom: false,
  creatingRoomLoading: false,
  roomDeleteLoading: false,
  nameErrors: null,
  newRoomNameError: { error: '', erroneousName: '', roomId: '' },
  // modals:
  roomSettingsRoomId: '',
  roomSettingsRoomName: '',
  roomDeleteRoomId: '',
  accountVisible: false,
  appSettingsModalOpen: false,
  nextMyRoomsUrl: '',
  nextInvitedRoomsUrl: '',
};

const reducer = produce((draft: DashboardState, action: DashboardAction | SharedAction) => {
  switch (action.type) {
    case SET_MY_ROOMS:
      draft.myRooms = action.payload.rooms;
      break;
    case APPEND_MY_ROOMS:
      draft.myRooms = [...draft.myRooms, ...action.payload.rooms];
      break;
    case DELETE_MY_ROOM:
      draft.myRooms = draft.myRooms.filter((rm) => rm.slug !== action.payload.roomId);
      break;
    case DELETE_ALL_ROOMS:
      draft.myRooms = [];
      break;
    case SET_IS_CREATING_ROOM:
      draft.isCreatingRoom = action.payload.isCreatingRoom;
      break;
    case SET_CREATING_LOADING:
      draft.creatingRoomLoading = action.payload.loading;
      break;
    case TOGGLE_ACCOUNT_MODAL:
      draft.accountVisible = !draft.accountVisible;
      break;
    case SET_NAME_ERROR:
      draft.nameErrors = {
        ...draft.nameErrors,
        [action.payload.roomId]: action.payload.message,
      };
      break;
    case SET_ROOM_SETTINGS_MODAL:
      draft.roomSettingsRoomId = action.payload.roomId;
      break;
    case SET_ROOM_DELETE_MODAL:
      draft.roomDeleteRoomId = action.payload.roomId;
      break;
    case SET_ROOM_DELETE_LOADING:
      draft.roomDeleteLoading = action.payload.loading;
      break;
    case SET_NEW_ROOM_NAME_ERROR:
      draft.newRoomNameError = action.payload;
      break;
    case SET_ROOM_NAME: {
      const index = draft.myRooms.findIndex((room) => room.slug === action.payload.roomId);
      if (index > -1) {
        const room = { ...draft.myRooms[index] };
        room.name = action.payload.roomName;
        const newRooms = [...draft.myRooms];
        newRooms[index] = room;
        draft.myRooms = newRooms;
      }
      break;
    }
    case SET_ROOM_SETTINGS_ROOM_NAME:
      draft.roomSettingsRoomName = action.payload.roomSettingsRoomName;
      break;
    case LOGOUT: {
      Object.assign(draft, initialState);
      break;
    }
    case PREPEND_MY_ROOM:
      draft.myRooms = [action.payload.room, ...draft.myRooms];
      break;
    case TOGGLE_APP_SETTINGS_MODAL_OPEN:
      draft.appSettingsModalOpen = action.payload.open ?? !draft.appSettingsModalOpen;
      break;
    case SET_NEXT_MY_ROOMS_URL:
      draft.nextMyRoomsUrl = action.payload.url;
      break;
    case SET_INVITED_ROOMS:
      draft.invitedRooms = action.payload.rooms;
      break;
    case APPEND_INVITED_ROOMS:
      draft.invitedRooms = [...draft.invitedRooms, ...action.payload.rooms];
      break;
    case SET_NEXT_INVITED_ROOMS_URL:
      draft.nextInvitedRoomsUrl = action.payload.url;
      break;
    default:
      break;
  }
}, initialState);

export default reducer;
