import { getMessageFromError } from './errorUtils';
import logger from './logger';

interface Options {
  delay?: number,
  max?: number,
}

type UnknownCallback = (...args: unknown[]) => unknown

/**
 * Takes a synchronous callback and retries until results are returned
 * This is currently only being used for getting audio/video devices from
 * the users computer. There is a race condition with video client that
 * necessitates using this retry logic.
 * @param callback
 * @param options
 */
async function retrySync(
  callback: UnknownCallback,
  { delay = 100, max = 20 }: Options,
) {
  return new Promise((resolve, reject) => {
    let count = 0;
    let result;
    const interval = setInterval(() => {
      if (count === max) {
        clearInterval(interval);
        return reject(new Error('Max retries'));
      }
      count += 1;
      try {
        result = callback();
        clearInterval(interval);
        return resolve(result);
      } catch (error) {
        const errorMessage = getMessageFromError(error);
        logger.warn('Failed retrySync', { errorMessage });
      }
      return null;
    }, delay);
  });
}

export default retrySync;
