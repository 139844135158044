/* eslint-disable import/prefer-default-export */
import logger from 'utils/logger';

/**
 * For given array of dates, get max date
 */
export function getMaxDate(dates: Array<Date | number>) {
  return dates.length ? new Date(Math.max.apply(null, dates as number[])) : undefined; // does work with Date[]
}

/**
 * For given array of dates, get min date
 */
export function getMinDate(dates: Array<Date | number>) {
  return dates.length ? new Date(Math.min.apply(null, dates as number[])) : undefined; // does work with Date[]
}

/**
 * Converts date string from date into Month Day format
 * Example: January 01
 */
export function dateToMonthDayString(date: string | number | Date) {
  try {
    return new Intl.DateTimeFormat(undefined, {
      month: 'long',
      day: 'numeric',
    // formats in local time by default
    }).format(new Date(date));
  } catch (error) {
    logger.warn('Error converting date string into Month Day format', { error } as any);
    return '';
  }
}

/**
 * Converts date string from date into month
 * Example: January
 */
export function dateToMonthString(date: string | number | Date) {
  try {
    return new Intl.DateTimeFormat(undefined, {
      month: 'long',
    // formats in local time by default
    }).format(new Date(date));
  } catch (error) {
    logger.warn('Error converting date string into month format', { error } as any);
    return '';
  }
}

/**
 * Converts date string from date into weekday format
 * Example: Monday
 */
export function dateToWeekdayString(date: string | number | Date) {
  try {
    return new Intl.DateTimeFormat(undefined, {
      weekday: 'long',
    // formats in local time by default
    }).format(new Date(date));
  } catch (error) {
    logger.warn('Error converting date string into weekday format', { error } as any);
    return '';
  }
}

/**
 * Converts date string from date into numeric date format
 * Example: 15 (for the 15th of January, for instance)
 */
export function dateToNumericDateString(date: string | number | Date) {
  try {
    return new Intl.DateTimeFormat(undefined, {
      day: 'numeric',
    // formats in local time by default
    }).format(new Date(date));
  } catch (error) {
    logger.warn('Error converting date string into numeric date format', { error } as any);
    return '';
  }
}

/**
 * Converts date string into Month Year format
 * Example: January 2021
 */
export function dateToMonthYearString(date: string | number | Date) {
  try {
    return new Intl.DateTimeFormat(undefined, {
      month: 'long',
      year: 'numeric',
    // formats in local time by default
    }).format(new Date(date));
  } catch (error) {
    logger.warn('Error converting date string into Month Year format', { error } as any);
    return '';
  }
}

/**
 * Adds a leading zero to positive, single digit numbers.
 * Examples: 1 --> 01, 5 --> 05, 10 --> 10
 *
 * TODO: Could be a more generic string utility
 */
function addLeadingZero(number: number) {
  return Number.isInteger(number) && number >= 0 && number < 10 ? `0${number}` : `${number}`;
}

/**
 * Converts a date in string format to a string in readable local time format
 * Example: 10:45PM
 */
export function dateToClockTime(date: string | number | Date) {
  try {
    // with no locales argument provided, Intl.DateTimeFormat assumes the browser's default locale
    const time = new Intl.DateTimeFormat(undefined, {
      hour: 'numeric',
      minute: 'numeric',
    // formats in local time by default
    }).format(new Date(date));
    const timeWithoutSpaces = time.replace(/ +/g, '');
    return timeWithoutSpaces;
  } catch (error) {
    logger.warn('Error converting date in string format to a time in 2:00PM format', { error } as any);
    return '';
  }
}

/**
 * Calculates the difference in time in ms
 */
export function calculateTimeDifference(timeA: string | number | Date, timeB: string | number | Date) {
  try {
    return Math.abs(new Date(timeB).getTime() - new Date(timeA).getTime());
  } catch (error) {
    logger.warn('Error calculating total time', { error } as any);
    return 0;
  }
}

export interface MsToDurationStringOptions {
  leadingZeroForMinute?: boolean
  leadingZeroForHour?: boolean,
  leadingZeroForSecond?: boolean,
}

const MS_TO_DURATION_STRING_DEFAULT_OPTIONS: MsToDurationStringOptions = {
  leadingZeroForHour: false,
  leadingZeroForMinute: false,
  leadingZeroForSecond: false,
};

/**
 * Given a number of milliseconds, returns a duration in 00h 00m 00s format,
 */
export function msToDurationString(ms: number, {
  leadingZeroForMinute = MS_TO_DURATION_STRING_DEFAULT_OPTIONS.leadingZeroForMinute,
  leadingZeroForHour = MS_TO_DURATION_STRING_DEFAULT_OPTIONS.leadingZeroForHour,
  leadingZeroForSecond = MS_TO_DURATION_STRING_DEFAULT_OPTIONS.leadingZeroForSecond,
}: MsToDurationStringOptions = MS_TO_DURATION_STRING_DEFAULT_OPTIONS) {
  try {
    const totalSeconds = Math.floor(ms / 1000);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);

    const remainingMinutes = totalMinutes % 60;
    const remainingSeconds = totalSeconds % 60;

    const formattedH = leadingZeroForHour ? addLeadingZero(totalHours) : totalHours.toString();
    const formattedM = leadingZeroForMinute ? addLeadingZero(remainingMinutes) : remainingMinutes.toString();
    const formattedS = leadingZeroForSecond ? addLeadingZero(remainingSeconds) : remainingSeconds.toString();

    return totalHours ? `${formattedH}h ${formattedM}m ${formattedS}s` : `${formattedM}m ${formattedS}s`;
  } catch (error) {
    logger.warn('Error converting milliseconds into 00h 00m 00s format', { error } as any);
    return '';
  }
}

/** Makes creating raw ms easier by specifying only the larger time denominators  */
export const makeMs = (hours = 0, minutes = 0, seconds = 0, ms = 0) => (
  (60 * 60 * 1000 * hours) + (60 * 1000 * minutes) + (1000 * seconds) + ms
);

export const formatMsToTime = (ms: number) => {
  const MS = 1000;
  const SECONDS = 60 * MS;
  const MINUTES = 60 * SECONDS;

  const timeInSeconds = Math.floor((ms / MS) % 60).toString().padStart(2, '0');
  const timeInMinutes = Math.floor((ms / SECONDS) % 60).toString().padStart(1, '0');
  const timeInHours = Math.floor(ms / MINUTES);
  const formattedTimeInHours = timeInHours === 0 ? '' : `${timeInHours}:`.toString().padStart(2, '0');
  return `${formattedTimeInHours}${timeInMinutes}:${timeInSeconds}`;
};
