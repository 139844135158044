import { boxShadowLight, boxShadowLightHover, buttonResetStyles } from 'styles/styles';
import {
  GREY_0, GREY_10, GREY_50, GREY_70, WHITE,
  TEAL_DARK, TEAL_LIGHT, GREY_40, GREY_5, GREY_90, GREY_60, TEAL_TRUE, TEAL_MEDIUM, RED_DARK, RED_40, ColorsEnum,
} from './colors';

const base = {
  appearance: 'none',
  display: 'flex',
  position: 'relative',
  boxSizing: 'border-box',
  p: '0.8rem 2rem',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '10rem',
  cursor: 'pointer',
  transition: 'all 0.2s ease, box-shadow 0.1s ease',
  border: 'none',
  outline: 'none',
  '& > div': {
    fontFamily: 'open',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '1.6rem',
    lineHeight: '2.8rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& > svg': {
    height: '1.8rem',
    width: '1.8rem',
    fill: WHITE,
  },
  '&:focus': {
    border: 'none',
    outline: 'none',
  },
  '&:active:not(:disabled)': {},
  '&:hover': {},
  '&:disabled': {},
} as const;

const controlBarBase = {
  height: ['5.2rem', '5.2rem', '5.2rem', '6rem'],
  width: ['5.2rem', '5.2rem', '5.2rem', '6rem'],
  borderRadius: ['2.6rem', '2.6rem', '2.6rem', '3rem'],
  color: WHITE,
  backgroundColor: ColorsEnum.BLACK.withOpacity(0.6),
  p: 0,
  border: 'solid 2px transparent',
  transition: 'all 0.2s ease',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  position: 'relative',
  '& > div': {
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'normal',
  },
  '& svg': {
    transition: 'transform 0.2s ease',
    fill: WHITE,
    height: '2.5rem',
    position: 'relative',
    top: '0.5rem !important',
  },
  '& p': {
    transition: 'opacity 0.2s ease',
    fontSize: '1.2rem',
    lineHeight: '1.2rem',
    opacity: 0,
    m: 0,
    maxHeight: '1rem',
  },
  '&:focus': {
    outline: 'none',
  },
  '&:focus-visible': {
    outline: 'none',
    border: 'solid 2px teal',
  },
  '&:hover': {
    '& svg': {
      transition: 'transform 0.2s ease',
      transform: 'translateY(-1.2rem)',
    },
    '& p': {
      maxHeight: null,
      opacity: 1,
    },
  },
  '&:disabled': {
    backgroundColor: ColorsEnum.BLACK.withOpacity(0.2),
    cursor: 'not-allowed',
    '& svg': {
      opacity: 0.2,
      transform: 'translateY(0)',
    },
    '& p': {
      opacity: 0,
      maxHeight: '1rem',
    },
  },
};

export const TILE_CONTROL_BUTTON_SIZE = '4.8rem';

const buttonVariantStyles = {
  primary: {
    ...base,
    color: WHITE,
    background: `linear-gradient(117.46deg, ${TEAL_MEDIUM} 11.44%, ${TEAL_DARK} 74.61%)`,
    boxShadow: `0px 4px 16px ${ColorsEnum.TEAL_TRUE.withOpacity(0.6)}`,
    '&:hover': {
      ...base['&:hover'],
      boxShadow: `0px 8px 20px ${ColorsEnum.TEAL_TRUE.withOpacity(0.6)}`,
    },
    '&:focus': {
      ...base['&:focus'],
    },
    '&:active:not(:disabled)': {
      ...base['&:active:not(:disabled)'],
      background: `linear-gradient(117.46deg, ${TEAL_DARK} 11.44%, ${TEAL_MEDIUM} 74.61%)`,
      boxShadow: 'none',
    },
    '&:disabled': {
      ...base['&:disabled'],
      color: TEAL_DARK,
      background: 'unset',
      backgroundColor: TEAL_LIGHT,
      boxShadow: 'none',
      '& > svg': {
        ...base['& > svg'],
        fill: TEAL_DARK,
      },
    },
    '& > svg': {
      ...base['& > svg'],
    },
    '& > div': {
      ...base['& > div'],
    },
  },
  secondaryLight: {
    ...base,
    color: GREY_70,
    background: `linear-gradient(117.46deg, ${GREY_0} 11.44%, ${GREY_10} 74.61%)`,
    boxShadow: boxShadowLight,
    '&:hover': {
      ...base['&:hover'],
      boxShadow: boxShadowLightHover,
    },
    '&:focus': {
      ...base['&:focus'],
    },
    '&:active:not(:disabled)': {
      ...base['&:active:not(:disabled)'],
      background: `linear-gradient(117.46deg, ${GREY_10} 11.44%, ${GREY_0} 74.61%)`,
      boxShadow: 'none',
    },
    '&:disabled': {
      ...base['&:disabled'],
      color: GREY_0,
      background: 'unset',
      backgroundColor: GREY_40,
      boxShadow: 'none',
      '& > svg': {
        ...base['& > svg'],
        fill: GREY_0,
      },
    },
    '& > svg': {
      ...base['& > svg'],
    },
    '& > div': {
      ...base['& > div'],
    },
  },
  secondaryDark: {
    ...base,
    color: GREY_5,
    background: `linear-gradient(117.46deg, ${GREY_70} 11.44%, ${GREY_90} 74.61%)`,
    boxShadow: `0px 4px 15px ${ColorsEnum.BLACK.withOpacity(0.3)}`,
    '&:hover': {
      ...base['&:hover'],
      boxShadow: `0px 8px 20px ${ColorsEnum.BLACK.withOpacity(0.3)}`,
    },
    '&:focus': {
      ...base['&:focus'],
    },
    '&:active:not(:disabled)': {
      ...base['&:active:not(:disabled)'],
      background: ` linear-gradient(117.46deg, ${GREY_90} 11.44%, ${GREY_70} 74.61%)`,
      boxShadow: 'none',
    },
    '&:disabled': {
      ...base['&:disabled'],
      color: GREY_60,
      background: 'unset',
      backgroundColor: GREY_90,
      boxShadow: 'none',
    },
    '& > svg': {
      ...base['& > svg'],
    },
    '& > div': {
      ...base['& > div'],
    },
  },
  error: {
    ...base,
    color: WHITE,
    background: `linear-gradient(117.46deg, ${RED_40} 11.44%, ${RED_DARK} 74.61%)`,
    boxShadow: `0px 4px 16px ${ColorsEnum.RED_TRUE.withOpacity(0.6)}`,
    '&:hover': {
      ...base['&:hover'],
      boxShadow: `0px 8px 20px ${ColorsEnum.RED_TRUE.withOpacity(0.6)}`,
    },
    '&:focus': {
      ...base['&:focus'],
    },
    '&:active:not(:disabled)': {
      ...base['&:active:not(:disabled)'],
      background: ` linear-gradient(117.46deg, ${RED_DARK} 11.44%,  ${RED_40} 74.61%)`,
      boxShadow: 'none',
    },
    '&:disabled': {
      ...base['&:disabled'],
      color: GREY_60,
      background: 'unset',
      backgroundColor: GREY_90,
      boxShadow: 'none',
    },
    '& > svg': {
      ...base['& > svg'],
    },
    '& > div': {
      ...base['& > div'],
    },
  },
  neutral: {
    ...buttonResetStyles,
    '&:focus': {
      ...buttonResetStyles['&:focus'],
    },
    '&:hover': {},
    '&:active:not(:disabled)': {},
    '&:disabled': {},
    '& > div': {
      ...base['& > div'],
    },
    '& > svg': {
      ...base['& > svg'],
      m: 0,
    },
  },
  transparent: {
    ...base,
    color: WHITE,
    background: 'transparent',
    '&:disabled': {
      ...base['&:disabled'],
      color: GREY_50,
    },
    '& > div': {
      ...base['& > div'],
      transition: 'border-color 0.1s ease',
      borderBottom: '1px solid',
      borderColor: 'transparent',
    },
    '&:hover': {
      ...base['&:hover'],
      '& > div': {
        borderColor: WHITE,
      },
    },
  },
  tileControl: {
    ...buttonResetStyles,
    ...base,
    color: WHITE,
    height: TILE_CONTROL_BUTTON_SIZE,
    width: TILE_CONTROL_BUTTON_SIZE,
    borderRadius: '2.4rem',
    pointerEvents: 'auto',
    backdropFilter: 'blur(3rem)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    mb: '1rem',
    '& svg': {
      fill: WHITE,
      height: '2.4rem',
      width: '2.4rem',
      overflow: 'visible',
    },
  },
  controlBar: {
    ...controlBarBase,
  },
  controlBarNoBackground: {
    ...controlBarBase,
    backgroundColor: 'transparent',
  },
  controlBarActive: {
    ...controlBarBase,
    bg: TEAL_TRUE,
    boxShadow: `0 0.5rem 1.5rem ${ColorsEnum.TEAL_DARK.withOpacity(0.6)}`,
    '&:focus-visible': {
      ...controlBarBase['&:focus-visible'],
      borderColor: WHITE,
    },
  },
} as const;

export type ButtonVariantsLong = `buttons.${keyof typeof buttonVariantStyles}`;

/**
 * For use in the ButtonComponent props.
 */
export type ButtonVariantsShort = keyof typeof buttonVariantStyles;

export default buttonVariantStyles;
