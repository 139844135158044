import { LoginState } from 'store/types';

export type AuthStateTypes = 'anon' | 'noJWT' | 'noUser' | 'expired' | 'badLivelyToken' | 'valid' | 'error';

const isJWTExpired = (exp: number) => exp < Date.now() / 1000;

const isLivelyTokenExpired = (livelyTokenExp: string) => Date.parse(livelyTokenExp) < Date.now();

export type DetermineAuthStateArgs = Pick<LoginState, 'token' | 'livelyToken' | 'livelyTokenExp' | 'user'>;

/**
 * Given a user's JWT, livelyToken, and user object, determines the current state of the user
 * for use in the useSyncAuthState.tsx hook.
 *
 * Exists as independent function here for ease of testing.
 */
export default function determineAuthState({
  token, livelyToken, livelyTokenExp, user,
}: DetermineAuthStateArgs): AuthStateTypes {
  // validate input
  if ((token !== null && typeof token !== 'string')
    || (livelyToken !== null && typeof livelyToken !== 'string')
    || (livelyTokenExp !== null && typeof livelyTokenExp !== 'string')
    || (!user && user !== null)
    || (user && typeof user.exp !== 'number')) {
    return 'error';
  }
  if (!token && !user && !livelyToken && !livelyTokenExp) {
    /* this user has no authentication credentials whatsoever and is an anonymous user */
    return 'anon';
  }
  if (!token) {
    /* without a JWT, it is impossible to fetch the user's information or fetch a livelyToken.
    Logging out is the only option here, since it is not currently possible to fetch a new JWT */
    return 'noJWT';
  }
  if (!user) {
    /* JWT exists, but user is null--we can't verify the JWT is not expired without the
    user object, but we can still try to use it to fetch the user's info */
    return 'noUser';
  } if (isJWTExpired(user.exp)) {
    // JWT and user exist, but the the user/JWT is expired
    return 'expired';
  } if (!livelyToken || !livelyTokenExp || isLivelyTokenExpired(livelyTokenExp)) {
    // user and JWT are valid, but livelyToken is invalid, use the JWT to fetch a new livelyToken
    return 'badLivelyToken';
  }
  // user, jwt, and livelyToken are all valid
  return 'valid';
}
