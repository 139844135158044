import {
  getCurrentUserGroupId, getIsCurrentUserOwner, getJoinAudioId, getOwnerId,
} from 'selectors';
import { BreakoutGroupsState, Player } from 'store/types';

export interface DeterminePlayerPlayPauseArgs {
  isLaunched: BreakoutGroupsState['isLaunched'],
  player: Player | undefined,
  playerUserId: string,
  ownerId: ReturnType<typeof getOwnerId>,
  isOwner: ReturnType<typeof getIsCurrentUserOwner>,
  currentUserGroupId: ReturnType<typeof getCurrentUserGroupId>,
  currentGroupParticipants: string[],
  joinAudioGroupId: ReturnType<typeof getJoinAudioId>
  joinAudioUsers: string[],
}
export interface DeterminePlayerPlaySuccessOrError {
  playerUserId: string,
  error: boolean,
  pause: boolean,
  mute: boolean,
  info: string[],
}

export interface DeterminePlayerPlayPauseNoop {
  playerUserId: string,
  error: boolean,
  info: string[],
}

export type DeterminePlayerPlayPauseResult = DeterminePlayerPlaySuccessOrError | DeterminePlayerPlayPauseNoop;

export const resultIsNoop = (result: DeterminePlayerPlayPauseResult): result is DeterminePlayerPlayPauseNoop => (
  !('pause' in result) && !('mute' in result) && result.error === false
);

export const resultIsSuccessOrError = (result: DeterminePlayerPlayPauseResult): result is DeterminePlayerPlaySuccessOrError => (
  'pause' in result && 'mute' in result
);

/**
 * Provides information about the state (for testing and debug purposes).
 */
export enum PlayerPlayPauseInfoEnum {
  // Prerequisites for determining state:
  LAUNCHED = 'Breakout groups are launched',
  NOT_LAUNCHED = 'Breakout groups are not launched',

  PLAYER_DEFINED = 'Player is defined',
  PLAYER_UNDEFINED = 'Noop: Player is not defined',

  // Current user states:
  IS_OWNER = 'Current user is the owner',
  IS_PARTICIPANT = 'Current user is a participant',

  IN_GROUP = 'Current user is in a group',
  NOT_IN_GROUP = 'Current is not in a breakout group',

  // Player states:
  PLAYER_IS_OWNER = 'Player is the owner',
  PLAYER_IS_PARTICIPANT = 'Player is a participant',

  PLAYER_IN_USERS_GROUP = 'Player is in the current user\'s group',
  PLAYER_NOT_IN_USERS_GROUP = 'Player is not in the current user\'s group',

  PLAYER_IN_CONNECTED_GROUP = 'Player is in the group to which the current user (owner) is connected',
  PLAYER_NOT_IN_CONNECTED_GROUP = 'Player is not in the group to which the current user (owner) is connected',

  PLAYER_CONNECTED_OR_IN_GROUP = 'Player (owner) is either connected to the current user\'s group or is in the current user\'s group',
  PLAYER_NOT_CONNECTED_AND_NOT_IN_GROUP = 'Player (owner) is not connected to the current user\'s group and is not in the current user\'s group',

  // Errors:
  INVALID_STATE = 'Invalid state: Current is not in a breakout group.',
}

/**
 * When breakout groups are launched, this function determines which state a player's audio
 * and video need to be in in relation to the current user: i.e. audio muted/playing, video paused/playing.
 */
export default function determinePlayerPlayPause({
  isLaunched,
  player,
  playerUserId,
  ownerId,
  isOwner: currentUserIsOwner,
  currentUserGroupId,
  currentGroupParticipants,
  joinAudioGroupId,
  joinAudioUsers,
}: DeterminePlayerPlayPauseArgs): DeterminePlayerPlayPauseResult {
  const isInAGroup = !!currentUserGroupId;
  const playerIsInCurrentUsersGroup = currentGroupParticipants.includes(playerUserId);
  const playerIsInConnectedAudioGroup = joinAudioUsers.includes(playerUserId);
  const playerIsOwner = playerUserId === ownerId;
  const ownerIsConnectedToCurrentUsersGroup = joinAudioGroupId === currentUserGroupId;
  const info = [`Determining new player play/pause state for userId ${playerUserId}`];

  // Noop:
  if (!player) {
    info.push(PlayerPlayPauseInfoEnum.PLAYER_UNDEFINED);
    return { info, error: false, playerUserId };
  }
  info.push(PlayerPlayPauseInfoEnum.PLAYER_DEFINED);

  // Groups are not launched:
  if (!isLaunched) {
    info.push(PlayerPlayPauseInfoEnum.NOT_LAUNCHED);
    return {
      pause: false, mute: false, info, error: false, playerUserId,
    };
  }
  info.push(PlayerPlayPauseInfoEnum.LAUNCHED);

  // Owner's perspective:
  if (currentUserIsOwner) {
    info.push(PlayerPlayPauseInfoEnum.IS_OWNER);
    if (isInAGroup) {
      info.push(PlayerPlayPauseInfoEnum.IN_GROUP);
      if (playerIsInCurrentUsersGroup) {
        info.push(PlayerPlayPauseInfoEnum.PLAYER_IN_USERS_GROUP);
        return {
          pause: false, mute: false, info, error: false, playerUserId,
        };
      }
      info.push(PlayerPlayPauseInfoEnum.PLAYER_NOT_IN_USERS_GROUP);
      return {
        info, pause: true, mute: true, error: false, playerUserId,
      };
    }
    info.push(PlayerPlayPauseInfoEnum.NOT_IN_GROUP);
    if (playerIsInConnectedAudioGroup) {
      info.push(PlayerPlayPauseInfoEnum.PLAYER_IN_CONNECTED_GROUP);
      return {
        info, pause: false, mute: false, error: false, playerUserId,
      };
    }
    info.push(PlayerPlayPauseInfoEnum.PLAYER_NOT_IN_CONNECTED_GROUP);
    return {
      info, pause: false, mute: true, error: false, playerUserId,
    };
  }

  // Participant's perspective:
  info.push(PlayerPlayPauseInfoEnum.IS_PARTICIPANT);
  if (isInAGroup) {
    info.push(PlayerPlayPauseInfoEnum.IN_GROUP);
    if (playerIsOwner) {
      info.push(PlayerPlayPauseInfoEnum.PLAYER_IS_OWNER);
      if (ownerIsConnectedToCurrentUsersGroup || playerIsInCurrentUsersGroup) {
        info.push(PlayerPlayPauseInfoEnum.PLAYER_CONNECTED_OR_IN_GROUP);
        return {
          info, pause: false, mute: false, error: false, playerUserId,
        };
      }
      info.push(PlayerPlayPauseInfoEnum.PLAYER_NOT_CONNECTED_AND_NOT_IN_GROUP);
      return {
        info, pause: false, mute: true, error: false, playerUserId,
      };
    }
    info.push(PlayerPlayPauseInfoEnum.PLAYER_IS_PARTICIPANT);
    if (playerIsInCurrentUsersGroup) {
      info.push(PlayerPlayPauseInfoEnum.PLAYER_IN_USERS_GROUP);
      return {
        info, pause: false, mute: false, error: false, playerUserId,
      };
    }
    info.push(PlayerPlayPauseInfoEnum.PLAYER_NOT_IN_USERS_GROUP);
    return {
      info, pause: true, mute: true, error: false, playerUserId,
    };
  }

  /** Invalid state: If an error occurs, to be on the safe side, we should disable all audio/video so
   * that the user is not able to listen in on any conversations without the group members being aware */
  info.push(PlayerPlayPauseInfoEnum.INVALID_STATE);
  return {
    info, error: true, mute: true, pause: true, playerUserId,
  };
}
