import { push } from 'connected-react-router';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import logger from 'utils/logger';
import request from 'utils/request';
import { MakeActionType } from 'utils/typeUtils';
import { AppThunkAction, Nudge } from 'store/types';
import { getMessageFromError, getStatusCodeFromError } from 'utils/errorUtils';
import RouteEnum from 'utils/routeEnum';
import { logout } from './loginActions';

export const RESET_ROOM = 'RESET_ROOM' as const;
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE' as const;
export const SET_JOIN_ROOM_ERROR = 'SET_JOIN_ROOM_ERROR' as const;
export const SET_ROOM_ERROR = 'SET_ROOM_ERROR' as const;
export const LOGOUT = 'LOGOUT' as const;
export const SET_BREAKOUT_GROUP_ERROR = 'SET_BREAKOUT_GROUP_ERROR' as const;
export const SET_LAUNCH_GROUPS_ERROR = 'SET_LAUNCH_GROUPS_ERROR' as const;
export const SET_TIMEOUT_ERROR = 'SET_TIMEOUT_ERROR' as const;
export const SET_DEVICE_ERROR = 'SET_DEVICE_ERROR' as const;
export const SET_DISABLE_ROOM_ACTIONS = 'SET_DISABLE_ROOM_ACTIONS' as const;
export const SET_ATTENDANCE_ERROR = 'SET_ATTENDANCE_ERROR' as const;
export const ACTIVATE_NUDGE = 'nudgeState/ACTIVATE_NUDGE' as const;
export const DEACTIVATE_NUDGE = 'nudgeState/DEACTIVATE_NUDGE' as const;
export const RESET_ALL_MEDIA = 'RESET_ALL_MEDIA' as const;

export const MALFORMED_TOKEN = 'Malformed token';
export const ECONNABORTED = 'ECONNABORTED';

export const setErrorMessage = (errorPageError: string) => ({
  type: SET_ERROR_MESSAGE,
  payload: {
    errorPageError,
  },
});

export const setJoinError = (joinError: string) => ({
  type: SET_JOIN_ROOM_ERROR,
  payload: {
    joinError,
  },
});

export const setRoomError = (roomError: string) => ({
  type: SET_ROOM_ERROR,
  payload: {
    roomError,
  },
});

export const setBreakoutGroupsError = (breakoutGroupsError: string) => ({
  type: SET_BREAKOUT_GROUP_ERROR,
  payload: { breakoutGroupsError },
});

export const setBreakoutGroupsLaunchError = (launchGroupsError: string) => ({
  type: SET_LAUNCH_GROUPS_ERROR,
  payload: { launchGroupsError },
});

export const setTimeoutErrorMessage = (timeoutError: string) => ({
  type: SET_TIMEOUT_ERROR,
  payload: { timeoutError },
});

export const setDeviceErrorMessage = (deviceError: string) => ({
  type: SET_DEVICE_ERROR,
  payload: { deviceError },
});

export const setDisableRoomActions = (isDisabled: boolean) => ({
  type: SET_DISABLE_ROOM_ACTIONS,
  payload: { isDisabled },
});

export const setAttendanceError = (error: string) => ({
  type: SET_ATTENDANCE_ERROR,
  payload: { error },
});

export const activateNudge = (userId: string, nudge: Nudge) => ({
  type: ACTIVATE_NUDGE,
  payload: { userId, nudge },
});

/** No request made, only deactivates nudge in the redux state */
export const deactivateNudge = (userId: string) => ({
  type: DEACTIVATE_NUDGE,
  payload: { userId },
});

export const resetAllMedia = (resetVideoClient: boolean) => ({
  type: RESET_ALL_MEDIA,
  payload: {
    resetVideoClient,
  },
});

export type SharedAction = MakeActionType<[
  typeof RESET_ROOM,
  typeof LOGOUT,
  typeof setErrorMessage,
  typeof setJoinError,
  typeof setRoomError,
  typeof setBreakoutGroupsError,
  typeof setBreakoutGroupsLaunchError,
  typeof setTimeoutErrorMessage,
  typeof setDeviceErrorMessage,
  typeof setDisableRoomActions,
  typeof setAttendanceError,
  typeof activateNudge,
  typeof deactivateNudge,
  typeof resetAllMedia,
]>

/**
 * Sends the user to the error page and logs to kibana
 */
export function sendUserToErrorPage(): AppThunkAction {
  return (dispatch) => {
    logger.error('User sent to error page');
    dispatch(push('/error'));
  };
}

/**
 * Checks status code of error and logs user out if 400 or 401
 */
interface ErrorInfo {
  error: unknown
  [key: string]: any
}

export function handleError(logMessage: string, errorInfo: ErrorInfo): AppThunkAction {
  return (dispatch) => {
    const { error: originalError, ...extraInfo } = errorInfo;
    const axiosError = 'isAxiosError' in (originalError as AxiosError) ? originalError as AxiosError : null;
    const statusCode = getStatusCodeFromError(axiosError);
    const message = getMessageFromError(axiosError);
    const dataMessage = axiosError?.response?.data?.message;

    const logInfo = {
      ...extraInfo,
      error: axiosError?.toJSON() as Record<string, string>,
      statusCode,
      dataMessage,
      logMessage,
      message,
      url: axiosError?.config?.url,
      aggregates: statusCode ? { statusCode } : undefined,
    };

    if (statusCode === 400) {
      if (dataMessage === MALFORMED_TOKEN) {
        // redirect to the session-expired page instead of the error page
        logger.warn('Logging out because malformed token', logInfo);
        dispatch(logout({ redirect: RouteEnum.SESSION_EXPIRED }));
      } else {
        logger.error(logMessage, logInfo);
      }
    } else if (statusCode === 401) {
      // redirect to the session-expired page instead of the error page
      logger.warn('Logging out because session expired', logInfo);
      dispatch(logout({ redirect: RouteEnum.SESSION_EXPIRED }));
    } else if (statusCode === 422 || statusCode === 404) {
      // 422 - duplicate name or similar error
      // 404 - wrong username/password or similar error
      logger.warn(logMessage, logInfo);
    } else if (statusCode === 413) {
      logger.error(logMessage, logInfo);
    } else if (statusCode === 502 || statusCode === 503) {
      // 502 and 503 errors happen if CC Service needs to restart for some reason

      logger.error(logMessage, logInfo);

      // disable until CC Service restarts
      dispatch(setDisableRoomActions(true));
      dispatch(setErrorMessage('Hold tight... Reconnecting'));
    } else if (statusCode === 504) {
      logger.error(logMessage, logInfo);
      dispatch(setTimeoutErrorMessage('Your request has timed out'));
    } else if (typeof statusCode === 'number') {
      // Any other error with a numbered status code
      // send to error page with a message
      logger.error(logMessage, logInfo);
      const errorMessage = `${logMessage}${statusCode ? `, error code ${statusCode}` : ''}`;
      dispatch(setErrorMessage(errorMessage));
      dispatch(sendUserToErrorPage());
    } else {
      // Any errors that do not have a status code
      logger.info('No status code');
      if (axiosError && axiosError.code === ECONNABORTED) {
        // Axios aborted because timeout
        logger.error(logMessage, {
          ...logInfo,
          code: ECONNABORTED,
        });
        dispatch(setTimeoutErrorMessage('Your request has timed out'));
      } else {
        dispatch(setErrorMessage('Something went wrong'));
        logger.error(logMessage, { ...logInfo, originalError } as any);
      }
    }
  };
}

/**
 * Wrapper for sending requests to the backend
 * It first checks to make sure isDisabled is false
 * isDisabled can switch to true if the backend goes down and the pods
 * are restarting. When this happens, we wait until the room websocket
 * reconnects to change isDisabled back to false
 */
export function roomRequest<Response = unknown>(options: AxiosRequestConfig): AppThunkAction<Promise<null | AxiosResponse<Response>>> {
  return async (_dispatch, getState) => {
    const { isDisabled } = getState().roomState;
    if (isDisabled) {
      logger.warn('Requests are disabled because of 502/503 response from CC Service', options as any);
      return null;
    }
    return request<Response>(options);
  };
}
