/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import SwitchLarge, { SwitchLargeProps } from 'components/ui/SwitchLarge';
import { push } from 'connected-react-router';
import { LoginViewEnum } from 'hooks/useLoginContext';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CSS } from 'types/css';
import RouteEnum from 'utils/routeEnum';

const switchStyles: CSS = {
  mx: 'auto',
  mb: '6rem',
};

const switchLabelStyles: CSS = {
  maxWidth: '100%',
  minWidth: '8rem',
  textAlign: 'center',
};

interface LoginSignupSwitchProps extends Omit<
  SwitchLargeProps,
  'onChange' | 'checked' | 'ariaLabel'
  | 'ref' | 'labelLeft' | 'labelRight'
> {
  isLogin: boolean,
  singlePage: boolean,
  setLoginView: (loginViewEnum: LoginViewEnum) => void,
}

/**
 * Handles redirecting/changing views when the user clicks
 * between signing up and logging in.
 */
export default function LoginSignupSwitch({
  isLogin, singlePage, setLoginView, ...rest
}: LoginSignupSwitchProps) {
  const dispatch = useDispatch();
  const handleSwitchClick = useCallback(() => {
    if (isLogin) {
      if (singlePage) setLoginView(LoginViewEnum.SIGNUP);
      else dispatch(push(RouteEnum.SIGN_UP.encodePath()));
    } else if (singlePage) setLoginView(LoginViewEnum.LOGIN);
    else dispatch(push(RouteEnum.HOME.encodePath()));
  }, [isLogin, singlePage, setLoginView, dispatch]);

  return (
    <SwitchLarge
      variant="light"
      onChange={handleSwitchClick}
      checked={!isLogin}
      labelLeft={<span sx={switchLabelStyles}>Log in</span>}
      labelRight={<span sx={switchLabelStyles}>Sign up</span>}
      ariaLabel={`Go to ${isLogin ? 'Sign up' : 'Log in'}`}
      componentStyles={switchStyles}
      {...rest}
    />
  );
}
