import produce from 'immer';
import { IcebreakerState } from 'store/types';
import {
  IcebreakerAction,
  SET_QUESTION_TYPE,
  SET_QUESTION_LABEL,
  ADD_ANSWER,
  SET_ICEBREAKER_LOADING,
  SET_ICEBREAKER_OPEN,
  SET_CORRECT_ANSWER,
  UPDATE_ANSWER,
  RESET_ICEBREAKER_CREATION,
  ADD_ACTIVE_ICEBREAKER_RESULT,
  ADD_ANSWERED_ICEBREAKER,
  SET_RESULTS_DISMISSED,
  RESET_ACTIVE_RESULTS,
  TOGGLE_QUESTION_ANONYMOUS,
} from 'actions/icebreakerActions';

const initialMultipleChoiceState = [
  { label: '', value: 'A', correct: false },
  { label: '', value: 'B', correct: false },
];

const initialTrueFalseState = [
  { label: 'True', value: 'True', correct: false },
  { label: 'False', value: 'False', correct: false },
];

export const initialState: IcebreakerState = {
  isIcebreakerOpen: false,
  loading: false,
  question: {
    label: '',
    questionType: 'multipleChoice',
    anonymousAnswers: false,
    answerCharLimit: null,
    answers: initialMultipleChoiceState,
  },
  activeResults: [],
  answeredIcebreakers: [],
  activeCorrectAnswer: '',
  resultsDismissed: true,
  shortAnswer: '',
};

const reducer = produce((draft: IcebreakerState, action: IcebreakerAction) => {
  switch (action.type) {
    case SET_QUESTION_TYPE: {
      const { questionType } = action.payload;
      draft.question.questionType = questionType;
      if (questionType === 'multipleChoice') {
        draft.question.answers = initialMultipleChoiceState;
      } else if (questionType === 'trueFalse') {
        draft.question.answers = initialTrueFalseState;
      } else if (questionType === 'shortAnswer') {
        draft.question.answers = [];
      }
      break;
    }
    case SET_QUESTION_LABEL:
      draft.question.label = action.payload.label;
      break;
    case TOGGLE_QUESTION_ANONYMOUS:
      draft.question.anonymousAnswers = !draft.question.anonymousAnswers;
      break;
    case SET_ICEBREAKER_LOADING:
      draft.loading = action.payload.loading;
      break;
    case ADD_ANSWER:
      if (draft.question.answers) {
        draft.question.answers.push(action.payload.answer);
      }
      break;
    case UPDATE_ANSWER: {
      const { answerLabel, index } = action.payload;
      if (draft.question.answers && draft.question.answers[index]) {
        draft.question.answers[index].label = answerLabel;
      }
      break;
    }
    case SET_ICEBREAKER_OPEN:
      draft.isIcebreakerOpen = action.payload.isIcebreakerOpen;
      break;
    case SET_CORRECT_ANSWER:
      if (draft.question.answers && draft.question.answers[action.payload.correctIndex]) {
        draft.question.answers.forEach((ans) => {
          ans.correct = false;
        });
        draft.question.answers[action.payload.correctIndex].correct = action.payload.isCorrect;
        draft.activeCorrectAnswer = action.payload.value;
      }
      break;
    case RESET_ICEBREAKER_CREATION:
      draft.question = {
        label: '',
        questionType: 'multipleChoice',
        anonymousAnswers: false,
        answerCharLimit: null,
        answers: initialMultipleChoiceState,
      };
      break;
    case ADD_ACTIVE_ICEBREAKER_RESULT:
      draft.activeResults.push(action.payload.activeResult);
      break;
    case ADD_ANSWERED_ICEBREAKER:
      draft.answeredIcebreakers.push(action.payload.id);
      break;
    case SET_RESULTS_DISMISSED:
      draft.resultsDismissed = action.payload.resultsDismissed;
      break;
    case RESET_ACTIVE_RESULTS:
      draft.activeResults = [];
      break;
    default:
      break;
  }
}, initialState);

export default reducer;
