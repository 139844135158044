import { cleanUpMediaStreamControllers } from 'actions/encoderActions';
import { useEffect } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { useLocation } from 'react-router';
import { getIsJoin, getIsRoom } from 'selectors';
import logger from 'utils/logger';

/** Clean up user's media stream controllers when user navigates to a room that is not the Room or Join page.
 * If the user travels from Join → Room or Room → Join, we should persist their media stream controllers in state. */
export default function useCleanUpMediaStreamControllers() {
  const dispatch = useDispatch();
  const location = useLocation();
  const store = useStore();

  useEffect(() => {
    const state = store.getState();
    const isRoom = getIsRoom(state);
    const isJoin = getIsJoin(state);
    const { videoMediaStreamController, filterMediaStreamController } = state.encoderState;
    if (!isRoom && !isJoin && (videoMediaStreamController || filterMediaStreamController)) {
      const reason = 'user moved to a route that is not Join or Room';
      logger.debug(`Cleaning up users mediaStreamControllers because ${reason}`);
      dispatch(cleanUpMediaStreamControllers(reason));
    }
  }, [location, store, dispatch]);
}
