import { EnumBase, WithUnknownString } from 'utils/enum';

export interface PermissionStateWrapper {
  name: WithUnknownString<PermissionState>;
  isGranted: boolean;
  isDenied: boolean;
}

/**
 * The PermissionStateEnum wraps the PermissionState "granted" | "denied" | "prompt"
 *
 * @member name: PermissionState or 'UNKNOWN' (string)
 * @member isGranted: Access was granted (boolean)
 * @member isDenied: Access was denied (boolean)
 *
 * Reference: https://developer.mozilla.org/en-US/docs/Web/API/PermissionStatus
 */
export class PermissionStateEnum extends EnumBase implements PermissionStateWrapper {
  public static readonly GRANTED = new PermissionStateEnum('granted', true, false);
  public static readonly DENIED = new PermissionStateEnum('denied', false, true);
  public static readonly PROMPT = new PermissionStateEnum('prompt', false, false);

  public static readonly UNKNOWN = new PermissionStateEnum('UNKNOWN', false, false);

  protected constructor(
    public name: WithUnknownString<PermissionState>,
    public isGranted: boolean,
    public isDenied: boolean,
  ) {
    super();
  }

  public static asArray() {
    return super.asArray() as Array<PermissionStateEnum>;
  }

  public static asObject() {
    return super.asObject() as { [key: string]: PermissionStateEnum };
  }

  public static getByState(state: string) {
    return this.asArray().find(({ name }) => (
      name.toLowerCase().trim() === state.toLowerCase().trim()
    )) || this.UNKNOWN;
  }
}
