import axios from 'axios';
import logger from 'utils/logger';
import SoundPlayerBase from './base';
import SoundFileEnum from '../soundFileEnum';
import { SoundPlayerOptions } from '../soundPlayerOptions';

/**
 * Custom sound player that manages playing sound files and
 * stores audio file data in memory to prevent redundant file requests.
 *
 * Some implementation details for persisting audio buffers are based on this
 * SO answer: https://stackoverflow.com/questions/30433667/cloning-audio-source-without-having-to-download-it-again/30440830#30440830
 */
export default class WebAudioSoundPlayer extends SoundPlayerBase {
  /** Public */
  public readonly isUsingWebAudioApi = true;

  constructor(options?: SoundPlayerOptions) {
    super(options);

    this.ctx = new AudioContext();
    this.audioBufferPool = {};
    this.gainNodePool = {};
    this.bufferSourceNodes = {};
  }

  public async playAudioFile(sound: SoundFileEnum, options?: { loop?: boolean, forceReload?: boolean }) {
    const { loop = false, forceReload = false } = options || { loop: false, forceReload: false };
    await this.playAudioBuffer(sound, await this.getAudioBuffer(sound, forceReload), loop);
  }

  public async stop(sound: SoundFileEnum) {
    try {
      this.getBufferSourceNode(sound)?.stop();
    } catch (err) {
      logger.warn('Error stopping sound', { err, sound: sound.keyName } as any);
    }
  }

  public async load(sound: SoundFileEnum, options: { forceReload?: boolean } = { forceReload: false }) {
    try {
      await this.getAudioBuffer(sound, !!options.forceReload);
    } catch (err) {
      logger.warn('Error loading sound', { err, sound: sound.keyName } as any);
    }
  }

  public isSoundPlaying(sound: SoundFileEnum) {
    return !!this.bufferSourceNodes[sound.keyName];
  }

  public clearStoredAudioData() {
    this.audioBufferPool = {};
    this.gainNodePool = {};
  }

  /** Implementation */

  /** AudioContext is a customarily long-lived obj necessary handling web audio */
  private ctx: AudioContext;
  /** A GainNode is held in memory for each sound for individual volume configuration */
  private gainNodePool: { [soundKeyName: string]: GainNode | undefined };
  /** Each AudioBuffer is playable audio data held in memory from each sound file */
  private audioBufferPool: { [soundKeyName: string]: AudioBuffer | undefined };
  /** Needed in order to stop a playing sound */
  private bufferSourceNodes: { [soundKeyName: string]: AudioBufferSourceNode | undefined};

  /** Get AudioBuffer from pool or fetch and save new buffer */
  private async getAudioBuffer(sound: SoundFileEnum, forceReload: boolean) {
    let buffer = this.audioBufferPool[sound.keyName];
    if (!buffer || forceReload) {
      buffer = await this.fetchAudioBuffer(sound);
    }
    return buffer;
  }

  /** Persist AudioBuffer in pool */
  private saveAudioBuffer(sound: SoundFileEnum, buffer: AudioBuffer) {
    this.audioBufferPool[sound.keyName] = buffer;
  }

  /** This is what actually causes the audio to play in the browser */
  private playAudioBuffer(sound: SoundFileEnum, buffer: AudioBuffer, loop: boolean) {
    const source = this.ctx.createBufferSource();
    source.buffer = buffer;
    source.connect(this.getGainNode(sound));
    source.loop = loop;
    source.start(0);
    this.emit('soundPlayed', sound);
    source.onended = () => {
      this.emit('soundEnded', sound);
      delete this.bufferSourceNodes[sound.keyName];
    };
    this.bufferSourceNodes[sound.keyName] = source;
  }

  /** Returns existing GainNode for sound or creates new one
   * and ensures gain is set to correct level based on options */
  private getGainNode(sound: SoundFileEnum) {
    let node = this.gainNodePool[sound.keyName];
    if (!node) {
      node = this.ctx.createGain();
      node.connect(this.ctx.destination);
      this.gainNodePool[sound.keyName] = node;
    }
    node.gain.value = this.getFilePlaybackConfig(sound).gain * this.options.overallGainRatio;
    return node;
  }

  private getBufferSourceNode(sound: SoundFileEnum) {
    return this.bufferSourceNodes[sound.keyName];
  }

  /** ArrayBuffer from GET response needs to be converted to AudioBuffer */
  private decodeBuffer(data: ArrayBuffer): Promise<AudioBuffer> {
    return new Promise((res, rej) => {
      // decodeAudioData can return a Promise itself, but that isn't supported by Safari (o'course)
      this.ctx.decodeAudioData(data, (b) => res(b), (e) => {
        logger.warn('Error decoding audio', e as any);
        rej(e);
      });
    });
  }

  /** Fetch, decode and save audio data */
  private async fetchAudioBuffer(sound: SoundFileEnum): Promise<AudioBuffer> {
    const res = await axios.get(sound.file, {
      responseType: 'arraybuffer',
    });

    const buffer = await this.decodeBuffer(res.data);

    this.saveAudioBuffer(sound, buffer);

    return buffer;
  }
}
