import { getHexByTheme } from '../../../common/colorUtils';
import { RenderItemFunc } from '../common';
import { SELECTED_ITEM_COLOR, SELECTED_ITEM_WIDTH } from '../renderItem';

const renderEllipse: RenderItemFunc<'ellipse'> = (item, theme, existing) => {
  const el = existing || document.createElement('div');

  const { size } = item.bounds;

  const strokeColor = item.isSelected ? SELECTED_ITEM_COLOR : getHexByTheme(item.drawData.strokeColor, theme);
  const strokeWidth = item.isSelected ? SELECTED_ITEM_WIDTH : item.drawData.strokeWidth;

  el.style.border = `${strokeWidth}px solid ${strokeColor}`;
  el.style.width = `${size.width}px`;
  el.style.height = `${size.height}px`;

  if (!existing) {
    el.style.borderRadius = '50%';
  }

  return el;
};

export default renderEllipse;
