import {
  blurredBackgroundPseudoStyles, hideScrollBar, modal, modalBorderRadius, modalFooterHeight,
} from 'styles/styles';
import {
  ColorsEnum, GREY_20, GREY_80, WHITE,
} from './colors';

const base = {
  minWidth: '30rem',
  width: '100%',
  zIndex: modal, // !
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  borderRadius: modalBorderRadius,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: 'auto',
  cursor: 'auto',

  /**
   * Styling the modal with `backdropFilter: blur` or any `transform` properties causes `position: fixed`
   * issues for any child elements in the modal, because it turns the Modal into a containing block.
   * This breaks the drag-n-drop functionality, for instance.
   * Use a :before pseudo-selector to style the background instead.
   * @see: https://developer.mozilla.org/en-US/docs/Web/CSS/position#values
   */
  '&:before': {
    ...blurredBackgroundPseudoStyles,
    borderRadius: modalBorderRadius,
  },
} as const;

export const modalVariantStyles = {
  dark: {
    ...base,
    backgroundColor: ColorsEnum.BLACK.withOpacity(0.7),
  },
  light: {
    ...base,
    backgroundColor: ColorsEnum.WHITE.withOpacity(0.8),
  },
  lightWithTealUnderlay: {
    ...base,
    backgroundColor: ColorsEnum.WHITE.withOpacity(0.8),
  },
} as const;

const baseUnderlay = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1050,
  overflow: 'hidden auto',
} as const;

export const modalUnderlayVariantStyles: { [key in keyof typeof modalVariantStyles]: Record<string, unknown> } = {
  dark: {
    ...baseUnderlay,
    backgroundColor: ColorsEnum.BLACK.withOpacity(0.5),
  },
  light: {
    ...baseUnderlay,
    backgroundColor: 'rgba(192, 192, 192, 0.5)', // one-off color
  },
  lightWithTealUnderlay: {
    ...baseUnderlay,
    backgroundColor: ColorsEnum.TEAL_TRUE.withOpacity(0.16),
  },
} as const;

const baseBody = {
  ...hideScrollBar,
  flexGrow: 1,
  px: '3.6rem',
  overflowY: 'scroll',
  zIndex: 0,
  pb: modalFooterHeight,
  variant: 'text.body',
};

export const modalBodyVariantStyles: { [key in keyof typeof modalVariantStyles]: Record<string, unknown> } = {
  dark: {
    ...baseBody,
    color: GREY_20,
  },
  light: {
    ...baseBody,
    color: GREY_80,
  },
  lightWithTealUnderlay: {
    ...baseBody,
    color: GREY_80,
  },
} as const;

const baseTitle = {
  borderRadius: '2.4rem 2.4rem 0 0',
  position: 'relative',
  height: 'fit-content',
  minHeight: '8.8rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  py: '2.4rem',
  px: '3.6rem',
  '& h2': {
    variant: 'text.h2',
  },
  '& svg': {
    height: '2rem',
    width: '2rem',
  },
};

export const modalTitleVariantStyles: { [key in keyof typeof modalVariantStyles]: Record<string, unknown> } = {
  dark: {
    ...baseTitle,
    '& h2': {
      ...baseTitle['& h2'],
      color: GREY_20,
    },
    '& svg': {
      ...baseTitle['& svg'],
      stroke: WHITE,
    },
  },
  light: {
    ...baseTitle,
    '& h2': {
      ...baseTitle['& h2'],
      color: GREY_80,
    },
    '& svg': {
      ...baseTitle['& svg'],
      stroke: GREY_80,
    },
  },
  lightWithTealUnderlay: {
    ...baseTitle,
    '& h2': {
      ...baseTitle['& h2'],
      color: GREY_80,
    },
    '& svg': {
      ...baseTitle['& svg'],
      stroke: GREY_80,
    },
  },
};

const MODAL_FOOTER_BORDER_RADIUS = `0 0 ${modalBorderRadius} ${modalBorderRadius}`;
const baseFooter = {
  height: modalFooterHeight,
  p: '2rem',
  width: '100%',
  borderRadius: MODAL_FOOTER_BORDER_RADIUS,
  position: 'absolute',
  bottom: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  zIndex: 1,

  '&:before': {
    ...blurredBackgroundPseudoStyles,
    borderRadius: MODAL_FOOTER_BORDER_RADIUS,
  },

  /* allows clicks to pass "through" the footer element when
  scrolling but also allows any of the footer's children to be
  interacted with like normal */
  pointerEvents: 'none',
  '& *': {
    pointerEvents: 'initial',
  },
} as const;

export const modalFooterVariantStyles: { [key in keyof typeof modalVariantStyles]: Record<string, unknown> } = {
  dark: {
    ...baseFooter,
    boxShadow: `0px -3px 15px ${ColorsEnum.BLACK.withOpacity(0.7)}`,
    '&:before': {
      ...baseFooter['&:before'],
      bg: ColorsEnum.BLACK.withOpacity(0.7),
    },
  },
  light: {
    ...baseFooter,
    boxShadow: `0px -3px 15px ${ColorsEnum.BLACK.withOpacity(0.1)}`,
    '&:before': {
      ...baseFooter['&:before'],
      bg: ColorsEnum.WHITE.withOpacity(0.7),
    },
  },
  lightWithTealUnderlay: {
    ...baseFooter,
    boxShadow: `0px -3px 15px ${ColorsEnum.BLACK.withOpacity(0.1)}`,
    '&:before': {
      ...baseFooter['&:before'],
      bg: ColorsEnum.WHITE.withOpacity(0.7),
    },
  },
};

export type ModalVariantsShort = keyof typeof modalVariantStyles

export type ModalVariantsLong = `modal.${ModalVariantsShort}`
export type ModalUnderlayVariantsLong = `modalUnderlay.${ModalVariantsShort}`
export type ModalBodyVariantsLong = `modalBody.${ModalVariantsShort}`
export type ModalTitleVariantsLong = `modalTitle.${ModalVariantsShort}`
