import { EnumBase, WithUnknownString } from 'utils/enum';

export interface PermissionType {
  name: WithUnknownString<PermissionName>;
  displayName: string;
  provisional: boolean;
}

/**
 * The PermissionEnum wraps the enum of possible PermissionName's
 *
 * @member name: PermissionName or 'UNKNOWN' (string)
 * @member displayName: User friendly name (string)
 * @member provisional: Is a provisional permission (boolean)
 *
 * Reference: https://w3c.github.io/permissions/#enumdef-permissionname
 */
export class PermissionEnum extends EnumBase implements PermissionType {
  public static readonly GEOLOCATION = new PermissionEnum('geolocation', 'Geolocation');
  public static readonly NOTIFICATIONS = new PermissionEnum('notifications', 'Notifications');
  public static readonly PUSH = new PermissionEnum('push', 'Push');
  public static readonly PERSISTENT_STORAGE = new PermissionEnum('persistent-storage', 'Persistant Storage');
  public static readonly NFC = new PermissionEnum('nfc' as PermissionName, 'NFC');
  public static readonly UNKNOWN = new PermissionEnum('UNKNOWN', 'UNKNOWN');

  // TS doesn't recognize some of these permissions yet
  public static readonly MIDI = new PermissionEnum('midi' as PermissionName, 'MIDI');
  public static readonly CAMERA = new PermissionEnum('camera' as PermissionName, 'Camera');
  public static readonly MICROPHONE = new PermissionEnum('microphone' as PermissionName, 'Microphone');
  public static readonly SPEAKER_SELECTION = new PermissionEnum('speaker-selection' as PermissionName, 'Speaker Selection');
  public static readonly DEVICE_INFO = new PermissionEnum('device-info' as PermissionName, 'Device Info');
  public static readonly BACKGROUND_FETCH = new PermissionEnum('background-fetch' as PermissionName, 'Background Fetch');
  public static readonly BACKGROUND_SYNC = new PermissionEnum('background-sync' as PermissionName, 'Background Sync');
  public static readonly BLUETOOTH = new PermissionEnum('bluetooth' as PermissionName, 'Bluetooth');
  public static readonly AMBIENT_LIGHT_SENSOR = new PermissionEnum('ambient-light-sensor' as PermissionName, 'Ambient Light Sensor');
  public static readonly ACCELEROMETER = new PermissionEnum('accelerometer' as PermissionName, 'Accelerometer', true);
  public static readonly GYROSCOPE = new PermissionEnum('gyroscope' as PermissionName, 'Gyroscope', true);
  public static readonly MAGNETOMETER = new PermissionEnum('magnetometer' as PermissionName, 'Magnetometer', true);
  public static readonly CLIPBOARD_READ = new PermissionEnum('clipboard-read' as PermissionName, 'Clipboard: Read');
  public static readonly CLIPBOARD_WRITE = new PermissionEnum('clipboard-write' as PermissionName, 'Clipboard: Write');
  public static readonly DISPLAY_CAPTURE = new PermissionEnum('display-capture' as PermissionName, 'Display Capture');

  protected constructor(
    public name: WithUnknownString<PermissionName>,
    public displayName: string,
    public provisional = false,
  ) {
    super();
  }

  public static asArray() {
    return super.asArray() as Array<PermissionEnum>;
  }

  public static asObject() {
    return super.asObject() as { [key: string]: PermissionEnum };
  }

  public static getByName(permName: PermissionName) {
    return this.asArray().find(({ name }) => (
      name.toLowerCase().trim() === permName.toLowerCase().trim()
    )) || this.UNKNOWN;
  }
}
