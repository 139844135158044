import { Filters } from 'filters';
import { createSelector } from 'reselect';
import { StoreState } from 'store/types';

export const getFilter = (state: StoreState) => state.filterState.filter;

export const getFilterSrcVideo = (state: StoreState) => state.filterState.srcVideo;

export const getFilterDestCanvas = (state: StoreState) => state.filterState.destCanvas;

export const getIsFiltering = (state: StoreState) => state.filterState.isFiltering;

export const getIsFilterLoading = (state: StoreState) => state.filterState.isFilterLoading;

export const getCurrentFilterKey = (state: StoreState) => state.filterState.currentFilterKey;

export const getEnabledPublicFilters = (state: StoreState) => state.filterState.enabledPublicFilters;

export const getCannyEdgeDetectionThreshold = (state: StoreState) => state.filterState.cannyEdgeDetectionThreshold;

export const getTensorFlowBackend = (state: StoreState) => state.filterState.tensorFlowBackend;

export const getEnableFaceDetection = (state: StoreState) => state.filterState.enableFaceDetection;

export const getMaxFrameRate = (state: StoreState) => state.filterState.maxFrameRate;

export const getPredictionInterval = (state: StoreState) => state.filterState.predictionInterval;

export const getCroppedCanvasSize = (state: StoreState) => state.filterState.croppedCanvasSize;

/** Makes a selector that checks if the currently selected filter matches 1 or more provided filter types */
export const makeGetIsFilter = (filterQuery: Filters | Filters[]) => createSelector(
  getCurrentFilterKey,
  (currentFilterKey) => {
    if (Array.isArray(filterQuery)) {
      return filterQuery.map((filter) => filter.keyOf()).includes(currentFilterKey);
    }
    return filterQuery.keyOf() === currentFilterKey;
  },
);

export const getIsPassThroughFilter = makeGetIsFilter([Filters.PASS_THROUGH, Filters.PASS_THROUGH_2]);

export const getIsCannyEdgeDetection = makeGetIsFilter([Filters.CANNY_EDGE_DETECTION_LIGHT_2, Filters.CANNY_EDGE_DETECTION_DARK_2]);
