interface playerState {
  userId: string,
  videoOff?: boolean | null,
  audioOff?: boolean | null,
  displayName?: string | null,
}

// Adds __QA__ to the window object which exposes player state for the QA team
export function initQAObject() {
  window.__QA__ = {
    players: {},
  };
}

// Add updated state for a player
export function setPlayerWindowState(newState:playerState) {
  if (!window.__QA__) {
    return;
  }
  const { userId } = newState;
  if (!userId) {
    return;
  }
  const prev = window.__QA__.players[userId];

  window.__QA__.players[userId] = {
    ...prev,
    ...newState,
  };
}

// Remove a player when a users leaves the session
export function removePlayerWindowState(userId:string) {
  if (!window.__QA__) {
    return;
  }
  delete window.__QA__.players[userId];
}
