import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import usePrevious from './usePrevious';

/** Keeps track of previous path user went. Does not keep comprehensive stack of paths, only the basic previous path */
export default function useGoBack() {
  const { pathname } = useLocation();
  const prevPathname = usePrevious(pathname);
  const history = useHistory();

  return useCallback((defaultPath?: string) => {
    if (prevPathname && prevPathname !== pathname) history.push(prevPathname);
    else if (defaultPath) history.push(defaultPath);
  }, [prevPathname, pathname, history]);
}
