import { JoinAction, SET_ROOM_NAME_FOR_REMOVED_MODAL, TOGGLE_IS_NEW_DEVICE } from 'actions/joinActions';
import produce from 'immer';
import { JoinState } from 'store/types';

export const initialState: JoinState = {
  roomNameForRemovedModal: '',
  isNewDevice: false,
};

const reducer = produce((draft: JoinState, action: JoinAction) => {
  switch (action.type) {
    case SET_ROOM_NAME_FOR_REMOVED_MODAL:
      draft.roomNameForRemovedModal = action.payload.roomName;
      break;
    case TOGGLE_IS_NEW_DEVICE:
      draft.isNewDevice = action.payload.isNewDevice ?? !draft.isNewDevice;
      break;
    default:
      break;
  }
}, initialState);

export default reducer;
