/* eslint-disable no-nested-ternary */
/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import {
  ComponentProps,
  ComponentPropsWithRef,
  createElement,
  forwardRef, memo, ReactElement, useMemo,
} from 'react';
import useIsKeyboardUser from 'hooks/useIsKeyboardUser';
import FocusRing from 'components/FocusRing/FocusRing';
import { textEllipsis } from 'styles/styles';
import buttonVariantStyles, { ButtonVariantsShort } from 'theme/ui/buttons';
import { CSS } from 'types/css';
import {
  GREY_60, RED_LIGHT, TEAL_TRUE, WHITE, ColorsEnum,
} from 'theme/ui/colors';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { LabeledSvgComponent } from 'store/types';

export interface ButtonProps extends ComponentPropsWithRef<'button'> {
  iconComponent?: ReactElement<ComponentProps<'svg'>, 'svg'> | ((props: ComponentProps<'svg'>) => ReactElement<ComponentProps<'svg'>, 'svg'>),
  iconPosition?: 'left' | 'right',
  variant?: ButtonVariantsShort,
  wide?: boolean,
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(({
  children = null,
  variant = 'primary',
  iconPosition = 'left',
  iconComponent: Icon = null,
  wide = false,
  disabled = false,
  ...rest
}, ref) => {
  const isKeyboardUser = useIsKeyboardUser();
  const styles: CSS = {
    variant: `buttons.${variant}` as `buttons.${ButtonVariantsShort}`,
    cursor: disabled ? 'not-allowed' : 'pointer',
    '& > svg': {
      // hacky, but merges the variant styles with any local styles
      ...(buttonVariantStyles[variant] as any)?.['& > svg'],
      mr: iconPosition === 'left' && children ? '0.8rem' : '0',
      ml: iconPosition === 'right' && children ? '0.8rem' : '0',
    } as CSS,
  };
  if (wide) {
    styles.width = '100%';
  }

  /**
   * Was getting weird errors from the linter here, so used
   * `createElement` manually instead of <Icon />.
   *
   * Accepts either `SvgExample` or `<Svg svg={SvgExample} />` style props.
   * If `SvgExample` is used, `SvgExample` must be type-asserted like so:
   * ```ts
   * SvgExample as unknown as LabeledSvgComponent
   * ```
   *
   * @see {@link LabeledSvgComponent}
   */
  const renderedIcon = useMemo(() => (Icon && (typeof Icon === 'function' || 'render' in Icon)
    ? createElement(Icon, null, null) : Icon), [Icon]);

  return (
    <button type="button" disabled={disabled} sx={styles} ref={ref} {...rest}>
      {renderedIcon && iconPosition === 'left' && renderedIcon}
      {children && <div sx={textEllipsis} data-selenium={typeof children === 'string' ? children : undefined}>{children}</div>}
      {renderedIcon && iconPosition === 'right' && renderedIcon}
      {(isKeyboardUser && !variant.includes('controlBar')) && ( // control bar buttons already have custom focus
        <FocusRing rounded />
      )}
    </button>
  );
});

Button.displayName = 'Button';

export default memo(Button);

/**
 * This is used in the control bar button. An alternate version of it has
 * opacity when disabled
 */
export const getAltStyles = (isAlternate: boolean, muted: boolean): CSS => {
  if (!isAlternate) {
    return {};
  }
  return {
    backgroundColor: muted ? GREY_60 : TEAL_TRUE,
    boxShadow: muted ? `0px 4px 16px ${ColorsEnum.GREY_40.withOpacity(0.6)}` : `0px 4px 16px ${ColorsEnum.TEAL_TRUE.withOpacity(0.6)}`,
    '& svg': {
      fill: muted ? RED_LIGHT : WHITE,
    },
  };
};
