/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import { CSS } from 'types/css';
import useStateIfMounted from 'hooks/useStateIfMounted';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getSoundPlayer } from 'selectors';
import {
  TEAL_100, WHITE, RED_50, GREY_20,
} from 'theme/ui/colors';
import { SoundFileEnum } from 'utils/soundPlayer';

interface AudioDebugTestToneProps {
  sound: SoundFileEnum;
  playingDisabled: boolean;
  setPlayingDisabled: (val: boolean) => void;
}

const styles: CSS = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const buttonStyles: CSS = {
  bg: WHITE,
  borderRadius: '0.5rem',
  width: '5rem',
  border: 'none',
  '&:disabled': {
    bg: GREY_20,
    cursor: 'default',
  },
  '&:focus': {
    outline: 'none',
  },
};

export default function AudioDebugTestTone({
  sound, playingDisabled, setPlayingDisabled,
}: AudioDebugTestToneProps) {
  const soundPlayer = useSelector(getSoundPlayer);
  const [isPlaying, setIsPlaying] = useStateIfMounted(soundPlayer.isSoundPlaying(sound));

  useEffect(() => {
    soundPlayer.load(sound);

    const onPlayed = (soundPlayed: SoundFileEnum) => {
      if (soundPlayed === sound) {
        setIsPlaying(true);
        setPlayingDisabled(true);
      }
    };

    const onEnded = (soundEnded: SoundFileEnum) => {
      if (soundEnded === sound) {
        setIsPlaying(false);
        setPlayingDisabled(false);
      }
    };

    soundPlayer.on('soundPlayed', onPlayed);
    soundPlayer.on('soundEnded', onEnded);

    return () => {
      soundPlayer.stop(sound);
      soundPlayer.off('soundPlayed', onPlayed);
      soundPlayer.off('soundEnded', onEnded);
    };
  }, [sound, setIsPlaying, soundPlayer, setPlayingDisabled]);

  return (
    <div sx={styles}>
      <div>
        <div sx={{ fontWeight: 'bold' }}>
          {sound.displayName}
        </div>
        <div>{sound.description}</div>
      </div>
      <button
        type="button"
        disabled={!isPlaying && playingDisabled}
        sx={{
          ...buttonStyles,
          bg: isPlaying ? RED_50 : TEAL_100,
          color: WHITE,
        }}
        onClick={() => {
          isPlaying ? soundPlayer.stop(sound) : soundPlayer.play(sound, { loop: true });
        }}
      >
        {isPlaying ? 'Stop' : 'Play'}
      </button>
    </div>
  );
}
