import { trackLeaveSessionOnClose } from 'actions/roomActions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

export default function useDetectPageClose() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    const onBeforeUnload = () => {
      if (pathname.match(/room\/.+/)) {
        dispatch(trackLeaveSessionOnClose());
      }
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [dispatch, pathname]);
}
