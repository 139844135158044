import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { fetchUser } from 'actions/loginActions';
import useStateIfMounted from 'hooks/useStateIfMounted';
import { getIsUserValid } from 'selectors';
import useAccountMergeError from './useAccountMergeError';

/**
 * Checks to see if a token has been included as a URL query parameter.
 * If so, it validates the token by fetching the user from CC Service.
 */
function useTokenAuth() {
  const location = useLocation();
  const { token } : {token?: string } = queryString.parse(location.search);
  const [isChecked, setIsChecked] = useStateIfMounted(false);
  const [loginError, setError] = useStateIfMounted<unknown>(null);
  /* user just tried to log into Google from native account--
  "token" here will be the token to merge the accounts, not a JWT */
  const { hasMergeError } = useAccountMergeError();
  const dispatch = useDispatch();
  const isUserValid = useSelector(getIsUserValid);

  // set check complete once user is authenticated or error occurs
  useEffect(() => {
    if (!isChecked && isUserValid) setIsChecked(true);
  }, [isChecked, isUserValid, setIsChecked]);

  useEffect(() => {
    const asyncFetchUser = async () => {
      if (!hasMergeError && token) {
        try {
          dispatch(fetchUser({ token }));
        } catch (error) {
          setError(error);
          setIsChecked(true);
        }
      }
    };
    asyncFetchUser();
  }, [hasMergeError, token, setIsChecked, setError, dispatch]);

  return {
    token, isChecked, loginError,
  };
}

export default useTokenAuth;
