/* eslint-disable import/prefer-default-export */
import React from 'react';
import App from 'App';
import { render } from 'react-dom';
import 'reboot.css/dist/reboot.min.css';
import './index.css';
import setupMockServiceWorker from 'utils/tests/mswWorker';
import { initQAObject } from 'utils/windowQA';

// Mocks CC-service responses while developing if REACT_APP_USE_MSW is true
setupMockServiceWorker();

// Adds __QA__ to the window object which exposes player state for the QA team
initQAObject();

render(<App />, document.getElementById('root'));
