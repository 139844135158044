import produce from 'immer';
import {
  ALL_VIEW_SET_PREV_SESSIONS_LINK,
  AttendanceAction,
  ALL_VIEW_TOGGLE_SESSIONS_LOADING,
  ALL_VIEW_SET_SESSION_ID,
  ALL_VIEW_SET_SESSIONS_FETCHED,
  RESET_ATTENDANCE_STATE,
  ALL_VIEW_SET_SESSIONS,
  ALL_VEW_SET_USER_ID_LABELS,
  ALL_VIEW_SET_SESSION_ID_LABELS,
  ALL_VIEW_SET_SESSIONS_ERROR,
  ALL_VIEW_SET_DETAIL_STATUS,
  ALL_VIEW_SET_CSV_DATA_LOADING,
  USER_VIEW_SET_SESSION_ID_LABELS,
  USER_VIEW_SET_SESSIONS,
  USER_VIEW_SET_SESSIONS_ERROR,
  USER_VIEW_TOGGLE_SESSIONS_LOADING,
  USER_VIEW_SET_SESSIONS_FETCHED,
  USER_VIEW_RESET_STATE,
  USER_VIEW_SET_NAME_DATA,
  USER_VIEW_SET_CSV_DATA_LOADING,
} from 'actions/attendanceActions';
import { AttendanceAllViewState, AttendanceUserViewState, AttendanceState } from 'store/types';

/** Keeps track of users & sessions that have already been logged with malformed data */
export const ALL_VIEW_ERROR_MAP = new Map<string, true>();
export const USER_VIEW_ERROR_MAP = new Map<string, true>();

const initialAllViewState: AttendanceAllViewState = {
  allViewPrevSessionsLink: '',
  allViewSessionsFetched: false,
  allViewSessionsLoading: false,
  allViewSessionsError: '',
  allViewSessionId: '',
  allViewSessions: {},
  allViewUserIdLabels: [],
  allViewSessionIdLabels: [],
  allViewDetailHovered: null,
  allViewDetailFocused: null,
  allViewCSVDataLoading: false,
};

const initialUserViewState: AttendanceUserViewState = {
  userViewPrevSessionsLink: '',
  userViewSessionsFetched: false,
  userViewSessionsLoading: false,
  userViewSessionsError: '',
  userViewSessions: {},
  userViewSessionIdLabels: [],
  userViewCSVDataLoading: false,
  userViewDisplayName: '',
  userViewFirstName: '',
  userViewLastName: '',
};

export const initialState: AttendanceState = {
  ...initialAllViewState,
  ...initialUserViewState,
};

const reducer = produce((draft: AttendanceState, action: AttendanceAction) => {
  switch (action.type) {
    case ALL_VIEW_SET_PREV_SESSIONS_LINK:
      draft.allViewPrevSessionsLink = action.payload.allViewPrevSessionsLink || '';
      break;
    case ALL_VIEW_TOGGLE_SESSIONS_LOADING:
      draft.allViewSessionsLoading = action.payload.loading ?? !draft.allViewSessionsLoading;
      break;
    case ALL_VIEW_SET_SESSIONS_ERROR:
      draft.allViewSessionsError = action.payload.error ?? !draft.allViewSessionsError;
      break;
    case ALL_VIEW_SET_SESSIONS:
      draft.allViewSessions = action.payload.sessions;
      break;
    case ALL_VIEW_SET_SESSION_ID:
      draft.allViewSessionId = action.payload.sessionId;
      break;
    case ALL_VIEW_SET_SESSIONS_FETCHED:
      draft.allViewSessionsFetched = action.payload.fetched;
      break;
    case ALL_VEW_SET_USER_ID_LABELS:
      draft.allViewUserIdLabels = action.payload.userIds;
      break;
    case ALL_VIEW_SET_SESSION_ID_LABELS:
      draft.allViewSessionIdLabels = action.payload.sessionIds;
      break;
    case ALL_VIEW_SET_DETAIL_STATUS: {
      const accessor = action.payload.actionType === 'focused' ? 'allViewDetailFocused' : 'allViewDetailHovered';
      draft[accessor] = action.payload.detailInfo;
      break;
    }
    case ALL_VIEW_SET_CSV_DATA_LOADING:
      draft.allViewCSVDataLoading = action.payload.loading;
      break;

    case USER_VIEW_SET_SESSION_ID_LABELS:
      draft.userViewSessionIdLabels = action.payload.labels;
      break;
    case USER_VIEW_SET_SESSIONS:
      draft.userViewSessions = action.payload.sessions;
      break;
    case USER_VIEW_SET_SESSIONS_ERROR:
      draft.userViewSessionsError = action.payload.error;
      break;
    case USER_VIEW_TOGGLE_SESSIONS_LOADING:
      draft.userViewSessionsLoading = action.payload.loading ?? !draft.userViewSessionsLoading;
      break;
    case USER_VIEW_SET_SESSIONS_FETCHED:
      draft.userViewSessionsFetched = action.payload.fetched;
      break;
    case USER_VIEW_SET_NAME_DATA:
      draft.userViewDisplayName = action.payload.displayName;
      draft.userViewFirstName = action.payload.firstName;
      draft.userViewLastName = action.payload.lastName;
      break;
    case USER_VIEW_SET_CSV_DATA_LOADING:
      draft.userViewCSVDataLoading = action.payload.loading;
      break;
    case USER_VIEW_RESET_STATE:
      Object.assign(draft, initialUserViewState);
      break;

    case RESET_ATTENDANCE_STATE:
      ALL_VIEW_ERROR_MAP.clear();
      USER_VIEW_ERROR_MAP.clear();
      Object.assign(draft, initialState);
      break;
    default:
      break;
  }
}, initialState);

export default reducer;
