import typography from './typography';
import breakpoints from './breakpoints';
import buttons from './buttons';
import radioButtons from './radioButtons';
import {
  modalVariantStyles as modal,
  modalUnderlayVariantStyles as modalUnderlay,
  modalBodyVariantStyles as modalBody,
  modalTitleVariantStyles as modalTitle,
  modalFooterVariantStyles as modalFooter,
} from './modal';

const theme = {
  breakpoints,
  buttons,
  radioButtons,
  modal,
  modalUnderlay,
  modalBody,
  modalTitle,
  modalFooter,
  ...typography,
};

export default theme;
