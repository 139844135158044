import { useEffect } from 'react';
import request from 'utils/request';
import { useSelector } from 'react-redux';
import { getIsUserValid, getUserProfile } from 'selectors';
import useStateIfMounted from 'hooks/useStateIfMounted';
import { GetRoomPermissions } from 'utils/ccService/types';
import useTypedSelector from './useTypedSelector';

export enum OwnerStatusEnum {
  OWNER = 'owner',
  NOT_OWNER = 'notOwner',
  UNKNOWN = 'unknown',
}

/**
 * Occasionally, we must find out if the user is the owner of a room
 * outside of that room's context (which means we can't use the
 * getIsCurrentUserOwner in room state). Sends request to CC Service
 * to find out if the user is the owner of the indicated room.
 */
export default function useIsOwner(roomId: string) {
  const user = useSelector(getUserProfile);
  const userId = user?.userId;
  const isUserValid = useTypedSelector(getIsUserValid);
  const [ownerStatus, setOwnerStatus] = useStateIfMounted<OwnerStatusEnum>(
    isUserValid ? OwnerStatusEnum.UNKNOWN : OwnerStatusEnum.NOT_OWNER,
  );

  /* check ownerStatus initially and then check again if the user's state changes */
  useEffect(() => {
    const fetchIsOwner = async () => {
      // only valid users can possibly be the owner
      if (isUserValid) {
        setOwnerStatus(OwnerStatusEnum.UNKNOWN);
        try {
          const { data } = await request<GetRoomPermissions>({ method: 'GET', url: `/rooms/${roomId}/permissions` });
          setOwnerStatus(data.owner ? OwnerStatusEnum.OWNER : OwnerStatusEnum.NOT_OWNER);
        } catch (error) {
          setOwnerStatus(OwnerStatusEnum.NOT_OWNER);
        }
      } else {
        setOwnerStatus(OwnerStatusEnum.NOT_OWNER);
      }
    };

    fetchIsOwner();
  }, [roomId, userId, isUserValid, setOwnerStatus]);

  return ownerStatus;
}
