/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import {
  ComponentPropsWithoutRef,
} from 'react';
import { ModalVariantsShort } from 'theme/ui/modal';

interface ModalFooterProps extends ComponentPropsWithoutRef<'div'>{
  variant?: ModalVariantsShort,
}

function ModalFooter({ children, variant = 'light', ...rest }: ModalFooterProps) {
  return (
    <div sx={{ variant: `modalFooter.${variant}` }} {...rest}>
      {children}
    </div>
  );
}

export default ModalFooter;
