/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import FocusRing from 'components/FocusRing/FocusRing';
import { ComponentPropsWithoutRef } from 'react';
import { useSelector } from 'react-redux';
import { getIsTablet } from 'selectors';
import { ColorsEnumValue } from 'theme/ui/colors';
import { CSS } from 'types/css';
import ExternalLinkEnum from 'utils/externalLinkEnum';
import Logo from './Logo';
import LogoBeta from './LogoBeta';

const logoLinkStyles: CSS = {
  position: 'relative',
  appearance: 'none',
  width: 'fit-content',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '1.1rem',
  p: 0,
  m: 0,
  '&:focus': {
    outline: 0,
  },
};

interface ChalkCastLogoLinkProps extends ComponentPropsWithoutRef<'a'>{
  fill?: ColorsEnumValue,
  hideLogoNameOnTablet?: boolean,
}

/**
 * Monotone, C CHALKCAST logo that links to the .org website.
 */
export default function LogoLink({ hideLogoNameOnTablet = false, ...rest }: ChalkCastLogoLinkProps) {
  const isTablet = useSelector(getIsTablet);
  return (
    <a
      sx={logoLinkStyles}
      href={ExternalLinkEnum.ORG_SITE}
      target="_blank"
      rel="noopener noreferrer"
      data-selenium="top-nav__chalkcast-logo-link"
      {...rest}
    >
      {/* Site Accessibility Checker flags <Logo /> / <LogoBeta /> if an svg title is given here */}
      {isTablet && hideLogoNameOnTablet ? <Logo wordStyles={{ display: 'none' }} aria-hidden /> : <LogoBeta aria-hidden />}
      <FocusRing as="a" />
    </a>
  );
}
