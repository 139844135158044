import { ReactElement, ReactNode } from 'react';
import { AxiosError } from 'axios';
import {
  AuthUser,
  BreakoutGroupChatMetadata, ChatMetadata, DirectMessageChatMetadata, EveryoneChatMetadata, GoogleUser, TempUser, NativeUser,
} from 'store/types';
import {
  CCServiceGoogleUserData, CCServiceTempUserData, CCServiceNativeUserData,
} from './ccService/types';

/**
 * Given an unknown value, determines whether that element is an HTMLElement.
 *
 * @example
 * Can be used as the argument to a filter function:
 * const anyElements: Element[] = Array.from(divRef.current.children)
 * const onlyHtmlElements: HTMLElement[] = anyElements.filter(isHTMLElement);
 *
 * @example
 * Can be used to narrow a ref's type to HTMLElement:
 * if (isHTMLElement(ref)){
 *    ref.focus();
 * }
 */
export const isHTMLElement = (element: unknown): element is HTMLElement => element instanceof HTMLElement;

/** Like isHTMLElement, but specifically for \<input\> */
export const isHTMLInputElement = (element: unknown): element is HTMLInputElement => element instanceof HTMLInputElement;

/** Like isHTMLElement, but specifically for \<textarea\> */
export const isHTMLTextAreaElement = (element: unknown): element is HTMLTextAreaElement => element instanceof HTMLTextAreaElement;

/**
 * Checks whether a rendered element was created by a specific React Component.
 * A react JSX.Element is just a plain JS object behind the scenes.
 * We can check whether a rendered element came from a particular function by
 * looking at it's `type` property, which, if it is was rendered by that function, will match.
 * See: https://reactjs.org/blog/2015/12/18/react-components-elements-and-instances.html
 *
 * This works with both children and plain rendered elements.
 */
export const isElementFromComponent = <C extends ReactNode>(element: unknown, Component: C)
  : element is (C extends (...args: any[]) => any ? ReactElement<Parameters<C>[0], C> : never) => (
    (element as ReactElement)?.type === Component
  );

export const isEveryoneChatMeta = (meta?: ChatMetadata | null): meta is EveryoneChatMetadata => !meta?.breakoutRoomId && !meta?.recipientUserId;

export const isBreakoutGroupChatMeta = (meta?: ChatMetadata | null): meta is BreakoutGroupChatMetadata => (
  !!meta?.breakoutRoomId && !meta?.recipientUserId
);

export const isDMChatMeta = (meta?: ChatMetadata | null): meta is DirectMessageChatMetadata => !meta?.breakoutRoomId && !!meta?.recipientUserId;

/* These type predicates determine whether a response received
from CC Service is in the shape of a google, native, or guest response
CC Service term === "guest" users, but we call them "temp" users */
export const isGoogleUserData = (data?: unknown): data is CCServiceGoogleUserData => (
  (data as CCServiceGoogleUserData)?.source === 'google'
);

export const isNativeUserData = (data?: unknown): data is CCServiceNativeUserData => (
  (data as CCServiceNativeUserData)?.source === 'native'
);

export const isTempUserData = (data?: unknown): data is CCServiceTempUserData => (
  (data as CCServiceTempUserData)?.source === 'guest'
);

/* These type predicates determine whether a given user object
is in the shape of a google, native, or temp user */
export const isGoogleUser = (user?: unknown): user is GoogleUser => (
  (user as GoogleUser)?.source === 'google'
);

export const isNativeUser = (user?: unknown): user is NativeUser => (
  (user as NativeUser)?.source === 'native'
);

export const isTempUser = (user?: unknown): user is TempUser => (
  (user as TempUser)?.source === 'guest'
);

export const isAuthUser = (user?: unknown): user is AuthUser => (
  isGoogleUser(user) || isNativeUser(user)
);

export const isAxiosError = (error: unknown): error is AxiosError => (
  'isAxiosError' in (error as AxiosError)
);
