import { createSelector } from 'reselect';
import { LoginTypesEnum, StoreState } from 'store/types';

export const getUserProfile = (state: StoreState) => state.loginState.user;

export const getAuthFormError = (state: StoreState) => state.loginState.authFormError;

export const getAuthFormSending = (state: StoreState) => state.loginState.authFormSending;

export const getLivelyTokenString = (state: StoreState) => state.loginState.livelyToken;

export const getLivelyTokenExp = (state: StoreState) => state.loginState.livelyTokenExp;

export const getJWT = (state: StoreState) => state.loginState.token;

export const getJoinedRoom = (state: StoreState) => state.loginState.joinedRoom;

export const getLoginType = (state: StoreState) => state.loginState.loginType;

export const getFetchingUser = (state: StoreState) => state.loginState.fetchingUser;

export const getFetchingTokens = (state: StoreState) => state.loginState.fetchingTokens;

export const getNewDisplayName = (state: StoreState) => state.loginState.participantForm.displayName;

export const getRedirectAfterLoginPath = (state: StoreState) => state.loginState.redirectAfterLoginPath;

export const getCurrentUserId = createSelector(
  getUserProfile,
  (user) => user?.userId,
);

export const getCurrentUserName = createSelector(
  getUserProfile,
  (user) => user?.displayName,
);

export const getIsAuthUser = createSelector(
  getLoginType,
  (loginType) => loginType === LoginTypesEnum.AUTH_USER,
);

export const getIsUserValid = createSelector(
  getLoginType,
  (loginType) => loginType === LoginTypesEnum.AUTH_USER || loginType === LoginTypesEnum.TEMP_USER,
);

export const getIsAuthInTransition = createSelector(
  getAuthFormSending,
  getFetchingUser,
  getFetchingTokens,
  getLoginType,
  (authFormSending, fetchingUser, fetchingTokens, loginType) => (
    authFormSending || fetchingUser || fetchingTokens || loginType === LoginTypesEnum.UNKNOWN
  ),
);
