import { CSS } from 'types/css';
import {
  TEAL_TRUE, WHITE, BLUE_TRUE, PURPLE_TRUE, ORANGE_DARK, RED_DARK,
} from 'theme/ui/colors';

export const baseStyles: CSS = {
  height: '4.4rem',
  width: 'fit-content',
  borderRadius: '10rem',
  display: 'flex',
  p: '1.2rem 2rem',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  position: 'relative',
  transition: 'all .2s ease-in-out',
  boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
  color: WHITE,
  '&:hover': {
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
    transform: 'scale(1.05)',
  },
};

const radioButtonVariantStyles: CSS = {
  primary: {
    ...baseStyles,
    backgroundColor: TEAL_TRUE,
  },
  secondary: {
    ...baseStyles,
    backgroundColor: WHITE,
  },
  blue: {
    ...baseStyles,
    backgroundColor: BLUE_TRUE,
  },
  purple: {
    ...baseStyles,
    backgroundColor: PURPLE_TRUE,
  },
  orange: {
    ...baseStyles,
    backgroundColor: ORANGE_DARK,
  },
  red: {
    ...baseStyles,
    background: RED_DARK,
  },
};

export default radioButtonVariantStyles;
