/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import { ComponentPropsWithoutRef } from 'react';
import { ColorsEnum, ColorsEnumValue, TEAL_100 } from 'theme/ui/colors';
import { CSS } from 'types/css';

const chaseStyles: CSS = {
  '@keyframes loader': {
    from: { transform: 'scale(0, 0)' },
    to: { transform: 'scale(1, 1)' },
  },
};

const defaultSpanStyles: CSS = {
  borderRadius: '100%',
  display: 'inline-block',
  width: '1.6rem',
  height: '1.6rem',
  margin: '0.3rem 0.2rem',
  animation: 'loader 0.8s linear infinite alternate',
  background: ColorsEnum.WHITE.withOpacity(0.8),
  '&:nth-of-type(1)': {
    animationDelay: '-1s',
  },
  '&:nth-of-type(2)': {
    animationDelay: '-0.8ss',
  },
  '&:nth-of-type(3)': {
    animationDelay: '-0.27s',
  },
  '&:nth-of-type(4)': {
    animationDelay: '-0.8s',
  },
  '&:nth-of-type(5)': {
    animationDelay: '-1s',
  },
};

export interface LoaderProps extends ComponentPropsWithoutRef<'div'>{
  color?: ColorsEnumValue,
}

function Loader({ color = TEAL_100, ...rest }: LoaderProps) {
  const spanStyles = { ...defaultSpanStyles, bg: color };
  return (
    <div sx={chaseStyles} {...rest}>
      <div sx={spanStyles} />
      <div sx={spanStyles} />
      <div sx={spanStyles} />
      <div sx={spanStyles} />
      <div sx={spanStyles} />
    </div>
  );
}

export default Loader;
