import produce from 'immer';
import {
  SET_SCREEN_SHARE_BROADCAST,
  SET_SCREEN_SHARE_MEDIA_STREAM_CONTROLLER,
  SET_SCREEN_SHARE_UI_STATE,
  SET_SCREEN_SHARE_MEDIA_STREAM_CONTROLLER_ERROR,
  SET_SCREEN_CAPTURE,
  RESET_SCREEN_SHARE_ENCODER,
  SET_DISPLAY_CAPTURE_SURFACE,
  SET_SWAPPED_SCREENSHARE_PREVIEW,
  ScreenShareAction,
} from 'actions/screenShareActions';
import { RESET_ROOM, SharedAction } from 'actions/sharedActions';
import { DisplayCaptureSurfaceEnum } from 'utils/screenshare';
import { ScreenShareState } from 'store/types';

export const initialState: ScreenShareState = {
  broadcast: null,
  screenShareMediaStreamController: null,
  screenShareUiState: null,
  screenShareMediaStreamControllerError: null,
  isScreenCapture: false,
  displayCaptureSurface: DisplayCaptureSurfaceEnum.UNKNOWN,
  swappedScreenSharePreview: false,
};

const reducer = produce((draft: ScreenShareState, action: ScreenShareAction | SharedAction) => {
  switch (action.type) {
    case SET_SCREEN_CAPTURE:
      draft.isScreenCapture = action.payload.isScreenCapture;
      break;
    case SET_SCREEN_SHARE_BROADCAST:
      draft.broadcast = action.payload.broadcast;
      break;
    case SET_SCREEN_SHARE_MEDIA_STREAM_CONTROLLER:
      draft.screenShareMediaStreamController = action.payload.mediaStreamController;
      break;
    case SET_SCREEN_SHARE_UI_STATE:
      draft.screenShareUiState = action.payload.screenShareUiState;
      break;
    case SET_SCREEN_SHARE_MEDIA_STREAM_CONTROLLER_ERROR:
      draft.screenShareMediaStreamControllerError = action.payload.mediaControllerError;
      break;
    case RESET_SCREEN_SHARE_ENCODER:
      Object.assign(draft, initialState);
      break;
    case RESET_ROOM:
      Object.assign(draft, initialState);
      break;
    case SET_DISPLAY_CAPTURE_SURFACE:
      draft.displayCaptureSurface = action.payload.displayCaptureSurface;
      break;
    case SET_SWAPPED_SCREENSHARE_PREVIEW:
      draft.swappedScreenSharePreview = action.payload.swapped;
      break;
    default:
      break;
  }

  /* HACK: From what I can tell, Redux doesn't like receiving a class instance in TypeScript.
  This shouldn't impact our type inference, since the StoreState is our source of truth for
  types rather than the return type of this reducer. Using unknown here just in case */
}, (initialState as unknown));

export default reducer;
