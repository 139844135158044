import RouteEnum from 'utils/routeEnum';

const MERGE_ACCOUNT_ERROR_CODE = '409';

/**
 * Checks query parameters to determine whether a merge error has
 * ocurred when native account user tries to login through Google.
 */
function useAccountMergeError() {
  const [{ err, code, token }] = RouteEnum.HOME.useQueryParams();
  const hasMergeError = code === MERGE_ACCOUNT_ERROR_CODE;

  return {
    hasMergeError, token, code, err,
  };
}

export default useAccountMergeError;
