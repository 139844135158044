import logger from 'utils/logger';

/** compiles a shader from source code and returns it if successful */
export const createShader = <GL extends WebGLRenderingContext | WebGL2RenderingContext>(
  gl: GL,
  type: number,
  source: string,
) => {
  const shader = gl.createShader(type)!;
  gl.shaderSource(shader, source);
  gl.compileShader(shader);

  const success = gl.getShaderParameter(shader, gl.COMPILE_STATUS);
  if (success) return shader;
  logger.debug('Shader source that failed to compile', { source } as any);
  const error = gl.getShaderInfoLog(shader) || 'Could not create shader';
  gl.deleteShader(shader);
  throw new Error(error);
};

export const createProgram = <GL extends WebGLRenderingContext | WebGL2RenderingContext>(
  gl: GL,
  vertexShader: WebGLShader,
  fragmentShader: WebGLShader,
) => {
  const program = gl.createProgram()!;
  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);
  gl.linkProgram(program);

  const success = gl.getProgramParameter(program, gl.LINK_STATUS);
  if (success) return program;
  const error = gl.getProgramInfoLog(program) || 'Could not create program';
  gl.deleteProgram(program);
  throw new Error(error);
};

export const resizeCanvasToDisplaySize = (canvas: HTMLCanvasElement) => {
  // Lookup the size the browser is displaying the canvas in CSS pixels.
  const canvasElWidth = canvas.clientWidth;
  const canvasElHeight = canvas.clientHeight;

  // Check if the canvas is not the same size.
  const needResize = canvas.width !== canvasElWidth || canvas.height !== canvasElHeight;

  if (needResize) {
    // Make the canvas the same size
    canvas.width = canvasElWidth;
    canvas.height = canvasElHeight;
  }

  return needResize;
};

export const createTexture = <GL extends WebGLRenderingContext | WebGL2RenderingContext>(gl: GL) => {
  const texture = gl.createTexture();
  if (!texture) throw new Error('Error creating texture');
  gl.bindTexture(gl.TEXTURE_2D, texture);

  // Set the parameters so we don't need mips, we're not filtering, and we don't repeat
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

  return texture;
};
