import {
  WHITE, TEAL_100, GREY_20, GREY_80, ColorsEnum,
} from 'theme/ui/colors';
import breakpoints, { BreakpointObj } from 'theme/ui/breakpoints';
import CheckSVG from 'assets/icons/checkmark-blocky.svg';
import { CSS } from 'types/css';

export const hideScrollBar: CSS = {
  scrollbarWidth: 'none' /* Firefox */,
  msOverflowStyle: 'none' /* IE 10+ */,
  '&::-webkit-scrollbar': {
    width: '0px',
    height: '0px',
    background: 'transparent' /* Chrome/Safari/Webkit */,
  },
};

export const modalFooterHeight = '8.4rem' as const;
export const controlsHeight = 80 as const;
export const controlsWidth = 120 as const;
export const aspectRatio = 16 / 9;
export const paginationHeight = 28 as const;
export const filmStripWidth = `max(calc((100vw - ${controlsWidth}px) * 0.2), 217px)`;
export const presenterWidth = `calc(100vw - ${controlsWidth}px - ${filmStripWidth})`;

export const presenterAspectRatio = 1.3 as const;

export const breakoutGroupHeights = ['26.1rem', '31.5rem', '31.5rem', '50.4rem'];

/* These z-index values were copied from bootstrap and are open for discussion */
export const topnav = 2;
export const dropdown = 1000 as const;
export const sticky = 1020 as const;
export const fixed = 1030 as const;
export const modalBackdrop = 1040 as const;
export const modal = 1050 as const;
export const popover = 1060 as const;
export const tooltip = 1070 as const;

export const roomBackdropFilter = 'blur(30px)' as const;

export const selectLabelStylesBase: CSS = {
  /* don't use ThemeUI syntax here, since these
  styles are also used on VideoClient Select component */
  display: 'block',
  fontFamily: '"Open Sans", sans-serif',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '1.4rem',
  lineHeight: '1.9rem',
  margin: '0 0 0.6rem 0 !important',
};

export const selectLabelStylesDark: CSS = {
  /* don't use ThemeUI syntax here, since these
  styles are also used on VideoClient Select component */
  color: `${GREY_20} !important`,
};

export const selectLabelStylesLight: CSS = {
  /* don't use ThemeUI syntax here, since these
  styles are also used on VideoClient Select component */
  color: `${GREY_80} !important`,
};

export const selectStylesBase: CSS = {
  /* don't use ThemeUI syntax here, since these
  styles are also used on VideoClient Select component */
  fontFamily: '"Open Sans", sans-serif',
  padding: '1.2rem',
  paddingRight: '3.2rem',
  position: 'relative',
  boxSizing: 'border-box',
  width: '100%',
  maxWidth: 'fit-content',
  cursor: 'pointer',
  border: '0 !important',
  outline: '0 !important',
  fontSize: '1.6rem',
  background: 'transparent',
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'calc(100% - 1rem)',
  backgroundPositionY: '2rem',
  height: '100%',
  lineHeight: '2rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  appearance: 'none',
  borderRadius: '2.2rem',
  '&:focus': {
    border: '0 !important',
    outline: '0 !important',
  },
};

export const selectStylesDark: CSS = {
  /* don't use ThemeUI syntax here, since these
  styles are also used on VideoClient Select component */
  backgroundColor: ColorsEnum.BLACK.withOpacity(0.6),
  color: `${GREY_20} !important`,
  // eslint-disable-next-line max-len
  backgroundImage: 'url("data:image/svg+xml;charset=UTF-8,%3csvg width=\'12\' height=\'8\' viewBox=\'0 0 12 8\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3cpath fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M0.46967 0.46967C0.762563 0.176777 1.23744 0.176777 1.53033 0.46967L6 4.93934L10.4697 0.46967C10.7626 0.176777 11.2374 0.176777 11.5303 0.46967C11.8232 0.762563 11.8232 1.23744 11.5303 1.53033L6 7.06066L0.46967 1.53033C0.176777 1.23744 0.176777 0.762563 0.46967 0.46967Z\' fill=\'white\'/%3e%3c/svg%3e ")',
};

export const selectStylesLight: CSS = {
  /* don't use ThemeUI syntax here, since these
  styles are also used on VideoClient Select component */
  backgroundColor: `${WHITE}`,
  color: `${GREY_80} !important`,
  // eslint-disable-next-line max-len
  backgroundImage: 'url("data:image/svg+xml;charset=UTF-8,%3csvg width=\'12\' height=\'8\' viewBox=\'0 0 12 8\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3cpath fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M0.46967 0.46967C0.762563 0.176777 1.23744 0.176777 1.53033 0.46967L6 4.93934L10.4697 0.46967C10.7626 0.176777 11.2374 0.176777 11.5303 0.46967C11.8232 0.762563 11.8232 1.23744 11.5303 1.53033L6 7.06066L0.46967 1.53033C0.176777 1.23744 0.176777 0.762563 0.46967 0.46967Z\' fill=\'black\'/%3e%3c/svg%3e ")',
};

// a decently close approximation to our FocusRing styles
export const selectFocusStyles: CSS = {
  /* don't use ThemeUI syntax here, since these
  styles are also used on VideoClient Select component */
  transition: 'border 0.2s',
  border: '2px solid transparent !important',
  '&:focus': {
    border: `2px solid ${TEAL_100} !important`,
  },
};

export const textEllipsis: CSS = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

/* The weird (but only) CSS-only way to create a
text ellipsis that breaks after two lines
instead of one. Decently supported.
https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp */
export const multiLineEllipsis: CSS = {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  wordBreak: 'break-word',
};

export const encoderTransformStyles: CSS = {
  // Need this because transform scaleX on encoder breaks stacking order of elements
  // see: https://stackoverflow.com/questions/6953497/webkit-transform-overwrites-z-index-ordering-in-chrome-13
  transform: 'translate3d(0rem, 0rem, 0rem)',
};

export const flipEncoder: CSS = {
  transform: 'scaleX(-1)',
};

export const googleLoginWrapperStyles: CSS = {
  width: '100%',
  maxWidth: '38rem',
  p: 0,
  mx: 'auto',
  mb: '4rem',
  '& button': {
    border: 'none',
    bg: 'transparent',
    position: 'relative',
    p: 0,
    m: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:focus': {
      outline: 'none',
    },
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
};

/* Shorthand media queries for breakpoints.
maxWidth: x < breakpoint
minWidth: breakpoint <= x
Making the max width exclusive of the breakpoint itself prevents
a 1px overlap where both min-width and max-width both apply. */

export const mediaMaxWidth: { [I in keyof BreakpointObj]: string } = {
  _0: `@media (max-width: calc(${breakpoints[0]} - 1px))`, // 360px
  _1: `@media (max-width: calc(${breakpoints[1]} - 1px))`, // 480px
  _2: `@media (max-width: calc(${breakpoints[2]} - 1px))`, // 768px
  _3: `@media (max-width: calc(${breakpoints[3]} - 1px))`, // 900px
  _4: `@media (max-width: calc(${breakpoints[4]} - 1px))`, // 980px
  _5: `@media (max-width: calc(${breakpoints[5]} - 1px))`, // 1280px
  _6: `@media (max-width: calc(${breakpoints[6]} - 1px))`, // 1440px
  _7: `@media (max-width: calc(${breakpoints[7]} - 1px))`, // 1600px
  _8: `@media (max-width: calc(${breakpoints[8]} - 1px))`, // Infinity
} as const;

export const mediaMinWidth: { [I in keyof BreakpointObj]: string } = {
  _0: `@media (min-width: ${breakpoints[0]})`, // 360px
  _1: `@media (min-width: ${breakpoints[1]})`, // 480px
  _2: `@media (min-width: ${breakpoints[2]})`, // 768px
  _3: `@media (min-width: ${breakpoints[3]})`, // 900px
  _4: `@media (min-width: ${breakpoints[4]})`, // 980px
  _5: `@media (min-width: ${breakpoints[5]})`, // 1280px
  _6: `@media (min-width: ${breakpoints[6]})`, // 1440px
  _7: `@media (min-width: ${breakpoints[7]})`, // 1600px
  _8: `@media (min-width: ${breakpoints[8]})`, // Infinity
};

// @todo: consolidate into theme
export const roomSettingsSwitchStyles = {
  /* When the panel fills the entire screen on tablet sizes,
  just fit-content to avoid excessive stretching */
  [mediaMaxWidth._1]: {
    width: '100%',
  },
  width: 'fit-content',
  [mediaMinWidth._2]: {
    width: '100%',
  },
  justifyContent: 'space-between',
  my: '0.75rem',
  '& label': {
    color: WHITE,
  },
};

// @todo: consolidate into theme
export const roomSettingsHeaderStyles = {
  fontFamily: 'quicksand',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '20px',
  lineHeight: '25px',
  color: GREY_20,
  mt: '2.5rem',
  mb: '1.5rem',
};

/**
 * This is the minimum height necessary to keep overlays
 * from overflowing the tile edges.
 */
export const minTileHeight = 122 as const;

export const videoTilePadding = 6 as const;

export const videoTileBorderRadius = '2.8rem' as const;

export const videoBackgroundColor = ColorsEnum.BLACK.withOpacity(0.6);

export const videoContainerStyles: CSS = {
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: videoBackgroundColor,
  textAlign: 'center',
  '&:hover button': {
    visibility: 'visible',
  },
};

export const pointerEventsNone: CSS = {
  pointerEvents: 'none',
};

export const overlayButtonStyles: CSS = {
  border: 0,
  padding: 0,
  m: 0,
  height: '4.8rem',
  width: '4.8rem',
  borderRadius: '3rem',
  position: 'absolute',
  visibility: 'hidden',
  overflow: 'hidden',
  transition: 'width 0.2s, background-color 0.2s',
  backdropFilter: 'blur(0.4rem)',
  pointerEvents: 'auto',
  bg: ColorsEnum.BLACK.withOpacity(0.3),
  zIndex: 2,
  '& p': {
    transition: 'opacity 0.2s',
    position: 'absolute',
    top: '50%',
    textAlign: 'left',
    transform: 'translateY(-50%)',
    left: '4.8rem',
    fontSize: '1.8rem',
    fontWeight: 'bold',
    color: WHITE,
    opacity: 0,
  },
  '& svg': {
    height: '2.1rem',
    width: '2.1rem',
    position: 'absolute',
    top: '50%',
    left: '1.3rem',
    transform: 'translateY(-50%)',
    fill: WHITE,
  },
  '&:focus': {
    outline: 'none',
  },
  '&:hover p': {
    opacity: 1,
  },
};

export const createOverlayButtonStyles = ({
  ownerBroadcastLoading,
  isOwner,
  isEncoder,
  off,
}: {
  ownerBroadcastLoading: boolean,
  isOwner: boolean,
  isEncoder: boolean,
  off: boolean,
}) => {
  let cursor = 'default';
  if (ownerBroadcastLoading) cursor = 'not-allowed';
  else if (isOwner) cursor = 'pointer';

  return {
    height: '4rem',
    width: '4rem',
    borderRadius: '2rem',
    border: 0,
    cursor,
    pointerEvents: !isEncoder ? 'auto' : 'none',
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Open Sans',
    fontWeight: 'normal',
    '& svg': {
      height: '2rem',
      width: '2rem',
      fill: off ? '#E82125' : TEAL_100,
    },
    '&:hover': {
      backgroundColor: ColorsEnum.BLACK.withOpacity(0.6),
      transitionDuration: '0.2s',
    },
    '&:focus': {
      outline: 'none',
      border: 'solid 2px white',
    },
  } as CSS;
};

export const controlBarFlexDirection: CSS = { flexDirection: ['row', 'row', 'row', 'row', 'row', 'column'] };

/**
 * Style resets for a plain, transparent button.
 */
export const buttonResetStyles = {
  appearance: 'none',
  position: 'relative',
  bg: 'transparent',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  outline: 0,
  border: 0,
  p: 0,
  m: 0,
  boxShadow: 'none',
  '&:focus': {
    outline: 0,
  },
} as const;

// Reset default <a> styles
export const linkResetStyles = {
  textDecoration: 'none',
  color: 'inherit',
};

export const listResetStyles = {
  listStyle: 'none',
  m: 0,
  p: 0,
};

export const HEADER_HEIGHT = '10rem' as const;
export const SIDE_NAV_WIDTH = '30rem' as const;
export const SIDE_NAV_PADDING_LEFT = '2rem' as const;
export const PROFILE_BUTTON_DEFAULT_WIDTH: CSS['width'] = [0, 0, 0, '7rem'];

export const hideOnGreaterThanTablet: CSS = {
  [mediaMinWidth._4]: {
    display: 'none',
  },
};

export const hideOnLessThanTablet: CSS = {
  [mediaMaxWidth._4]: {
    display: 'none',
  },
};

export const roomGradientBackground: CSS['background'] = 'radial-gradient(rgb(11 65 66) 0%, rgba(0,0,0,1) 100%)';

export const gradientBackground: CSS['background'] = `linear-gradient(180deg, rgb(255 255 255 / 100%), rgba(0,0,100,0.1) 150%), 
linear-gradient(32deg, rgb(0 255 98 / 30%), rgba(0,255,0,0) 75%), 
linear-gradient(334deg, rgba(0,0,255,.3), rgba(0,0,255,0) 75%)`;

export const boxShadowLight = `0 0.4rem 1.6rem ${ColorsEnum.GREY_40.withOpacity(0.6)}`;
export const boxShadowLightHover = `0 0.8rem 20rem ${ColorsEnum.GREY_40.withOpacity(0.6)}`;

export const boxShadowDark = `0px 4px 15px ${ColorsEnum.BLACK.withOpacity(0.3)}`;
export const boxShadowDarkHover = `0px 8px 20px ${ColorsEnum.BLACK.withOpacity(0.3)}`;

export const modalBorderRadius = '2.4rem';

export const loginCardPx = ['2rem', '2rem', '6rem'];

export const skeletonLoaderAnimation: CSS = {
  background: 'linear-gradient(270deg, #D9D9D9, transparent 50%, #D9D9D9)',
  backgroundSize: '200% 100%',
  animation: 'wave 2s infinite linear forwards',
  '@keyframes wave': {
    '0%': { backgroundPosition: '150% 50%' },
    '100%': { backgroundPosition: '-50% 50%' },
  },
};

export const BG_HEADER_HEIGHT_PX = 80;

/**
 * Styling a container with `backdropFilter: blur` or any `transform` properties can cause `position: fixed`
 * issues for any child elements, because it turns the container into a containing block.
 * This breaks the drag-n-drop functionality, for instance.
 * Use a :before pseudo-selector to style the background instead.
 * @see: https://developer.mozilla.org/en-US/docs/Web/CSS/position#values
 *
 * Notes: must be exported from here to avoid dependency cycles with styles.ts.
 */
export const blurredBackgroundPseudoStyles = {
  content: '""',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backdropFilter: roomBackdropFilter,
  zIndex: -1,
  pointerEvents: 'none',
};

export const previewNameBoxStyles: CSS = {
  height: '100%',
  width: '100%',
  backgroundColor: videoBackgroundColor,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  '& p': {
    fontSize: '1.6rem',
    color: WHITE,
  },
};

export const previewNameBoxNameStyles: CSS = {
  px: '0.25rem',
  ...textEllipsis,
};

export const topRightGreenCheckmark: CSS = {
  '&:after': {
    content: '""',
    position: 'absolute',
    backgroundColor: WHITE,
    zIndex: 2,
    bottom: 25,
    right: '-6px',
    width: '2.5rem',
    height: '2.5rem',
    borderRadius: 16,
    boxShadow: '0px 5px 15px #00000019',
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    width: '1.5rem',
    height: '1.5rem',
    WebkitMask: `url(${CheckSVG}) center / contain no-repeat`,
    background: '#008000',
    WebkitTransition: 'background-image 0.2s ease-in-out',
    zIndex: 3,
    bottom: 30,
    right: '-2px',
  },
};
