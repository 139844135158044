import { MakeIndexes, NumberToPx } from 'utils/typeUtils';

type Breakpoints = [360, 480, 768, 900, 980, 1280, 1440, 1600, typeof Infinity];
type BreakpointsPx = NumberToPx<Breakpoints>;
export type BreakpointIndexes = MakeIndexes<Breakpoints['length']>
export type BreakpointObj = { [I in BreakpointIndexes as `_${I}`]: Breakpoints[I] };

/**
 * Breakpoints as an array of numbers.
 */
export const breakpointNums = [360, 480, 768, 900, 980, 1280, 1440, 1600, Infinity] as Breakpoints;

/**
 * Breakpoints as an array of string "px" values.
 */
export default breakpointNums.map((el) => `${el}px`) as BreakpointsPx;

/**
 * Breakpoints as an object (similar to min/maxMediaWidth in styles/styles.ts).
 *
 * The `redux-responsive` library doesn't play well with our ThemeUI breakpoints, since they use differing
 * comparators (i.e. does "greaterThan" mean > or >=?). So to compensate, I'm subtracting 1px
 * from each breakpoint that redux-responsive uses, which produces the desired logic.
 *
 * Without breakpoint - 1:
 * `lessThan._2` means: width <= 480
 * `is._2` means: 480 < width <= 768
 * `greaterThan._2` means: 768 < width
 *
 * With breakpoint - 1:
 * `lessThan._2` means: width < 480
 * `is._2` means: 480 <= width < 768
 * `greaterThan._2` means: 768 <= width
 *
 * That means that `greaterThan` now corresponds to ThemeUI's breakpoint logic
 * and also, by extension, our mediaMinWidth & mediaMaxWidth logic.
 *
 */
const breakpointObj = {} as BreakpointObj;
breakpointNums.reduce((obj, breakpoint, i) => {
  (obj as Record<string, number>)[`_${i}`] = breakpoint - 1;
  return obj;
}, breakpointObj);
export { breakpointObj };
