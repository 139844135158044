import logger from 'utils/logger';
import { getMessageFromError } from 'utils/errorUtils';
import SoundPlayerBase from './base';
import SoundFileEnum from '../soundFileEnum';
import {
  SoundPlayerOptions,
} from '../soundPlayerOptions';

/**
 * Simpler sound player used as fallback when Web Audio API is not available.
 *
 * Luckily, AudioContext is implemented on all common browsers except IE,
 * so this should be encountered rarely.
 */
export default class FallbackSoundPlayer extends SoundPlayerBase {
  public isUsingWebAudioApi = false;

  constructor(options?: SoundPlayerOptions) {
    super(options);
    this.audioPool = {};
  }

  public playAudioFile(sound: SoundFileEnum, options?: { loop?: boolean, forceReload?: boolean }) {
    const { loop = false, forceReload = false } = options || { loop: false, forceReload: false };
    const audio = this.getAudio(sound, forceReload);
    audio.loop = loop;
    audio.play();
    this.emit('soundPlayed', sound);
  }

  public stop(sound: SoundFileEnum) {
    try {
      this.stopAudio(sound);
    } catch (err) {
      const errorMessage = getMessageFromError(err);
      logger.warn('Error stopping sound', { errorMessage, sound: sound.keyName });
    }
  }

  public load(sound: SoundFileEnum, options: { forceReload?: boolean } = { forceReload: false }) {
    try {
      this.getAudio(sound, !!options.forceReload);
    } catch (err) {
      const errorMessage = getMessageFromError(err);
      logger.warn('Error loading sound', { errorMessage, sound: sound.keyName });
    }
  }

  public isSoundPlaying(sound: SoundFileEnum) {
    const audio = this.audioPool[sound.keyName];
    if (!audio) return false;
    return !audio.paused;
  }

  public clearStoredAudioData() {
    this.audioPool = {};
  }

  /* Implementation */

  private getAudio(sound: SoundFileEnum, forceReload: boolean) {
    let audio = this.audioPool[sound.keyName];
    if (forceReload || !audio) {
      audio = this.saveAudio(sound);
    }
    return audio;
  }

  private stopAudio(sound: SoundFileEnum) {
    const audio = this.audioPool[sound.keyName];
    if (audio && !audio.paused) {
      audio.pause();
      audio.currentTime = 0;
      this.emit('soundEnded', sound);
    }
  }

  private saveAudio(sound: SoundFileEnum) {
    const audio = new Audio(sound.file);
    this.audioPool[sound.keyName] = audio;
    return audio;
  }

  private audioPool: { [key: string]: HTMLAudioElement | undefined };
}
