import { ChalkboardColor, ChalkboardTheme } from 'store/types';
import { RED_50, TEAL_MEDIUM } from 'theme/ui/colors';

export type ColorConfig = {
  [key in ChalkboardTheme]?: string;
} & { default: string }

export const ColorConfigs: { [key in ChalkboardColor]: ColorConfig } = {
  primary: { default: '#000', dark: '#FFF', light: '#000' },
  green: { default: TEAL_MEDIUM },
  blue: { default: '#678fe0' },
  purple: { default: '#9e7ad9' },
  orange: { default: '#d69e54' },
  red: { default: RED_50 },
};

export const ThemeBGColorConfig: { [key in ChalkboardTheme]: string } = {
  light: '#FFF',
  dark: '#031210',
};

export const getHexByTheme = (color: ChalkboardColor, theme: ChalkboardTheme) => {
  const config = ColorConfigs[color];
  return config[theme] || config.default;
};

// This should be reliable as long as each hex used in ColorConfigs is unique
export const getThemeColorByHex = (colorHex: string, theme: ChalkboardTheme): ChalkboardColor => {
  let lowerConfidenceResult = '';
  for (const [themeColor, config] of Object.entries(ColorConfigs)) {
    for (const [key, hex] of Object.entries(config)) {
      if (hex === colorHex) {
        if (theme === key) {
          return themeColor as ChalkboardColor;
        }
        lowerConfidenceResult = themeColor;
      }
    }
  }
  return lowerConfidenceResult as ChalkboardColor || 'primary';
};
