import { GREY_70, GREY_100 } from './colors';

const typography = {
  fonts: {
    source: '"Source Sans Pro", sans-serif',
    quicksand: '"Quicksand", sans-serif',
    open: '"Open Sans", sans-serif',
  },
  fontSizes: [
    '1.4rem',
    '1.6rem',
    '1.8rem',
    '2.4rem',
    '2.8rem',
    '3.2rem',
  ],
  fontWeights: {
    bold: '600',
    normal: '300',
    light: '100',
  },
  text: {
    h1: {
      color: GREY_100,
      fontFamily: 'quicksand',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '4.8rem',
      lineHeight: '6rem',
    },
    h2: {
      color: GREY_100,
      fontFamily: 'quicksand',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '3.6rem',
      lineHeight: '4.5rem',
    },
    h3: {
      color: GREY_100,
      fontFamily: 'quicksand',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '3.2rem',
      lineHeight: '4rem',
    },
    h4: {
      color: GREY_100,
      fontFamily: 'quicksand',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '2.4rem',
      lineHeight: '3rem',
    },
    h5: {
      color: GREY_100,
      fontFamily: 'quicksand',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '2rem',
      lineHeight: '2.5rem',
    },
    h6: {
      color: GREY_100,
      fontFamily: 'quicksand',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '1.6rem',
      lineHeight: '2rem',
    },
    h1_bold: {
      fontFamily: 'quicksand',
      fontStyle: 'normal',
      color: GREY_70,
      fontWeight: 'bold',
      fontSize: '4.8rem',
      lineHeight: '6rem',
    },
    h2_bold: {
      fontFamily: 'quicksand',
      fontStyle: 'normal',
      color: GREY_70,
      fontWeight: 'bold',
      fontSize: '3.6rem',
      lineHeight: '4.5rem',
    },
    h3_bold: {
      fontFamily: 'quicksand',
      fontStyle: 'normal',
      color: GREY_70,
      fontWeight: 'bold',
      fontSize: '3.2rem',
      lineHeight: '4rem',
    },
    h4_bold: {
      fontFamily: 'quicksand',
      fontStyle: 'normal',
      color: GREY_70,
      fontWeight: 'bold',
      fontSize: '2.4rem',
      lineHeight: '3rem',
    },
    h5_bold: {
      fontFamily: 'quicksand',
      fontStyle: 'normal',
      color: GREY_70,
      fontWeight: 'bold',
      fontSize: '2rem',
      lineHeight: '2.5rem',
    },
    h6_bold: {
      fontFamily: 'quicksand',
      fontStyle: 'normal',
      color: GREY_70,
      fontWeight: 'bold',
      fontSize: '1.6rem',
      lineHeight: '2rem',
    },
    body: {
      fontFamily: 'open',
      fontStyle: 'normal',
      color: GREY_70,
      fontWeight: 'normal',
      fontSize: '1.8rem',
      lineHeight: '2.8rem',
    },
    body_source_normal: {
      fontFamily: 'source',
      fontSize: 1,
      fontWeight: 'normal',
      lineHeight: '2rem',
    },
    body_italic: {
      fontFamily: 'open',
      fontStyle: 'italic',
      color: GREY_70,
      fontWeight: 'normal',
      fontSize: '1.8rem',
      lineHeight: '2.8rem',
    },
    body_semibold: {
      fontFamily: 'open',
      fontStyle: 'normal',
      color: GREY_70,
      fontWeight: 'bold',
      fontSize: '1.8rem',
      lineHeight: '2.8rem',
    },
    body_small: {
      fontFamily: 'open',
      fontStyle: 'normal',
      color: GREY_70,
      fontWeight: 'normal',
      fontSize: '1.6rem',
      lineHeight: '2.8rem',
    },
    body_small_italic: {
      fontFamily: 'open',
      fontStyle: 'italic',
      color: GREY_70,
      fontWeight: 'normal',
      fontSize: '1.6rem',
      lineHeight: '2.8rem',
    },
    body_small_semibold: {
      fontFamily: 'open',
      fontStyle: 'normal',
      color: GREY_70,
      fontWeight: 'bold',
      fontSize: '1.6rem',
      lineHeight: '2.8rem',
    },

    label: {
      fontFamily: 'open',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '1.4rem',
      lineHeight: '1.9rem',
      color: GREY_70,
    },
    caption: {
      fontFamily: 'open',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '1.4rem',
      lineHeight: '1.9rem',
      color: GREY_70,
    },
  },
} as const;

export default typography;

type Typography = typeof typography;
export type TextVariants = `text.${keyof Typography['text']}`;
export type FontFamilyVariants = `${keyof Typography['fonts']}`;
export type FontSizeVariants = `${Typography['fontSizes'][number]}`;
