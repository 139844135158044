/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import {
  memo, ComponentProps, useMemo, useRef,
} from 'react';
import Svg from 'components/ui/Svg';
import { CSS } from 'types/css';
import { ReactComponent as Check } from 'assets/icons/checkmark-rounded.svg';
import { ReactComponent as X } from 'assets/icons/x.svg';
import { RED_MEDIUM, TEAL_MEDIUM } from 'theme/ui/colors';
import { CCServiceAttendanceRosterUser } from 'utils/ccService/types';
import { ALL_VIEW_FADE_TRANSITION_TIME } from '../common';
import AttendanceStatusIconWithHover from './AttendanceStatusIconWithHover';
import AttendanceStatusIconPlain from './AttendanceStatusIconPlain';

const checkStyles: CSS = {
  transition: `stroke ${ALL_VIEW_FADE_TRANSITION_TIME}`,
  stroke: TEAL_MEDIUM,
};

const xStyles: CSS = {
  transition: `fill ${ALL_VIEW_FADE_TRANSITION_TIME}`,
  fill: RED_MEDIUM,
};

interface AttendanceStatusIconProps extends ComponentProps<'td'> {
  loading: boolean,
  attendanceStatus: CCServiceAttendanceRosterUser['attendanceStatus'] | '',
  userId: string,
  sessionId: string,
  rowIsDark: boolean,
  enableHoverForDetail?: boolean,
}

/** Present / Absent status icon. This top level component chooses between a component with listeners attached or just a plain component */
const AttendanceStatusIcon = memo(({
  enableHoverForDetail = false, attendanceStatus, loading, userId, sessionId, rowIsDark, ...rest
}: AttendanceStatusIconProps) => {
  const iconRef = useRef<SVGElement | null>(null);
  const attendanceIcon = useMemo(() => {
    switch (attendanceStatus) {
      case 'present':
        return <Svg svg={Check} sx={checkStyles} title="Present" ref={iconRef} />;
      case 'absent':
        return <Svg svg={X} sx={xStyles} title="Absent" ref={iconRef} />;
      default:
        return '';
    }
  }, [attendanceStatus]);

  return enableHoverForDetail
    ? (
      <AttendanceStatusIconWithHover
        loading={loading}
        userId={userId}
        sessionId={sessionId}
        rowIsDark={rowIsDark}
        attendanceIcon={attendanceIcon}
        attendanceStatus={attendanceStatus}
        iconRef={iconRef}
        {...rest}
      />
    ) : (
      <AttendanceStatusIconPlain
        attendanceIcon={attendanceIcon}
        {...rest}
      />
    );
});

export default memo(AttendanceStatusIcon);
