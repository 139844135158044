import { createSelector } from 'reselect';
import { getBrowser } from 'selectors/browserSelectors';
import { AllViewDetailInfo, StoreState, UserViewSession } from 'store/types';
import { BreakpointIndexes, BreakpointObj } from 'theme/ui/breakpoints';
import logger from 'utils/logger';
import {
  COLS_FOR_BACK_BUTTON, COLS_FOR_DISPLAY_NAME_LABELS,
  ROWS_FOR_DATE_HEADING_LABELS,
} from 'views/RoomManager/Attendance/AttendanceAllView/AttendanceAllView';
import { USER_VIEW_ROWS_FOR_USER_DATA_LABELS } from 'views/RoomManager/Attendance/AttendanceUserView/AttendanceUserView';

export const getAllViewPrevLink = (state: StoreState) => state.attendanceState.allViewPrevSessionsLink;

export const getAllViewSessions = (state: StoreState) => state.attendanceState.allViewSessions;

export const getAllViewSessionsLoading = (state: StoreState) => state.attendanceState.allViewSessionsLoading;

export const getAllViewSessionsError = (state: StoreState) => state.attendanceState.allViewSessionsError;

export const getAllViewSessionId = (state: StoreState) => state.attendanceState.allViewSessionId;

export const getAllViewSessionsFetched = (state: StoreState) => state.attendanceState.allViewSessionsFetched;

export const getAllViewAllSessionIdLabels = (state: StoreState) => state.attendanceState.allViewSessionIdLabels;

export const getAllViewAllUserIdLabels = (state: StoreState) => state.attendanceState.allViewUserIdLabels;

export const getAllViewDetailHovered = (state: StoreState) => state.attendanceState.allViewDetailHovered;

export const getAllViewDetailFocused = (state: StoreState) => state.attendanceState.allViewDetailFocused;

export const getAllViewCSVDataLoading = (state: StoreState) => state.attendanceState.allViewCSVDataLoading;

export const getUserViewPrevSessionsLink = (state: StoreState) => state.attendanceState.userViewPrevSessionsLink;

export const getUserViewSessionsFetched = (state: StoreState) => state.attendanceState.userViewSessionsFetched;

export const getUserViewSessionsLoading = (state: StoreState) => state.attendanceState.userViewSessionsLoading;

export const getUserViewSessionsError = (state: StoreState) => state.attendanceState.userViewSessionsError;

export const getUserViewSessions = (state: StoreState) => state.attendanceState.userViewSessions;

export const getUserViewSessionIdLabels = (state: StoreState) => state.attendanceState.userViewSessionIdLabels;

export const getUserViewCSVDataLoading = (state: StoreState) => state.attendanceState.userViewCSVDataLoading;

export const getUserViewDisplayName = (state: StoreState) => state.attendanceState.userViewDisplayName;

export const getUserViewFirstName = (state: StoreState) => state.attendanceState.userViewFirstName;

export const getUserViewLastName = (state: StoreState) => state.attendanceState.userViewLastName;

/** Maps breakpoints to how many cols should be displayed in the Attendance "All Guests" view of the Attendance report */
export const breakpointToAllViewColsMap: {
  [key in keyof BreakpointObj]: number;
} = {
  _0: 2, _1: 3, _2: 3, _3: 3, _4: 4, _5: 5, _6: 5, _7: 5, _8: 5,
};

/** The actual number of attendance DATA columns being displayed
 * Does not account for columns for buttons, labels, etc. */
export const getAllViewNumDataCols = createSelector(
  getBrowser,
  (browser) => {
    let cols = 2;
    let i: BreakpointIndexes = 1;
    while (browser.minWidth[`_${i}` as keyof BreakpointObj]) {
      cols = breakpointToAllViewColsMap[`_${i}` as keyof BreakpointObj];
      i += 1;
    }
    return cols;
  },
);

/** Finds the current user's index in the sessions that have been fetched for the All View */
export const getAllViewSessionIndex = createSelector(
  getAllViewSessionId,
  getAllViewAllSessionIdLabels,
  (sessionId, sessionIdLabels) => sessionIdLabels.findIndex((sessionIdLabel) => sessionId === sessionIdLabel),
);

/** Can user go to previous page in the "All Guests" view of Attendance */
export const getAllViewCanGoToPrevPage = createSelector(
  getAllViewSessionsLoading,
  getAllViewSessionId,
  getAllViewPrevLink,
  getAllViewSessionsFetched,
  getAllViewSessionIndex,
  getAllViewNumDataCols,
  getAllViewAllSessionIdLabels,
  (loading, sessionId, prevLink, fetched, index, numDataCols, sessionIdLabels) => {
    // attendance sessions not initialized yet
    if (!sessionId || !fetched || !sessionIdLabels.length) return false;

    // error, sessions index not found
    if (index < 0) {
      logger.debug('Attendance session id not found in getAllViewCanGoToPrevPage');
      return false;
    }

    // can't navigate past 0 while still loading rooms
    if (index - numDataCols < 0 && loading) return false;

    // already seeing the most previous session
    if (index === 0) {
      // can't fetch more while loading
      if (loading) return false;
      // not loading, can fetch more if there are more to load from the server
      return !fetched || (fetched && !!prevLink);
    }

    return true;
  },
);

/** Can user go to next page in the "All Guests" view of Attendance */
export const getAllViewCanGoToNextPage = createSelector(
  getAllViewAllSessionIdLabels,
  getAllViewSessionId,
  getAllViewSessionsFetched,
  getAllViewSessionIndex,
  getAllViewNumDataCols,
  getAllViewAllSessionIdLabels,
  (sessionTimeLabels, sessionId, fetched, sessionIndex, numDataCols, sessionIdLabels) => {
    // not initialized yet
    if (!sessionId || !fetched || !sessionIdLabels.length) return false;

    // error, index not found
    if (sessionIndex < 0) {
      logger.debug('Attendance session id not found in getAllViewCanGoToNextPage');
      return false;
    }

    // already seeing the last page of sessions
    if (sessionIndex >= sessionTimeLabels.length - numDataCols) return false;

    return true;
  },
);

/** This is the slice/"page" of attendance sessionIds that the current user is viewing */
export const getAllViewSessionIdsForView = createSelector(
  getAllViewAllSessionIdLabels,
  getAllViewSessionId,
  getAllViewNumDataCols,
  (sessionIdLabels, sessionId, cols) => {
    if (!sessionId || !sessionIdLabels.length) return [];
    const i = sessionIdLabels.findIndex((sessionIdLabel) => sessionIdLabel === sessionId);
    if (i < 0) return [];
    return sessionIdLabels.slice(i, i + cols);
  },
);

/** Number of columns for the purposes of visual grid layout in the Attendance "All" view
 * Adds in columns for buttons, column / row labels etc. */
export const getAllViewGridDimensions = createSelector(
  getAllViewSessionIdsForView,
  getAllViewAllUserIdLabels,
  (sessionTimeLabels, displayNameLabels): [cols: number, rows: number] => {
    const cols = sessionTimeLabels.length + COLS_FOR_DISPLAY_NAME_LABELS + COLS_FOR_BACK_BUTTON;
    const rows = displayNameLabels.length + ROWS_FOR_DATE_HEADING_LABELS;
    return [cols, rows];
  },
);

/** Detects when the current user is "stuck" in a 2-column view (or other smallish view)
 * at the end of the sessions array in the Attendance "All" view */
export const getAllViewShowingTooFewCols = createSelector(
  getAllViewSessionIdsForView,
  getAllViewNumDataCols,
  (actualSessionColumnsShown, expectedCols) => !!actualSessionColumnsShown.length && actualSessionColumnsShown.length < expectedCols,
);

/** Is state valid for recalibrating sessionId when showing too few cols in "All Guests" view */
export const getAllViewCanRecalibrateSessionId = createSelector(
  getAllViewShowingTooFewCols,
  getAllViewSessionsFetched,
  getAllViewSessionsLoading,
  getAllViewAllSessionIdLabels,
  (showingTooFewCols, allViewSessionsFetched, allViewSessionsLoading, sessionStartTimeLabels) => !!(
    showingTooFewCols && allViewSessionsFetched && !allViewSessionsLoading && sessionStartTimeLabels.length
  ),
);

/** Returns the hash of whatever details is currently focused or, if none is focused, the currently hovered hash */
export const getAllViewSelectedDetailInfo = createSelector(
  getAllViewDetailFocused,
  getAllViewDetailHovered,
  (focusedHash, hoveredHash) => focusedHash || hoveredHash,
);

/** Returns true if **any** attendance detail is selected (i.e. either focused or hovered) */
export const getAllViewSomeAttendanceDetailIsSelected = createSelector(
  getAllViewSelectedDetailInfo,
  (selectedDetailInfo): boolean => !!selectedDetailInfo,
);

/** Only allows one detail to be selected at a time. If a detail is focused, it gets precedent over the one that is being hovered */
export const makeGetAllViewAttendanceDetailIsSelected = (detailInfo: AllViewDetailInfo) => createSelector(
  getAllViewSelectedDetailInfo,
  (selectedDetailInfo): boolean => !!(selectedDetailInfo
    && detailInfo.sessionId === selectedDetailInfo.sessionId
  && detailInfo.userId === selectedDetailInfo.userId),
);

/** Determines when an attendance status icon should appear faded */
export const makeGetAllViewIconShouldBeFaded = (detailInfo: AllViewDetailInfo) => createSelector(
  getAllViewSomeAttendanceDetailIsSelected,
  makeGetAllViewAttendanceDetailIsSelected(detailInfo),
  (someDetailIsSelected, isSelected): boolean => someDetailIsSelected && !isSelected,
);

/** Determines if row has a detail that is currently selected */
export const makeGetAllViewDisplayNameLabelWeight = (userId: string) => createSelector(
  getAllViewSelectedDetailInfo,
  (selectedDetailInfo): 'bold' | 'light' | 'regular' => {
    if (selectedDetailInfo) return userId === selectedDetailInfo.userId ? 'bold' : 'light';
    return 'regular';
  },
);

/** Determines if column has a detail that is currently selected */
export const makeGetAllViewSessionTimeLabelWeight = (sessionId: string) => createSelector(
  getAllViewSelectedDetailInfo,
  (selectedDetailInfo): 'bold' | 'light' | 'regular' => {
    if (selectedDetailInfo) return sessionId === selectedDetailInfo.sessionId ? 'bold' : 'light';
    return 'regular';
  },
);

export const getAllViewCanFetchAttendanceCSVData = createSelector(
  getAllViewCSVDataLoading,
  (loading) => !loading,
);

export const getUserViewNumRows = createSelector(
  getUserViewSessionIdLabels,
  (sessionIdLabels) => sessionIdLabels.length + USER_VIEW_ROWS_FOR_USER_DATA_LABELS,
);

export const getUserViewCanFetchAttendanceCSVData = createSelector(
  getUserViewCSVDataLoading,
  (loading) => !loading,
);

/** Returns the user's overall attendance percentage as a decimal number between 0 and 1 */
export const getUserViewOverallAttendance = createSelector(
  getUserViewSessions,
  (sessions) => {
    const sessionsAsArray = Object.values(sessions) as UserViewSession[];
    const sessionsPresent = sessionsAsArray.filter((session) => session.attendanceStatus === 'present').length;
    return sessionsPresent / sessionsAsArray.length;
  },
);
