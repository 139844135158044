import produce from 'immer';
import { RESET_ROOM, SharedAction } from 'actions/sharedActions';
import { NotesState } from 'store/types';
import {
  NotesAction, SET_NOTE, SET_NOTES_LOADING, SET_NOTES_OPEN,
} from 'actions/notesActions';

export const initialState: NotesState = {
  isNotesOpen: false,
  note: null,
  loading: false,
};

const reducer = produce((draft: NotesState, action: NotesAction | SharedAction) => {
  switch (action.type) {
    case SET_NOTES_OPEN:
      draft.isNotesOpen = !draft.isNotesOpen;
      break;
    case SET_NOTE:
      draft.note = action.payload.note;
      break;
    case SET_NOTES_LOADING:
      draft.loading = action.payload.loading;
      break;
    case RESET_ROOM:
      Object.assign(draft, initialState);
      break;
    default:
      break;
  }
}, initialState);

export default reducer;
