/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import {
  ComponentProps,
  ComponentPropsWithoutRef,
  ElementType, Ref, forwardRef,
  ReactElement, useLayoutEffect, useMemo, useRef, MutableRefObject,
} from 'react';
import { formatTextAsId } from 'utils/stringUtils';
import { ExcludeType } from 'utils/typeUtils';
import { nanoid } from 'nanoid';
import { LabeledSvgComponent, UnlabeledSvgComponent } from 'store/types';

export type ReactElementOfType<T extends ElementType> = ReactElement<ComponentPropsWithoutRef<T>, T>

/**
 * If aria-hidden="true", then accessibility props should NOT be specified.
 */
type SVGHiddenProps = {
  'aria-hidden': 'true' | true,
  svg: UnlabeledSvgComponent,
  titleId?: undefined,
  title?: undefined,
  desc?: undefined,
  descId?: undefined,
}

/**
 * If aria-hidden !== "true", then accessibility props SHOULD be specified.
 *
 * `descId` should only be specified if `desc` is specified
 *
 * `excludeEmptyString` is a little hacky, but it allows us to require that
 * titleId be any string EXCEPT for the empty string type ''.
 * TypeScript doesn't have any formal way of representing this, so
 * using a custom Generic parameter here lets us "turn off" this requirement
 * in the actual component, so that is treated like a normal string type there.
 */
type SVGAccessibilityProps<T, D, excludeEmptyString extends boolean = true> = {
  'aria-hidden'?: 'false' | false,
  svg: UnlabeledSvgComponent,
  title: excludeEmptyString extends true ? ExcludeType<T, string, ''> : string, // any string except for "",
  titleId?: string,
} & ({
  desc?: undefined,
  descId?: undefined,
} | {
  desc: excludeEmptyString extends true ? ExcludeType<D, string, ''> : string, // any string except for ""
  descId?: string,
});

type SvgProps<T, D, excludeEmptyString extends boolean = true> = ComponentProps<'svg'> & (
  SVGHiddenProps | SVGAccessibilityProps<T, D, excludeEmptyString>
);

const formatTextAsUid = (text: string) => `svg-${formatTextAsId(text)}-${nanoid(5)}`;

/**
 * Wrapper component for Svg components that are imported as
 * `import { ReactComponent as SomeSvg } from 'path/to/cool.svg'
 *
 * This component allows to us to more conveniently supply accessibility
 * labels like <title /> and <desc /> to Svg components that we would otherwise
 * have no convenient way of labelling.
 */
function Svg<T, D>({
  svg: _svg, title: _title, titleId: titleIdProp,
  desc: _desc, descId: descIdProp, 'aria-hidden': ariaHidden = false, ...rest
}: SvgProps<T, D>, ref: Ref<SVGElement>) {
  // treat title & desc as normal types here
  const title = _title as SvgProps<T, D, false>['title'];
  const desc = _desc as SvgProps<T, D, false>['desc'];
  const PlainSvg = _svg as unknown as LabeledSvgComponent;
  const svgRef = useRef<SVGElement | null>(null);
  const isProduction = process.env.NODE_ENV === 'production';
  const defaultTitleId = useMemo(() => formatTextAsUid(title || ''), [title]);
  const defaultDescId = useMemo(() => formatTextAsUid(desc || ''), [desc]);
  const titleId = titleIdProp || defaultTitleId;
  const descId = descIdProp || defaultDescId;

  if (!isProduction && ((typeof title === 'string' && !title) || (typeof desc === 'string' && !desc))) {
    throw new Error('title and desc must not be empty strings');
  }

  if (!isProduction && titleId && descId && titleId === descId) {
    throw new Error(`titleId and descId must be unique: titleId === ${titleId} and descId === ${titleId}`);
  }

  useLayoutEffect(() => {
    if (svgRef.current && !ariaHidden) {
      if (!ariaHidden) {
        // do not manipulate DOM directly more than is necessary
        const fragment = document.createDocumentFragment();
        svgRef.current.setAttribute('role', 'img');

        const existingTitleEl = svgRef.current.querySelector('title');
        if (title && titleId && !existingTitleEl) {
          // create new accessibility elements
          const titleEl = document.createElement('title');
          titleEl.id = titleId;
          titleEl.textContent = title as string;
          fragment.append(titleEl);
        } else if (title && titleId && existingTitleEl) {
          // update existing accessibility elements
          existingTitleEl.id = titleId;
          existingTitleEl.textContent = title;
        }

        const existingDescEl = svgRef.current.querySelector('desc');
        if (desc && descId && !existingDescEl) {
          // create new accessibility elements
          const descEl = document.createElement('desc');
          descEl.id = descId;
          descEl.textContent = desc;
          fragment.append(descEl);
        } else if (desc && descId && existingDescEl) {
          // update existing accessibility elements
          existingDescEl.id = descId;
          existingDescEl.textContent = desc;
        }

        if (fragment.hasChildNodes()) {
          svgRef.current.prepend(fragment);
        }

        const ids: string[] = [];
        if (title && titleId) ids.push(titleId);
        if (desc && descId) ids.push(descId);
        svgRef.current.setAttribute('aria-labelledby', ids.join(' ').trim());
      }
    } else if (svgRef.current) {
      // remove any superfluous accessibility labels
      svgRef.current.querySelector('title')?.remove();
      svgRef.current.querySelector('desc')?.remove();
      svgRef.current.removeAttribute('aria-labelledby');
    }
  }, [title, titleId, desc, descId, ariaHidden]);

  return (
    <PlainSvg
      ref={(el) => {
        // forward ref up
        if (ref) {
          if (typeof ref === 'function') ref(el);
          else (ref as MutableRefObject<SVGElement | null>).current = el;
        }
        // use ref in component too
        svgRef.current = el;
      }}
      aria-hidden={ariaHidden}
      {...rest}
    />
  );
}

/** Hack to use generic props while still using forwardRef
 * @see https://stackoverflow.com/a/58473012/14967537 */
export default forwardRef(Svg) as <T, D>(props: SvgProps<T, D>) => ReactElement;
