import { useRef, useEffect } from 'react';

/**
 * Store a previous prop or piece of state
 * Useful replacement for prevProps that were available with componentDidUpdate
 */
const usePrevious = <Value extends unknown>(value: Value) => {
  const ref = useRef(value);

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]);

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export default usePrevious;
