/* eslint-disable import/prefer-default-export */
interface GaussianReturnType {
  kernel: number[]
   kernelSize: number,
  kernelWeight: number
}

export class Kernel {
  private static cache = new Map<string, unknown>();

  private static getHashId(fn: (typeof Kernel)[keyof typeof Kernel], kernelSize = 0) {
    switch (fn) {
      case this.gaussian:
        return `gaussian-${kernelSize}`;
      default:
        throw new Error('Unexpected case reached');
    }
  }

  public static gaussian(kernelSize: number): GaussianReturnType {
    const hash = this.getHashId(this.gaussian, kernelSize);

    // return pre-made one if it has already been created before
    if (this.cache.has(hash)) return this.cache.get(hash) as GaussianReturnType;

    // else make new kernel
    const kernel: number[] = new Array(kernelSize ** 2);
    const sigma = 1.4;
    for (let x = 0; x < kernelSize; x++) {
      for (let y = 0; y < kernelSize; y++) {
        kernel[y * kernelSize + x] = Math.exp(-((x ** 2 + y ** 2) / (2 * sigma ** 2))) / (2 * Math.PI * sigma ** 2);
      }
    }
    const sum = kernel.reduce((a, b) => a + b);
    kernel.map((el) => el / sum); // Normalize the kernel
    const kernelWeight = kernel.reduce((a, b) => a + b);
    const returnValue = { kernel, kernelSize, kernelWeight };
    this.cache.set(hash, returnValue);
    return returnValue;
  }
}
