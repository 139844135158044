import { GifResult, GifsResult } from '@giphy/js-fetch-api';
import { AppThunkAction } from 'store/types';
import { giphyClient, PRESET_GIFS } from 'utils/giphy';
import logger from 'utils/logger';
import { getMessageFromError } from 'utils/errorUtils';
import { MakeActionType } from 'utils/typeUtils';
import { setRoomError } from './sharedActions';

export const reducerName = 'giphyState' as const;
export const UPDATE_GIF_DATA = `${reducerName}/UPDATE_GIF_DATA` as const;
export const SET_PRESET_GIFS = `${reducerName}/SET_PRESET_GIFS` as const;

const updateGifData = (id: string, data: GifResult) => ({
  type: UPDATE_GIF_DATA,
  payload: { id, data },
});

const setPresetGifs = (presetGifs: GifsResult) => ({
  type: SET_PRESET_GIFS,
  payload: { presetGifs },
});

export type GiphyAction = MakeActionType<[
  typeof updateGifData,
  typeof setPresetGifs,
]>

const dummyResult: GifsResult = { data: [], meta: { msg: '', response_id: '', status: -1 }, pagination: { count: 0, total_count: 0, offset: 0 } };

/** Fetches and saves GIPHY data for all preset gifs */
export const fetchPresetGifs = (): AppThunkAction => async (dispatch): Promise<GifsResult> => {
  logger.info('Fetching preset GIFs');
  try {
    const res = await giphyClient.gifs(PRESET_GIFS);
    dispatch(setPresetGifs(res));
    return res;
  } catch (error) {
    const message = 'Error fetching preset GIFs';
    logger.warn(message, { error } as any);
    dispatch(setRoomError(`${message}. ${getMessageFromError(error)}`));
  }
  return dummyResult;
};

/** Fetches and saves GIPHY data for one gif */
export const fetchGif = (gifId: string): AppThunkAction => async (dispatch) => {
  logger.info('Fetching GIF', { gifId });
  try {
    const res = await giphyClient.gif(gifId);
    dispatch(updateGifData(gifId, res));
  } catch (error) {
    const message = 'Error fetching GIF';
    logger.warn(message, { error, gifId } as any);
    dispatch(setRoomError(`${message}. ${getMessageFromError(error)}`));
  }
};

/** Search for GIPHY gifs */
export const searchGifs = (offset: number, query: string): AppThunkAction => async (dispatch): Promise<GifsResult> => {
  logger.info('Searching for GIFs', { offset, query });
  try {
    const res = await giphyClient.search(query, { offset, rating: 'g' });
    return res;
  } catch (error) {
    const message = 'Error searching GIFs';
    logger.warn(message, { error } as any);
    dispatch(setRoomError(`${message}. ${getMessageFromError(error)}`));
  }
  return dummyResult;
};
