import { StoredKNRoom } from 'store/types';
import { CCServiceRoomParticipants, CCServiceRoomState, CCServiceRoster } from 'utils/ccService/legacy';

const convertParticipants = (legacyParticipants: CCServiceRoomParticipants): StoredKNRoom['participants'] => (
  Object.values(legacyParticipants).reduce<StoredKNRoom['participants']>((obj, participant) => {
    obj[participant.userId] = {
      userId: participant.userId,
      roles: { },
      sessionParticipantId: '',
      reqId: '',
      displayName: participant.displayName,
      peerId: '',
      callBaseUrl: '',
      callToken: '',
      isHandRaised: false,
      handRaisedTimeStampMillis: -1,
      isScreenShared: false,
      nudged: false,
      nudgeAcknowledged: false,
      reserved: '',
      profileUrl: '',
    };
    return obj;
  }, {})
);

export const convertLegacyRoomRosterToKnats = (legacy: CCServiceRoster): StoredKNRoom['roomRoster'] => (
  legacy.reduce<StoredKNRoom['roomRoster']>((roster, rosterUser) => {
    roster[rosterUser.id] = { ...rosterUser, id: `${rosterUser.id}` };
    return roster;
  }, {} as StoredKNRoom['roomRoster'])
);

export const convertLegacyRoomStateToKnats = (legacy: CCServiceRoomState): StoredKNRoom => {
  const participants = convertParticipants(legacy.participants);

  Object.values(participants).forEach((participant) => {
    if (participant) {
      participant.isHandRaised = !!legacy.raiseHand.find(({ userId }) => userId === participant.userId);
      participant.isScreenShared = !!legacy.screenSharer.find((userId) => userId === participant.userId);
    }
  });

  let ownerId = '';
  Object.values(legacy.participants).forEach((participant) => {
    if (participant.owner) {
      ownerId = participant.userId;
    }
  });

  return {
    id: legacy.id,
    roomName: '',
    callId: legacy.callId,
    isJoinable: legacy.isJoinable,
    participants,
    ownerJoinUnassigned: !!legacy.ownerJoinUnassigned,
    ownerBroadcastData: {} as any,
    breakoutRooms: Object.entries(legacy.breakoutRooms).reduce<StoredKNRoom['breakoutRooms']>((obj, [key, val]) => {
      obj[key] = {
        ...val,
        users: val.users.reduce<StoredKNRoom['breakoutRooms'][string]['users']>((users, userId) => {
          users[userId] = true;
          return users;
        }, {}),
      };
      return obj;
    }, {}),
    ownerId,
    ownerPresent: legacy.ownerPresent,
    isLocked: !!legacy.isLocked,
    knockers: legacy.knockers,
    roomRoster: {},
    isLoginRequired: !!legacy.requireLogin,
    reserved: {},
    roomPermissions: {},
    questions: legacy.questions,
    calledOnId: legacy.calledOnId,
    openroom: !!legacy.openroom,
    recordingStart: legacy.recordingStart,
  };
};
