import { getHexByTheme } from 'components/Chalkboard/common/colorUtils';
import { TEAL_TRUE } from 'theme/ui/colors';
import { BASE_FONT_REM } from '../../config';
import { RenderItemFunc } from '../common';
import { SELECTED_ITEM_WIDTH } from '../renderItem';

const renderText: RenderItemFunc<'text'> = (item, theme, existing) => {
  const el = existing || document.createElement('p');

  if (item.drawData.slices.length === 0) return el;

  const { size } = item.bounds;

  el.style.width = `${size.width}px`;
  el.style.height = `${size.height}px`;

  if (item.isSelected) {
    el.style.outline = `${SELECTED_ITEM_WIDTH}px solid ${TEAL_TRUE}`;
  }

  el.style.fontFamily = 'Quicksand, sans-serif'; /** @todo could be part of the item.drawData */

  let spans: HTMLSpanElement[] = [];
  if (existing) {
    spans = Array.from(el.querySelectorAll('span'));
  }

  for (let i = 0; i < item.drawData.slices.length; i++) {
    const slice = item.drawData.slices[i];
    const existingSpan = spans[i];

    const span = existingSpan || document.createElement('span');

    span.textContent = slice.content;
    span.style.color = getHexByTheme(slice.fontColor, theme);
    span.style.fontSize = slice.fontSize || `${BASE_FONT_REM}rem`;
    span.style.whiteSpace = 'pre-line';
    span.style.wordBreak = 'break-word'; /** @todo textarea should auto-grow width instead */

    if (!existingSpan) el.appendChild(span);
  }

  return el;
};

export default renderText;
