/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import { CSS } from 'types/css';
import { ColorsEnum, WHITE } from 'theme/ui/colors';
import { ReactComponent as GoogleLogoSVG } from 'assets/logos/google-g-logo.svg';
import { useDispatch } from 'react-redux';
import { logout } from 'actions/loginActions';
import FocusRing from 'components/FocusRing/FocusRing';
import { ComponentPropsWithoutRef } from 'react';
import Svg from 'components/ui/Svg';

const buttonStyles: CSS = {
  transition: 'box-shadow 0.1s',
  variant: 'buttons.neutral',
  height: '4.4rem',
  width: '100%',
  borderRadius: '10rem',
  bg: WHITE,
  boxShadow: `0px 4px 16px ${ColorsEnum.GREY_40.withOpacity(0.3)}`,
  p: '0.8rem',
  position: 'relative',
  '&:active': {
    boxShadow: 'none',
  },
};

const svgStyles: CSS = {
  position: 'absolute',
  top: '50%',
  left: '0.8rem',
  transform: 'translateY(-50%)',
  height: '2.8rem',
  width: '2.8rem',
  minHeight: '2.8rem',
  minWidth: '2.8rem',
  maxWidth: '2.8rem',
  maxHeight: '2.8rem',
};

const textStyles: CSS = {
  variant: 'text.body_small',
};

interface GoogleButtonProps extends ComponentPropsWithoutRef<'button'>{
  redirectUrl: string,
}

export default function GoogleButton({ redirectUrl, ...rest }: GoogleButtonProps) {
  const dispatch = useDispatch();
  const handleClick = () => {
    // make sure user is logged out before being redirected
    dispatch(logout());
    window.location.href = `${appConfig.ccServiceApi}/api/chalkcast/v1/auth/google${redirectUrl ? `?redirectUrl=${redirectUrl}` : ''}`;
  };
  return (
    <button sx={buttonStyles} type="button" onClick={handleClick} {...rest}>
      <Svg svg={GoogleLogoSVG} sx={svgStyles} aria-hidden />
      <span sx={textStyles}>Continue with Google</span>
      <FocusRing rounded />
    </button>
  );
}
