import { MakeActionType } from 'utils/typeUtils';

const reducerName = 'network' as const;
export const SET_ONLINE = `${reducerName}/SET_ONLINE` as const;

export const setOnline = (online: boolean) => ({
  type: SET_ONLINE,
  payload: { online },
});

export type NetworkAction = MakeActionType<[
  typeof setOnline,
]>
