/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import {
  setCodecEnabled, sortBitrateOptions, toggleBitrateOptionsModal, toggleDebugModal, toggleShowBitrateLevels,
} from 'actions/debugActions';
import { setNumBitrates, shiftBitrateAndResolution } from 'actions/encoderActions';
import Button from 'components/ui/Button';
import Modal from 'components/ui/Modal/Modal';
import ModalBody from 'components/ui/Modal/ModalBody';
import ModalFooter from 'components/ui/Modal/ModalFooter';
import ModalTitle from 'components/ui/Modal/ModalTitle';
import Switch from 'components/ui/Switch';
import {
  Fragment, useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCodecEnabled, getIsBitrateOptionsModalOpen, getShowBitrateLevels, getNumBitrates,
} from 'selectors';
import logger from 'utils/logger';
import { lowEncodings } from 'utils/encoder/encodings';
import { CSS } from 'types/css';
import SelectInput from 'components/Input/SelectInput';
import { GREY_70 } from 'theme/ui/colors';
import BitrateOptionInputs from './BitrateOptionInputs';

const modalBodyStyles: CSS = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '1rem',
  width: 'fit-content',
  m: '1rem auto',
};

const bitrateOps = [
  { id: '1', value: '1', label: '1' },
  { id: '2', value: '2', label: '2' },
  { id: '3', value: '3', label: '3' },
];

export default function BitrateOptionsModal() {
  const dispatch = useDispatch();
  const open = useSelector(getIsBitrateOptionsModalOpen);
  const showBitrateLevels = useSelector(getShowBitrateLevels);
  const codecEnabled = useSelector(getCodecEnabled);
  const numBitrates = useSelector(getNumBitrates);

  const [shouldApply, setShouldApply] = useState(false);

  const toggle = useCallback(() => dispatch(toggleBitrateOptionsModal()), [dispatch]);

  const goBack = useCallback(() => {
    dispatch(toggleBitrateOptionsModal(false));
    dispatch(toggleDebugModal(true));
  }, [dispatch]);

  const applyUpdates = useCallback(() => {
    logger.info('Updating bitrates from debug modal');
    setShouldApply(false);
    dispatch(shiftBitrateAndResolution(numBitrates));
  }, [dispatch, numBitrates]);

  useEffect(() => () => {
    dispatch(sortBitrateOptions());
  }, [dispatch]);

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      width="narrow"
      minContentHeight
      addFooterSpacing
    >
      <ModalTitle toggle={toggle}>Bitrate Options</ModalTitle>
      <ModalBody>
        <div sx={modalBodyStyles}>
          <Switch
            variant="dark"
            id="bitrate-level-indicator-switch"
            label="Enable Bitrate Level Overlays on Video Tiles"
            checked={showBitrateLevels}
            onChange={() => dispatch(toggleShowBitrateLevels())}
          />
          <Switch
            variant="dark"
            id="codec-enabled-input"
            label="Codec"
            checked={codecEnabled}
            onChange={() => {
              dispatch(setCodecEnabled(!codecEnabled));
              setShouldApply(true);
            }}
          />
          <SelectInput
            id="bitrate-number"
            label="Bitrates"
            data-selenium="device-selection__microphone"
            onChange={(e:any) => {
              const val = e.target.value;
              logger.info(`Setting number of bitrates to ${val}`);
              dispatch(setNumBitrates(+val as 1|2|3));
              setShouldApply(true);
            }}
            options={bitrateOps}
            value={`${numBitrates}`}
            componentStyles={{ width: '100%', '& label': { color: `${GREY_70} !important` } }}
          />
          {numBitrates === 3 ? (
            <Fragment>
              <BitrateOptionInputs option="High" setShouldApply={setShouldApply} />
              <BitrateOptionInputs option="Medium" setShouldApply={setShouldApply} />
              <BitrateOptionInputs option="Low" setShouldApply={setShouldApply} />
            </Fragment>
          ) : (
            <Fragment>
              <p>Broadcast at:</p>
              {lowEncodings.map((value) => <p key={value.maxBitrate}>{value.maxBitrate}</p>)}
            </Fragment>
          )}
          <Button onClick={applyUpdates} disabled={!shouldApply}>
            Apply Changes
          </Button>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="secondaryLight" onClick={goBack}>Back</Button>
        <Button onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
  );
}
