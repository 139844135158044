/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import Button from 'components/ui/Button';
import { useCallback, useEffect } from 'react';
import Input from 'components/ui/Input';
import {
  GREY_70, RED_MEDIUM, TEAL_DARK,
} from 'theme/ui/colors';
import { resetAuthFormError, signupUser, validateSignUpData } from 'actions/loginActions';
import { useDispatch } from 'react-redux';
import { getAuthFormError, getAuthFormSending } from 'selectors';
import useLoginContext from 'hooks/useLoginContext';
import useStateIfMounted from 'hooks/useStateIfMounted';
import useTypedSelector from 'hooks/useTypedSelector';
import { ReactComponent as WarningSVG } from 'assets/icons/warning.svg';
// import mixpanel from 'utils/mixpanel';
import validatePassword from 'utils/validatePassword';
import validateEmail from 'utils/validateEmail';
import { CSS } from 'types/css';
import Svg from 'components/ui/Svg';
import validateName from 'utils/validateName';
import ExternalLinkEnum from 'utils/externalLinkEnum';
import GoogleButton from './GoogleButton';
import LoginSignupSwitch from './LoginSignupSwitch';

const containerStyles: CSS = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  maxWidth: '38.3rem',
  mx: 'auto',
};

const formStyles: CSS = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

/* preserve spacing between button and
privacy policy when a form error appears */
const buttonAndErrorContainerStyles: CSS = {
  width: '100%',
  minHeight: '10rem',
  mb: '1.6rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

const submitButtonStyles: CSS = {
  width: '100%',
};

const formErrorStyles: CSS = {
  variant: 'text.caption',
  mt: '1.6rem',
  color: RED_MEDIUM,
  display: 'flex',
  alignItems: 'center',
};

const warningIconStyles: CSS = {
  fill: RED_MEDIUM,
  mr: '0.5rem',
};

const privacyPolicyStyles: CSS = {
  variant: 'text.caption',
  color: GREY_70,
  textAlign: 'center',
  '& a': {
    color: TEAL_DARK,
    textDecoration: 'underline',
  },
};

const nameInputStyles: CSS = {
  marginBottom: '2.2rem',
  minWidth: 'unset',
};

const nameInputContainerStyles: CSS = {
  display: 'flex',
  gap: '1rem',
  flexWrap: ['wrap', 'wrap', 'wrap', 'nowrap'],
  justifyContent: 'space-between',
  marginBottom: '2.2rem',
};

export default function SignupForm() {
  const dispatch = useDispatch();
  const formError = useTypedSelector(getAuthFormError);
  const isSending = useTypedSelector(getAuthFormSending);
  const [email, setEmail] = useStateIfMounted('');
  const [password, setPassword] = useStateIfMounted('');
  const [firstName, setFirstName] = useStateIfMounted('');
  const [lastName, setLastName] = useStateIfMounted('');
  const {
    singlePage, setLoginView, redirectUrl,
  } = useLoginContext();

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    // mixpanel.track('Sign Up Button Click');
    dispatch(signupUser({
      email, password, firstName, lastName,
    }));
  }, [email, password, firstName, lastName, dispatch]);

  useEffect(() => () => {
    dispatch(resetAuthFormError());
  }, [dispatch]);

  return (
    <section sx={containerStyles}>
      <GoogleButton
        redirectUrl={redirectUrl}
        data-selenium="signup-form__continue-with-google-button"
        sx={{ mb: '4rem' }}
      />
      <LoginSignupSwitch
        setLoginView={setLoginView}
        singlePage={singlePage}
        isLogin={false}
        data-selenium="signup-form__go-to-login-link"
      />
      <form sx={formStyles} onSubmit={handleSubmit}>
        <div sx={nameInputContainerStyles}>
          <Input
            value={firstName}
            onChangeSanitized={setFirstName}
            sanitizeOnBlur={(val) => val.trim()}
            wide
            disabled={isSending}
            label="First Name"
            id="first-name"
            validate={validateName}
            autoComplete="given-name"
            data-selenium="signup-first-name-input"
            required
            componentStyles={nameInputStyles}
          />
          <Input
            value={lastName}
            onChangeSanitized={setLastName}
            sanitizeOnBlur={(val) => val.trim()}
            wide
            disabled={isSending}
            label="Last Name"
            id="last-name"
            validate={validateName}
            autoComplete="family-name"
            data-selenium="signup-form__last-name-input"
            required
            componentStyles={nameInputStyles}
          />
        </div>
        <Input
          value={email}
          onChangeSanitized={setEmail}
          sanitizeOnBlur={(val) => val.trim()}
          wide
          disabled={isSending}
          label="Email address"
          id="email"
          validate={validateEmail}
          autoComplete="email"
          data-selenium="signup-form__email-input"
          required
          componentStyles={{ mb: '4.4rem' }}
        />
        <Input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          wide
          label="Password"
          type="password"
          id="password"
          minLength={8}
          validate={validatePassword}
          disabled={isSending}
          autoComplete="off"
          name="password"
          data-selenium="signup-form__password-input"
          componentStyles={{ mb: '4.4rem' }}
          required
        />
        <div sx={buttonAndErrorContainerStyles}>
          <Button
            type="submit"
            sx={submitButtonStyles}
            data-selenium="signup-form__log-in-button"
            disabled={isSending || validateSignUpData({
              email, firstName, lastName, password,
            }).containsError}
          >
            Agree and continue
          </Button>
          {formError && (
          <p sx={formErrorStyles}>
            <Svg svg={WarningSVG} sx={warningIconStyles} aria-hidden />
            {formError}
          </p>
          )}
        </div>
        <p sx={privacyPolicyStyles}>
          By creating an account, you agree to ChalkCast&apos;s
          {' '}
          <a href={ExternalLinkEnum.TERMS_OF_SERVICE} target="_blank" rel="noopener noreferrer">Terms of Service</a>
          {' '}
          and
          {' '}
          <a href={ExternalLinkEnum.PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">Privacy Policy</a>
          .
        </p>
      </form>
    </section>
  );
}
