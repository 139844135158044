import { GifResult } from '@giphy/js-fetch-api';
import { batch } from 'react-redux';
import { AppThunkAction, Nudge } from 'store/types';
import logger from 'utils/logger';
// import mixpanel, { asYesNo } from 'utils/mixpanel';
import { getMessageFromError } from 'utils/errorUtils';
import { MakeActionType } from 'utils/typeUtils';
import {
  getCurrentUserId, getIsAllowedToNudge, getIsCurrentUserOwner, getNudge, getRoomPermission,
} from 'selectors';
import WS from '@livelyvideo/hub-websocket';
import { setRoomAlert } from './alertActions';
import { publishToRoom, sendWebsocketMessage } from './roomActions';
import {
  activateNudge, deactivateNudge, handleError, setRoomError,
} from './sharedActions';

const reducerName = 'nudgeState' as const;
export const SET_NUDGING_USER_ID = `${reducerName}/SET_NUDGING_USER_ID` as const;
export const SET_SELECTED_NUDGE_GIF_ID = `${reducerName}/SET_SELECTED_NUDGE_GIF_ID` as const;
export const SET_SELECTED_NUDGE_EMOJI = `${reducerName}/SET_SELECTED_NUDGE_EMOJI` as const;

/** Used to select user for nudge input, not for active sent/received nudge */
export const setNudgingUser = (userId: string) => ({
  type: SET_NUDGING_USER_ID,
  payload: { userId },
});

export const setSelectedNudgeGif = (gif: GifResult['data'] | null) => ({
  type: SET_SELECTED_NUDGE_GIF_ID,
  payload: { gif },
});

export const unsetSelectedNudgeGif = () => setSelectedNudgeGif(null);

export const setSelectedNudgeEmoji = (emoji: string) => ({
  type: SET_SELECTED_NUDGE_EMOJI,
  payload: { emoji },
});

export const unsetSelectedNudgeEmoji = () => setSelectedNudgeEmoji('');

export const unsetNudgingUser = (): AppThunkAction => (dispatch) => {
  batch(() => {
    dispatch(setNudgingUser(''));
    dispatch(unsetSelectedNudgeGif());
  });
};

export type NudgeAction = MakeActionType<[
  typeof setNudgingUser,
  typeof setSelectedNudgeGif,
  typeof setSelectedNudgeEmoji,
]>

export const NUDGE_CHAR_MAX = 60;

/** Returns true if request was successful */
export const sendNudge = (userId: string, nudge: Nudge): AppThunkAction => async (dispatch, getState) => {
  const state = getState();

  const { roomState: { socket, ownerId, room: { id: roomId, participants } }, loginState: { user } } = state;

  const currentUserId = getCurrentUserId(state);
  const isOwner = getIsCurrentUserOwner(state);
  const canPrivateNudge = getRoomPermission('privateNudge')(state);
  const isAllowed = getIsAllowedToNudge(state);

  let invalidReason = '';
  if (!roomId) {
    invalidReason = 'No room ID';
  } else if (!currentUserId) {
    invalidReason = 'No current user ID';
  } else if (!isAllowed) {
    invalidReason = 'Sending nudge is not permitted for this user';
  } else if (!socket?.connected) {
    invalidReason = 'Websocket is not ready';
  }

  if (invalidReason) {
    logger.warn(invalidReason, {
      reason: invalidReason,
      ownerId,
      userId: user?.userId,
      nudge,
      canPrivateNudge,
      isSocket: !!socket,
      socketConnected: socket?.connected,
    } as any);
    return false;
  }

  nudge.message = nudge.message.slice(0, NUDGE_CHAR_MAX).trim();

  try {
    dispatch(activateNudge(userId, nudge));
    logger.info('Sending nudge', { userId, nudge } as any);
    if (isOwner) {
      await publishToRoom(roomId as string, 'nudge', { userId, nudge });
    } else {
      sendWebsocketMessage(socket as WS, 'nudge-private', { body: { to: userId, from: currentUserId || '', nudge } });
    }
    // mixpanel.track('Nudge Sent', {
    //   Message: asYesNo(!!nudge.message),
    //   Gif: asYesNo(!!nudge.gifId),
    // });
  } catch (error) {
    const errMess = 'Failed to send nudge';
    batch(() => {
      dispatch(handleError(errMess, { error }));
      dispatch(setRoomError(`${errMess}. ${getMessageFromError(error)}`));
      dispatch(deactivateNudge(userId));
    });
    return false;
  }
  dispatch(setRoomAlert(`Nudge sent to ${participants[userId]?.displayName || '<Name N/A>'}`));
  return true;
};

/** Returns true if request was successful. This doubles as both a user acknowledging a nudge from the owner and the owner unsending a nudge */
export const acknowledgeNudge = (userId: string): AppThunkAction => async (dispatch, getState) => {
  const state = getState();

  const {
    roomState: {
      socket,
      room: { id: roomId, ownerId },
    },
  } = state;

  const currentUserId = getCurrentUserId(state);
  const isOwner = getIsCurrentUserOwner(state);
  const nudge = getNudge(userId)(state);

  const isAcknowledging = userId === currentUserId;
  const isUnsending = nudge?.fromUserId === currentUserId;

  let invalidReason = '';
  if (!socket) {
    invalidReason = 'No websocket';
  } else if (!nudge) {
    invalidReason = 'No nudge to acknowledge';
  } else if (!roomId) {
    invalidReason = 'No room id';
  } else if (!isAcknowledging && !isUnsending) {
    invalidReason = 'Cannot acknowledge nudge that was not sent or received by current user';
  }

  if (invalidReason) {
    logger.warn('Invalid state to acknowledge nudge', {
      reason: invalidReason, userId,
    });
    return false;
  }

  dispatch(deactivateNudge(userId));

  try {
    logger.info(`${isUnsending ? 'Unsending' : 'Acknowledging'} nudge`, { userId, nudge } as any);
    if (nudge?.fromUserId === ownerId && isOwner) {
      await sendWebsocketMessage(socket as WS, 'nudge-ack', { userId, roomId: roomId as string });
    } else {
      await sendWebsocketMessage(socket as WS, 'nudge-ack-private', {
        body: {
          to: nudge?.toUserId || '',
          from: isUnsending ? nudge?.toUserId || '' : nudge?.fromUserId || '',
        },
      });
    }
  } catch (error) {
    const message = `Failed to ${isUnsending ? 'unsend' : 'acknowledge'} nudge`;
    logger.error(message, { error, userId } as any);
    dispatch(setRoomError(`${message}. ${getMessageFromError(error)}`));
    return false;
  }

  if (isUnsending) dispatch(setRoomAlert('Nudge unsent'));

  return true;
};
