import { types } from '@livelyvideo/video-client-web';
import {
  FaceDetectionEvent,
  Filter, FilterEvent, Filters, FiltersKeys, Logger, TensorFlowBackends,
} from 'filters';
import { batch } from 'react-redux';
import { getEncoderRenderId } from 'selectors';
import { AppThunkAction } from 'store/types';
import { errorHasMessage } from 'utils/errorUtils';
import logger from 'utils/logger';
import { MakeActionType } from 'utils/typeUtils';
import { cleanUpBroadcast, cleanUpMediaStreamControllers, createMediaStreamControllers } from './encoderActions';

export const reducerName = 'filterState' as const;
export const SET_FILTER = `${reducerName}/SET_FILTER` as const;
export const SET_IS_FILTERING = `${reducerName}/SET_IS_FILTERING` as const;
export const SET_IS_FILTER_LOADING = `${reducerName}/SET_IS_FILTER_LOADING` as const;
export const SET_SRC_VIDEO = `${reducerName}/SET_SRC_VIDEO` as const;
export const SET_DEST_CANVAS = `${reducerName}/SET_DEST_CANVAS` as const;
export const SET_TENSOR_FLOW_BACKEND = `${reducerName}/SET_TENSOR_FLOW_BACKEND` as const;
export const SET_ENABLE_FACE_DETECTION = `${reducerName}/SET_ENABLE_FACE_DETECTION` as const;
export const SET_MAX_FRAME_RATE = `${reducerName}/SET_MAX_FRAME_RATE` as const;
export const SET_PREDICTION_INTERVAL = `${reducerName}/SET_PREDICTION_INTERVAL` as const;
export const SET_CROPPED_CANVAS_SIZE = `${reducerName}/SET_CROPPED_CANVAS_SIZE` as const;
export const SET_CURRENT_FILTER_KEY = `${reducerName}/SET_CURRENT_FILTER_KEY` as const;
export const SET_CANNY_EDGE_DETECTION_THRESHOLD = `${reducerName}/SET_CANNY_EDGE_DETECTION_THRESHOLD` as const;
export const SET_ENABLED_PUBLIC_FILTERS = `${reducerName}/SET_ENABLED_PUBLIC_FILTERS` as const;
export const RESET_FILTER_SETTINGS = `${reducerName}/RESET_FILTER_SETTINGS` as const;

export const setFilter = (filter: Filter | null) => ({
  type: SET_FILTER,
  payload: { filter },
});

export const setIsFiltering = (isFiltering: boolean) => ({
  type: SET_IS_FILTERING,
  payload: { isFiltering },
});

export const setIsFilterLoading = (loading: boolean) => ({
  type: SET_IS_FILTER_LOADING,
  payload: { loading },
});

export const setSrcVideo = (srcVideo: HTMLVideoElement | null) => ({
  type: SET_SRC_VIDEO,
  payload: { srcVideo },
});

export const setDestCanvas = (destCanvas: HTMLCanvasElement | null) => ({
  type: SET_DEST_CANVAS,
  payload: { destCanvas },
});

export const setTensorFlowBackend = (backend: TensorFlowBackends) => ({
  type: SET_TENSOR_FLOW_BACKEND,
  payload: { backend },
});

export const setEnableFaceDetection = (enable: boolean) => ({
  type: SET_ENABLE_FACE_DETECTION,
  payload: { enable },
});

export const setMaxFrameRate = (max: number) => ({
  type: SET_MAX_FRAME_RATE,
  payload: { max },
});

export const setPredictionInterval = (ms: number) => ({
  type: SET_PREDICTION_INTERVAL,
  payload: { ms },
});

export const setCroppedCanvasSize = (size: number) => ({
  type: SET_CROPPED_CANVAS_SIZE,
  payload: { size },
});

export const setCurrentFilterKey = (currentFilterKey: FiltersKeys) => ({
  type: SET_CURRENT_FILTER_KEY,
  payload: { currentFilterKey },
});

export const setCannyEdgeDetectionThreshold = (threshold: number) => ({
  type: SET_CANNY_EDGE_DETECTION_THRESHOLD,
  payload: { threshold },
});

export const setEnabledPublicFilters = (filters: Filters[]) => ({
  type: SET_ENABLED_PUBLIC_FILTERS,
  payload: { filters },
});

export const resetFilterSettings = () => ({
  type: RESET_FILTER_SETTINGS,
});

export type FilterAction = MakeActionType<[
  typeof setEnabledPublicFilters,
  typeof setCurrentFilterKey,
  typeof setCannyEdgeDetectionThreshold,
  typeof setTensorFlowBackend,
  typeof setEnableFaceDetection,
  typeof setMaxFrameRate,
  typeof setPredictionInterval,
  typeof setCroppedCanvasSize,
  typeof setFilter,
  typeof setIsFiltering,
  typeof setIsFilterLoading,
  typeof setSrcVideo,
  typeof setDestCanvas,
  typeof resetFilterSettings,
]>;

export const createSrcVideo = () => {
  const srcVideo = document.createElement('video');
  srcVideo.autoplay = true;
  srcVideo.playsInline = true;
  return srcVideo;
};

export const createDestCanvas = () => document.createElement('canvas');

export const resetFilterState = (debugString: string): AppThunkAction<Promise<void>> => async (dispatch) => {
  logger.debug('Resetting Filter state', { debugString });
  dispatch(cleanUpMediaStreamControllers(debugString));
  dispatch(resetFilterSettings());
  await dispatch(createMediaStreamControllers());
};

const addEventListenersToFilter = (filter: Filter): AppThunkAction => (dispatch, getState) => {
  // FILTER EVENTS
  filter.on(FilterEvent.CURRENT_FILTER_SET.id, () => {
    const currentFilter = filter.currentFilter.keyOf();
    logger.debug(`FilterEvent ${FilterEvent.CURRENT_FILTER_SET.id}`, {
      event: FilterEvent.CURRENT_FILTER_SET.id,
      currentFilter,
    });
    dispatch(setCurrentFilterKey(currentFilter));
  });
  filter.on(FilterEvent.DOUBLE_THRESHOLD_LOW_VALUE_SET.id, (high) => {
    logger.debug(`FilterEvent ${FilterEvent.DOUBLE_THRESHOLD_LOW_VALUE_SET.id}`, {
      event: FilterEvent.DOUBLE_THRESHOLD_LOW_VALUE_SET.id,
      high,
    });
    dispatch(setCannyEdgeDetectionThreshold(high));
  });
  filter.on(FilterEvent.DOUBLE_THRESHOLD_HIGH_VALUE_SET.id, (high) => {
    logger.debug(`FilterEvent ${FilterEvent.DOUBLE_THRESHOLD_HIGH_VALUE_SET.id}`, {
      event: FilterEvent.DOUBLE_THRESHOLD_HIGH_VALUE_SET.id,
      high,
    });
    dispatch(setCannyEdgeDetectionThreshold(high));
  });
  filter.on(FilterEvent.ENABLE_FACE_DETECTION_SET.id, (enabled) => {
    logger.debug(`FilterEvent ${FilterEvent.ENABLE_FACE_DETECTION_SET.id}`, {
      event: FilterEvent.ENABLE_FACE_DETECTION_SET.id,
      enabled,
    });
    dispatch(setEnableFaceDetection(enabled));
  });
  filter.on(FilterEvent.MAX_FRAME_RATE_SET.id, (rate) => {
    logger.debug(`FilterEvent ${FilterEvent.MAX_FRAME_RATE_SET.id}`, {
      event: FilterEvent.MAX_FRAME_RATE_SET.id,
      rate,
    });
    dispatch(setMaxFrameRate(rate));
  });
  filter.on(FilterEvent.CROPPED_CANVAS_SIZE_SET.id, (size) => {
    logger.debug(`FilterEvent ${FilterEvent.CROPPED_CANVAS_SIZE_SET.id}`, {
      event: FilterEvent.CROPPED_CANVAS_SIZE_SET.id,
      size,
    });
    dispatch(setCroppedCanvasSize(size));
  });
  filter.on(FilterEvent.PREDICTION_INTERVAL_SET.id, (interval) => {
    logger.debug(`FilterEvent ${FilterEvent.PREDICTION_INTERVAL_SET.id}`, {
      event: FilterEvent.PREDICTION_INTERVAL_SET.id,
      interval,
    });
    dispatch(setPredictionInterval(interval));
  });
  filter.on(FilterEvent.VIDEO_DATA_ALREADY_READY.id, () => {
    logger.debug(`FilterEvent ${FilterEvent.VIDEO_DATA_ALREADY_READY.id}`, {
      event: FilterEvent.VIDEO_DATA_ALREADY_READY.id,
      info: 'Filter srcVideo data is already ready at initialization',
    });
  });
  filter.on(FilterEvent.VIDEO_DATA_LOAD_START.id, () => {
    logger.debug(`FilterEvent ${FilterEvent.VIDEO_DATA_LOAD_START.id}`, {
      event: FilterEvent.VIDEO_DATA_LOAD_START.id,
      info: 'Filter srcVideo onloadstart event',
    });
  });
  filter.on(FilterEvent.VIDEO_DATA_LOADED.id, () => {
    logger.debug(`FilterEvent ${FilterEvent.VIDEO_DATA_LOADED.id}`, {
      event: FilterEvent.VIDEO_DATA_LOADED.id,
      info: 'Filter srcVideo onloadeddata event',
    });
  });
  filter.on(FilterEvent.VIDEO_DIMENSIONS_0_DETECTED.id, () => {
    logger.debug(`FilterEvent ${FilterEvent.VIDEO_DIMENSIONS_0_DETECTED.id}`, {
      event: FilterEvent.VIDEO_DIMENSIONS_0_DETECTED.id,
      info: 'Filter srcVideo width or height was 0',
    });
  });
  filter.on(FilterEvent.CLEANING_UP.id, () => {
    logger.debug(`FilterEvent ${FilterEvent.CLEANING_UP.id}`, {
      event: FilterEvent.CLEANING_UP.id,
    });
  });
  filter.on(FilterEvent.CONTEXT_LOST.id, async (event) => {
    /* A user's browser can lose the WebGL context for any number of reasons, including
    if we are trying to upload a videoElement as a texture before its data has loaded,
    if the tab is taking up too many GPU resources, or if the OS determines that the GPU is overworked.

    The GPU is a shared resource and losing the WebGL context is the primary way a user's
    computer can take back control of the GPU. But this circumstance should be very rare.
    Recovering from a lost WebGL context is difficult, and in practice, I've found
    resetting the media stream controllers, Filter class, and any saved
    filter settings to be the most reliable way to get things working again. */
    const state = getState();
    const {
      encoderState: {
        filterMediaStreamControllerLoading,
        filterMediaStreamControllerError,
        filterMediaStreamController,
        videoMediaStreamControllerLoading,
        videoMediaStreamControllerError,
        videoMediaStreamController,
      },
      filterState: {
        destCanvas,
        cannyEdgeDetectionThreshold,
        croppedCanvasSize,
        currentFilterKey,
        enableFaceDetection,
        enabledPublicFilters,
        isFilterLoading,
        isFiltering,
        maxFrameRate,
        predictionInterval,
        tensorFlowBackend,
        srcVideo,
      },
    } = state;

    // this error is very difficult to diagnose (and should be very rare),
    // so reporting as much info here as possible is helpful
    logger.error('WebGL context was lost', {
      event,
      encoderState: {
        filterMediaStreamControllerLoading,
        filterMediaStreamControllerError,
        filterMediaStreamControllerIsDefined: !!filterMediaStreamController,
        videoMediaStreamControllerLoading,
        videoMediaStreamControllerError,
        videoMediaStreamControllerIsDefined: !!videoMediaStreamController,
      },
      filterSettings: {
        cannyEdgeDetectionThreshold,
        croppedCanvasSize,
        currentFilterKey,
        enableFaceDetection,
        enabledPublicFilters,
        isFilterLoading,
        isFiltering,
        maxFrameRate,
        predictionInterval,
        tensorFlowBackend,
      },
      srcVideoInfo: {
        isDefined: !!srcVideo,
        videoWidth: srcVideo?.videoWidth,
        videoHeight: srcVideo?.videoHeight,
        readyState: srcVideo?.readyState,
      },
      destCanvasInfo: {
        isDefined: !!destCanvas,
        width: destCanvas?.width,
        height: destCanvas?.height,
        clientWidth: destCanvas?.clientWidth,
        clientHeight: destCanvas?.clientHeight,
      },
    } as any);

    /* if encoder is currently being rendered somewhere, we should try to recover the webgl context
    else, we can chalk it up to the mediaStreamControllers getting cleaned up */
    if (getEncoderRenderId(state)) {
      const message = 'Destroying and recreating media stream controllers and broadcast after WebGL context was lost';
      logger.debug(message);

      dispatch(cleanUpBroadcast(message));
      dispatch(resetFilterState(message));
    }
  });
  filter.on(FilterEvent.CONTEXT_RESTORED.id, () => {
    logger.debug(`FilterEvent ${FilterEvent.CONTEXT_RESTORED.id}`, {
      event: FilterEvent.CONTEXT_RESTORED.id,
    });
  });

  // FACE DETECTION EVENTS
  filter.on(FaceDetectionEvent.MODEL_CHOSEN.id, (model) => {
    logger.debug(`FaceDetectionEvent ${FaceDetectionEvent.MODEL_CHOSEN.id}`, {
      event: FaceDetectionEvent.MODEL_CHOSEN.id,
      model,
    });
  });
  filter.on(FaceDetectionEvent.INITIALIZING_MODEL.id, () => {
    logger.debug(`FaceDetectionEvent ${FaceDetectionEvent.INITIALIZING_MODEL.id}`, { event: FaceDetectionEvent.INITIALIZING_MODEL.id });
  });
  filter.on(FaceDetectionEvent.MODEL_READY.id, () => {
    logger.debug(`FaceDetectionEvent ${FaceDetectionEvent.MODEL_READY.id}`, { event: FaceDetectionEvent.MODEL_READY.id });
  });
  filter.on(FaceDetectionEvent.PREDICTION_COMPLETE.id, (prediction) => {
    logger.debug(`FaceDetectionEvent ${FaceDetectionEvent.PREDICTION_COMPLETE.id}`, {
      event: FaceDetectionEvent.PREDICTION_COMPLETE.id,
      prediction: prediction as any,
    });
  });
};

/** Setup Filter class to start filtering video from the source video to the to the destination canvas.
 * Make sure state in Redux is in sync (and stays in sync) with Filter's internal state */
export const startFiltering = (
  srcVideo: HTMLVideoElement,
  destCanvas: HTMLCanvasElement,
): AppThunkAction<Promise<void>> => async (dispatch, getState) => {
  const {
    filterState: {
      isFiltering, filter, tensorFlowBackend, enableFaceDetection,
      maxFrameRate, currentFilterKey, predictionInterval, croppedCanvasSize,
      srcVideo: prevSrcVideo, isFilterLoading,
    },
  } = getState();

  if (isFilterLoading) {
    logger.warn('Can\'t start filtering, because Filter is already loading', { isFilterLoading } as any);
    return;
  }

  if (isFiltering) {
    logger.warn('startFiltering was called while current Filter was already running', { isFiltering } as any);
  }

  if (filter) {
    logger.debug('Cleaning up existing Filter class in state');
    filter.cleanup();
  }

  if (prevSrcVideo && prevSrcVideo !== srcVideo) {
    logger.debug('Setting previous srcVideo.srcObject to null');
    prevSrcVideo.srcObject = null;
  }

  logger.debug('Setting up new Filter class');

  try {
    dispatch(setIsFilterLoading(true));

    /** This value may be nullish if the user's saved filter is no longer supported
     * or if the key of the filter variant has changed */
    const initialFilter = Filters[currentFilterKey] ?? Filters.PASS_THROUGH;

    const newFilter = new Filter(srcVideo, destCanvas, {
      tensorFlowBackend,
      enableFaceDetection,
      maxFrameRate,
      initialFilter,
      predictionInterval,
      croppedCanvasSize,
      logger: logger as Logger,
    });

    dispatch(addEventListenersToFilter(newFilter));

    batch(() => {
      dispatch(setFilter(newFilter));
      dispatch(setIsFiltering(true));
      dispatch(setIsFilterLoading(false));

      // these are not passed in the constructor during Filter's initialization,
      // so must be initialized in our state after Filter has already been constructed
      dispatch(setEnabledPublicFilters(newFilter.enabledPublicFilters));
      dispatch(setCannyEdgeDetectionThreshold(newFilter.doubleThresholdHigh));
    });

    logger.debug('new Filter class created and set to Redux', {
      tensorFlowBackend,
      enableFaceDetection,
      maxFrameRate,
      predictionInterval,
      croppedCanvasSize,
      initialFilterName: initialFilter.name,
    });
  } catch (error) {
    const message = errorHasMessage(error) ? error.message : '';
    logger.error('Error setting up video Filter', { error, message } as any);
    batch(() => {
      dispatch(setFilter(null));
      dispatch(setIsFiltering(false));
      dispatch(setIsFilterLoading(false));
    });
  }
};

export const setupFilterElements = async (videoMediaStreamController: types.MediaStreamController) => {
  logger.debug('Setting up srcVideo and destCanvas for Filter');

  // these elements are used to filter the user's video
  // they should be fresh elements if we are setting up a new media stream controller for the user
  const srcVideo = createSrcVideo();
  const destCanvas = createDestCanvas();

  if (videoMediaStreamController.source) {
    srcVideo.srcObject = videoMediaStreamController.source as MediaStream;
  }

  logger.debug('srcVideo and destCanvas created for Filter');

  return { srcVideo, destCanvas };
};
