/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import {
  selectLabelStylesBase, selectLabelStylesDark, selectLabelStylesLight, selectStylesBase, selectStylesDark, selectStylesLight,
} from 'styles/styles';
import { ComponentPropsWithRef, forwardRef, RefObject } from 'react';
import { TEAL_100 } from 'theme/ui/colors';
import useIsKeyboardUser from 'hooks/useIsKeyboardUser';
import { CSS } from 'types/css';

export type SelectInputVariant = 'light' | 'dark';

/**
 * This allows the FocusRing to fit its size to the Select component.
 */
const containerStyles: CSS = {
  position: 'relative',
};

export interface SelectProps extends ComponentPropsWithRef<'select'> {
  label: string,
  value?: string,
  inputRef?: RefObject<Element | null>,
  error?: string,
  options?: { value: string, label: string, id: string }[],
  componentStyles?: CSS,
  variant?: SelectInputVariant,
}

const SelectInput = forwardRef<HTMLSelectElement, SelectProps>(({
  id,
  variant = 'dark',
  label,
  value = '',
  error = '',
  options = [],
  onChange = () => null,
  onBlur = () => null,
  componentStyles = {},
  ...rest
}, ref) => {
  const selectLabelStyles = { ...selectLabelStylesBase, ...(variant === 'light' ? selectLabelStylesLight : selectLabelStylesDark) };
  const selectStyles = { ...selectStylesBase, ...(variant === 'light' ? selectStylesLight : selectStylesDark) };
  const isKeyboardUser = useIsKeyboardUser();
  const focusRingStyles: CSS = {
    opacity: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'calc(100% + 2rem)',
    height: 'calc(100% + 2rem)',
    border: '0.4rem solid',
    borderColor: TEAL_100,
    borderRadius: '10rem',
    pointerEvents: 'none',
    transition: '0.2s ease',
    'select:focus ~ &': {
      width: 'calc(100% + 1rem)',
      height: 'calc(100% + 1rem)',
      opacity: isKeyboardUser ? 1 : 0,
      border: '0.2rem solid',
      borderColor: TEAL_100,
    },
  };

  return (
    <ErrorBoundary fallback={(
      <div sx={componentStyles}>
        <label sx={selectLabelStyles} htmlFor={id}>
          {label}
        </label>
        <select
          id={id}
          sx={selectStyles}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          disabled
          {...rest}
        />
        {!!error && <p>{error}</p>}
      </div>
  )}
    >
      <div sx={{ ...componentStyles, ...containerStyles }}>
        <label sx={selectLabelStyles} htmlFor={id}>{label}</label>
        <select
          id={id}
          sx={selectStyles}
          value={value}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          {...rest}
        >
          {options.map((option) => (
            <option
              key={option.id}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </select>
        <span sx={focusRingStyles} />
      </div>

      {!!error && <p>{error}</p>}
    </ErrorBoundary>
  );
});

SelectInput.displayName = 'SelectInput';

export default SelectInput;
