/** @jsx jsx  */
import { jsx } from '@theme-ui/core';
import { ComponentProps, ReactNode } from 'react';
import {
  mediaMaxWidth, mediaMinWidth, popover, roomBackdropFilter,
} from 'styles/styles';
import { BLUE_TRUE, ColorsEnum, ColorsEnumValue } from 'theme/ui/colors';
import { CSS } from 'types/css';

const toastStyles: CSS = {
  position: 'fixed',
  top: 0,
  left: '50%',
  [mediaMaxWidth._1]: {
    width: '100%',
  },
  [mediaMinWidth._1]: {
    minWidth: '50rem',
    width: 'max-content',
    maxWidth: '80vw',
    borderRadius: '0 0 2.4rem 2.4rem',
  },
  zIndex: popover,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 'max-content',
  transition: 'transform 0.3s ease',
  backdropFilter: roomBackdropFilter,
  boxShadow: `0px 4px 4px ${ColorsEnum.BLACK.withOpacity(0.25)}`,
};

const textStyles: CSS = {
  display: 'block',
  width: '100%',
  p: '1.3rem 2rem',
  // apply these styles to all text contained inside:
  '&, & p': {
    fontFamily: 'quicksand',
    fontSize: '2.4rem',
    fontWeight: 'normal',
    color: 'white',
    textAlign: 'center',
    wordBreak: 'break-word',
  },
};

interface ToastProps extends ComponentProps<'div'> {
  bgColor?: ColorsEnumValue,
  componentStyles?: CSS,
  childrenStyles?: CSS,
  children?: ReactNode,
}

/**
 * Generic notification--does not close on its own. Default color is blue_true.
 * Currently, the three variants of the Toast component are Alert, Notification, and ErrorMessage.
 *
 * See spec: https://www.figma.com/file/DGr70ei18pKpTNGNTi8jou/Session-2.0?node-id=353%3A2
 */
function Toast({
  children, componentStyles = {}, childrenStyles = {}, bgColor = BLUE_TRUE, ...rest
}: ToastProps) {
  toastStyles.transform = children ? 'translate3d(-50%, 0rem, 0rem)' : 'translate3d(-50%, -12rem, 0rem)';
  toastStyles.bg = `${bgColor}CC`; // 0.8 transparency === CC hex value
  return (
    <div sx={{ ...toastStyles, ...componentStyles }} role="status" aria-hidden={!children} {...rest}>
      <span sx={{ ...textStyles, ...childrenStyles }}>
        {children}
      </span>
    </div>
  );
}

export default Toast;
