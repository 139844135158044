import React, {
  Fragment,
  lazy, ReactNode, Suspense,
  useEffect,
} from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import {
  Switch, Route, Redirect, useLocation,
} from 'react-router-dom';
import { ThemeProvider } from '@theme-ui/core';
import smoothscroll from 'smoothscroll-polyfill';
import useSyncAuthState from 'hooks/useSyncAuthState';
import useFeatureFlags, { FeatureFlagProvider } from 'hooks/useFeatureFlags';
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';
import ErrorMessage from 'components/ui/Messages/ErrorMessage';
import SignUp from 'views/SignUp/SignUp';
import RegistrationCompleteModal from 'views/Dashboard/RegistrationCompleteModal';
import { IsKeyboardUserContextProvider } from 'hooks/useIsKeyboardUser';
import useLoggerAggregates from 'hooks/useLoggerAggregates';
import useDetectPageRefresh from 'hooks/useDetectPageRefresh';
import {
  getArcadeEnabled, getLoginType, getSandboxEnabled, getDismissedMobileRedirect,
} from 'selectors';
import { LoginTypesEnum } from 'store/types';
import Loading from 'components/Loading/Loading';
import AudioDebugModal from 'components/Debug/Audio/AudioDebugModal';
import BitrateOptionsModal from 'components/Debug/Bitrate/BitrateOptionsModal';
import DebugModal from 'components/Debug/DebugModal';
import GrafanaOptionsModal from 'components/Debug/Grafana/GrafanaOptionsModal';
import useTypedSelector from 'hooks/useTypedSelector';
import useInternetConnListener from 'hooks/useInternetConnListener';
import { isNativeMobileDevice, UserDeviceTypeEnum } from 'utils/detectDevice';
import useGoBack from 'hooks/useGoBack';
import useDetectUserDeviceType from 'hooks/useDetectUserDeviceType';
import { createIosUrl } from 'utils/iosAppUrl';
import useDetectPageClose from 'hooks/useDetectPageClose';
// import mixpanel from 'utils/mixpanel';
import { QueryParamProvider } from 'use-query-params';
import RouteEnum from 'utils/routeEnum';
import useDetectIncompatibleBrowser from 'hooks/useDetectIncompatibleBrowser';
import FiltersModal from 'components/Debug/Filters/FiltersModal';
import useCleanUpMediaStreamControllers from 'hooks/useCleanUpMediaStreamControllers';
import store, { history } from './store/store';
import uiTheme from './theme/ui';

// polyfill for safari
smoothscroll.polyfill();

const Dashboard = lazy(() => import('views/Dashboard/Dashboard'));
const Room = lazy(() => import('views/Room/Room'));
const Login = lazy(() => import('views/Login/Login'));
const Error = lazy(() => import('views/Error/Error'));
const NotFound = lazy(() => import('views/NotFound/NotFound'));
const LogOut = lazy(() => import('views/LogOut/LogOut'));
const Auth = lazy(() => import('views/Auth/Auth'));
const PrivacyPolicy = lazy(() => import('views/PrivacyPolicy/PrivacyPolicy'));
const ForgotPassword = lazy(() => import('views/ForgotPassword/ForgotPassword'));
const ChangePassword = lazy(() => import('views/ChangePassword/ChangePassword'));
const SessionExpired = lazy(() => import('views/SessionExpired/SessionExpired'));
const Sandbox = lazy(() => import('views/Sandbox/Sandbox'));
const Snake = lazy(() => import('views/Snake/Snake'));
const RoomManager = lazy(() => import('views/RoomManager/RoomManager'));
const MobileDownload = lazy(() => import('views/MobileDownload/MobileDownload'));
const Incompatible = lazy(() => import('views/Incompatible/Incompatible'));

function App() {
  useLoggerAggregates();
  useDetectPageRefresh();
  useDetectPageClose();
  useSyncAuthState();
  useInternetConnListener();
  useCleanUpMediaStreamControllers();
  const incompatibleError = useDetectIncompatibleBrowser();

  const loginType = useTypedSelector(getLoginType);
  const sandboxEnabled = useTypedSelector(getSandboxEnabled);
  const arcadeEnabled = useTypedSelector(getArcadeEnabled);
  const dismissedMobileRedirect = useTypedSelector(getDismissedMobileRedirect);
  const { enableAppleAppStoreRedirect, enableGooglePlayStoreRedirect } = useFeatureFlags();
  const userDevice = useDetectUserDeviceType();
  const location = useLocation();
  const goBack = useGoBack();

  const iosRedirectDisabled = userDevice === UserDeviceTypeEnum.IOS && !enableAppleAppStoreRedirect;
  const androidRedirectDisabled = userDevice === UserDeviceTypeEnum.ANDROID && !enableGooglePlayStoreRedirect;

  const nativeRedirectDisabled = iosRedirectDisabled || androidRedirectDisabled;

  // useEffect(() => {
  //   if (!RouteEnum.ROOM_MANAGER.matchPath(location.pathname) && !RouteEnum.ROOM.matchPath(location.pathname)) {
  //     mixpanel.setSuperEventProperties({
  //       'Room ID': '',
  //       Role: '',
  //     });
  //   }
  // }, [location.pathname]);

  /**
   *  This is an iOS workaround for when someone opens a link to a room
   *  from an app that doesn't support linking to the native app
   *  via the .well-known/apple-app-site-association file in public/.
   *  This will attempt to open the native app if installed.
   */
  useEffect(() => {
    if (userDevice === UserDeviceTypeEnum.IOS
        && !iosRedirectDisabled
        && location.pathname.match(/\/room-manager\/join|\/room\//i) // should match apple-app-site-association paths
    ) {
      // Must be changed in-place. Page should behave as normal if this happens to fail
      window.location.href = createIosUrl(window.location.href);
    }
  }, []);

  // don't render app unless the user's auth state is stable
  if (loginType === LoginTypesEnum.UNKNOWN && !location.pathname.match(/^\/error/i)) return <Loading />;

  return (
    <Fragment>
      {/* All app level modals MUST NOT be direct descendants of the Suspense */}
      <RegistrationCompleteModal />
      {/* DebugModal opens/closes the rest of these modals: */}
      <DebugModal />
      <AudioDebugModal />
      <BitrateOptionsModal />
      <GrafanaOptionsModal />
      <FiltersModal />

      <Suspense fallback={null}>
        <ErrorMessage type="timeout" />
        <ErrorMessage type="device" />

        {/* Views: */}
        <Switch>
          {/* Redirects */}
          {incompatibleError ? (
            <Fragment>
              <Route path={RouteEnum.NOT_SUPPORTED.path} exact component={Incompatible} />
              <Redirect from="*" to={RouteEnum.NOT_SUPPORTED.path} />
            </Fragment>
          ) : (
            <Redirect from={RouteEnum.NOT_SUPPORTED.path} to="/" />
          )}
          <Redirect from="/join/:roomId" to="/room-manager/join/:roomId" />
          <Redirect from="/attendance/:roomId" to="/room-manager/attendance/:roomId" />
          {isNativeMobileDevice(userDevice) && !nativeRedirectDisabled && !dismissedMobileRedirect ? (
            <Fragment>
              <Redirect to={RouteEnum.MOBILE_DOWNLOAD.path} from="/room-manager|/room" />
              <Route path={RouteEnum.MOBILE_DOWNLOAD.path} exact component={() => <MobileDownload deviceType={userDevice} goBack={goBack} />} />
            </Fragment>
          ) : (
            // In case non-native-mobile/dismissed user navigated here from link/bookmark/back
            <Redirect exact from={RouteEnum.MOBILE_DOWNLOAD.path} to={RouteEnum.HOME.path} />
          )}

          {/* Authentication Pages: */}
          <Route path={RouteEnum.HOME.path} exact render={() => <ProtectedRoute type="prohibitAuth" component={Login} />} />
          <Route path={RouteEnum.SIGN_UP.path} exact render={() => <ProtectedRoute type="prohibitAuth" component={SignUp} />} />
          <Route path={RouteEnum.SESSION_EXPIRED.path} exact render={() => <ProtectedRoute type="prohibitAuth" component={SessionExpired} />} />
          <Route path={RouteEnum.FORGOT_PASSWORD.path} exact render={() => <ProtectedRoute type="prohibitAuth" component={ForgotPassword} />} />
          <Route
            path={RouteEnum.RESET_PASSWORD.path}
            exact
            render={({ match: { params: { roomId } } }) => (
              <ProtectedRoute type="prohibitAuth">
                <ChangePassword roomId={roomId} />
              </ProtectedRoute>
            )}
          />
          <Route path={RouteEnum.LOGOUT.path} exact render={() => <ProtectedRoute type="prohibitAuth" component={LogOut} />} />
          <Route path={RouteEnum.TOKEN_AUTH.path} exact component={Auth} />

          {/* Main Pages: */}
          <Route path={RouteEnum.DASHBOARD.path} exact render={() => <ProtectedRoute component={Dashboard} />} />
          <Route
            path={RouteEnum.ROOM_MANAGER.path}
            exact
            render={({ match: { params: { view, roomId } } }) => (
              <ProtectedRoute type="roomManager">
                <RoomManager view={view} roomId={roomId} />
              </ProtectedRoute>
            )}
          />
          <Route path={RouteEnum.ROOM.path} exact>
            <ProtectedRoute type="room">
              <Room />
            </ProtectedRoute>
          </Route>

          {/* Other Pages:  */}
          <Route path={RouteEnum.ERROR.path} exact component={Error} />
          <Route path={RouteEnum.PRIVACY.path} exact component={PrivacyPolicy} />

          {/* For Developers Only: */}
          {sandboxEnabled && <Route path={RouteEnum.SANDBOX.path} exact component={Sandbox} />}
          {arcadeEnabled && <Route path={RouteEnum.SNAKE.path} exact component={Snake} />}

          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </Fragment>
  );
}

interface AppProviderProps {
  customStore: typeof store,
  children?: ReactNode,
}

/**
 * All the providers of the ChalkCast app, exported for use in testing-library.
 * Providing a customStore here allows us to create unique initial state in our
 * testing components, while still getting the consistency of only defining all
 * of our providers once.
 */
export function AppProviders({ children, customStore }: AppProviderProps) {
  return (
    <Provider store={customStore}>
      <ConnectedRouter history={history}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <ThemeProvider theme={uiTheme}>
            <FeatureFlagProvider>
              <IsKeyboardUserContextProvider>
                {children}
              </IsKeyboardUserContextProvider>
            </FeatureFlagProvider>
          </ThemeProvider>
        </QueryParamProvider>
      </ConnectedRouter>
    </Provider>
  );
}

function AppWithProviders() {
  return (
    <AppProviders customStore={store}>
      <App />
    </AppProviders>
  );
}

export default AppWithProviders;
