/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import FocusTrap from 'focus-trap-react';
import {
  ColorsEnum, GREY_70, TEAL_100, WHITE,
} from 'theme/ui/colors';
import Svg from 'components/ui/Svg';
import Button from 'components/ui/Button';
import { ReactComponent as SettingsSVG } from 'assets/icons/buttons/settings.svg';
import { ReactComponent as DoorSVG } from 'assets/icons/join.svg';
import { FocusTrapOptions } from 'utils/typeUtils';
// import mixpanel from 'utils/mixpanel';
import { logout } from 'actions/loginActions';
import { toggleAppSettingsModalOpen } from 'actions/dashboardActions';
import { CSS } from 'types/css';
import RouteEnum from 'utils/routeEnum';

const styles: CSS = {
  position: 'absolute',
  top: 'calc(100% + 20px)',
  right: '0',
  boxShadow: `0px 5px 15px ${ColorsEnum.BLACK.withOpacity(0.1)}`,
  borderRadius: '2.4rem',
  height: '10.4rem',
  width: '24rem',
  bg: WHITE,
  zIndex: 2,
  overflow: 'hidden',
  animation: '0.3s 1 expandHeight',
  '@keyframes expandHeight': {
    from: { height: 0 },
    to: { height: '10.4rem' },
  },
};

const buttonStyles: CSS = {
  height: '5.2rem',
  pl: '1.2rem',
  color: GREY_70,
  '& div': {
    fontSize: '1.8rem',
  },
  '&:hover': {
    color: TEAL_100,
    '& div': {
      transition: '0.3s',
      fontWeight: 'bold',
    },
  },
};

const svgStyles: CSS = {
  fill: GREY_70,
  height: '3rem',
  width: '3rem',
  mr: '1.2rem',
  'button:hover & ': {
    transition: '0.3s',
    fill: TEAL_100,
  },
};

interface Props {
  closeDropdown: () => void,
}

function AccountDropdown({ closeDropdown }:Props) {
  const dispatch = useDispatch();

  const focusTrapOptions: FocusTrapOptions = useMemo(() => ({
    escapeDeactivates: true,
    clickOutsideDeactivates: true,
    onDeactivate: closeDropdown,
    fallbackFocus: 'focusable-fallback',
  }), [closeDropdown]);

  return (
    <FocusTrap focusTrapOptions={focusTrapOptions}>
      <div sx={styles} id="focusable-fallback">
        <Button
          variant="transparent"
          onClick={() => {
            closeDropdown();
            // mixpanel.track('App Settings Click');
            dispatch(toggleAppSettingsModalOpen());
          }}
          sx={buttonStyles}
        >
          <Svg svg={SettingsSVG} title="Settings" sx={svgStyles} />
          App Settings
        </Button>
        <Button
          variant="transparent"
          onClick={() => {
            closeDropdown();
            dispatch(logout({ redirect: RouteEnum.LOGOUT }));
          }}
          data-selenium="top-nav__logout-button"
          sx={buttonStyles}
        >
          <Svg svg={DoorSVG} title="Logout" sx={svgStyles} />
          Logout
        </Button>
      </div>
    </FocusTrap>
  );
}

export default AccountDropdown;
