import {
  DashboardRoom, ISOString, LivelyToken, Note, RoomPermission, Scopes, UserPhotos, UserSource,
} from 'store/types';
import { CCServiceRoomState } from './legacy';

export interface CCPaginationResponse {
  _links: { next?: string, prev?: string, self: string }
}

/* ********** */
/* Sub-types: */
/* ********** */

export interface CCServiceError {
  message: string,
  name: string,
  stack: string,
  config: {
    url: string,
    method: string,
    data: string,
    headers: {
      Accept: string,
      'Content-Type': string,
    },
    baseURL: string
    transformRequest: any,
    transformResponse: any,
    timeout: number,
    xsrfCookieName: string,
    xsrfHeaderName: string,
    maxContentLength: number,
  }
  response: {
    data: {
      reasons?: any[],
      message?: string,
    },
    status: number,
    statusText: string,
    headers: {
      'content-length': string,
      'content-type': string,
    },
    config: {
      url: string,
      method: string,
      data: string,
      headers: {
        Accept: string,
        'Content-Type': string,
      },
      baseURL: string,
      transformRequest: any,
      transformResponse: any,
      timeout: number,
      xsrfCookieName: string,
      xsrfHeaderName: string,
      maxContentLength: number,
    },
    request: any,
  }
}

export interface CCServiceRecentBreakoutGroupUser {
  displayName: string
  id: number
  username: string
}

export interface CCServiceRecentBreakoutGroup {
  id: string
  updated: string
  created: string
  closureWarningTimer?: number
  description?: string | null
  roomId?: number
  slug: string
  users: CCServiceRecentBreakoutGroupUser[]
  active: boolean
  collectionId: string
}

/* *************** */
/* Response Types: */
/* *************** */

/**
 * This data is shared between all responses from auth/jwtdecode
 */
interface CCServiceSharedUserData {
  source: UserSource,
  scopes: Scopes,
  livelyToken: LivelyToken,
  userId: string | number,
}

/**
 * This data is only received from auth/jwtdecode when a temp user hits the endpoint.
 */
export type CCServiceTempUserData = CCServiceSharedUserData & {
  user: {
    displayName: string,
    userName: string,
  },
}

/**
 * This data is only received from auth/jwtdecode when a native account user hits the endpoint.
 */
export type CCServiceNativeUserData = CCServiceTempUserData & {
  user: {
    sanitizedEmail: string,
  }
};

/**
 * This data is only received from auth/jwtdecode when a Google account user hits the endpoint.
 */
export type CCServiceGoogleUserData = CCServiceSharedUserData & {
  newUser: boolean,
  user: {
    // only appears after user merges their Google and native emails
    confirmMerge?: true,
    emailHash: string,
    profile: {
      displayName: string,
      id: string,
      name: {
        familyName: string,
        givenName: string,
      },
      photos: UserPhotos,
      provider: 'google',
    },
    sanitizedEmail: string,
    // this token is a Google token, not a JWT--it's not currently used by CC
    token: string,
  },
}

/**
 * CC Service responds with a different shape depending on the source type of the user.
 * This interface maps type of the user to the shape we expect from CC Service.
 */
export interface FetchUserResponseData {
  google: CCServiceGoogleUserData,
  native: CCServiceNativeUserData,
  temp: CCServiceTempUserData,
}

/**
 * GET to /chalkcast/v1/auth/jwtdecode
 * Assumes the most generic shape--which type we expect can be specified
 * if needed with a key from FetchUserResponseData.
 */
export interface FetchUserResponse<Source extends keyof FetchUserResponseData = keyof FetchUserResponseData> {
  data: {
    data: FetchUserResponseData[Source],
    iat: number,
    exp: number
  }
}

/**
 * PUT to /users/me
 */
export interface DisplayNameUpdateResponse {
  data: {
    jwtToken: string,
    livelyToken: LivelyToken,
  }
}

/**
 * POST to /rooms/${roomId}/user-join
 */
export interface CreateTempAccountResponse {
  data: {
    livelyToken: LivelyToken,
    jwtToken: string,
    // roomState: CCServiceRoomState //
    userId: string,
  }
}

/**
 * GET to /auth/token
 */
export interface FetchLivelyTokenResponse {
  livelyToken: LivelyToken,
  token: string,
}

/**
 * GET to /rooms
 */
export interface FetchDashboardRooms extends CCPaginationResponse {
  rooms: DashboardRoom[],
}

/**
 * POST to /rooms
 */
export interface CreateRoomResponse {
  room: CCServiceRoomState
}

export interface CreateRoomRequestPayload {
  name: string,
  permissions: Array<{ // same payload as /rooms/{{roomId}}/permissions
    permissionId: number,
    permissionGranted: boolean
  }>,
  isLocked: boolean,
  isLoginRequired: boolean,
  openroomSetting: boolean,
}

/**
 * GET to /rooms/${roomId}/permissions
 */
export interface GetRoomPermissions {
  permissions: RoomPermission[],
  owner: boolean,
}

/**
 * These are the various sound notifications that users can either
 * enable or disable in the AccountModal or RoomSettingsModal.
 */
export enum CCServiceSoundPreferencesEnum {
  JOIN = 'join',
  LEAVE = 'leave',
  RAISE_HAND = 'raiseHand',
  CALLED_ON = 'calledOn',
  ROOM_CHAT_MESSAGE = 'roomChatMessage',
  DIRECT_CHAT_MESSAGE = 'directChatMessage',
}

export interface CCServiceSoundPreference {
  description: string;
  name: CCServiceSoundPreferencesEnum;
  soundPreferenceId: number;
  enabled: 1 | 0;
  created?: string;
  updated?: string;
  userId: string,
  id: number,
}

/**
 * GET to /users/sound-preferences
 */
export interface GetSoundPreferences {
 globalSoundPreferences: CCServiceSoundPreference[],
}

export interface CCServiceTimeInRoom {
  joined: ISOString,
  /**
   * @todo: These null values are currently a bug from CC-service.
   * Update to be only strings once the bug is fixed/CC-service is TS
   */
  left: ISOString | null,
  totalTime: number | null,
}

export type CCServiceAttendanceStatus = 'present' | 'absent';

export interface CCServiceAttendanceRosterUser {
  displayName?: string, //  CC-service bug: displayName is sometimes not present
  attendanceStatus: CCServiceAttendanceStatus,
  joinTime?: ISOString,
  leaveTime?: ISOString | null,
  timesInRoom: CCServiceTimeInRoom[],
  totalTime: number,
  firstName: string,
  lastName: string,
}

export interface CCServiceAllViewAttendanceRoster {
  [userId: string]: CCServiceAttendanceRosterUser
}

export interface CCServiceAllViewAttendanceSession {
  endTime: ISOString | null,
  sessionId: string,
  startTime: ISOString,
  roster: CCServiceAllViewAttendanceRoster,
}

export interface CCServiceAttendanceLinks {
  next?: string,
    self: string,
    prev?: string,
}

/** GET to rooms/:roomId/attendance */
export interface GetAllViewAttendance {
  attendanceReport: CCServiceAllViewAttendanceSession[],
  _links?: CCServiceAttendanceLinks
}

export interface CCServiceUserViewAttendanceSession {
  // session data
  sessionId: string,
  startTime: ISOString,
  endTime: ISOString,

  // user data
  attendanceStatus: CCServiceAttendanceStatus,
  joinTime?: ISOString,
  leaveTime?: ISOString,
  totalTime: number,
}

/** GET to /rooms/:roomId/individual-attendance?userId=<USER_ID> */
export interface GetUserViewAttendance {
  sessions: CCServiceUserViewAttendanceSession[],
  displayName: string,
  userId: number,
  firstName: string,
  lastName: string,
}

/** GET to /rooms/:roomId/my-attendance */
export interface GetMyAttendance {
  sessions: CCServiceUserViewAttendanceSession[],
  displayName: string,
  userId: number,
  firstName: string,
  lastName: string,
}

/**
 * GET /rooms/${roomId}/name
 */
export interface FetchRoomNameResponse {
  data: {
    name: string
  }
}

/**
 * GET /rooms/${roomId}/breakout-rooms?collection=true
 */
export interface FetchBreakoutGroupsResponse {
  breakoutRooms: CCServiceRecentBreakoutGroup[]
}

/**
 * GET /rooms/${roomId}
 */
export interface FetchRoomStateResponse {
  roomState: CCServiceRoomState
}

/**
 * POST /users/register
 * POST /users/login
 */
export interface PostLoginResponse {
  userId: string,
  livelyToken: LivelyToken,
  jwtToken: string,
}

/**
 * POST to /rooms/${roomId}/note
 */
export interface PostNoteResponse {
  id: number
}

/**
 * GET to /rooms/${roomId}/notes
 */
export interface FetchNotesResponse {
  notes: Note[]
}
