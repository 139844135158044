import logger from './logger';

export enum UserDeviceTypeEnum {
  IOS,
  ANDROID,
  OTHER_MOBILE,
  DESKTOP,
}

/** Includes only mobile platforms CC has a native app for */
export type NativeMobileDeviceType = UserDeviceTypeEnum.ANDROID | UserDeviceTypeEnum.IOS

/** Device type is a platform CC has a native app for */
export const isNativeMobileDevice = (deviceType: UserDeviceTypeEnum): deviceType is NativeMobileDeviceType => (
  [UserDeviceTypeEnum.ANDROID, UserDeviceTypeEnum.IOS].includes(deviceType)
);

const detectIOS = () => /iP(ad|hone|od)/i.test(navigator.platform) || (
  /mac/i.test(navigator.userAgent) && 'ontouchend' in document // iPad on iOS 13
);

const detectAndroid = () => /android/i.test(navigator.userAgent);

const detectOtherMobile = () => /webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

const DEVICE_DETECTION_CONFIG = {
  detectors: [
    {
      type: UserDeviceTypeEnum.IOS,
      detect: detectIOS,
    },
    {
      type: UserDeviceTypeEnum.ANDROID,
      detect: detectAndroid,
    },
    {
      type: UserDeviceTypeEnum.OTHER_MOBILE,
      detect: detectOtherMobile,
    },
  ],
  default: UserDeviceTypeEnum.DESKTOP,
};

/**
 * Result is like a const representing the kind of device a user is on (desktop, iOS, Android, etc.).
 * This is func rather than const for the off chance it's ever used in SSR,
 * where the window/navigator may not be available top-level.
 *
 *  Note: This is only the best guess that can be taken for detecting platform. Be cautious about using this for any mission-critical logic.
 */
export const detectUserDeviceType = () => {
  for (const { type, detect } of DEVICE_DETECTION_CONFIG.detectors) {
    try {
      if (detect()) return type;
    } catch (error) {
      logger.warn('Error detecting device type', { error: error as any, type });
    }
  }
  return DEVICE_DETECTION_CONFIG.default;
};
