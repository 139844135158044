import React, {
  useEffect, useState, createContext, useContext, ReactNode,
} from 'react';
import { node } from 'prop-types';

const KeyboardUserContext = createContext(false);

interface Props {
  children?: ReactNode;
}

function IsKeyboardUserContextProvider({ children }: Props) {
  const [isKeyboardUser, setIsKeyboardUser] = useState(false);
  useEffect(() => {
    const setIsKeyboardUserHandler = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        setIsKeyboardUser(true);
        window.removeEventListener('keyup', setIsKeyboardUserHandler);
      }
    };
    window.addEventListener('keyup', setIsKeyboardUserHandler);
    return function cleanup() {
      window.removeEventListener('keyup', setIsKeyboardUserHandler);
    };
  });
  return (
    <KeyboardUserContext.Provider value={isKeyboardUser}>
      {children}
    </KeyboardUserContext.Provider>
  );
}

IsKeyboardUserContextProvider.propTypes = {
  children: node.isRequired,
};

export { IsKeyboardUserContextProvider };

export default function useIsKeyboardUser() {
  return useContext(KeyboardUserContext);
}
