import produce from 'immer';
import {
  SET_RESET_LINK_SENDING,
  SET_RESET_LINK_SENT,
  SET_RESET_LINK_ERROR,
  RESET_FORGOT_STATE,
  SET_CHANGE_PASSWORD_SENDING,
  SET_CHANGE_PASSWORD_SUCCESS,
  SET_CHANGE_PASSWORD_ERROR,
  SET_CHANGE_PASSWORD_LINK_EXPIRED,
  SET_INVALID_PASSWORD,
  RESET_CHANGE_PASSWORD_STATE,
  RESET_ALL_PASSWORD_RESET_STATE,
  PasswordResetAction,
  SET_RESET_PASSWORD_EMAIL,
} from 'actions/passwordResetActions';
import { ChangePasswordState, ForgotPasswordState, PasswordResetState } from 'store/types';

const forgotPasswordInitialState: ForgotPasswordState = {
  resetLinkSending: false,
  resetLinkSent: false,
  resetLinkError: false,
  resetEmail: '',
};

const changePasswordInitialState: ChangePasswordState = {
  changePasswordSending: false,
  changePasswordError: false,
  changePasswordSuccess: false,
  changePasswordLinkExpired: false,
  invalidPassword: null,
};

export const initialState: PasswordResetState = {
  ...forgotPasswordInitialState,
  ...changePasswordInitialState,
};

const reducer = produce((draft: PasswordResetState, action: PasswordResetAction) => {
  switch (action.type) {
    // reset link state
    case SET_RESET_LINK_SENDING:
      draft.resetLinkSending = action.payload.sending;
      break;
    case SET_RESET_LINK_SENT:
      draft.resetLinkSent = action.payload.sent;
      break;
    case SET_RESET_LINK_ERROR:
      draft.resetLinkError = action.payload.error;
      break;
    case SET_RESET_PASSWORD_EMAIL:
      draft.resetEmail = action.payload.email;
      break;
    case RESET_FORGOT_STATE:
      Object.assign(draft, forgotPasswordInitialState);
      break;

    // change password state
    case SET_CHANGE_PASSWORD_SENDING:
      draft.changePasswordSending = action.payload.sending;
      break;
    case SET_CHANGE_PASSWORD_SUCCESS:
      draft.changePasswordSuccess = action.payload.success;
      break;
    case SET_CHANGE_PASSWORD_ERROR:
      draft.changePasswordError = action.payload.error;
      break;
    case SET_CHANGE_PASSWORD_LINK_EXPIRED:
      draft.changePasswordLinkExpired = action.payload.expired;
      break;
    case SET_INVALID_PASSWORD:
      draft.invalidPassword = action.payload.invalidPassword;
      break;
    case RESET_CHANGE_PASSWORD_STATE:
      Object.assign(draft, changePasswordInitialState);
      break;
    case RESET_ALL_PASSWORD_RESET_STATE:
      Object.assign(draft, initialState);
      break;
    default:
      break;
  }
}, initialState);

export default reducer;
