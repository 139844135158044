import produce from 'immer';
import omit from 'lodash/omit';
import {
  UPDATE_AUTH_DATA,
  UPDATE_PARTICIPANT_FORM,
  SET_JOINED_ROOM,
  SET_AUTH_FORM_ERROR,
  TOGGLE_AUTH_FORM_SENDING,
  TOGGLE_REGISTRATION_COMPLETE_MODAL,
  LoginAction,
  SET_LOGIN_TYPE,
  SET_FETCHING_TOKENS,
  SET_FETCHING_USER,
  SET_REDIRECT_AFTER_LOGIN_PATH,
} from 'actions/loginActions';
import { LOGOUT, SharedAction } from 'actions/sharedActions';
import { persistReducer, persistConfig } from 'store/localStoragePersistMiddleware';
import { LoginState, LoginTypesEnum } from 'store/types';

const SESSION_STORAGE_REDIRECT_KEY = 'chalkcast-login-redirect-path';

export const initialState: LoginState = {
  loading: false,
  error: '',
  user: null,
  livelyToken: null,
  livelyTokenExp: null,
  token: null,
  joinedRoom: null,
  authFormError: null,
  authFormSending: false,
  registrationCompleteVisible: false,
  participantForm: {
    displayName: '',
    school: '',
  },
  loginType: LoginTypesEnum.UNKNOWN,
  fetchingUser: false,
  fetchingTokens: false,
  redirectAfterLoginPath: sessionStorage.getItem(SESSION_STORAGE_REDIRECT_KEY) || '',
};

const reducer = produce((draft: LoginState, action: LoginAction | SharedAction) => {
  switch (action.type) {
    case UPDATE_AUTH_DATA:
      if (action.payload.user) {
        draft.loading = false;
        draft.user = action.payload.user;
      }
      if (action.payload.jwt) draft.token = action.payload.jwt;
      if (action.payload.livelyToken) {
        draft.livelyToken = action.payload.livelyToken.token;
        draft.livelyTokenExp = action.payload.livelyToken.expire;
      }
      break;
    case SET_AUTH_FORM_ERROR:
      draft.authFormError = action.payload.error;
      break;
    case TOGGLE_AUTH_FORM_SENDING:
      if (action.payload.sending === undefined) draft.authFormSending = !draft.authFormSending;
      else draft.authFormSending = action.payload.sending;
      break;
    case LOGOUT:
      Object.assign(draft, omit<LoginState, keyof LoginState>(initialState, 'redirectAfterLoginPath'));
      // should set the user to ANON, not UNKNOWN when logging out
      draft.loginType = LoginTypesEnum.ANON_USER;
      break;
    case UPDATE_PARTICIPANT_FORM:
      draft.participantForm[action.payload.field] = action.payload.value;
      break;
    case SET_JOINED_ROOM:
      draft.joinedRoom = action.payload.joinedRoom;
      break;
    case TOGGLE_REGISTRATION_COMPLETE_MODAL:
      draft.registrationCompleteVisible = !draft.registrationCompleteVisible;
      break;
    case SET_LOGIN_TYPE:
      draft.loginType = action.payload.loginType;
      break;
    case SET_FETCHING_USER:
      draft.fetchingTokens = action.payload.fetching;
      break;
    case SET_FETCHING_TOKENS:
      draft.fetchingTokens = action.payload.fetching;
      break;
    case SET_REDIRECT_AFTER_LOGIN_PATH:
      draft.redirectAfterLoginPath = action.payload.path;
      sessionStorage.setItem(SESSION_STORAGE_REDIRECT_KEY, action.payload.path);
      break;
    default:
      break;
  }
}, initialState);

export default persistReducer<LoginState>(reducer, persistConfig.loginState);
