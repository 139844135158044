/* eslint-disable consistent-return */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-await-in-loop */
import logger from './logger';

type Options = {
  retries?: number,
  cancelOnNavigation?: boolean,
}

const DEFAULT_MAX_RETRIES = 5;

export const pauseLoop = (ms: number) => new Promise((res) => setTimeout(res, ms));

/**
 * Retries an asynchronous callback the desired number of times and returns the result on success.
 * Increases the time between function calls exponentially beginning with 200ms by default.
 * If cancelOnNavigation is true, retry will be stopped if the user navigates to a new page
 * @param {function} callback
 * @param {Options} options
 */
export default async function retry(
  callback: () => void,
  { retries = DEFAULT_MAX_RETRIES, cancelOnNavigation = true }: Options,
) {
  return new Promise(async (res, rej) => {
    let locationPath = '';
    if (window) {
      locationPath = window.location.pathname;
    }

    for (let i = 0, ms = 200; i < retries; i += 1, ms *= 2) {
      try {
        if (cancelOnNavigation && locationPath && window && window.location.pathname !== locationPath) {
          logger.info('User navigated away - cancel loop');
          return;
        }

        const loggerMessage = i === 0
          ? 'Attempting first try with async callback'
          : 'Attempting to retry async callback';

        logger.info(loggerMessage, {
          attemptPercentage: (i + 1) / retries,
          maxAttempts: retries,
          currentAttempt: i + 1,
        });
        return res(await callback());
      } catch (error) {
        logger.warn('Retry failed', {
          error: error as any,
          maxAttempts: retries,
          currentAttempt: i + 1,
        });
        await pauseLoop(ms);
      }
    }

    const MAX_ATTEMPTS_ERROR = `Maximum number of retries (${retries}/${retries}) reached for async function`;
    return rej(new Error(MAX_ATTEMPTS_ERROR));
  });
}
