import { createSelector } from 'reselect';
import {
  StoreState, IcebreakerIndexes, IcebreakerPublishQuestion, IcebreakerParticipantResponseNotification,
} from 'store/types';
import { calculateResults } from 'components/Icebreaker/common';

export const getIsIcebreakerOpen = (state: StoreState) => state.icebreakerState.isIcebreakerOpen;

export const getIcebreakerQuestion = (state: StoreState) => state.icebreakerState.question;

export const getIcebreakerType = (state: StoreState) => state.icebreakerState.question.questionType;

export const getPublishedIcebreakers = (state: StoreState) => state.roomState.room.questions;

export const getIcebreakerLoading = (state: StoreState) => state.icebreakerState.loading;

export const getActiveResults = (state: StoreState) => state.icebreakerState.activeResults;

export const getAnsweredIcebreakers = (state: StoreState) => state.icebreakerState.answeredIcebreakers;

export const getCorrectAnswer = (state: StoreState) => state.icebreakerState.activeCorrectAnswer;

export const getResultsDismissed = (state: StoreState) => state.icebreakerState.resultsDismissed;

export const getActiveIcebreaker = createSelector(
  getPublishedIcebreakers,
  (questions) => {
    if (!questions) {
      return null;
    }
    const actives:IcebreakerPublishQuestion[] = [];
    Object.keys(questions).forEach((id) => {
      if (questions[id].active) {
        actives.push(questions[id]);
      }
    });
    if (actives.length) {
      actives.sort((a:IcebreakerPublishQuestion, b:IcebreakerPublishQuestion) => new Date(b.created).valueOf() - new Date(a.created).valueOf());
      return actives[0];
    }
    return null;
  },
);

export const getNewestIcebreaker = createSelector(
  getPublishedIcebreakers,
  (questions) => {
    if (!questions) {
      return null;
    }
    const questionsArr = Object.keys(questions).map((id) => questions[id]);
    if (!questionsArr.length) {
      return null;
    }
    questionsArr.sort((a:IcebreakerPublishQuestion, b:IcebreakerPublishQuestion) => new Date(b.created).valueOf() - new Date(a.created).valueOf());
    return questionsArr[0];
  },
);

export const getIsLastAnswer = (index:IcebreakerIndexes) => createSelector(
  getIcebreakerQuestion,
  (question) => {
    if (question.answers) {
      const lastIndex = question.answers.length - 1;
      return index === lastIndex;
    }
    return false;
  },
);

export const getCalculatedResults = createSelector(
  getActiveResults,
  getActiveIcebreaker,
  (results, question) => {
    if (!question) {
      return {};
    }
    if (question.questionType === 'shortAnswer') {
      return results;
    }
    return calculateResults(question.answers, results);
  },
);

export const getCalculatedParticipantResults = createSelector(
  getNewestIcebreaker,
  (question) => {
    if (!question) {
      return {};
    }
    if (question.questionType === 'shortAnswer') {
      const saResults = Object.keys(question.results).map((id) => ({
        questionId: +question.questionSessionId,
        answerId: +id,
        response: question.results[id].response,
        userId: '',
      })) as IcebreakerParticipantResponseNotification[];
      return saResults;
    }
    const results = Object.keys(question.results).map((id) => question.results[id]);
    return calculateResults(question.answers, results);
  },
);

export const getActiveOrNewestIcebreaker = createSelector(
  getActiveIcebreaker,
  getNewestIcebreaker,
  (active, newest) => active || newest,
);

export const getActiveOrNewestResultByUserId = (userId:string) => createSelector(
  getActiveResults,
  getActiveOrNewestIcebreaker,
  (results, question) => {
    let result!: IcebreakerParticipantResponseNotification;
    results.forEach((r) => {
      if (`${r.userId}` === userId) {
        result = r;
      }
    });
    if (result && question) {
      return question.questionType === 'shortAnswer'
        ? result.response
        : `${result.answerId}`;
    }
    return null;
  },
);

export const getIsActiveOrNewestAnonymous = createSelector(
  getActiveOrNewestIcebreaker,
  (question) => {
    if (!question) {
      return true;
    }
    return question.anonymousAnswers;
  },
);

export const getHasAnsweredActiveOrNewestIcebreaker = createSelector(
  getActiveOrNewestIcebreaker,
  getAnsweredIcebreakers,
  (active, answered) => !!active && !!answered.find((id) => `${id}` === `${active.questionSessionId}`),
);
