/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import { ComponentPropsWithoutRef } from 'react';
import { buttonResetStyles } from 'styles/styles';
import { TEAL_TRUE } from 'theme/ui/colors';
import { ReactComponent as HamburgerIcon } from 'assets/icons/buttons/hamburger.svg';
import { CSS } from 'types/css';
import Svg from 'components/ui/Svg';
import Button from 'components/ui/Button';

const hamburgerButtonStyles: CSS = {
  ...buttonResetStyles,
  height: '3.6rem',
  minWidth: '3.6rem',
  width: '3.6rem',
  py: '0.2rem',
};

const hamburgerIconStyles: CSS = {
  stroke: TEAL_TRUE,
  minHeight: '3.6rem',
  height: '3.6rem',
  minWidth: '3.6rem',
  width: '3.6rem',
};

/**
 * Button that opens the SideNav
 */
function HamburgerButton(props: ComponentPropsWithoutRef<'button'>) {
  return (
    <Button
      sx={hamburgerButtonStyles}
      iconComponent={<Svg svg={HamburgerIcon} sx={hamburgerIconStyles} title="Toggle menu open" />}
      variant="neutral"
      {...props}
    />
  );
}

HamburgerButton.displayName = 'HamburgerButton';

export default HamburgerButton;
