/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import {
  GREY_20, GREY_40, GREY_60, TEAL_TRUE, TEAL_LIGHT, TEAL_DARK, GREY_80, GREY_90, GREY_100, GREY_70, ColorsEnum,
} from 'theme/ui/colors';
import useIsKeyboardUser from 'hooks/useIsKeyboardUser';
import {
  ChangeEvent, ComponentPropsWithRef, forwardRef, memo, ReactNode,
} from 'react';
import { CSS } from 'types/css';

type SwitchVariants = 'light' | 'dark';
type CheckedKey = 'checkedTrue' | 'checkedFalse';
type DisabledKey = 'disabledTrue' | 'disabledFalse';
type SwitchThemeStyles = {
  [key in SwitchVariants]: {
    [checked in CheckedKey]: {
      [disabled in DisabledKey]: CSS;
    }
  };
}

const SWITCH_WIDTH = '3.5rem' as const;
const SWITCH_CIRCLE_SIZE = 23 as const;
const BORDER_RADIUS = '1.6rem' as const;
const CIRCLE_CLASS = 'circle' as const;
const CIRCLE_CLASS_SELECTOR = `& .${CIRCLE_CLASS}`;
const boxShadow = `0px 4px 16px ${ColorsEnum.TEAL_TRUE.withOpacity(0.6)}` as const;

const containerStyles: CSS = {
  position: 'relative',
  height: '1.6rem',
  width: SWITCH_WIDTH,
  borderRadius: BORDER_RADIUS,
  minWidth: '3.5rem',
};

/**
 * 6 possible combinations of variant, checked, and disabled.
 * This object allows us to determine styles for all these states
 * without having a bunch of nested if/else statements
 */
const switchThemedStyles: SwitchThemeStyles = {
  light: {
    checkedTrue: {
      // light, checked true, disabled true
      disabledTrue: {
        opacity: 0.5,
        [CIRCLE_CLASS_SELECTOR]: { bg: TEAL_TRUE },
        '& input': { bg: TEAL_LIGHT },
      },
      // light, checked true, disabled false
      disabledFalse: {
        [CIRCLE_CLASS_SELECTOR]: { bg: TEAL_TRUE, boxShadow },
        '& input': { bg: TEAL_LIGHT },
        '&:hover, &:focus': {
          [CIRCLE_CLASS_SELECTOR]: { bg: TEAL_DARK, boxShadow },
          '& input': { bg: TEAL_LIGHT },
        },
      },
    },
    checkedFalse: {
      // light, checked false, disabled true
      disabledTrue: {
        opacity: 0.5,
        [CIRCLE_CLASS_SELECTOR]: { bg: GREY_60 },
        '& input': { bg: GREY_20 },
      },
      // light, checked false, disabled false
      disabledFalse: {
        [CIRCLE_CLASS_SELECTOR]: { bg: GREY_40 },
        '& input': { bg: GREY_20 },
        '&:hover, &:focus': {
          [CIRCLE_CLASS_SELECTOR]: { bg: GREY_60 },
          '& input': { bg: GREY_20 },
        },
      },
    },
  },
  dark: {
    checkedTrue: {
      // dark, checked true, disabled true
      disabledTrue: {
        opacity: 0.5,
        [CIRCLE_CLASS_SELECTOR]: { bg: TEAL_TRUE },
        '& input': { bg: TEAL_LIGHT },
      },
      // dark, checked true, disabled false
      disabledFalse: {
        [CIRCLE_CLASS_SELECTOR]: { bg: TEAL_TRUE, boxShadow },
        '& input': { bg: TEAL_LIGHT },
        '&:hover, &:focus': {
          [CIRCLE_CLASS_SELECTOR]: { bg: TEAL_DARK, boxShadow },
          '& input': { bg: TEAL_LIGHT },
        },
      },
    },
    checkedFalse: {
      // dark, checked false, disabled true
      disabledTrue: {
        opacity: 0.5,
        [CIRCLE_CLASS_SELECTOR]: { bg: GREY_80 },
        '& input': { bg: GREY_90 },
      },
      // dark, checked false, disabled false
      disabledFalse: {
        [CIRCLE_CLASS_SELECTOR]: { bg: GREY_80 },
        '& input': { bg: GREY_90 },
        '&:hover': {
          [CIRCLE_CLASS_SELECTOR]: { bg: GREY_100 },
          '& input': { bg: GREY_90 },
        },
        '&focus': {
          [CIRCLE_CLASS_SELECTOR]: { bg: GREY_80 },
          '& input': { bg: GREY_90 },
        },
      },
    },
  },
};

/**
 * This ring provides a ring around the component when
 * the component is focused by keyboard users.
 */
const focusRingStyles: CSS = {
  transition: '0.2s ease',
  opacity: 0,
  position: 'absolute',
  width: 'calc(100% + 0.9rem)',
  height: 'calc(100% + 1.4rem)',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: '0.2rem solid',
  borderRadius: '10rem',
  borderColor: TEAL_TRUE,
  bg: 'transparent',
  boxSizing: 'border-box',
  pointerEvents: 'none',
  'input:focus + &': {
    opacity: 1,
  },
};

export interface SwitchProps extends ComponentPropsWithRef<'input'> {
  id: string;
  checked: boolean;
  toggleLast?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  variant?: SwitchVariants,
  componentStyles?: CSS,
  label?: ReactNode,
}

/**
 * Renders an on/off toggle to the screen. Can be focused with TAB
 * and can be toggled on/off with SPACE.
 *
 * See spec: https://www.figma.com/file/ONU4JR00a4JLDqtCTrkB80/ChalkCast-Style-Guide?node-id=8071%3A0
 */
const Switch = forwardRef<HTMLInputElement, SwitchProps>(({
  checked, label = '', id, variant = 'light',
  onChange, disabled = false, className = '', componentStyles = {},
  toggleLast = false, ...rest
}, ref) => {
  const isKeyboardUser = useIsKeyboardUser();
  const checkedKey: CheckedKey = checked ? 'checkedTrue' : 'checkedFalse';
  const disabledKey: DisabledKey = disabled ? 'disabledTrue' : 'disabledFalse';

  /**
   * Wraps both the component's label and the input element.
   * Enables the label to be placed either on the left or the right.
   */
  const outerContainerStyles: CSS = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: toggleLast ? 'row-reverse' : 'row',
    justifyContent: 'space-between',
    width: 'max-content',
    maxWidth: '100%',
    height: 'max-content',
    minHeight: '3.5rem',
  };

  /**
   * This is the input itself that we use to receive the user's interaction.
   * Visually, we only use it for the background color of the component.
   */
  const inputStyles: CSS = {
    position: 'absolute',
    left: 0,
    appearance: 'none',
    transition: '0.2s ease',
    height: '100%',
    width: '100%',
    border: 'none',
    borderRadius: BORDER_RADIUS,
    cursor: disabled ? 'not-allowed' : 'pointer',
    '&:focus': {
      outline: 'none',
    },
  };

  /**
   * This is the circle knob that moves on the switch when it is toggled on/off.
   * Use transforms to move it rather than top/left, since that is more performant.
   */
  const circleStyles: CSS = {
    position: 'absolute',
    transition: 'transform 0.1s, background-color 0.2s ease',
    top: '50%',
    left: 0,
    transform: checked
      ? `translate(calc(calc(-50% + ${SWITCH_WIDTH}) - ${SWITCH_CIRCLE_SIZE / 2}px), -50%)`
      : `translate(calc(-50% + ${SWITCH_CIRCLE_SIZE / 2}px), -50%)`,
    height: SWITCH_CIRCLE_SIZE,
    width: SWITCH_CIRCLE_SIZE,
    borderRadius: '10rem',
    pointerEvents: 'none',
    cursor: disabled ? 'not-allowed' : 'pointer',
  };

  const dynamicLabelStyles = {
    ...(toggleLast ? { mr: '1rem' } : { ml: '1rem' }),
    color: variant === 'light' ? GREY_20 : GREY_70,
  };

  return (
    <div sx={{ ...outerContainerStyles, ...componentStyles }} className={className}>
      <div sx={{ ...containerStyles, ...switchThemedStyles[variant][checkedKey][disabledKey] }}>
        <input
          ref={ref}
          sx={inputStyles}
          type="checkbox"
          role="switch"
          disabled={disabled}
          aria-checked={checked}
          id={id}
          checked={checked}
          onChange={(e) => {
            if (!disabled) {
              onChange(e);
            }
          }}
          data-selenium={`${id}-${checked}-switch`}
          {...rest}
        />
        {isKeyboardUser && <span sx={focusRingStyles} />}
        <span className={CIRCLE_CLASS} sx={circleStyles} />
      </div>
      {label && <label sx={{ variant: 'text.body_small', ...dynamicLabelStyles }} htmlFor={id}>{label}</label>}
    </div>
  );
});

Switch.displayName = 'Switch';

export default memo(Switch);
