/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import { setDtxOption, setMaxBitrateOption } from 'actions/debugActions';
import Input from 'components/ui/Input';
import Switch from 'components/ui/Switch';
import useTypedSelector from 'hooks/useTypedSelector';
import { ChangeEvent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getEncoderBitrateOptions } from 'selectors';
import { BitrateOptions } from 'store/types';
import { CSS } from 'types/css';

const containerStyles: CSS = {
  display: 'flex',
  alignItems: 'center',
};

const toggleStyles: CSS = {
  m: '0 0 -1.5rem 1rem',
  minWidth: 'max-content',
};

interface Props {
  option: keyof BitrateOptions,
  setShouldApply: (arg0: boolean) => void
}

export default function BitrateOptionInputs({ option, setShouldApply }: Props) {
  const encoderBitrateOptions = useTypedSelector(getEncoderBitrateOptions);
  const dispatch = useDispatch();

  const { maxBitrate, dtx } = encoderBitrateOptions[option];

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setMaxBitrateOption({ option, bitrate: +e.target.value }));
    setShouldApply(true);
  }, [dispatch, setShouldApply, option]);

  return (
    <div sx={containerStyles}>
      <Input
        id={`bitrate-option-input-${option}-bitrate`}
        label={option}
        type="number"
        value={`${maxBitrate}`}
        onChange={onChange}
      />
      <Switch
        variant="dark"
        sx={toggleStyles}
        id={`bitrate-option-input-${option}-dtx`}
        label="DTX"
        checked={dtx}
        onChange={() => {
          dispatch(setDtxOption({ option, dtx: !dtx }));
          setShouldApply(true);
        }}
      />
    </div>
  );
}
