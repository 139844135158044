import produce from 'immer';
import {
  SET_WEBSOCKET,
  CLOSE_WEBSOCKET,
  TOGGLE_LAYOUT,
  SET_PRESENTER_ID,
  JOIN_ROOM_START,
  JOIN_ROOM_COMPLETE,
  SET_OWNER_ID_AND_NAME,
  TOGGLE_ROSTER_VISIBLE,
  ROOM_WEBSOCKET_DISCONNECTED,
  SET_ROOM_PERMISSIONS,
  SET_PERMISSIONS_LOADING,
  RESET_ROOM_PERMISSIONS,
  SET_OWNER_BROADCAST_LOADING,
  SET_ROOM_JOINED_DATE,
  RoomLayoutEnum,
  SET_DROPDOWN_OPEN,
  SET_LIVELY_ROOM_TOKEN,
  SET_FULLSCREEN_USER_ID,
  emptyRoomToken,
  RoomAction,
  SET_ROOM_JOINABLE,
  ADD_MOCK_PARTICIPANT,
  SET_ROOM_LOCK_LOADING,
  SET_IS_LOCKED_FROM_JOINING,
  SET_ROOM_PERMISSIONS_UPDATING,
  SET_HEARTBEAT_FAILURE,
  SET_ROOM_STATE,
  SET_WS_READY,
  SET_IS_REQUIRE_LOGIN_LOADING,
  SET_ROOM_STATE_REQUIRES_LOGIN,
  SET_ROOM_STATE_IS_LOCKED,
  SET_RECORDING_START,
  INITIAL_ROOM_STATE_RECEIVED,
  SET_ROOM_STATE_KNOCKERS,
  SET_ROOM_STATE_PARTICIPANTS,
  SET_PREVIOUSLY_ADMITTED,
  SET_ICEBREAKER_QUESTIONS,
  SET_CHAPERONE_LOADING,
  SET_OPEN_ROOM,
} from 'actions/roomActions';
import {
  LOGOUT,
  RESET_ROOM, SET_DISABLE_ROOM_ACTIONS, SET_JOIN_ROOM_ERROR, SET_ROOM_ERROR, SharedAction,
} from 'actions/sharedActions';
import { persistReducer, persistConfig } from 'store/localStoragePersistMiddleware';
import { RoomState } from 'store/types';
import { DEFAULT_ROOM_PERMISSIONS } from 'utils/roomPermissions';

export const initialState: RoomState = {
  room: {
    breakoutRooms: {},
    id: '',
    roomName: '',
    ownerId: '',
    callId: false,
    participants: {},
    knockers: {},
    calledOnId: '',
    ownerJoinUnassigned: false,
    isJoinable: false,
    isLocked: false,
    ownerPresent: false,
    roomRoster: {},
    isLoginRequired: false,
    roomPermissions: {},
    // ? we need these ?
    ownerBroadcastData: {} as any,
    reserved: {} as any,
    questions: {},
    openroom: false,
    recordingStart: 0,
  },
  permissionsLoading: true,
  permissionsUpdating: false,
  permissions: {
    ...DEFAULT_ROOM_PERMISSIONS,
  },
  ownerId: null,
  ownerName: null,
  layout: RoomLayoutEnum.GRID,
  prevUserSelectedLayout: RoomLayoutEnum.GRID,
  presenterId: null,
  isRosterVisible: false,
  preJoinSettings: {
    hideAll: false,
    muteAll: false,
  },
  socket: null,
  isWSReady: false,
  loading: false,
  ownerBroadcastLoading: {},
  roomJoinedTimestamp: 0,
  dropdownOpen: false,
  livelyRoomToken: emptyRoomToken,
  fullscreenUserId: '',
  isJoinable: false,
  isRoomLockLoading: false,
  isLockedFromJoining: false,
  isRequireLoginLoading: false,
  isDisabled: false,
  heartbeatFailure: false,
  initialRoomStateReceived: false,
  previouslyAdmitted: false,
  isChaperoneUpdateLoading: false,
};

const reducer = produce((draft: RoomState, action: RoomAction | SharedAction) => {
  switch (action.type) {
    case SET_ROOM_STATE:
      draft.room = action.payload.room;
      break;
    case RESET_ROOM:
      Object.assign(draft, initialState);
      break;
    case LOGOUT:
      draft.livelyRoomToken = initialState.livelyRoomToken;
      break;
    case TOGGLE_LAYOUT:
      draft.layout = action.payload.layout;
      if (action.payload.isUserSelected) {
        draft.prevUserSelectedLayout = action.payload.layout;
      }
      break;
    case SET_PRESENTER_ID:
      draft.presenterId = action.payload.presenterId;
      break;
    case SET_WEBSOCKET:
      draft.socket = action.payload.socket;
      break;
    case CLOSE_WEBSOCKET:
      draft.socket = null;
      draft.isWSReady = false;
      break;
    case SET_ROOM_PERMISSIONS:
      draft.permissions = action.payload.permissions;
      break;
    case SET_PERMISSIONS_LOADING:
      draft.permissionsLoading = action.payload.loading;
      break;
    case RESET_ROOM_PERMISSIONS:
      draft.permissions = initialState.permissions;
      draft.permissionsLoading = initialState.permissionsLoading;
      break;
    case SET_ROOM_PERMISSIONS_UPDATING:
      draft.permissionsUpdating = action.payload.permissionsUpdating;
      break;
    case TOGGLE_ROSTER_VISIBLE:
      draft.isRosterVisible = !draft.isRosterVisible;
      break;
    case JOIN_ROOM_START:
      draft.loading = true;
      break;
    case JOIN_ROOM_COMPLETE:
      draft.loading = false;
      break;
    case SET_ROOM_ERROR:
      draft.loading = false;
      break;
    case SET_JOIN_ROOM_ERROR:
      draft.loading = false;
      break;
    case SET_OWNER_ID_AND_NAME:
      draft.ownerId = action.payload.ownerId;
      draft.ownerName = action.payload.ownerName;
      break;
    case ROOM_WEBSOCKET_DISCONNECTED:
      draft.room.callId = null; // set to null so the SessionEnded screen shows
      break;
    case SET_OWNER_BROADCAST_LOADING:
      draft.ownerBroadcastLoading = {
        ...draft.ownerBroadcastLoading, ...action.payload.loadingState,
      };
      break;
    case SET_ROOM_JOINED_DATE:
      draft.roomJoinedTimestamp = action.payload.timestamp;
      break;
    case SET_DROPDOWN_OPEN:
      draft.dropdownOpen = action.payload.dropdownOpen;
      break;
    case SET_LIVELY_ROOM_TOKEN:
      draft.livelyRoomToken = action.payload.livelyRoomToken;
      break;
    case SET_FULLSCREEN_USER_ID:
      draft.fullscreenUserId = action.payload.fullscreenUserId;
      break;
    case SET_ROOM_JOINABLE:
      draft.isJoinable = action.payload.joinable;
      break;
    case ADD_MOCK_PARTICIPANT:
      draft.room.participants[action.payload.id] = action.payload.participant;
      break;
    case SET_ROOM_LOCK_LOADING:
      draft.isRoomLockLoading = action.payload.loading;
      break;
    case SET_IS_LOCKED_FROM_JOINING:
      draft.isLockedFromJoining = action.payload.isLocked;
      break;
    case SET_IS_REQUIRE_LOGIN_LOADING:
      draft.isRequireLoginLoading = action.payload.loading;
      break;
    case SET_DISABLE_ROOM_ACTIONS:
      draft.isDisabled = action.payload.isDisabled;
      break;
    case SET_HEARTBEAT_FAILURE:
      draft.heartbeatFailure = action.payload.heartbeatFailure;
      break;
    case SET_WS_READY:
      draft.isWSReady = action.payload.isWSReady;
      break;
    case SET_ROOM_STATE_IS_LOCKED:
      draft.room.isLocked = action.payload.isLocked;
      break;
    case SET_ROOM_STATE_REQUIRES_LOGIN:
      draft.room.isLoginRequired = action.payload.isLoginRequired;
      break;
    case SET_ROOM_STATE_KNOCKERS:
      draft.room.knockers = action.payload.knockers;
      break;
    case SET_ROOM_STATE_PARTICIPANTS:
      draft.room.participants = action.payload.participants;
      break;
    case SET_ICEBREAKER_QUESTIONS:
      draft.room.questions = action.payload.questions;
      break;
    case INITIAL_ROOM_STATE_RECEIVED:
      draft.initialRoomStateReceived = true;
      break;
    case SET_PREVIOUSLY_ADMITTED:
      draft.previouslyAdmitted = action.payload.previouslyAdmitted;
      break;
    case SET_CHAPERONE_LOADING:
      draft.isChaperoneUpdateLoading = action.payload.loading;
      break;
    case SET_OPEN_ROOM:
      draft.room.openroom = action.payload.open;
      break;
    case SET_RECORDING_START:
      draft.room.recordingStart = action.payload.start;
      break;
    default:
      break;
  }
}, initialState);

export default persistReducer<RoomState>(reducer, persistConfig.roomState);
