/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import { ReactComponent as LogoBetaSVG } from 'assets/logos/logo-beta.svg';
import { ColorsEnumValue, TEAL_TRUE, WHITE } from 'theme/ui/colors';
import { ComponentProps } from 'react';
import { CSS } from 'types/css';
import Svg from 'components/ui/Svg';
import { Either } from 'utils/typeUtils';

type LogoBetaProps = ComponentProps<'div'> & {
  fill?: ColorsEnumValue,
  betaWordFill?: ColorsEnumValue
} & Either<{
  svgTitle?: string,
  'aria-hidden'?: true,
}, 'svgTitle', 'aria-hidden'>

const createStyles = (fill: ColorsEnumValue, betaWordFill: ColorsEnumValue): CSS => ({
  fill,
  width: '100%',
  height: 'auto',
  mt: '0.5rem',
  '& path:not(:last-child), & rect': {
    fill,
  },
  '& path:last-child': {
    fill: betaWordFill,
  },
});

export default function LogoBeta({
  fill = TEAL_TRUE, svgTitle, 'aria-hidden': ariaHidden, betaWordFill = WHITE, ...props
}: LogoBetaProps) {
  return (
    <div sx={{ width: '24rem', maxHeight: '5.2rem' }} {...props}>
      {svgTitle ? (
        <Svg svg={LogoBetaSVG} title={svgTitle} sx={createStyles(fill, betaWordFill)} />
      ) : (
        <Svg svg={LogoBetaSVG} aria-hidden={ariaHidden ?? true} sx={createStyles(fill, betaWordFill)} />
      )}
    </div>
  );
}
