/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import throttle from 'lodash/throttle';
import { CSS } from 'types/css';
import { setSoundFileGain, setSoundFileThrottleMs } from 'actions/audioActions';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFilePlaybackConfig, getSoundPlayer } from 'selectors';
import {
  ORANGE_100, TEAL_100, WHITE, GREY_20,
} from 'theme/ui/colors';
import { SoundFileEnum } from 'utils/soundPlayer';

interface AudioDebugNotificationProps {
  sound: SoundFileEnum;
  playingDisabled: boolean;
  setPlayingDisabled: (val: boolean) => void;
}

const styles: CSS = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const buttonStyles: CSS = {
  bg: TEAL_100,
  color: WHITE,
  borderRadius: '0.5rem',
  p: '0 1rem',
  border: 'none',
  ml: '1rem',
  '&:disabled': {
    bg: GREY_20,
    cursor: 'default',
  },
  '&:focus': {
    outline: 'none',
  },
};

const rangeStyles = {
  mr: '1rem',
  width: '75%',
};

export default function AudioDebugNotification({
  sound, playingDisabled, setPlayingDisabled,
}: AudioDebugNotificationProps) {
  const dispatch = useDispatch();
  const soundPlayer = useSelector(getSoundPlayer);
  const playbackConfig = useSelector(getFilePlaybackConfig(sound));

  const rapidFire = () => {
    if (playingDisabled) return;
    setPlayingDisabled(true);
    let plays = 0;
    const intvl = setInterval(() => {
      soundPlayer.play(sound);
      plays += 1;
      if (plays === 80) {
        clearInterval(intvl);
        setPlayingDisabled(false);
      }
    }, 25);
  };

  useEffect(() => {
    soundPlayer.load(sound);
  }, [sound, soundPlayer]);

  const perSec = playbackConfig.throttleMs ? (
    Math.round(100000 / playbackConfig.throttleMs) / 100
  ) : 0;

  const onThrottleMsChange = throttle(useCallback(({ target: { value } }) => {
    dispatch(setSoundFileThrottleMs(sound, parseInt(value, 10)));
  }, [dispatch, sound]), 50);

  const onGainChange = throttle(useCallback(({ target: { value } }) => {
    dispatch(setSoundFileGain(sound, parseFloat(value)));
  }, [dispatch, sound]), 50);

  return (
    <div>
      <hr />
      <div sx={styles}>
        <div>
          <div sx={{ fontWeight: 'bold' }}>
            {sound.displayName}
          </div>
          <div sx={{ fontSize: '1.33rem' }}>{sound.description}</div>
        </div>
        <div sx={{ display: 'flex' }}>
          <button
            type="button"
            disabled={playingDisabled}
            sx={buttonStyles}
            onClick={() => soundPlayer.play(sound)}
          >
            Play
          </button>
          <button
            type="button"
            disabled={playingDisabled}
            sx={{ ...buttonStyles, bg: ORANGE_100 }}
            onClick={rapidFire}
          >
            Rapid Fire 2s
          </button>
        </div>
      </div>
      <div>
        <div>Limit playback frequency</div>
        <input
          type="range"
          sx={rangeStyles}
          min={0}
          max={2000}
          step={1}
          value={playbackConfig.throttleMs}
          onChange={onThrottleMsChange}
        />
        <span sx={{ transform: 'translateY(-1rem)' }}>
          {playbackConfig.throttleMs}
          ms
          {' '}
          (
          {perSec === 0 ? '∞' : perSec}
          /sec)
        </span>
      </div>
      <div>
        <div>Gain</div>
        <input
          type="range"
          sx={rangeStyles}
          min={0}
          max={2}
          step={0.001}
          value={playbackConfig.gain}
          onChange={onGainChange}
        />
        {Math.round(playbackConfig.gain * 100)}
        %
      </div>
    </div>
  );
}
