import { EnumBase } from 'utils/enum';

export type FilterEventKeys = Exclude<keyof typeof FilterEvent, keyof typeof EnumBase>;

export class FilterEvent<S extends string = string> extends EnumBase {
  public static readonly VIDEO_DATA_ALREADY_READY = new FilterEvent('VIDEO_DATA_ALREADY_READY');

  public static readonly VIDEO_DATA_LOADED = new FilterEvent('VIDEO_DATA_LOADED');

  public static readonly VIDEO_DATA_LOAD_START = new FilterEvent('VIDEO_DATA_LOAD_START');

  public static readonly VIDEO_DIMENSIONS_0_DETECTED = new FilterEvent('VIDEO_DIMENSIONS_0_DETECTED');

  public static readonly CURRENT_FILTER_SET = new FilterEvent('CURRENT_FILTER_SET');

  public static readonly DOUBLE_THRESHOLD_LOW_VALUE_SET = new FilterEvent('DOUBLE_THRESHOLD_LOW_VALUE_SET');

  public static readonly DOUBLE_THRESHOLD_HIGH_VALUE_SET = new FilterEvent('DOUBLE_THRESHOLD_HIGH_VALUE_SET');

  public static readonly ENABLE_FACE_DETECTION_SET = new FilterEvent('ENABLE_FACE_DETECTION_SET');

  public static readonly MAX_FRAME_RATE_SET = new FilterEvent('MAX_FRAME_RATE_SET');

  public static readonly PREDICTION_INTERVAL_SET = new FilterEvent('PREDICTION_INTERVAL_SET');

  public static readonly CROPPED_CANVAS_SIZE_SET = new FilterEvent('CROPPED_CANVAS_SIZE_SET');

  public static readonly CONTEXT_LOST = new FilterEvent('CONTEXT_LOST');

  public static readonly CONTEXT_RESTORED = new FilterEvent('CONTEXT_RESTORED');

  public static readonly CLEANING_UP = new FilterEvent('CLEANING_UP');

  constructor(public id: S) {
    super();
  }

  /** Identical ordering is guaranteed on successive calls */
  public static asArray() {
    const array = super.asArray();
    array.sort();
    // hacky, but gives exact union values for array
    return super.asArray() as Array<typeof FilterEvent[FilterEventKeys]>;
  }

  public static asObject() {
    // hacky, but gives exact object keys/values
    return super.asObject() as { [key in FilterEventKeys]: typeof FilterEvent[key] };
  }
}
