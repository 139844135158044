import produce from 'immer';
import {
  SET_PLAYER,
  REMOVE_PLAYER,
  SET_SCREEN_CAPTURE_PLAYER,
  REMOVE_SCREEN_CAPTURE_PLAYER,
  PlayersAction,
  SET_CURRENT_MAX_BITRATE,
  SET_PLAYER_BITRATE_PREFERENCE,
  SET_PLAYER_MEDIA_STATE,
  SET_PLAYER_VISIBILITY,
  SET_HIGH_CPU,
  TOGGLE_PLAYER_BITRATE_LOCKED,
} from 'actions/playersActions';
import { RESET_ROOM, RESET_ALL_MEDIA, SharedAction } from 'actions/sharedActions';
import { PlayersState } from 'store/types';

export const initialState: PlayersState = {
  players: {},
  screenSharePlayers: {},
  playerBitrateLevels: {},
  mediaState: {},
  visibility: {},
  isHighCPU: false,
};

const reducer = produce((draft: PlayersState, action: PlayersAction | SharedAction) => {
  switch (action.type) {
    case SET_PLAYER: {
      const { userId } = action.payload.player;
      draft.players[userId] = {
        ...action.payload.player,
      };
      draft.playerBitrateLevels[userId] = {
        currentMaxBitrate: null,
        preference: null,
        displayName: action.payload.player.displayName,
        locked: false,
      };
      break;
    }
    case REMOVE_PLAYER:
      delete draft.players[action.payload.id];
      break;
    case SET_SCREEN_CAPTURE_PLAYER: {
      const { userId } = action.payload.player;
      draft.screenSharePlayers[userId] = {
        ...action.payload.player,
      };
      break;
    }
    case REMOVE_SCREEN_CAPTURE_PLAYER:
      delete draft.screenSharePlayers[action.payload.id];
      break;
    case SET_PLAYER_BITRATE_PREFERENCE:
      if (draft.playerBitrateLevels[action.payload.userId]) {
        draft.playerBitrateLevels[action.payload.userId].preference = action.payload.preference;
      } else {
        draft.playerBitrateLevels[action.payload.userId] = {
          currentMaxBitrate: null,
          preference: action.payload.preference,
          displayName: '',
          locked: false,
        };
      }
      break;
    case SET_CURRENT_MAX_BITRATE:
      if (draft.playerBitrateLevels[action.payload.userId]) {
        draft.playerBitrateLevels[action.payload.userId].currentMaxBitrate = action.payload.maxBitrate;
      } else {
        draft.playerBitrateLevels[action.payload.userId] = {
          currentMaxBitrate: action.payload.maxBitrate,
          preference: 'Low',
          displayName: '',
          locked: false,
        };
      }
      break;
    case RESET_ROOM:
      Object.assign(draft, initialState);
      break;
    case SET_PLAYER_MEDIA_STATE:
      if (draft.mediaState[action.payload.userId] === undefined) {
        draft.mediaState[action.payload.userId] = {
          audioOff: true,
          videoOff: true,
        };
      }
      if (action.payload.audioOff !== undefined) {
        draft.mediaState[action.payload.userId].audioOff = action.payload.audioOff;
      }
      if (action.payload.videoOff !== undefined) {
        draft.mediaState[action.payload.userId].videoOff = action.payload.videoOff;
      }
      break;
    case SET_PLAYER_VISIBILITY:
      draft.visibility[action.payload.userId] = action.payload.isVisible;
      break;
    case SET_HIGH_CPU:
      draft.isHighCPU = action.payload.isHighCPU;
      break;
    case TOGGLE_PLAYER_BITRATE_LOCKED: {
      const playerLevels = draft.playerBitrateLevels[action.payload.userId];
      if (playerLevels) {
        playerLevels.locked = !playerLevels.locked;
      }
    }
      break;
    case RESET_ALL_MEDIA:
      Object.assign(draft, initialState);
      break;
    default:
      break;
  }
  // hack due to PlayerUIState instances in Player, see encoderReducer etc.
}, (initialState as any));

export default reducer;
