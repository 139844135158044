import mergeWith from 'lodash/mergeWith';
import { StoredKNRoom } from 'store/types';
import { KNRoom } from './knats';

const isPlainObj = (obj: any) => !!obj && typeof obj === 'object' && !Array.isArray(obj);

const customizeMerge = (prevVal: any, nextVal: any): any => {
  // In this implementation, null means to delete a key from an object
  if (isPlainObj(prevVal) && isPlainObj(nextVal)) {
    const prevCopy = { ...prevVal };
    const nextCopy = { ...nextVal };
    Object.keys(nextVal).forEach((key) => {
      if (nextVal[key] === null) {
        delete prevCopy[key];
        delete nextCopy[key];
      }
    });
    return mergeWith(prevCopy, nextCopy, customizeMerge);
  }
  return undefined;
};

const mergeRoomStates = (
  roomState: StoredKNRoom,
  knatsDiff: KNRoom,
): StoredKNRoom => mergeWith({ ...roomState }, { ...knatsDiff }, customizeMerge);

export default mergeRoomStates;
