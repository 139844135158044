import {
  ColorsEnum, GREY_0, TEAL_DARK, WHITE,
} from 'theme/ui/colors';
import { CSS } from 'types/css';

export const GRID_PADDING = '1.3rem';
export const TABLE_ROW_HEIGHT = '5.3rem';

export const headerStyles: CSS = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'end',
  marginBottom: '6rem',
};

export const mainHeadingStylesBase: CSS = {
  variant: 'text.h4',
  color: TEAL_DARK,
};

export const noSessionsStyles: CSS = {
  ml: '5rem',
  mt: '5rem',
};

export const darkerRowStyles: CSS = {
  bg: GREY_0,
};

export const spacerStyles: CSS = {
  height: '10rem',
};

export const ATTENDANCE_BORDER_RADIUS = '2.4rem';

export const attendanceContainerStyles: CSS = {
  minWidth: '30rem',
  width: 'fit-content',
  maxWidth: 'calc(100% - 4rem)',
  mx: 'auto',
};

export const attendanceCardBaseStyles: CSS = {
  m: '1rem auto 0rem',
  borderRadius: ATTENDANCE_BORDER_RADIUS,
  boxShadow: `0px 4px 16px ${ColorsEnum.BLACK.withOpacity(0.1)}`,
  bg: WHITE,
};

export const attendanceLoaderStyles: CSS = {
  background: 'linear-gradient(270deg, transparent, #D9D9D9 50%, transparent)',
  backgroundSize: '200% 100%',
  animation: 'attendance-skeleton-loader 2s infinite linear forwards',
  '@keyframes attendance-skeleton-loader': {
    '0%': { backgroundPosition: '150% 50%' },
    '100%': { backgroundPosition: '-50% 50%' },
  },
  borderRadius: '50%',
  opacity: 0.75,
};

export const ALL_VIEW_FADE_TRANSITION_TIME = '0.4s';

export const bottomBorderRadius: CSS = {
  borderRadius: `0 0 ${ATTENDANCE_BORDER_RADIUS} ${ATTENDANCE_BORDER_RADIUS}`,
};
