import produce from 'immer';
import {
  AudioAction,
  SET_OVERALL_GAIN_RATIO,
  SET_SOUND_FILE_GAIN,
  SET_SOUND_FILE_THROTTLE_MS,
} from 'actions/audioActions';
import { AudioState } from 'store/types';
import { soundPlayerFactory } from 'utils/soundPlayer';

export const soundPlayer = soundPlayerFactory();

export const initialState: AudioState = {
  soundPlayer,
  soundPlayerOptions: { ...soundPlayer.options },
};

const reducer = produce((draft: AudioState, action: AudioAction) => {
  switch (action.type) {
    case SET_SOUND_FILE_GAIN:
      draft.soundPlayer.updateFilePlaybackConfig(action.payload.sound, { gain: action.payload.gain });
      draft.soundPlayerOptions = { ...draft.soundPlayer.options };
      break;
    case SET_SOUND_FILE_THROTTLE_MS:
      draft.soundPlayer.updateFilePlaybackConfig(action.payload.sound, { throttleMs: action.payload.throttleMs });
      draft.soundPlayerOptions = { ...draft.soundPlayer.options };
      break;
    case SET_OVERALL_GAIN_RATIO:
      draft.soundPlayer.updateOverallGainRatio(action.payload.ratio);
      draft.soundPlayerOptions = { ...draft.soundPlayer.options };
      break;
    default:
      break;
  }
}, initialState);

export default reducer;
