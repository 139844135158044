import { EventEmitter } from 'events';

export default abstract class TypedEventEmitter<Events extends { [key in keyof Events]: unknown }> extends EventEmitter {
  /* The following event code is done this way to allow strict typing for events fired/listened to */
  private _eventEmitterOn = this.on;
  private _eventEmitterEmit = this.emit;
  private _eventEmitterOff = this.off;

  // Regrettable ts-ignores, but it does not seem possible to use the generic Events type without ignoring the built in event emitter types
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  public on = <E extends keyof Events>(event: E, listener: (arg: Events[E]) => void) => {
    this._eventEmitterOn(event, listener);
    return this;
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  public off = <E extends keyof Events>(event: E, listener: (arg: Events[E]) => void) => {
    this._eventEmitterOff(event, listener);
    return this;
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  public emit = <E extends keyof Events>(event: E, arg: Events[E]): boolean => (
    this._eventEmitterEmit(event, arg)
  );
}
