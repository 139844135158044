import { createSelector } from 'reselect';
import { StoreState } from 'store/types';
import { CCServiceSoundPreferencesEnum } from 'utils/ccService/types';

// eslint-disable-next-line import/prefer-default-export
export const getSoundPreferences = (state: StoreState) => state.preferencesState.soundPreferences;

export const getTileSort = (state: StoreState) => state.preferencesState.tileSort;

export const getTileSortIncludeDisabledCameras = (state: StoreState) => state.preferencesState.tileSortIncludeDisabledCameras;

/**
 * Returns boolean for a single soundSetting
 */
export const getSoundPreference = (soundSettingName: CCServiceSoundPreferencesEnum) => createSelector(
  getSoundPreferences,
  (soundPreferences) => {
    if (soundPreferences && soundSettingName in soundPreferences) {
      return !!soundPreferences[soundSettingName].enabled;
    }
    return null;
  },
);
