export const NAME_MAX_LENGTH = 20;

export const ERROR_REQUIRED = 'This field is required';
export const ERROR_MAX_LENGTH = `${NAME_MAX_LENGTH} character limit reached`;

/** Validates first/last name and returns any error messages, if any. */
export default function validateName(firstName: string, required = true) {
  let errorMessage = '';
  if (required && firstName.trim().length === 0) {
    errorMessage = ERROR_REQUIRED;
  } else if (firstName.length > NAME_MAX_LENGTH) {
    errorMessage = ERROR_MAX_LENGTH;
  }
  return errorMessage;
}
