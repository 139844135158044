import { useEffect, useRef } from 'react';

import { TimeoutState } from 'utils/typeUtils';
import usePrevious from './usePrevious';

export type UseResetStateOnTimerCallback<StateType> = (defaultState: StateType) => void;

export interface UseResetStateOnTimerOptions<StateType>{
  state: StateType
  defaultState: StateType,
  clearStateCallback: UseResetStateOnTimerCallback<StateType>,
  useTimer?: boolean,
  timerLength?: number,
}

export const CLEAR_ALERT_TIMER_DEFAULT_LENGTH = 3000;

/**
 * Calls a callback function with the default state on a timeout.
 * However, if the state changes before the callback is called, the timer is reset.
 * Useful for notifications/alerts that disappear on their own after a while.
 */
export default function useResetStateOnTimer<StateType>({
  useTimer = true,
  state, clearStateCallback,
  defaultState,
  timerLength = CLEAR_ALERT_TIMER_DEFAULT_LENGTH,
}: UseResetStateOnTimerOptions<StateType>) {
  const timeout = useRef<TimeoutState>(null);
  const prevState = usePrevious(state);
  const prevTimeout = usePrevious(timeout.current);

  useEffect(() => {
    // If a new message comes in before the previous one has been cleared, reset timer
    if (prevTimeout && prevState && prevState !== state) {
      clearTimeout(prevTimeout);
    }

    // only restart timer if state has not already returned to its default
    if (state !== defaultState && useTimer) {
      if (timeout.current) clearTimeout(timeout.current);
      timeout.current = setTimeout(() => clearStateCallback(defaultState), timerLength);
    }

    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, [state]);
}
