import { createSelector } from 'reselect';
import { StoreState } from 'store/types';

export const getIsCreatingRoom = (state: StoreState) => state.dashboardState.isCreatingRoom;

export const getMyRooms = (state: StoreState) => state.dashboardState.myRooms;

export const getCreatingRoomLoading = (state: StoreState) => state.dashboardState.creatingRoomLoading;

export const getRoomSettingsRoomId = (state: StoreState) => state.dashboardState.roomSettingsRoomId;

export const getRoomDeleteLoading = (state: StoreState) => state.dashboardState.roomDeleteLoading;

export const getNameErrors = (state: StoreState) => state.dashboardState.nameErrors;

export const getAppSettingsModalOpen = (state: StoreState) => state.dashboardState.appSettingsModalOpen;

export const getAreMoreMyRooms = (state: StoreState) => !!state.dashboardState.nextMyRoomsUrl;

export const getAreMoreInvitedRooms = (state: StoreState) => !!state.dashboardState.nextInvitedRoomsUrl;

export const getInvitedRooms = (state: StoreState) => state.dashboardState.invitedRooms;

export const getNameError = (roomId: string) => createSelector(
  getNameErrors,
  (nameErrors) => nameErrors?.[roomId] || null,
);

export const getNewRoomNameError = (state: StoreState) => state.dashboardState.newRoomNameError;

export const getRoomSettingsRoomName = (
  state: StoreState,
) => state.dashboardState.roomSettingsRoomName;
