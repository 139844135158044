import { useContext, Context } from 'react';
import { MustBeNullish } from 'utils/typeUtils';

/**
 * Requires a provider to be supplied to this this useContext.
 * useContextWithProvider expects to never receive null or undefined values,
 * and if it does receive them, it assumes that this is an error and throws accordingly.
 *
 * Any context that uses this hook should use null or undefined as it's default state, since
 * otherwise, it would be possible to accidentally leave out a Provider and the hook would not catch this error.
 * TypeScript helps ensure this by requiring the type that useContextWithProvider receives to possibly be either null or undefined.
 *
 * @example
 * const SomeContext = createContext<SomeContextType | null>(null);
 */
export default function useContextWithProvider<T extends unknown>(CreatedContext: Context<MustBeNullish<T>>) {
  const isProduction = process.env.NODE_ENV === 'production';
  const context = useContext(CreatedContext);
  if (!isProduction && (context === null || context === undefined)) {
    throw new Error('useContextWithProvider must be wrapped in it\'s corresponding Provider');
  }
  return context as Exclude<T, null | undefined>;
}
