import {
  compose, applyMiddleware, createStore, combineReducers,
} from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import mobileReducer from 'reducers/mobileReducer';
import loginReducer from 'reducers/loginReducer';
import roomReducer from 'reducers/roomReducer';
import chatReducer from 'reducers/chatReducer';
import encoderReducer from 'reducers/encoderReducer';
import playersReducer from 'reducers/playersReducer';
import breakoutGroupsReducer from 'reducers/breakoutGroupsReducer';
import errorMessageReducer from 'reducers/errorMessageReducer';
import dashboardReducer from 'reducers/dashboardReducer';
import attendanceReducer from 'reducers/attendanceReducer';
import passwordResetReducer from 'reducers/passwordResetReducer';
import screenShareReducer from 'reducers/screenShareReducer';
import preferencesReducer from 'reducers/preferencesReducer';
import debugReducer from 'reducers/debugReducer';
import audioReducer from 'reducers/audioReducer';
import browserReducer from 'reducers/browserReducer';
import alertReducer from 'reducers/alertReducer';
import notificationReducer from 'reducers/notificationReducer';
import chalkboardReducer from 'reducers/chalkboardReducer';
import { responsiveStoreEnhancer } from 'redux-responsive';
import joinReducer from 'reducers/joinReducer';
import giphyReducer from 'reducers/giphyReducer';
import networkReducer from 'reducers/networkReducer';
import nudgeReducer from 'reducers/nudgeReducer';
import icebreakerReducer from 'reducers/icebreakerReducer';
import filterReducer from 'reducers/filterReducer';
import notesReducer from 'reducers/notesReducer';
import initState from './initState';
import localStoragePersistMiddleware from './localStoragePersistMiddleware';
import type { StoreState } from './types';

export const history = createBrowserHistory();

export const rootReducer = combineReducers<StoreState>({
  router: connectRouter(history),
  loginState: loginReducer,
  roomState: roomReducer,
  chatState: chatReducer,
  encoderState: encoderReducer,
  playersState: playersReducer,
  dashboardState: dashboardReducer,
  breakoutGroupsState: breakoutGroupsReducer,
  alertState: alertReducer,
  errorMessageState: errorMessageReducer,
  attendanceState: attendanceReducer,
  passwordResetState: passwordResetReducer,
  screenShareState: screenShareReducer,
  preferencesState: preferencesReducer,
  debugState: debugReducer,
  audioState: audioReducer,
  browser: browserReducer,
  joinState: joinReducer,
  giphyState: giphyReducer,
  networkState: networkReducer,
  notificationState: notificationReducer,
  mobileState: mobileReducer,
  chalkboardState: chalkboardReducer,
  nudgeState: nudgeReducer,
  icebreakerState: icebreakerReducer,
  filterState: filterReducer,
  notesState: notesReducer,
});

const composer = (window as Window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const composedEnhancers = composer(
  responsiveStoreEnhancer,
  applyMiddleware(
    thunk,
    routerMiddleware(history),
    localStoragePersistMiddleware,
    createLogger({
      collapsed: true,
    }),
  ),
);

// TODO: Update to redux toolkit
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const store = createStore(rootReducer, initState(), composedEnhancers);

export default store;
