/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import {
  ComponentProps, memo, useEffect, useMemo, useRef,
} from 'react';
import { useStore } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeGetAllViewDisplayNameLabelWeight } from 'selectors';
import { mediaMinWidth, textEllipsis } from 'styles/styles';
import {
  BLACK, GREY_10, GREY_50, GREY_80,
} from 'theme/ui/colors';
import { CSS } from 'types/css';
import { formatNameData } from 'utils/stringUtils';
import { useRoomManagerContext } from 'views/RoomManager/roomManagerContext';
import { GRID_PADDING, ALL_VIEW_FADE_TRANSITION_TIME } from '../common';

const displayNameLabelStyles: CSS = {
  justifySelf: 'start',
  transition: 'background-color 0.2s',
  mx: GRID_PADDING,
  width: '100%',
  height: '100%',
  maxWidth: '100%',
  ...textEllipsis,
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  '& a': {
    p: '0.4rem 0.8rem',
    variant: 'text.caption',
    [mediaMinWidth._1]: {
      variant: 'text.body_small',
    } as CSS,
    transition: `color ${ALL_VIEW_FADE_TRANSITION_TIME}`,
    textDecoration: 'none',
    color: GREY_80,
    borderRadius: '2.2rem',
    maxWidth: '100%',
    ...textEllipsis,
    '&:hover, &:focus': {
      bg: GREY_10,
      outline: 0,
      border: 0,
    },
  },
};
interface AttendanceDisplayNameLabelProps extends ComponentProps<'th'> {
  firstName: string,
  lastName: string,
  displayName: string,
  userId: string,
}

function AttendanceNameLabel({
  displayName, firstName, lastName, userId, ...rest
}: AttendanceDisplayNameLabelProps) {
  const formattedName = useMemo(() => formatNameData(firstName, lastName, displayName), [firstName, lastName, displayName]);
  const getDisplayNameWeight = useMemo(() => makeGetAllViewDisplayNameLabelWeight(userId), [userId]);
  const store = useStore();
  const linkRef = useRef<HTMLAnchorElement | null>(null);
  const { roomId } = useRoomManagerContext();

  // Imperatively update styles to change color (prevents 100ms+ rerenders on hover/click)
  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      if (linkRef.current) {
        const displayNameWeight = getDisplayNameWeight(store.getState());
        let color: CSS['color'];
        switch (displayNameWeight) {
          case 'light':
            color = GREY_50;
            break;
          case 'bold':
            color = BLACK;
            break;
          default:
            color = GREY_80;
            break;
        }
        linkRef.current.style.color = color;
      }
    });

    return () => unsubscribe();
  }, [getDisplayNameWeight, store]);

  return (
    <th sx={displayNameLabelStyles} {...rest}>
      <Link to={`/room-manager/attendance/${roomId}/${userId}`} ref={linkRef}>{formattedName}</Link>
    </th>
  );
}

export default memo(AttendanceNameLabel);
