import React, { createContext, ReactNode, useContext } from 'react';
import { node } from 'prop-types';

// empty object when no provider is used
const FeatureFlagContext = createContext<FeatureFlags>({} as FeatureFlags);

/**
 * Enable or disable features using localStorage
 * Default values are set in the config per each environment
 *
 * Example:
 * The 'add mock participants' button is disabled in production.
 * To enable it, manually set the value to true in localStorage with:
 * localStorage.setItem('enableBreakoutGroups', true);
 */
interface Props {
 children?: ReactNode,
}

export const getLocalStorageFeatureFlagValue = (featureFlagKey: keyof FeatureFlags) => {
  const localStorageValue = localStorage.getItem(featureFlagKey);
  return localStorageValue === 'true' || localStorageValue === 'false'
    ? JSON.parse(localStorageValue)
    : appConfig[featureFlagKey];
};

function FeatureFlagProvider({ children }: Props) {
  const flags: FeatureFlags = {
    enableBreakoutGroups: false,
    enableOwnerBreakoutGroupSettings: false,
    enableChatInWaitingRoom: false,
    enableAppleAppStoreRedirect: false,
    enableGooglePlayStoreRedirect: false,
    enableChalkboard: false,
    enableKafkaNatsRoomState: false,
    enableFilters: false,
    enableIncompatibleBrowsers: false,
    /** @todo may be unnecessary flag once this feature stabilizes */
    enableLockedRoomUI: false,
  };
  const features = Object.keys(flags) as (keyof FeatureFlags)[];

  features.reduce((result, feature) => {
    result[feature] = getLocalStorageFeatureFlagValue(feature);
    return result;
  }, flags);

  return (
    <FeatureFlagContext.Provider
      value={flags}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
}

FeatureFlagProvider.propTypes = {
  children: node.isRequired,
};

export { FeatureFlagProvider };
export default function useFeatureFlags() {
  return useContext(FeatureFlagContext);
}
