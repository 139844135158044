/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import React, {
  ComponentProps, ReactNode, useCallback, useMemo, MutableRefObject, memo, useEffect,
} from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { makeGetAllViewAttendanceDetailIsSelected, makeGetAllViewIconShouldBeFaded } from 'selectors';
import { CCServiceAttendanceRosterUser } from 'utils/ccService/types';
import { CSS } from 'types/css';
import {
  WHITE, GREY_10, TEAL_LIGHT, TEAL_MEDIUM, RED_LIGHT, RED_MEDIUM,
} from 'theme/ui/colors';
import { allViewSetDetailStatus } from 'actions/attendanceActions';
import { isElementFromComponent } from 'utils/typePredicates';
import { attendanceLoaderStyles } from '../common';
import { attendanceStatusIconDefaultStyles } from './common';
import AttendanceHoverDetail, { AttendanceHoverDetailProps } from '../AttendanceAllView/AttendanceHoverDetail';

const loaderStyles: CSS = {
  ...attendanceLoaderStyles,
  display: 'block',
  height: '2rem',
  width: '2rem',
};

interface AttendanceStatusIconWithHoverProps extends ComponentProps<'td'> {
  loading: boolean,
  attendanceIcon: ReactNode,
  attendanceStatus: CCServiceAttendanceRosterUser['attendanceStatus'] | '',
  userId: string,
  sessionId: string,
  rowIsDark: boolean,
  iconRef: MutableRefObject<SVGElement | null>
}

/** Responds to hover / click by updating "selected" state */
const AttendanceStatusIconWithHover = memo(({
  loading, attendanceIcon, attendanceStatus, iconRef, children, userId, rowIsDark, sessionId, ...rest
}: AttendanceStatusIconWithHoverProps) => {
  const dispatch = useDispatch();
  const store = useStore();
  const detailInfo = useMemo(() => ({ userId, sessionId }), [userId, sessionId]);
  const getDetailIsSelected = useMemo(() => makeGetAllViewAttendanceDetailIsSelected(detailInfo), [detailInfo]);
  const getIconShouldBeFaded = useMemo(() => makeGetAllViewIconShouldBeFaded(detailInfo), [detailInfo]);
  const detailIsSelected = useSelector(getDetailIsSelected);
  const activeBg = useMemo(() => (rowIsDark ? WHITE : GREY_10), [rowIsDark]);
  const tooltipId = useMemo(() => `tooltip-${userId}-${sessionId}`, [userId, sessionId]);
  const attendanceStatusIconStyles: CSS = useMemo(() => ({
    ...attendanceStatusIconDefaultStyles,
    cursor: 'pointer',
    bg: detailIsSelected ? activeBg : null,
    '&:hover': {
      bg: activeBg,
    },
    '&:focus': {
      outline: 0,
    },
  }), [detailIsSelected, activeBg]);

  const handleFocus = useCallback(() => dispatch(allViewSetDetailStatus('focused', true, detailInfo)), [detailInfo, dispatch]);
  const handleBlur = useCallback(() => dispatch(allViewSetDetailStatus('focused', false)), [dispatch]);
  const handleMouseEnter = useCallback(() => dispatch(allViewSetDetailStatus('hovered', true, detailInfo)), [detailInfo, dispatch]);
  const handleMouseLeave = useCallback(() => dispatch(allViewSetDetailStatus('hovered', false)), [dispatch]);

  // Imperatively update styles to fade in/out (prevents 100ms+ rerenders on hover
  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const iconShouldBeFaded = getIconShouldBeFaded(store.getState());
      if (iconRef.current) {
        switch (attendanceStatus) {
          case 'present':
            iconRef.current.style.stroke = iconShouldBeFaded ? TEAL_LIGHT : TEAL_MEDIUM;
            break;
          case 'absent':
            iconRef.current.style.fill = iconShouldBeFaded ? RED_LIGHT : RED_MEDIUM;
            break;
          default:
            break;
        }
      }
    });

    return () => unsubscribe();
  }, [attendanceStatus, iconRef, getIconShouldBeFaded, store]);

  return loading ? <td aria-label="Loading" sx={loaderStyles} /> : (
    <td
      sx={attendanceStatusIconStyles}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      onMouseEnter={handleMouseEnter}
      onFocus={handleFocus}
      onMouseLeave={handleMouseLeave}
      onBlur={handleBlur}
      aria-describedby={detailIsSelected ? tooltipId : undefined}
      {...rest}
    >
      {attendanceIcon}

      {/** Give Tooltip an id to match with <td>'s aria-describedby, per @see https://www.digitala11y.com/tooltip-role/ */}
      {detailIsSelected && React.Children.map(children, (child) => {
        if (isElementFromComponent(child, AttendanceHoverDetail)) {
          return React.cloneElement<AttendanceHoverDetailProps>(child, { id: tooltipId });
        } return child;
      })}
    </td>
  );
});

export default AttendanceStatusIconWithHover;
