/** HTML IDs used for inter-component logic, defined here to guard against typos */
enum HtmlIdEnum {
  CHAT_INPUT_EMOJI_BUTTON = 'chat-input__emoji-button',
  CONTROL_BAR_SETTINGS_BUTTON = 'control-bar__settings-button',
  CONTROL_BAR_CHAT_BUTTON = 'control-bar__chat-button',
  CONTROL_BAR_CHALKBOARD_BUTTON = 'control-bar__chalkboard-button',
  CHAT_NOTIFICATION = 'chat-notification',
  DUMMY_DOWNLOAD_LINK = 'dummy-download-link',
  SET_PRESENTER = 'video-tile__set-presenter',
  CHALKBOARD_TEXT_INPUT = 'chalkboard-text-input',
  VIDEO_CONTROLS_OVERLAY = 'video-tile__controls-overlay',
  CHALKBOARD_MENU = 'chalkboard-menu',
  CHALKBOARD_MENU_COLOR_SELECTION = 'chalkboard-menu-color-selection',
  VIDEO_DEBUG_OVERLAY = 'video-tile__debug-overlay',
}

export default HtmlIdEnum;
