export const DEFAULT_ROOM_PERMISSIONS = {
  screenShare: {
    permission: 'screenShare',
    permissionDescription: 'share screen with participants',
    permissionGranted: 0,
    permissionId: 5,
  },
  broadcastMessage: {
    permission: 'broadcastMessage',
    permissionDescription: 'broadcast message to participants',
    permissionGranted: 0,
    permissionId: 6,
  },
  directMessage: {
    permission: 'directMessage',
    permissionDescription: 'send direct/private message to another participant',
    permissionGranted: 0,
    permissionId: 7,
  },
  privateNudge: {
    permission: 'privateNudge',
    permissionDescription: 'send private nudge to other guests',
    permissionGranted: 0,
    permissionId: 19,
  },
} as const;

export const CREATE_ROOM_PERMISSION_DEFAULTS = {
  screenShare: false,
  broadcastMessage: true,
  directMessage: true,
  privateNudge: false,
} as const;
