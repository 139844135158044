export const lowResolution = 360;
export const mediumResolution = 480;
export const normalResolution = 720;

const resolutionOptions = {
  1: lowResolution,
  2: mediumResolution,
  3: normalResolution,
};

export default resolutionOptions;
