/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import { userViewFetchSessions, userViewResetState } from 'actions/attendanceActions';
import ErrorRetry from 'components/ErrorRetry/ErrorRetry';
import { OwnerStatusEnum } from 'hooks/useIsOwner';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CSS } from 'types/css';
import { useRoomManagerContext } from 'views/RoomManager/roomManagerContext';

const containerStyles: CSS = {
  width: '100%',
  minHeight: '60rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

interface AttendanceUserViewErrorProps {
  userId: string,
  ownerStatus: OwnerStatusEnum
}

export default function AttendanceUserViewError({ userId, ownerStatus }: AttendanceUserViewErrorProps) {
  const dispatch = useDispatch();
  const { roomId } = useRoomManagerContext();
  const retry = useCallback(() => {
    dispatch(userViewResetState());
    dispatch(userViewFetchSessions(roomId, userId, ownerStatus));
  }, [roomId, userId, ownerStatus, dispatch]);

  return (
    <div sx={containerStyles}>
      <ErrorRetry onClick={retry} />
    </div>
  );
}
