/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import { ComponentProps, ReactNode } from 'react';
import { ModalVariantsShort } from 'theme/ui/modal';

interface Props extends ComponentProps<'div'>{
  children: ReactNode,
  variant?: ModalVariantsShort,
}

function ModalBody({ children, variant = 'light', ...rest }: Props) {
  return (
    <div sx={{ variant: `modalBody.${variant}` }} {...rest}>
      {children}
    </div>
  );
}

export default ModalBody;
