/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import { ReactComponent as WarningSVG } from 'assets/icons/warning.svg';
import { GREY_0, GREY_100, RED_MEDIUM } from 'theme/ui/colors';
import Button from 'components/ui/Button';
import { CSS } from 'types/css';
import { ComponentPropsWithoutRef } from 'react';
import Svg from 'components/ui/Svg';

const errorStyles: CSS = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  mb: '3rem',
  minHeight: '20rem',
  fontWeight: 'bold',
};

const warningSvgStyles: CSS = {
  fill: RED_MEDIUM,
  height: '4.8rem',
  width: '4.8rem',
  mb: '2.5rem',
};

type ErrorRetryVariant = 'dark' | 'light';

interface ErrorRetryProps extends ComponentPropsWithoutRef<'button'> {
  componentButtonStyles?: CSS,
  variant?: ErrorRetryVariant,
}

export default function ErrorRetry({
  variant = 'light', onClick, componentButtonStyles, ...rest
}: ErrorRetryProps) {
  const textContainerStyles: CSS = {
    '& p': {
      variant: 'text.body',
      color: variant === 'light' ? GREY_100 : GREY_0,
      textAlign: 'center',
    },
    mb: '3.5rem',
  };

  const buttonStyles: CSS = {
    minWidth: '12.3rem',
  };

  return (
    <div sx={errorStyles}>
      <Svg svg={WarningSVG} aria-hidden sx={warningSvgStyles} />
      <div sx={textContainerStyles}>
        <p>Oops, something went wrong!</p>
        <p>Please try again.</p>
      </div>
      <Button onClick={onClick} sx={{ ...buttonStyles, ...componentButtonStyles }} {...rest}> Retry</Button>
    </div>
  );
}
