import { AxiosResponse } from 'axios';
import { AppThunkAction, Note } from 'store/types';
import { FetchNotesResponse, PostNoteResponse } from 'utils/ccService/types';
import { getMessageFromError } from 'utils/errorUtils';
import logger from 'utils/logger';
import request from 'utils/request';
import { MakeActionType } from 'utils/typeUtils';
import { setRoomError } from './sharedActions';

export const reducerName = 'notesState' as const;
export const SET_NOTE = `${reducerName}/SET_NOTE` as const;
export const ADD_NOTE = `${reducerName}/ADD_NOTE` as const;
export const SET_NOTES_OPEN = `${reducerName}/SET_NOTES_OPEN` as const;
export const SET_NOTES_LOADING = `${reducerName}/SET_NOTES_LOADING` as const;

export const setNotesLoading = (loading: boolean) => ({
  type: SET_NOTES_LOADING,
  payload: {
    loading,
  },
});
export const setNotesOpen = (isNotesOpen: boolean) => ({
  type: SET_NOTES_OPEN,
  payload: {
    isNotesOpen,
  },
});
export const setNote = (note: Note) => ({
  type: SET_NOTE,
  payload: {
    note,
  },
});

export const addNote = (note: Note) => ({
  type: ADD_NOTE,
  payload: {
    note,
  },
});

export type NotesAction = MakeActionType<[
  typeof setNotesLoading,
  typeof setNotesOpen,
  typeof setNote,
  typeof addNote,
]>

// Get notes associated to room
export const fetchRoomNote = ():AppThunkAction => async (dispatch, getState) => {
  const state = getState();
  const roomId = state.roomState.room.id;
  dispatch(setNotesLoading(true));

  try {
    const response: AxiosResponse<FetchNotesResponse> = await request({
      method: 'GET',
      url: `/rooms/${roomId}/notes`,
    });
    const notes = response?.data?.notes;
    if (notes?.length > 0) {
      dispatch(setNote(notes[0]));
    }
  } catch (error) {
    const errorMessage = getMessageFromError(error);
    logger.error('Error getting room note', { errorMessage });
    dispatch(setRoomError('Failed to get notes for room'));
  } finally {
    dispatch(setNotesLoading(false));
  }
};

export const submitRoomNote = (noteContent: string):AppThunkAction => async (dispatch, getState) => {
  const state = getState();
  const roomId = state.roomState.room.id;
  dispatch(setNotesLoading(true));
  try {
    logger.info('Submitting note', { roomId, noteContent });
    await request<AxiosResponse<PostNoteResponse>>({
      method: 'POST',
      url: `/rooms/${roomId}/note`,
      data: {
        note: {
          content: noteContent.trim(),
          metadata: {
            size: 'm',
          },
        },
      },
    });
    dispatch(fetchRoomNote());
  } catch (error) {
    const errorMessage = getMessageFromError(error);
    logger.error('Error submitting room note', { errorMessage });
    dispatch(setRoomError('Failed to submit room note'));
  } finally {
    dispatch(setNotesLoading(false));
  }
};

export const updateRoomNote = (noteId: number, noteContent: string):AppThunkAction => async (dispatch, getState) => {
  const state = getState();
  const roomId = state.roomState.room.id;
  dispatch(setNotesLoading(true));
  try {
    logger.info('Submitting note', { roomId, noteContent });
    await request<AxiosResponse<''>>({
      method: 'PUT',
      url: `/rooms/${roomId}/note`,
      data: {
        noteId,
        note: {
          // note cannot be empty
          content: noteContent || ' ',
          metadata: {
            size: 'm',
          },
        },
      },
    });
    dispatch(fetchRoomNote());
  } catch (error) {
    const errorMessage = getMessageFromError(error);
    logger.error('Error updating room note', { errorMessage });
    dispatch(setRoomError('Failed to update room note'));
  } finally {
    dispatch(setNotesLoading(false));
  }
};
