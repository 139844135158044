import logger from 'utils/logger';
import FallbackSoundPlayer from './players/fallbackSoundPlayer';
import { SoundPlayer } from './soundPlayer';
import WebAudioSoundPlayer from './players/webAudioSoundPlayer';
import { SoundPlayerOptions } from './soundPlayerOptions';

/**
 * If AudioContext is supported, returns the default SoundPlayer implementation,
 * else returns a fallback implementation.
 *
 * Also returns fallback if instantiation of default player fails.
 */
export default function soundPlayerFactory(options?: SoundPlayerOptions): SoundPlayer {
  if (window.AudioContext) {
    try {
      return new WebAudioSoundPlayer(options);
    } catch (e) {
      logger.warn('Could not set up WebAudioSoundPlayer', { error: e as any });
    }
  }
  return new FallbackSoundPlayer(options);
}
