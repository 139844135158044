import calledOn from 'assets/sounds/called-on.mp3';
import join from 'assets/sounds/join.mp3';
import leave from 'assets/sounds/leave.mp3';
import raiseHand from 'assets/sounds/raise-hand.mp3';
import newChatMessage from 'assets/sounds/new-chat-message.mp3';
import testTone1kHz0dB from 'assets/sounds/_test-tone-1khz-0db.mp3';
import nudge from 'assets/sounds/nudge.mp3';
import snakeApple from 'assets/sounds/snake-apple.mp3';
import snakeGameOver from 'assets/sounds/snake-game-over.mp3';
import { EnumBase } from 'utils/enum';

/**
 * Enum representing app's sound files. This Enum style used over normal TypeScript enum
 * in order to hold more complex objects for SoundPlayer implementation
 */
export default class SoundFileEnum extends EnumBase {
  public static readonly __TEST_TONE_1KHZ_0DB__ = new SoundFileEnum(
    testTone1kHz0dB,
    '__TEST_TONE_1KHZ_0DB__',
    'Test Tone: 1kHz',
    'A 0dB sine wave for reference',
    'test-tone',
  );
  public static readonly NUDGE = new SoundFileEnum(
    nudge,
    'NUDGE',
    'Nudge',
    'The room owner nudged you',
    'notification',
  );
  public static readonly JOIN = new SoundFileEnum(
    join,
    'JOIN',
    'Join',
    'Someone joined the room',
    'notification',
  );
  public static readonly LEAVE = new SoundFileEnum(
    leave,
    'LEAVE',
    'Leave',
    'Someone left the room',
    'notification',
  );
  public static readonly RAISE_HAND = new SoundFileEnum(
    raiseHand,
    'RAISE_HAND',
    'Raise Hand',
    'Someone raised their hand',
    'notification',
  );
  public static readonly NEW_CHAT_MESSAGE = new SoundFileEnum(
    newChatMessage,
    'NEW_CHAT_MESSAGE',
    'Chat Message',
    'You got a new chat message',
    'notification',
  );
  public static readonly CALLED_ON = new SoundFileEnum(
    calledOn,
    'CALLED_ON',
    'Called On',
    'You were called on',
    'notification',
  );
  public static readonly SNAKE_APPLE = new SoundFileEnum(
    snakeApple,
    'SNAKE_APPLE',
    'Snake Apple',
    'You ate an apple',
    'notification',
  );
  public static readonly SNAKE_GAME_OVER = new SoundFileEnum(
    snakeGameOver,
    'SNAKE_GAME_OVER',
    'Snake Game Over',
    'You lost the game',
    'notification',
  );

  protected constructor(
    public file: string,
    public keyName: string,
    public displayName: string,
    public description: string,
    public type: 'test-tone' | 'notification',
  ) {
    super();
  }

  public static asArray() {
    return super.asArray() as Array<SoundFileEnum>;
  }

  public static getAllByType(soundType: SoundFileEnum['type']) {
    return this.asArray().filter(({ type }) => soundType === type);
  }
}
