import { createSelector } from 'reselect';
import { StoreState } from 'store/types';

export const getPlayers = (state: StoreState) => state.playersState.players;

export const getScreenSharePlayers = (state: StoreState) => state.playersState.screenSharePlayers;

export const getMediaState = (state: StoreState) => state.playersState.mediaState;

export const getPlayersVisibility = (state: StoreState) => state.playersState.visibility;

export const getIsHighCPU = (state: StoreState) => state.playersState.isHighCPU;

export const getBitrateLevels = (state: StoreState) => state.playersState.playerBitrateLevels;

export const getPlayerById = (id: string) => createSelector(
  getPlayers,
  (players) => players[id] || null,
);

export const getScreenSharePlayerById = (id: string) => createSelector(
  getScreenSharePlayers,
  (players) => players[id] || null,
);

export const getBitrateLevelsById = (userId: string) => createSelector(
  getBitrateLevels,
  (levels) => (levels[userId] || null),
);

export const getPlayerMediaState = (userId: string) => createSelector(
  getMediaState,
  (mediaState) => mediaState[userId] || { videoOff: true, audioOff: true },
);

export const getPlayerVideoOff = (userId: string) => createSelector(
  getPlayerMediaState(userId),
  (mediaState) => mediaState.videoOff,
);

export const getPlayerAudioOff = (userId: string) => createSelector(
  getPlayerMediaState(userId),
  (mediaState) => mediaState.audioOff,
);

export const getIsPlayerVisible = (userId: string) => createSelector(
  getPlayersVisibility,
  (visibility) => visibility[userId],
);

export const getBitrateLevelPreferenceById = (userId: string) => createSelector(
  getBitrateLevelsById(userId),
  (data) => data?.preference,
);

export const getCurrentBitrateById = (userId: string) => createSelector(
  getBitrateLevelsById(userId),
  (data) => data?.currentMaxBitrate,
);

export const getBitrateLockedById = (userId: string) => createSelector(
  getBitrateLevelsById(userId),
  (data) => data?.locked,
);
