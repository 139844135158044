import merge from 'lodash/merge';
import throttle from 'lodash/throttle';
import logger from 'utils/logger';
import { DeepPartial } from 'redux';
import constrainNum from 'utils/constrainNum';
import TypedEventEmitter from 'utils/typedEventEmitter';
import SoundFileEnum from '../soundFileEnum';
import {
  defaultFilePlaybackConfig,
  defaultSoundPlayerOptions,
  FilePlaybackConfig,
  SoundPlayerOptions,
  MIN_GAIN_RATIO,
  MAX_GAIN_RATIO,
} from '../soundPlayerOptions';
import { SoundPlayer } from '../soundPlayer';
import { SoundPlayerEvents } from '../events';

const limitGainRatio = (n: number) => constrainNum(n, MIN_GAIN_RATIO, MAX_GAIN_RATIO);

/**
 * Base class implements handling of options, event emission, and `.play`, handling the throttling of
 * sounds, since that logic is not dependent on how the sound is played. `.playAudioFile` must
 * be implemented by subclasses rather than `.play`.
 */
export default abstract class SoundPlayerBase extends TypedEventEmitter<SoundPlayerEvents> implements SoundPlayer {
  public options: SoundPlayerOptions;

  constructor(options?: DeepPartial<SoundPlayerOptions>) {
    super();
    this.options = merge({ ...defaultSoundPlayerOptions }, options);
    this.options.overallGainRatio = limitGainRatio(this.options.overallGainRatio);
    this.throttledPlayFuncs = {};
  }

  public updateFilePlaybackConfig(sound: SoundFileEnum, config: Partial<FilePlaybackConfig>) {
    if (config.throttleMs && config.throttleMs < 0) {
      config.throttleMs = 0;
    }

    const prevConfig = this.options.filePlaybackConfigs[sound.keyName];

    this.options = {
      ...this.options,
      filePlaybackConfigs: {
        ...this.options.filePlaybackConfigs,
        [sound.keyName]: {
          ...(prevConfig || defaultFilePlaybackConfig),
          ...config,
        },
      },
    };

    if (prevConfig) {
      const prevThrottleMs = prevConfig.throttleMs;
      if (prevThrottleMs !== config.throttleMs) {
        this.saveThrottledPlayFunc(sound);
      }
    }
  }

  /** Constrained between 0 (muted) and 2 (100% boost) */
  public updateOverallGainRatio(ratio: number) {
    this.options.overallGainRatio = limitGainRatio(ratio);
  }

  /** Get the file playback config for a sound, configured in SoundPlayerOptions,
   * setting and returning default config if none exists */
  protected getFilePlaybackConfig(sound: SoundFileEnum): FilePlaybackConfig {
    const conf = { ...defaultFilePlaybackConfig, ...this.options.filePlaybackConfigs[sound.keyName] };
    if (!conf) {
      this.options.filePlaybackConfigs[sound.keyName] = { ...defaultFilePlaybackConfig };
    }
    return conf;
  }

  /* Play implementation, handling function throttling without handling specific audio details */
  public async play(sound: SoundFileEnum, options?: { loop?: boolean, forceReload?: boolean }) {
    try {
      await this.getThrottledPlayFunc(sound)(options);
    } catch (err) {
      logger.warn('Error playing sound', { sound } as any);
    }
  }

  private getThrottledPlayFunc(sound: SoundFileEnum) {
    let func = this.throttledPlayFuncs[sound.keyName];
    if (!func) {
      func = this.saveThrottledPlayFunc(sound);
    }
    return func;
  }

  private saveThrottledPlayFunc(sound: SoundFileEnum) {
    const { throttleMs } = this.getFilePlaybackConfig(sound);
    const partiallyApplied = (options?: { loop?: boolean, forceReload?: boolean }) => this.playAudioFile(sound, options);
    if (throttleMs === 0) {
      return partiallyApplied;
    }
    const func = throttle(
      partiallyApplied,
      throttleMs,
      { leading: true, trailing: false },
    );
    this.throttledPlayFuncs[sound.keyName] = func;
    return func;
  }

  private throttledPlayFuncs: { [soundKeyName: string]: (options?: { loop?: boolean, forceReload?: boolean }) => void };

  abstract isUsingWebAudioApi: boolean;
  abstract playAudioFile(sound: SoundFileEnum, options?: { loop?: boolean, forceReload?: boolean }): void;
  abstract load(sound: SoundFileEnum, options?: { forceReload?: boolean }): void;
  abstract isSoundPlaying(sound: SoundFileEnum): boolean;
  abstract stop(sound: SoundFileEnum): void;
  abstract clearStoredAudioData(): void;
}
