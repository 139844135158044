/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import {
  ColorsEnum,
  BLACK,
  GREY_100,
  GREY_20,
  GREY_5,
  TEAL_MEDIUM,
  TEAL_DARK,
  TEAL_TRUE, WHITE,
} from 'theme/ui/colors';
import useIsKeyboardUser from 'hooks/useIsKeyboardUser';
import {
  ChangeEvent, ComponentPropsWithRef, forwardRef, memo, ReactNode,
} from 'react';
import { CSS } from 'types/css';

export type SwitchLargeVariant = 'light' | 'dark';

const containerStyles: CSS = {
  width: 'min-content',
  height: 'min-content',
};

/**
 * This wraps around the switch itself only.
 */
const switchContainerStyles: CSS = {
  position: 'relative',
  height: '4.4rem',
  width: 'max-content',
  borderRadius: '2.2rem',
  display: 'flex',
  justifyContent: 'space-between',
};

/**
 * This is the input itself that we use to receive the user's interaction.
 * Visually, we don't really show it, since it's mostly there for the interaction/accessibility.
 */
const inputStyles: CSS = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  appearance: 'none',
  border: 'none',
  '&:focus': {
    outline: 'none',
  },
  bg: 'transparent',
};

const focusRingStyles: CSS = {
  transition: '0.2s ease',
  opacity: 0,
  position: 'absolute',
  width: 'calc(100% + 1.4rem)',
  height: 'calc(100% + 1.4rem)',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: '0.2rem solid',
  borderRadius: '10rem',
  borderColor: TEAL_TRUE,
  bg: 'transparent',
  boxSizing: 'border-box',
  pointerEvents: 'none',
  'input:focus + &': {
    opacity: 1,
  },
};

/**
 * These spans hold the text that gets highlighted by the indicator.
 * Their widths determine the width of the indicator.
 */
const spanStyles: CSS = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: 'max-content',
  p: '0.8rem 2rem',
  fontFamily: 'open',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '1.6rem',
  lineHeight: '2.8rem',
  borderRadius: '10rem',
  zIndex: 1,
  pointerEvents: 'none',
  minWidth: '8rem',
};

/**
 * Indicates which option is currently selected.
 */
const indicatorStyles: CSS = {
  background: `linear-gradient(117.46deg, ${TEAL_MEDIUM} 11.44%, ${TEAL_DARK} 74.61%)`,
  boxShadow: `0px 4px 16px ${ColorsEnum.TEAL_TRUE.withOpacity(0.6)}`,
};

const captionStyles: CSS = {
  display: 'block',
  mt: '0.25rem',
  mb: '0.5rem',
  fontFamily: 'open',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '1.4rem',
  lineHeight: '1.9rem',
  width: '100%',
};

export interface SwitchLargeProps extends ComponentPropsWithRef<'input'> {
  variant?: SwitchLargeVariant,
  checked: boolean;
  ariaLabel: string,
  labelLeft: ReactNode,
  labelRight: ReactNode,
  caption?: string,
  componentStyles?: CSS,
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

/**
 * Renders an on/off toggle to the screen that can also contain text inside.
 * Can be focused with TAB and can be toggled on/off with SPACE.
 */
const SwitchLarge = forwardRef<HTMLInputElement, SwitchLargeProps>(({
  checked, ariaLabel, labelLeft, labelRight, caption = '',
  onChange, disabled = false, componentStyles = {}, variant = 'dark', ...rest
}, ref) => {
  const isKeyboardUser = useIsKeyboardUser();

  const switchContainerMutableStyles: CSS = {
    bg: variant === 'light' ? GREY_5 : BLACK,
  };

  const leftSpanMutableStyles : CSS = {
    color: variant === 'dark' || (variant === 'light' && !checked) ? WHITE : GREY_100,
    fontWeight: !checked ? 'bold' : 'normal',
    ...(!checked && indicatorStyles),
  };

  const rightSpanMutableStyles : CSS = {
    color: variant === 'dark' || (variant === 'light' && checked) ? WHITE : GREY_100,
    fontWeight: checked ? 'bold' : 'normal',
    ...(checked && indicatorStyles),
  };

  const captionMutableStyles: CSS = {
    color: variant === 'dark' ? GREY_20 : GREY_100,
  };

  return (
    <div sx={containerStyles}>
      <div sx={{ ...switchContainerStyles, ...switchContainerMutableStyles, ...componentStyles }}>
        <span aria-selected={!checked} sx={{ ...spanStyles, ...leftSpanMutableStyles }}>{labelLeft}</span>
        <span aria-selected={checked} sx={{ ...spanStyles, ...rightSpanMutableStyles }}>{labelRight}</span>
        <input
          aria-label={ariaLabel}
          ref={ref}
          sx={{ ...inputStyles, cursor: disabled ? 'not-allowed' : 'pointer' }}
          type="checkbox"
          role="switch"
          disabled={disabled}
          aria-checked={checked}
          checked={checked}
          onChange={(e) => {
            if (!disabled) {
              onChange(e);
            }
          }}
          {...rest}
        />
        {isKeyboardUser && <span sx={focusRingStyles} />}
      </div>
      {caption && <span sx={{ ...captionStyles, ...captionMutableStyles }}>{caption}</span>}
    </div>
  );
});

SwitchLarge.displayName = 'SwitchLarge';

export default memo(SwitchLarge);
