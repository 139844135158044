import { createSelector } from 'reselect';
import { StoreState } from 'store/types';
import { BreakpointIndexes, BreakpointObj } from 'theme/ui/breakpoints';

export const getBrowser = (state: StoreState) => state.browser;

/**
 * Returns boolean whether current screen size is >= the indicated breakpoint index.
 */
export const getMinWidth = (breakpointIndex: BreakpointIndexes) => createSelector(
  getBrowser,
  (browser) => {
    const breakpointKey = `_${breakpointIndex}` as keyof BreakpointObj;
    return browser.minWidth[breakpointKey];
  },
);

/**
 * Returns boolean whether current screen size is < the indicated breakpoint index.
 */
export const getMaxWidth = (breakpointIndex: BreakpointIndexes) => createSelector(
  getBrowser,
  (browser) => {
    const breakpointKey = `_${breakpointIndex}` as keyof BreakpointObj;
    return browser.maxWidth[breakpointKey];
  },
);

export const getIsTablet = getMaxWidth(4);

export const getIsMobile = createSelector(
  getBrowser,
  (browser) => browser.lessThan._3,
);

export const getIsLargerThanTablet = createSelector(
  getBrowser,
  (browser) => browser.greaterThan._4,
);
