import produce from 'immer';
import {
  SET_CHAT_WS,
  SET_UNSEEN_MESSAGE,
  CLEAR_UNSEEN_MESSAGES,
  UPDATE_INPUT,
  ADD_CHAT_MESSAGE,
  TOGGLE_SHOW_EMOJIS,
  SET_EMOJIS,
  SET_CHAT_HISTORY,
  TOGGLE_CHAT_VISIBLE,
  SET_CURRENT_CONVERSATION,
  SET_RECENT_MESSAGE,
  SET_PARSED_LIMIT,
  SET_SUBMIT_DISABLED,
  STOP_CHAT,
  REPLACE_CHAT_MESSAGE,
  ChatAction,
} from 'actions/chatActions';
import { RESET_ROOM, SharedAction } from 'actions/sharedActions';
import { ChatState, CONVO_ID_NOT_SELECTED } from 'store/types';

export const initialState: ChatState = {
  chatClient: null,
  messages: [],
  unseenMessages: {},
  chatInput: '',
  showEmojis: false,
  emojis: {},
  isChatVisible: false,
  selectedConversationId: CONVO_ID_NOT_SELECTED,
  recentMessage: null,
  parsedLimitReached: false,
  submitDisabled: false,
};

const reducer = produce((draft: ChatState, action: ChatAction | SharedAction) => {
  let messageSet: Set<string>;
  switch (action.type) {
    case SET_CHAT_WS:
      draft.chatClient = action.payload.chatClient;
      break;
    case SET_UNSEEN_MESSAGE:
      if (draft.unseenMessages[action.payload.conversationId]) {
        draft.unseenMessages[action.payload.conversationId].count += 1;
      } else {
        draft.unseenMessages[action.payload.conversationId] = {
          messageId: action.payload.messageId,
          count: 1,
        };
      }
      break;
    case CLEAR_UNSEEN_MESSAGES:
      delete draft.unseenMessages[action.payload.conversationId];
      break;
    case UPDATE_INPUT:
      draft.chatInput = action.payload.message;
      break;
    case ADD_CHAT_MESSAGE:
      draft.messages.push(action.payload.message);
      break;
    case REPLACE_CHAT_MESSAGE: {
      if (action.payload.message.metadata?.tempId) {
        const messageIndex = draft.messages.findIndex(
          (msg) => msg.metadata?.tempId === action.payload.message.metadata?.tempId,
        );
        if (messageIndex > -1) draft.messages[messageIndex] = action.payload.message;
      }
      break;
    }
    case TOGGLE_SHOW_EMOJIS:
      draft.showEmojis = action.payload.showEmojis;
      break;
    case SET_EMOJIS:
      draft.emojis = action.payload.emojis;
      break;
    case SET_CHAT_HISTORY:
      messageSet = new Set();
      draft.messages.forEach((msg) => messageSet.add(msg.id));
      action.payload.messages.forEach((msg) => {
        if (!messageSet.has(msg.id)) {
          draft.messages.push(msg);
        }
      });
      break;
    case TOGGLE_CHAT_VISIBLE:
      draft.isChatVisible = !draft.isChatVisible;
      break;
    case SET_CURRENT_CONVERSATION:
      draft.selectedConversationId = action.payload.conversationId;
      break;
    case SET_RECENT_MESSAGE:
      draft.recentMessage = action.payload.message;
      break;
    case SET_PARSED_LIMIT:
      draft.parsedLimitReached = action.payload.parsedLimitReached;
      break;
    case SET_SUBMIT_DISABLED:
      draft.submitDisabled = action.payload.submitDisabled;
      break;
    case STOP_CHAT:
    case RESET_ROOM:
      Object.assign(draft, initialState);
      break;
    default:
      break;
  }
}, initialState);

export default reducer;
