/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import Loader from 'components/Loader/Loader';
import { CSS } from 'types/css';
import Svg from 'components/ui/Svg';
import { ReactComponent as CSVIcon } from 'assets/icons/buttons/csv.svg';
import Button from 'components/ui/Button';
import { mediaMaxWidth } from 'styles/styles';

const loaderContainerStyles: CSS = {
  height: '4.4rem',
  width: '17rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const downloadCSVButtonStyles: CSS = {
  [mediaMaxWidth._2]: {
    display: 'none',
  },
};

interface AttendanceDownloadCSVButtonProps {
  handleDownloadCSVClick: () => void,
  csvDataLoading: boolean,
}

export default function AttendanceDownloadCSVButton({ csvDataLoading, handleDownloadCSVClick }: AttendanceDownloadCSVButtonProps) {
  return csvDataLoading ? (
    <div sx={loaderContainerStyles}><Loader /></div>
  ) : (
    <Button
      variant="primary"
      iconComponent={<Svg svg={CSVIcon} title="Download CSV" />}
      sx={downloadCSVButtonStyles}
      onClick={handleDownloadCSVClick}
      disabled={csvDataLoading}
    >
      Download CSV
    </Button>
  );
}
