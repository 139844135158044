import { LoggerCore, LoggerGlobal } from '@livelyvideo/log-client';

if (process.env.NODE_ENV !== 'test') {
  console.info(`ChalkCast Web UI v${appConfig.version}`);
}

const loggerGlobal = new LoggerGlobal();

// Only write logs to Kibana when the app is built/deployed
if (process.env.NODE_ENV === 'production') {
  loggerGlobal.setOptions({
    host: appConfig.loggerBaseUri,
    interval: appConfig.loggerWriteIntervalMs,
    level: 'debug',
  });
}

/**
 * Create and extend a logger. Handles setting the chain metadata to allow tracing the parentage of a log.
 *
 * Based on a code snippet from David Jansen.
 */
export const createLogger = (loggerToExtend: LoggerCore, chainName: string): LoggerCore => {
  const newLogger = new LoggerCore(loggerToExtend.name);
  newLogger.extend(loggerToExtend);

  const currentChain = loggerToExtend.getLoggerMeta('chain');

  newLogger.setLoggerMeta('release', appConfig.version);
  newLogger.setLoggerMeta('chain', currentChain ? `${currentChain}:${chainName}` : chainName);
  newLogger.setLoggerMeta('client', 'chalkcast-web');

  return newLogger;
};

/** The default logger for ChalkCast Web */
const logger: LoggerCore = createLogger(new LoggerCore('chalkcast-web'), 'app');

// Force disable printing in tests
if (process.env.NODE_ENV === 'test') {
  // The logger stores console methods in memory and currently has no option/method to disable them
  (logger as any).printer = null;
}

export default logger;
