/**
 * Validates email string and returns any error messages, if any.
 */
export default function validateEmail(email: string) {
  // eslint-disable-next-line max-len
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let errorMessage = '';
  if (typeof email !== 'string' || email.trim().length === 0 || !regex.test(email)) {
    errorMessage = 'Please enter a valid email format';
  }
  return errorMessage;
}
