import { createSelector } from 'reselect';
import { ErrorMessageState, StoreState } from 'store/types';

export const getErrorState = (state: StoreState) => state.errorMessageState;

export const getErrorPageError = (state: StoreState) => state.errorMessageState.errorPage;

export const getLaunchGroupsError = (state: StoreState) => state.errorMessageState.launchGroups;

export const getIncompatibleError = (state: StoreState) => state.errorMessageState.incompatibleError;

export const makeGetErrorMessage = (type: keyof ErrorMessageState) => createSelector(
  getErrorState,
  (errorState) => errorState[type],
);
