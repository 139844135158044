import { setOnline } from 'actions/networkActions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import logger from 'utils/logger';

export default function useInternetConnListener() {
  const dispatch = useDispatch();

  useEffect(() => {
    const onOnline = () => {
      logger.info('User has regained internet connection');
      dispatch(setOnline(true));
    };

    const onOffline = () => {
      logger.info('User has lost internet connection');
      dispatch(setOnline(false));
    };

    window.addEventListener('online', onOnline);
    window.addEventListener('offline', onOffline);

    return () => {
      window.removeEventListener('online', onOnline);
      window.removeEventListener('offline', onOffline);
    };
  }, [dispatch]);
}
