/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import { toggleDebugModal, toggleFiltersModal } from 'actions/debugActions';
import Button from 'components/ui/Button';
import Modal from 'components/ui/Modal/Modal';
import ModalBody from 'components/ui/Modal/ModalBody';
import ModalFooter from 'components/ui/Modal/ModalFooter';
import ModalTitle from 'components/ui/Modal/ModalTitle';
import Switch from 'components/ui/Switch';
import {
  ChangeEvent, Fragment, useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import {
  getCannyEdgeDetectionThreshold,
  getCroppedCanvasSize,
  getCurrentFilterKey,
  getEnabledPublicFilters,
  getEnableFaceDetection,
  getFilter, getIsFiltersModalOpen, getMaxFrameRate, getPredictionInterval,
  getFilterMaxBitrate,
  getIsCannyEdgeDetection,
} from 'selectors';
import { CSS } from 'types/css';
import SelectInput from 'components/Input/SelectInput';
import { GREY_70 } from 'theme/ui/colors';
import { cleanUpBroadcast, setFilterMaxBitrate, setEncoderRenderId } from 'actions/encoderActions';
import { Filters } from 'filters';
import FilterVideoConditional from 'components/EncoderVideo/FilterVideo/FilterVideoConditional';
import { AppAction, EncoderRenderIdsEnum, StoreState } from 'store/types';
import { resetFilterState } from 'actions/filterActions';
import Input from 'components/ui/Input';
import useFeatureFlags from 'hooks/useFeatureFlags';
import RegularVideoConditional from 'components/EncoderVideo/RegularVideo/RegularVideoConditional';

const modalBodyStyles: CSS = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '1rem',
  width: 'fit-content',
  m: '1rem auto',
};

const videoContainerStyles: CSS = {
  maxWidth: '60rem',
};

const COMPONENT_RENDER_ID = EncoderRenderIdsEnum.FILTERS_MODAL;

export default function FiltersModal() {
  const dispatch = useDispatch();
  const open = useSelector(getIsFiltersModalOpen);
  const toggle = useCallback(() => dispatch(toggleFiltersModal()), [dispatch]);
  const goBack = useCallback(() => {
    dispatch(toggleFiltersModal(false));
    dispatch(toggleDebugModal(true));
  }, [dispatch]);
  const filter = useSelector(getFilter);
  const croppedCanvasSize = useSelector(getCroppedCanvasSize);
  const enabledPublicFilters = useSelector(getEnabledPublicFilters);
  const doubleThresholdHigh = useSelector(getCannyEdgeDetectionThreshold);
  const faceDetectionEnabled = useSelector(getEnableFaceDetection);
  const maxFrameRate = useSelector(getMaxFrameRate);
  const currentFilterKey = useSelector(getCurrentFilterKey);
  const predictionInterval = useSelector(getPredictionInterval);
  const filterMaxBitrate = useSelector(getFilterMaxBitrate);
  const [newFilterMaxBitrate, setNewFilterMaxBitrate] = useState(filterMaxBitrate);
  const currentFiltersIsCannyEdgeDetection = useSelector(getIsCannyEdgeDetection);
  const store = useStore<StoreState, AppAction>();
  const { enableFilters } = useFeatureFlags();

  useEffect(() => {
    dispatch(setEncoderRenderId(COMPONENT_RENDER_ID, open));
  }, [open, dispatch]);

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      width="wide"
      minContentHeight
      addFooterSpacing
    >
      <ModalTitle toggle={toggle}>Filter Options</ModalTitle>
      <ModalBody>
        <div sx={modalBodyStyles}>

          {/* View video previews */}
          <div sx={videoContainerStyles}>
            <p>Raw Source Video</p>
            <RegularVideoConditional encoderRenderId={COMPONENT_RENDER_ID} />
            {enableFilters && (
              <Fragment>
                <p>Filtered Video</p>
                <FilterVideoConditional encoderRenderId={COMPONENT_RENDER_ID} />
              </Fragment>
            )}
          </div>

          {/* Enable / disable */}
          <p>
            Filters are currently
            {' '}
            {enableFilters ? 'enabled' : 'disabled'}
            .
          </p>
          <Button
            sx={{ mb: '3rem' }}
            onClick={() => {
              localStorage.setItem('enableFilters', JSON.stringify(!enableFilters));
              window.location.reload();
            }}
          >
            {enableFilters ? 'Disable' : 'Enable'}
            {' '}
            and refresh
          </Button>

          {/* Settings */}
          {!enableFilters || !filter ? (
            <p>Filter encoder has not been setup</p>
          ) : (
            <Fragment>
              <Switch
                variant="dark"
                id="enable-face-detection"
                label="Enable Face Detection"
                checked={faceDetectionEnabled}
                onChange={(e) => { filter.enableFaceDetection = e.target.checked; }}
              />
              <SelectInput
                id="choose-filter"
                label="Choose Filter"
                onChange={(e) => {
                  const chosenFilter = enabledPublicFilters[e.target.selectedIndex];
                  if (!chosenFilter) throw new Error('Filter for corresponding in <select /> was not found');
                  filter.currentFilter = chosenFilter;
                }}
                options={enabledPublicFilters.map((publicFilter) => ({
                  id: publicFilter.integer.toString(),
                  label: publicFilter.name,
                  value: publicFilter.name,
                }))}
                value={Filters[currentFilterKey].name}
                componentStyles={{ width: '100%', '& label': { color: `${GREY_70} !important` } }}
              />
              <label htmlFor="prediction-interval">
                Face Detection Interval:
                {' '}
                {predictionInterval}
                ms
              </label>
              <input
                id="prediction-interval"
                type="range"
                min={1}
                max={10_000}
                step={1}
                value={predictionInterval}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const interval = Math.floor(e.currentTarget.valueAsNumber);
                  filter.predictionInterval = interval;
                }}
              />
              <label htmlFor="canvas-size">
                Cropped Canvas Size
                {' '}
                {croppedCanvasSize}
                x
                {croppedCanvasSize}
                px
              </label>
              <input
                id="canvas-size"
                type="range"
                min={1}
                max={1000}
                step={1}
                value={croppedCanvasSize}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const size = Math.floor(e.currentTarget.valueAsNumber);
                  filter.croppedCanvasSize = size;
                }}
              />
              <label htmlFor="max-frame-rate">
                Max Frame Rate:
                {' '}
                {maxFrameRate}
                fps
              </label>
              <input
                id="max-frame-rate"
                type="range"
                min={1}
                max={120}
                step={1}
                value={maxFrameRate}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const rate = Math.floor(e.currentTarget.valueAsNumber);
                  filter.maxFrameRate = rate;
                }}
              />
              {currentFiltersIsCannyEdgeDetection && (
                <label htmlFor="double-threshold-high">
                  Canny Edge Detection Threshold:
                  {' '}
                  {doubleThresholdHigh}
                  <input
                    id="double-threshold-high"
                    type="range"
                    min={0}
                    max={1}
                    step={0.001}
                    value={doubleThresholdHigh}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      filter.doubleThresholdHigh = e.currentTarget.valueAsNumber;
                    }}
                    style={{
                      display: 'block',
                    }}
                  />
                </label>
              )}
              <form
                sx={{ my: '3rem' }}
                onSubmit={async (e) => {
                  e.preventDefault();
                  if (newFilterMaxBitrate !== filterMaxBitrate) {
                    dispatch(setFilterMaxBitrate(newFilterMaxBitrate));
                    dispatch(cleanUpBroadcast(`Restarting broadcast with new maxBitrate of ${newFilterMaxBitrate}`));
                  }
                }}
              >
                <Input
                  label="Max Bitrate (restarts broadcast automatically when saved)"
                  type="number"
                  id="set-filter-max-bitrate"
                  value={newFilterMaxBitrate.toString()}
                  onChange={(e) => setNewFilterMaxBitrate(e.target.valueAsNumber)}
                />
                <Button type="submit" sx={{ mt: '1rem' }}>
                  Save
                </Button>
              </form>
              <Button
                sx={{ mb: '3rem ' }}
                onClick={() => {
                  const { filterState: { srcVideo }, encoderState: { videoMediaStreamController } } = store.getState();
                  if (srcVideo && videoMediaStreamController?.source) {
                    srcVideo.srcObject = videoMediaStreamController.source as MediaStream;
                  }
                }}
              >
                Sync srcVideo Stream
              </Button>
            </Fragment>
          )}
          <Button onClick={() => {
            const message = 'Resetting from FiltersModal';
            dispatch(resetFilterState(message));
            dispatch(cleanUpBroadcast(message));
          }}
          >
            Reset Filter, MediaStreamController, &amp; Broadcast
          </Button>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="secondaryLight" onClick={goBack}>Back</Button>
        <Button onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
  );
}
