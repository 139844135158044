import produce from 'immer';
import {
  SET_FILTER,
  FilterAction,
  SET_IS_FILTERING,
  SET_IS_FILTER_LOADING,
  SET_SRC_VIDEO,
  SET_DEST_CANVAS,
  SET_TENSOR_FLOW_BACKEND,
  SET_ENABLE_FACE_DETECTION,
  SET_MAX_FRAME_RATE,
  SET_PREDICTION_INTERVAL,
  SET_CROPPED_CANVAS_SIZE,
  SET_CURRENT_FILTER_KEY,
  SET_CANNY_EDGE_DETECTION_THRESHOLD,
  SET_ENABLED_PUBLIC_FILTERS,
  RESET_FILTER_SETTINGS,
} from 'actions/filterActions';
import {
  FilterState, MutableFilterState, FilterSettings,
} from 'store/types';
import { RESET_ROOM, SharedAction } from 'actions/sharedActions';
import { Filters } from 'filters';
import { persistReducer, persistConfig } from 'store/localStoragePersistMiddleware';

/** State that is linked directly to Filter class internal state.
 * This state does not get overwritten when the room is reset because it is persisted */
const filterSettings: FilterSettings = {
  cannyEdgeDetectionThreshold: 0.35,
  currentFilterKey: Filters.PASS_THROUGH.keyOf(),
  enabledPublicFilters: [],
  croppedCanvasSize: 250,
  enableFaceDetection: false,
  maxFrameRate: 24,
  predictionInterval: 2000,
  tensorFlowBackend: 'wasm',
};

/** This state gets overwritten when the room is reset */
const mutableFilterState: MutableFilterState = {
  filter: null,
  srcVideo: null,
  destCanvas: null,
  isFiltering: false,
  isFilterLoading: false,
};

export const initialState: FilterState = {
  ...filterSettings,
  ...mutableFilterState,
};

const reducer = produce((draft: FilterState, action: FilterAction | SharedAction) => {
  switch (action.type) {
    case SET_FILTER:
      draft.filter = action.payload.filter;
      break;
    case SET_IS_FILTERING:
      draft.isFiltering = action.payload.isFiltering;
      break;
    case SET_IS_FILTER_LOADING:
      draft.isFilterLoading = action.payload.loading;
      break;
    case SET_SRC_VIDEO:
      draft.srcVideo = action.payload.srcVideo;
      break;
    case SET_DEST_CANVAS:
      draft.destCanvas = action.payload.destCanvas;
      break;
    case SET_TENSOR_FLOW_BACKEND:
      draft.tensorFlowBackend = action.payload.backend;
      break;
    case SET_ENABLE_FACE_DETECTION:
      draft.enableFaceDetection = action.payload.enable;
      break;
    case SET_MAX_FRAME_RATE:
      draft.maxFrameRate = action.payload.max;
      break;
    case SET_PREDICTION_INTERVAL:
      draft.predictionInterval = action.payload.ms;
      break;
    case SET_CROPPED_CANVAS_SIZE:
      draft.croppedCanvasSize = action.payload.size;
      break;
    case SET_CURRENT_FILTER_KEY:
      draft.currentFilterKey = action.payload.currentFilterKey;
      break;
    case SET_CANNY_EDGE_DETECTION_THRESHOLD:
      draft.cannyEdgeDetectionThreshold = action.payload.threshold;
      break;
    case SET_ENABLED_PUBLIC_FILTERS:
      draft.enabledPublicFilters = action.payload.filters;
      break;
    case RESET_FILTER_SETTINGS:
      Object.assign(draft, filterSettings);
      break;
    case RESET_ROOM:
      Object.assign(draft, mutableFilterState);
      break;
    default:
      break;
  }

  /* HACK: From what I can tell, Redux doesn't like receiving a class instance in TypeScript.
  This shouldn't impact our type inference, since the StoreState is our source of truth for
  types rather than the return type of this reducer. Using unknown here just in case */
}, (initialState as unknown));

export default persistReducer<FilterState>(reducer, persistConfig.filterState);
