/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import {
  mediaMaxWidth,
  PROFILE_BUTTON_DEFAULT_WIDTH, textEllipsis,
} from 'styles/styles';
import { ReactComponent as AccountSVG } from 'assets/icons/account.svg';
import useTypedSelector from 'hooks/useTypedSelector';
import { getCurrentUserName, getUserProfile } from 'selectors';
import { ColorsEnum, TEAL_TRUE, WHITE } from 'theme/ui/colors';
import Button from 'components/ui/Button';
import {
  ComponentPropsWithoutRef, Fragment, useCallback, useState,
} from 'react';
import { isGoogleUser } from 'utils/typePredicates';
import { CSS } from 'types/css';
import { getFirstName } from 'utils/stringUtils';
import Svg from 'components/ui/Svg';
import AccountDropdown from './AccountDropdown';

/**
 * Container enables styles to be applied to the AccountDropdown independently
 * of the AccountButton when hovering on the button.
 */
const containerStyles: CSS = {
  position: 'relative',
  minWidth: PROFILE_BUTTON_DEFAULT_WIDTH,
  maxWidth: '100%',
};

/**
 * Overwrites some of the "secondaryLight" variant styles.
 */
const PROFILE_BUTTON_PADDING = [0, 0, 0, '0 1rem 0 0'];
const profileButtonStyles: CSS = {
  minWidth: PROFILE_BUTTON_DEFAULT_WIDTH,
  maxWidth: '100%',
  height: '4.4rem',
  bg: WHITE,
  p: PROFILE_BUTTON_PADDING,
  m: 0,
  boxShadow: `0px 5px 15px ${ColorsEnum.BLACK.withOpacity(0.1)}`,
  borderRadius: '2.4rem',

  // inner div of the button
  '& > div': {
    minWidth: PROFILE_BUTTON_DEFAULT_WIDTH,
    maxWidth: '100%',
    height: '4.4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '1rem',
    flex: 'none',
    p: PROFILE_BUTTON_PADDING,
  },
  '&:hover': {
    transform: 'scale(1.02)',
    transitionDuration: '0.3s',
    boxShadow: `0px 5px 15px ${ColorsEnum.BLACK.withOpacity(0.2)}`,
  },
};

const PROFILE_PIC_SIZE = '4.4rem' as const;

const profilePicContainerStyles: CSS = {
  width: PROFILE_PIC_SIZE,
  height: PROFILE_PIC_SIZE,
  minWidth: PROFILE_PIC_SIZE,
  minHeight: PROFILE_PIC_SIZE,
  borderRadius: '50%',
  overflow: 'hidden',
};

const profilePicStyles: CSS = {
  width: PROFILE_PIC_SIZE,
  height: PROFILE_PIC_SIZE,
  minWidth: PROFILE_PIC_SIZE,
  minHeight: PROFILE_PIC_SIZE,
};

const displayNameStyles: CSS = {
  ...textEllipsis,
  color: TEAL_TRUE,
  fontSize: '1.8rem',
  fontWeight: 'bold',
  [mediaMaxWidth._2]: {
    display: 'none',
  },
};

/**
 * Clear overlay that sits behind the account dropdown.
 * Prevents clicks outside from succeeding until the dropdown has closed.
 */
const overlayStyles: CSS = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1,
};

interface AccountButtonProps extends ComponentPropsWithoutRef<'button'> {
  componentStyles?: CSS
}

function AccountButton({ componentStyles, ...rest }: AccountButtonProps) {
  const displayName = useTypedSelector(getCurrentUserName);
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = useCallback(() => setShowDropdown((bool) => !bool), []);
  const closeDropdown = useCallback(() => setShowDropdown(false), []);
  const user = useTypedSelector(getUserProfile);

  return (
    <div sx={{ ...containerStyles, ...componentStyles }}>
      <Button variant="neutral" sx={profileButtonStyles} {...rest} onClick={toggleDropdown} data-selenium="top-nav__account-button">
        <div sx={profilePicContainerStyles}>
          {isGoogleUser(user) ? (
            <img src={user.photos[0].value} alt={displayName} sx={profilePicStyles} />
          ) : (
            <Svg svg={AccountSVG} title="User account" sx={profilePicStyles} />
          )}
        </div>
        <span sx={displayNameStyles}>{getFirstName(displayName || '')}</span>
      </Button>
      {showDropdown && (
        <Fragment>
          <AccountDropdown closeDropdown={closeDropdown} />
          <div sx={overlayStyles} />
        </Fragment>
      )}
    </div>
  );
}

AccountButton.displayName = 'AccountButton';

export default AccountButton;
