import { ReactComponent as CircleSVG } from 'assets/icons/chalkboard/circle.svg';
import { ReactComponent as EraserSVG } from 'assets/icons/chalkboard/eraser.svg';
import { ReactComponent as SquareSVG } from 'assets/icons/chalkboard/square.svg';
import { ReactComponent as FreeLineSVG } from 'assets/icons/chalkboard/freeLine.svg';
import { ReactComponent as LineSVG } from 'assets/icons/chalkboard/line.svg';
import { ReactComponent as TextSVG } from 'assets/icons/chalkboard/textT.svg';
import { ReactComponent as StarSVG } from 'assets/icons/chalkboard/star.svg';
import { ReactComponent as GrabSVG } from 'assets/icons/chalkboard/grab.svg';
import sketchCursorWhite from 'assets/cursors/chalkboard/sketchCursorWhite.svg';
import sketchCursorBlack from 'assets/cursors/chalkboard/sketchCursorBlack.svg';
import sketchCursorBlue from 'assets/cursors/chalkboard/sketchCursorBlue.svg';
import sketchCursorEraserWhite from 'assets/cursors/chalkboard/sketchCursorEraserWhite.svg';
import sketchCursorEraserBlack from 'assets/cursors/chalkboard/sketchCursorEraserBlack.svg';
import sketchCursorGreen from 'assets/cursors/chalkboard/sketchCursorGreen.svg';
import sketchCursorOrange from 'assets/cursors/chalkboard/sketchCursorOrange.svg';
import sketchCursorPurple from 'assets/cursors/chalkboard/sketchCursorPurple.svg';
import sketchCursorRed from 'assets/cursors/chalkboard/sketchCursorRed.svg';
import {
  ChalkboardColor, ChalkboardTheme, ChalkboardToolMode, UnlabeledSvgComponent,
} from 'store/types';
import { CSS } from 'types/css';

export type Cursor = {
  cssValue?: never,
  src: string,
} | { src?: never, cssValue: CSS['cursor'] }

type CursorConfig = {
  [color in ChalkboardColor]: {
    [theme in ChalkboardTheme]?: Cursor
  } & { default: Cursor }
}

const defaultToolCursors: CursorConfig = {
  primary: {
    light: { src: sketchCursorBlack },
    dark: { src: sketchCursorWhite },
    default: { src: sketchCursorWhite },
  },
  blue: { default: { src: sketchCursorBlue } },
  green: { default: { src: sketchCursorGreen } },
  orange: { default: { src: sketchCursorOrange } },
  purple: { default: { src: sketchCursorPurple } },
  red: { default: { src: sketchCursorRed } },
};

const eraserCursors = Object.keys(defaultToolCursors).reduce<CursorConfig>((obj, key) => {
  obj[key as keyof CursorConfig] = {
    light: { src: sketchCursorEraserBlack },
    dark: { src: sketchCursorEraserWhite },
    default: { src: sketchCursorEraserWhite },
  };
  return obj;
}, {} as CursorConfig);

const textCursors = Object.keys(defaultToolCursors).reduce<CursorConfig>((obj, key) => {
  obj[key as keyof CursorConfig] = {
    default: { cssValue: 'text' },
  };
  return obj;
}, {} as CursorConfig);

const dragCursors = Object.keys(defaultToolCursors).reduce<CursorConfig>((obj, key) => {
  obj[key as keyof CursorConfig] = {
    default: { cssValue: 'grab' },
  };
  return obj;
}, {} as CursorConfig);

const ToolConfig: {
  [key in ChalkboardToolMode]: {
    name: string,
    svg: UnlabeledSvgComponent,
    cursors: CursorConfig,
    enabled: boolean
  }
} = {
  freeDraw: {
    name: 'Free Line',
    svg: FreeLineSVG,
    cursors: defaultToolCursors,
    enabled: true,
  },
  line: {
    name: 'Line',
    svg: LineSVG,
    cursors: defaultToolCursors,
    enabled: true,
  },
  rectangle: {
    name: 'Rectangle',
    svg: SquareSVG,
    cursors: defaultToolCursors,
    enabled: true,
  },
  ellipse: {
    name: 'Ellipse',
    svg: CircleSVG,
    cursors: defaultToolCursors,
    enabled: true,
  },
  smartShape: {
    name: 'Smart Shape',
    svg: StarSVG,
    cursors: defaultToolCursors,
    enabled: true,
  },
  text: {
    name: 'Text',
    svg: TextSVG,
    cursors: textCursors,
    enabled: true,
  },
  drag: {
    name: 'Drag',
    svg: GrabSVG,
    cursors: dragCursors,
    enabled: true,
  },
  eraser: {
    name: 'Eraser',
    svg: EraserSVG,
    cursors: eraserCursors,
    enabled: false,
  },
};

export default ToolConfig;
