import TypedEventEmitter from 'utils/typedEventEmitter';
import type {
  WorkerToMainMessage, MainToWorkerMessage, FaceDetectionEventData,
  FaceDetectorOptions, MainToWorkerAction, PredictionMethod, Logger,
} from '../types/types';
import { FaceDetectionEvent, MainToWorkerActionWithPayload } from '../types';
// eslint-disable-next-line import/extensions
import DetectFaceWorker from './faceDetector.worker.ts';

export const FACE_DETECTOR_DEFAULTS: FaceDetectorOptions = {
  tensorFlowBackend: 'wasm',

  logger: console,
};

export class FaceDetector<L extends Logger = Logger> extends TypedEventEmitter<FaceDetectionEventData> {
  private worker: DetectFaceWorker | null = null;

  /** Used to get pixel data when Insertable Streams API is not supported */
  private hiddenCanvas: HTMLCanvasElement | null = null;

  /** Used to get pixel data when Insertable Streams API is not supported */
  private hiddenCtx: CanvasRenderingContext2D | null = null;

  /** Which backend to use for tensorflow face detection predictions */
  private tensorFlowBackend: FaceDetectorOptions['tensorFlowBackend'];

  private predictionMethod: PredictionMethod;

  private logger: L;

  private hasBeenCleanedUp = false;

  constructor(options?: Partial<FaceDetectorOptions<L>>) {
    super();

    // init state based on options passed in
    this.tensorFlowBackend = options?.tensorFlowBackend || FACE_DETECTOR_DEFAULTS.tensorFlowBackend;
    this.logger = options?.logger || (FACE_DETECTOR_DEFAULTS.logger as L);

    // set up hidden canvas (for pulling pixel data when Insertable Streams API is not available)
    this.hiddenCanvas = document.createElement('canvas');
    this.hiddenCtx = this.hiddenCanvas.getContext('2d', { desynchronized: true });

    // check browser support
    const offscreenCanvasSupported = 'OffscreenCanvas' in window;
    const createImageBitmapSupported = 'createImageBitmap' in window;
    const canUserImageBitmap = createImageBitmapSupported && offscreenCanvasSupported;

    if (canUserImageBitmap) {
      this.predictionMethod = 'imageBitmap';
    } else {
      this.predictionMethod = 'imageData';
    }
    this.logger.debug(`Using ${this.predictionMethod} prediction method`);

    // instantiate web worker
    this.worker = new DetectFaceWorker();
    this.worker.onmessage = (e) => this.propagateWorkerMessage(e);

    // set up necessary state in worker
    this.initWorkerStateWithoutStream();
  }

  /** Uses element source to make a prediction */
  public async predict(srcVideo: HTMLVideoElement) {
    if (this.hasBeenCleanedUp) return;
    if (!this.worker) throw new Error('Worker was not initialized correctly');
    if (!this.hiddenCanvas || !this.hiddenCtx) throw new Error('Canvas or context not initialized correctly');
    if (srcVideo.videoHeight === 0 || srcVideo.videoWidth === 0) {
      this.logger.warn('Can\'t make a prediction, because srcVideo\'s width or height is 0', { srcVideo });
      return;
    }
    if (srcVideo.readyState !== HTMLMediaElement.HAVE_ENOUGH_DATA) {
      this.logger.warn('Can\'t make a prediction, because srcVideo\'s readyState is not sufficient', { srcVideo, readyState: srcVideo.readyState });
      return;
    }

    switch (this.predictionMethod) {
      case 'imageBitmap': {
        // next best choice: sending ImageBitmap to worker
        const imageBitmap = await createImageBitmap(srcVideo);
        this.postMessageToWorker({
          action: MainToWorkerActionWithPayload.REQUEST_PREDICTION_WITH_IMAGE_BITMAP,
          body: { imageBitmap },
        });
        break;
      }
      // this case should fall through if there was an error above
      case 'imageData': {
        // least optimal choice, but most well-supported: send ImageData to worker:
        // extract pixel data from video frame using a hidden canvas
        // set canvas height to proper dimensions
        const width = srcVideo.videoWidth;
        const height = srcVideo.videoHeight;
        if (this.hiddenCanvas.width !== width) this.hiddenCanvas.width = width;
        if (this.hiddenCanvas.height !== height) this.hiddenCanvas.height = height;

        // get pixel data by drawing to canvas
        this.hiddenCtx.drawImage(srcVideo, 0, 0);
        const imageData = this.hiddenCtx.getImageData(0, 0, width, height);
        this.postMessageToWorker({
          action: MainToWorkerActionWithPayload.REQUEST_PREDICTION_WITH_IMAGE_DATA,
          body: { imageData },
        });
      }
        break;
      default:
        throw new Error('Unexpected case reached in predict');
    }
  }

  public cleanup() {
    this.hasBeenCleanedUp = true;
    this.worker?.terminate();
  }

  // PUBLIC METHODS END:
  // / //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // PRIVATE METHODS START:

  /** When Insertable Streams API is not supported, sends user's preferences to web worker to initialize its state
   * --video frames are sent separately once video data has loaded. */
  private initWorkerStateWithoutStream() {
    if (this.hasBeenCleanedUp) return;
    if (!this.worker) throw new Error('Worker was not initialized correctly');
    this.postMessageToWorker({
      action: MainToWorkerActionWithPayload.INIT,
      body: {
        tensorFlowBackend: this.tensorFlowBackend,
      },
    });
  }

  /** Typed wrapper around default, untyped worker.postMessage function */
  private postMessageToWorker<T extends MainToWorkerAction>(
    message: MainToWorkerMessage<T>,
    transfer?: Transferable[],
  ) {
    if (this.hasBeenCleanedUp) return;
    if (!this.worker) throw new Error('Trying to send message to worker when worker has not been initialized');
    if (transfer) this.worker.postMessage(message, transfer);
    else this.worker.postMessage(message);
  }

  /** Propagate worker messages upwards as events */
  private propagateWorkerMessage(e: MessageEvent<WorkerToMainMessage>) {
    if (this.hasBeenCleanedUp) return;
    for (const faceDetectionEvent of FaceDetectionEvent.asArray()) {
      if (e.data.action === faceDetectionEvent.id) {
        this.emit(faceDetectionEvent.id, e.data.body);
        return;
      }
    }
  }
}
