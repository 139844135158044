/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import useIsKeyboardUser from 'hooks/useIsKeyboardUser';
import { CSS } from 'types/css';
import { ReactHTML } from 'react';
import { TEAL_100 } from 'theme/ui/colors';

interface Props {
  rounded?: boolean,
  sibling?: boolean,
  color?: string,
  as?: keyof ReactHTML;
}

/**
 * Provides a focus outline around components for keyboard users.
 *
 * ## Props
 * ### `as` (default = `'button'`)
 * The `as` props is the HTML tag of parent component if the parent is not a button.
 * This allows us to select the FocusRing component from a parent component when that parent receives focus.
 * Normally this isn't allowed in CSS, but ThemeUI works some magic behind the scenes to make this work.
 *
 * ### `sibling` (default = `false`)
 * `sibling` allows us to select this component as an immediate sibling of another component rather than
 * as a child. This is useful when providing a FocusRing for components that don't accept children, such
 * as Inputs. We can style them as:
 * ```css
 * input:focus + &
 * ```
 * rather than:
 * ```css
 * input:focus &
 * ```
 */
function FocusRing({
  color = TEAL_100, rounded = false, as = 'button', sibling = false,
}: Props) {
  const isKeyboardUser = useIsKeyboardUser();
  const focusRingStyles: CSS = {
    opacity: 0,
    position: 'absolute',
    top: '-1rem',
    left: '-1rem',
    right: 0,
    bottom: 0,
    width: 'calc(100% + 2rem)',
    height: 'calc(100% + 2rem)',
    border: '0.4rem solid',
    borderColor: color,
    borderRadius: rounded ? '10rem' : '0.7rem',
    pointerEvents: 'none',
    transition: '0.2s ease',
    [`${as}:focus${sibling ? ' + ' : ' '}&`]: isKeyboardUser ? {
      opacity: 1,
      width: 'calc(100% + 1rem)',
      height: 'calc(100% + 1rem)',
      top: '-0.5rem',
      left: '-0.5rem',
      border: '0.2rem solid',
      borderColor: color,
    } : null,
  };
  return <span sx={focusRingStyles} />;
}

export default FocusRing;
