import { v4 as uuidv4 } from 'uuid';
import isEqual from 'lodash/isEqual';
import request from 'utils/request';
import WS from '@livelyvideo/hub-websocket';
import { push } from 'connected-react-router';
import logger from 'utils/logger';
import { setJoinedRoom, logout, setRedirectAfterLoginPath } from 'actions/loginActions';
import {
  addNewParticipant, removeLeavingParticipant, setGroupsFromWS, ownerJoinAudio, setBreakoutGroups,
} from 'actions/breakoutGroupsActions';
import {
  RESET_ROOM, setJoinError, setRoomError, handleError, setDisableRoomActions, roomRequest, activateNudge, deactivateNudge,
} from 'actions/sharedActions';
import { endScreenShare } from 'actions/screenShareActions';
import {
  LoginTypesEnum, RoomPermissionName,
  AppThunkAction, LivelyRoomToken, OwnerBroadcastLoading, RoomPermissions,
  RoomPermission, StoredKNRoom, MockParticipant, Nudge,
  CONVO_ID_NOT_SELECTED,
  NotificationComponentEnum,
  StoredKNParticipant,
  LaunchedGroups,
} from 'store/types';
import { SoundFileEnum } from 'utils/soundPlayer';
import { PermissionStateEnum } from 'utils/browserPermissions';
// import mixpanel, { asOnOff } from 'utils/mixpanel';
import { MakeActionType } from 'utils/typeUtils';
import {
  GetRoomPermissions, CCServiceSoundPreferencesEnum, FetchLivelyTokenResponse, FetchRoomNameResponse,
} from 'utils/ccService/types';
import { AxiosRequestConfig } from 'axios';
import { getAreUsersInSameGroup } from 'utils/breakoutGroupUtils';
import { batch } from 'react-redux';
import { getRandomLetterString } from 'utils/stringUtils';
import { WSCloseCodeEnum, getWsCloseCodeInfo } from 'utils/websocketCodes';
import { getCurrentUserGroupId, getIsLaunched, getRoomScreenSharers } from 'selectors';
import retry from 'utils/retry';
import { getMessageFromError, getStatusCodeFromError } from 'utils/errorUtils';
import { KNBreakoutRoom, KNRoomState } from 'utils/roomState/knats';
import mergeRoomStates from 'utils/roomState/mergeRoomStates';
import RouteEnum from 'utils/routeEnum';
import { convertLegacyRoomStateToKnats, convertLegacyRoomRosterToKnats } from 'utils/roomState/convertLegacyState';
import sanitizeRoomState from 'utils/roomState/sanitizeRoomState';
import { DEFAULT_ROOM_PERMISSIONS } from 'utils/roomPermissions';
import { cleanUpAllMedia, setEncoderAudioMuted, setEncoderVideoPaused } from './encoderActions';
import { setRoomSettingsRoomName } from './dashboardActions';
import { selectConversation, stopChatClient } from './chatActions';
import { setRoomNameForRemovedModal } from './joinActions';
import { setRoomAlert } from './alertActions';
import { setRoomNotification } from './notificationActions';
import { addActiveResult } from './icebreakerActions';

export const reducerName = 'roomState' as const;
export const SET_ROOM_STATE = `${reducerName}/SET_ROOM_STATE` as const;
export const SET_WEBSOCKET = `${reducerName}/SET_WEBSOCKET` as const;
export const CLOSE_WEBSOCKET = `${reducerName}/CLOSE_WEBSOCKET` as const;
export const TOGGLE_ROSTER_VISIBLE = `${reducerName}/TOGGLE_ROSTER_VISIBLE` as const;
export const TOGGLE_LAYOUT = `${reducerName}/TOGGLE_LAYOUT` as const;
export const SET_PRESENTER_ID = `${reducerName}/SET_PRESENTER_ID` as const;
export const JOIN_ROOM_START = `${reducerName}/JOIN_ROOM_START` as const;
export const JOIN_ROOM_COMPLETE = `${reducerName}/JOIN_ROOM_COMPLETE` as const;
export const SET_OWNER_ID_AND_NAME = `${reducerName}/SET_OWNER_ID_AND_NAME` as const;
export const ROOM_WEBSOCKET_DISCONNECTED = `${reducerName}/ROOM_WEBSOCKET_DISCONNECTED` as const;
export const SET_ROOM_PERMISSIONS_UPDATING = `${reducerName}/SET_ROOM_PERMISSIONS_UPDATING` as const;
export const SET_ROOM_PERMISSIONS = `${reducerName}/SET_ROOM_PERMISSIONS` as const;
export const SET_PERMISSIONS_LOADING = `${reducerName}/SET_PERMISSIONS_LOADING` as const;
export const RESET_ROOM_PERMISSIONS = `${reducerName}/RESET_ROOM_PERMISSIONS` as const;
export const SET_OWNER_BROADCAST_LOADING = `${reducerName}/SET_OWNER_BROADCAST_LOADING` as const;
export const SET_ROOM_JOINED_DATE = `${reducerName}/SET_ROOM_JOINED_DATE` as const;
export const SET_DROPDOWN_OPEN = `${reducerName}/SET_DROPDOWN_OPEN` as const;
export const SET_LIVELY_ROOM_TOKEN = `${reducerName}/SET_LIVELY_ROOM_TOKEN` as const;
export const SET_FULLSCREEN_USER_ID = `${reducerName}/SET_FULLSCREEN_USER_ID` as const;
export const SET_ROOM_JOINABLE = `${reducerName}/SET_ROOM_JOINABLE` as const;
export const ADD_MOCK_PARTICIPANT = `${reducerName}/ADD_MOCK_PARTICIPANT` as const;
export const SET_ROOM_LOCK_LOADING = `${reducerName}/SET_ROOM_LOCK_LOADING` as const;
export const SET_IS_LOCKED_FROM_JOINING = `${reducerName}/SET_IS_LOCKED_FROM_JOINING` as const;
export const SET_IS_REQUIRE_LOGIN_LOADING = `${reducerName}/SET_IS_REQUIRE_LOGIN_LOADING` as const;
export const SET_HEARTBEAT_FAILURE = `${reducerName}/SET_HEARTBEAT_FAILURE` as const;
export const SET_WS_READY = `${reducerName}/SET_WS_READY` as const;
export const SET_ROOM_STATE_REQUIRES_LOGIN = `${reducerName}/SET_ROOM_STATE_REQUIRES_LOGIN` as const;
export const SET_ROOM_STATE_IS_LOCKED = `${reducerName}/SET_ROOM_STATE_IS_LOCKED` as const;
export const SET_ROOM_STATE_KNOCKERS = `${reducerName}/SET_ROOM_STATE_KNOCKERS` as const;
export const SET_ROOM_STATE_PARTICIPANTS = `${reducerName}/SET_ROOM_STATE_PARTICIPANTS` as const;
export const SET_ICEBREAKER_QUESTIONS = `${reducerName}/SET_ICEBREAKER_QUESTIONS` as const;
export const INITIAL_ROOM_STATE_RECEIVED = `${reducerName}/INITIAL_ROOM_STATE_RECEIVED` as const;
export const SET_PREVIOUSLY_ADMITTED = `${reducerName}/SET_PREVIOUSLY_ADMITTED` as const;
export const SET_ROOM_ROSTER = `${reducerName}/SET_ROOM_ROSTER` as const;
export const SET_CHAPERONE_LOADING = `${reducerName}/SET_CHAPERONE_LOADING` as const;
export const SET_OPEN_ROOM = `${reducerName}/SET_OPEN_ROOM` as const;
export const SET_RECORDING_START = `${reducerName}/SET_RECORDING_START` as const;

export enum RoomLayoutEnum {
  GRID = 1,
  PRESENTER = 3,
  FULLSCREEN = 4,
}

export enum TileTypesEnum {
  DEFAULT = 'DEFAULT',
  PRESENTER = 'PRESENTER',
  FULLSCREEN = 'FULLSCREEN',
}

/** Types of actions the owner can take on behalf of a participant and their corresponding actions. */
export interface OwnerBroadcastTypes {
  audio: 'mute' | 'unmute',
  video: 'hide',
  ownerJoinAudio: string | null, // Breakout Group ID or null
}

/**
 * Shape of data that CC Service expects when sending an owner broadcast.
 * This is also the shape that we can expect to be received from the
 * owner-broadcast websocket event.
 */
export type OwnerBroadcastData<Type extends keyof OwnerBroadcastTypes> = {
  type: Type,
  message: OwnerBroadcastTypes[Type],
  users: string[]
}

/** Creates distributed union, which allows discriminated type inference */
type MakeOwnerBroadcastDataDistributedUnion<Union> = Union extends infer ConditionalUnion ? (
  ConditionalUnion extends keyof OwnerBroadcastTypes ? OwnerBroadcastData<ConditionalUnion> : never
) : never;

type OwnerBroadcastDataUnion = MakeOwnerBroadcastDataDistributedUnion<keyof OwnerBroadcastTypes>;

/** When there is a new room /publish action, add the action as a key and the expected body as the value type */
export interface RoomPublishAction {
  nudge: { userId: string, nudge: Nudge },
  'owner-broadcast': OwnerBroadcastData<keyof OwnerBroadcastTypes>,
}

export const setRoomState = (room: StoredKNRoom) => ({
  type: SET_ROOM_STATE,
  payload: { room },
});

export const setLivelyRoomToken = (livelyRoomToken: LivelyRoomToken) => ({
  type: SET_LIVELY_ROOM_TOKEN,
  payload: { livelyRoomToken },
});

export const setPresenterId = (presenterId: string | null) => ({
  type: SET_PRESENTER_ID,
  payload: { presenterId },
});

export const setOwnerId = ({ ownerId, ownerName }: { ownerId: string, ownerName: string }) => ({
  type: SET_OWNER_ID_AND_NAME,
  payload: {
    ownerId,
    ownerName,
  },
});

export const setOwnerBroadcastLoading = (loadingState: OwnerBroadcastLoading) => ({
  type: SET_OWNER_BROADCAST_LOADING,
  payload: { loadingState },
});

export const setDropdownOpen = (dropdownOpen: boolean) => ({
  type: SET_DROPDOWN_OPEN,
  payload: { dropdownOpen },
});

export const setRoomPermissions = (permissions: RoomPermissions) => ({
  type: SET_ROOM_PERMISSIONS,
  payload: { permissions },
});

export const setRoomPermissionsUpdating = (permissionsUpdating: boolean) => ({
  type: SET_ROOM_PERMISSIONS_UPDATING,
  payload: { permissionsUpdating },
});

export const setPermissionsLoading = (loading: boolean) => ({
  type: SET_PERMISSIONS_LOADING,
  payload: { loading },
});

export const resetRoomPermissions = () => ({
  type: RESET_ROOM_PERMISSIONS,
});

export const setRoomJoinedTimestamp = (timestamp: number) => ({
  type: SET_ROOM_JOINED_DATE,
  payload: { timestamp },
});

export const toggleLayout = (newLayout: RoomLayoutEnum, isUserSelected = true) => ({
  type: TOGGLE_LAYOUT,
  payload: {
    layout: newLayout,
    isUserSelected,
  },
});

export const setWebsocket = (socket: WS | null) => ({
  type: SET_WEBSOCKET,
  payload: { socket },
});

export const setFullscreenUserId = (fullscreenUserId: string) => ({
  type: SET_FULLSCREEN_USER_ID,
  payload: { fullscreenUserId },
});

export const setRoomJoinable = (joinable: boolean) => ({
  type: SET_ROOM_JOINABLE,
  payload: { joinable },
});

export const setIsLockedFromJoining = (isLocked: boolean) => ({
  type: SET_IS_LOCKED_FROM_JOINING,
  payload: { isLocked },
});

/**
 * Used specifically when a 'room-locked' message indicates the room room requires login,
 * despite getting no true room state update (due to the fact the room is locked)
 */
export const setIsLoginRequired = (isLoginRequired: boolean) => ({
  type: SET_ROOM_STATE_REQUIRES_LOGIN,
  payload: { isLoginRequired },
});

export const overrideRoomStateParticipants = (participants: StoredKNRoom['participants']) => ({
  type: SET_ROOM_STATE_PARTICIPANTS,
  payload: { participants },
});

/**
 * Mock function for adding users to a room - this will be removed eventually
 */
export const _addMockParticipant = (id: string, participant: MockParticipant) => ({
  type: ADD_MOCK_PARTICIPANT,
  payload: {
    id,
    participant,
  },
});

export const addMockParticipant = (): AppThunkAction => (dispatch, getState) => {
  const state = getState();
  const isLaunched = getIsLaunched(state);
  const groupId = getCurrentUserGroupId(state);
  const { breakoutGroupsState: { launchedGroups, groups, groupNames } } = state;

  const id = uuidv4();

  const participant: MockParticipant = {
    displayName: `${getRandomLetterString(5)} ${getRandomLetterString(6)}`,
    userId: id,
    roles: { 'conference-owner': false, chat: true },
    sessionParticipantId: '',
    reqId: '',
    peerId: '',
    callBaseUrl: '',
    callToken: '',
    handRaisedTimeStampMillis: -1,
    nudged: false,
    nudgeAcknowledged: false,
    reserved: {},
    // add "mock" property for styling mock users in the VideoNamePlaceholder
    mock: true,
    isScreenShared: false,
    isHandRaised: false,
    profileUrl: '',
  };

  dispatch(_addMockParticipant(id, participant));

  if (isLaunched && groupId) { // adds to your current group
    dispatch(setBreakoutGroups({
      ...launchedGroups,
      [groupId]: {
        ...launchedGroups[groupId],
        users: [...launchedGroups[groupId].users, id],
      },
    }, groups, groupNames));
  }
};

export const setIsRoomLockLoading = (loading: boolean) => ({
  type: SET_ROOM_LOCK_LOADING,
  payload: { loading },
});

export const setIsRequireLoginLoading = (loading: boolean) => ({
  type: SET_IS_REQUIRE_LOGIN_LOADING,
  payload: { loading },
});

export const setHeartbeatFailure = (heartbeatFailure: boolean) => ({
  type: SET_HEARTBEAT_FAILURE,
  payload: { heartbeatFailure },
});

export const setWSReady = (isWSReady: boolean) => ({
  type: SET_WS_READY,
  payload: { isWSReady },
});

export const setInitialRoomStateReceived = () => ({
  type: INITIAL_ROOM_STATE_RECEIVED,
});

export const setIsLocked = (isLocked: boolean) => ({
  type: SET_ROOM_STATE_IS_LOCKED,
  payload: { isLocked },
});

export const setKnockers = (knockers: StoredKNRoom['knockers']) => ({
  type: SET_ROOM_STATE_KNOCKERS,
  payload: { knockers },
});

export const setQuestions = (questions: StoredKNRoom['questions']) => ({
  type: SET_ICEBREAKER_QUESTIONS,
  payload: { questions },
});

export const setRecordingStart = (start: number) => ({
  type: SET_RECORDING_START,
  payload: { start },
});

export const setPreviouslyAdmitted = (previouslyAdmitted: boolean) => ({
  type: SET_PREVIOUSLY_ADMITTED,
  payload: { previouslyAdmitted },
});

export const setRoomRoster = (roomRoster: StoredKNRoom['roomRoster']) => ({
  type: SET_ROOM_ROSTER,
  payload: { roomRoster },
});

export const setChaperoneLoading = (loading: boolean) => ({
  type: SET_CHAPERONE_LOADING,
  payload: { loading },
});

/** Room event is not emitted when owner changes this setting, even though it does
 * change the room state, so we must manually update this in our state locally.
 * (The next room state that does come will be updated) */
export const setOpenRoom = (open: boolean) => ({
  type: SET_OPEN_ROOM,
  payload: { open },
});

export type RoomAction = MakeActionType<[
  typeof TOGGLE_ROSTER_VISIBLE,
  typeof CLOSE_WEBSOCKET,
  typeof JOIN_ROOM_START,
  typeof JOIN_ROOM_COMPLETE,
  typeof ROOM_WEBSOCKET_DISCONNECTED,
  typeof setOpenRoom,
  typeof setChaperoneLoading,
  typeof toggleLayout,
  typeof setRoomState,
  typeof setLivelyRoomToken,
  typeof setPresenterId,
  typeof setOwnerId,
  typeof setOwnerBroadcastLoading,
  typeof setDropdownOpen,
  typeof setRoomPermissionsUpdating,
  typeof setRoomPermissions,
  typeof setPermissionsLoading,
  typeof resetRoomPermissions,
  typeof setRoomJoinedTimestamp,
  typeof setWebsocket,
  typeof setFullscreenUserId,
  typeof setRoomJoinable,
  typeof _addMockParticipant,
  typeof setIsRoomLockLoading,
  typeof setIsLockedFromJoining,
  typeof setIsRequireLoginLoading,
  typeof setHeartbeatFailure,
  typeof setWSReady,
  typeof setIsLoginRequired,
  typeof setInitialRoomStateReceived,
  typeof setIsLocked,
  typeof setKnockers,
  typeof overrideRoomStateParticipants,
  typeof setQuestions,
  typeof setPreviouslyAdmitted,
  typeof setRoomRoster,
  typeof setRecordingStart,
]>

export const toggleRosterVisible = (): AppThunkAction => (dispatch) => dispatch({
  type: TOGGLE_ROSTER_VISIBLE,
});

/**
 * Determines what message to display when a teacher calls on a student
 */
export const pickRaiseHandMessage = (userId: string): AppThunkAction => (dispatch, getState) => {
  const {
    roomState: {
      ownerId,
      room: { participants },
    },
    encoderState: {
      devicePermission,
    },
    breakoutGroupsState: {
      isLaunched, launchedGroups,
    },
    loginState: {
      user: currentUser,
    },
  } = getState();

  const user = participants[userId];

  if (!user) {
    logger.warn('Invalid status to call pickRaisedHandMessage', { reason: 'Participant not found', userId, participants });
    return;
  }

  if (currentUser?.userId === ownerId) {
    dispatch(setRoomAlert(`You called on ${user?.displayName}.`));
  } else {
    let message;
    if (user?.userId === currentUser?.userId) {
      message = 'You were called on.';
      if (devicePermission === PermissionStateEnum.DENIED) {
        dispatch(setRoomError('You were called on, but you have denied access to your microphone. You can change this in your browser\'s settings'));
      }
    } else {
      if (isLaunched && !getAreUsersInSameGroup(launchedGroups, user?.userId, currentUser?.userId || '')) {
        return;
      }
      message = `${user.displayName} was called on.`;
      dispatch(setEncoderAudioMuted(true));
    }

    dispatch(setRoomAlert(message));
  }
};

/**
 * Determines what message to display when a user has ended speaking
 */
export const endCalledOnMessage = (
  userId: string | null,
): AppThunkAction => (dispatch, getState) => {
  const {
    roomState: { room: { participants } },
    loginState: { user: currentUser },
    breakoutGroupsState: { isLaunched, launchedGroups },
  } = getState();

  const user = participants[userId || ''];

  if (!user) {
    logger.warn('Could not set end called on message', { user, userId });
    return;
  }

  const message = user.userId === currentUser?.userId
    ? 'You have finished speaking.'
    : `${user?.displayName} has finished speaking.`;

  if (isLaunched && !getAreUsersInSameGroup(launchedGroups, user?.userId, currentUser?.userId || '')) {
    return;
  }
  dispatch(setRoomAlert(message));
};

/**
 * Update room based on new room state data from CC Service.
 * Used in both websocket room-event
 */
export const updateRoomState = (
  newRoomState: StoredKNRoom,
  // since these are all synchronous state updates, batch them
): AppThunkAction => (dispatch, getState) => batch(() => {
  const state = getState();
  const prevRoomState = state.roomState.room;
  const { ownerId, isLockedFromJoining } = state.roomState;
  const { ...newRoom } = newRoomState;

  batch(() => {
    if (prevRoomState.isJoinable !== newRoomState.isJoinable) {
      dispatch(setRoomJoinable(newRoomState.isJoinable));
    }

    dispatch(setRoomState(newRoom));

    if (!newRoomState.isLocked && isLockedFromJoining) {
      dispatch(setIsLockedFromJoining(false));
    }

    let ownerUser: StoredKNParticipant | undefined;
    Object.values(newRoom.participants).forEach((prt) => {
      if (prt?.userId === newRoomState.ownerId) {
        ownerUser = prt;
      }
    });
    if (ownerUser && ownerUser.userId !== ownerId) {
      const ownerName = ownerUser.displayName;
      dispatch(setOwnerId({ ownerId: ownerUser.userId, ownerName }));
    }
  });
});

/**
 * Audio actions that can be taken on the participant's end
 * when a broadcast message is received from the owner.
 */
export const changeCurrentUsersAudio = (
  message: OwnerBroadcastTypes['audio'],
): AppThunkAction => (dispatch, getState) => {
  const state = getState();
  const { ownerId } = state.roomState;
  if (!ownerId) {
    logger.warn('changeCurrentUsersAudio: Could not change current user\'s audio. ownerId = ', { ownerId });
    return;
  }
  const owner = state.roomState.room.participants[ownerId];
  const ownerName = owner?.displayName;
  switch (message) {
    case 'mute':
      dispatch(setEncoderAudioMuted(true));
      dispatch(setRoomAlert(`${ownerName} has muted your mic`));
      break;
    case 'unmute':
      dispatch(setEncoderAudioMuted(false));
      dispatch(setRoomAlert(`${ownerName} has unmuted your mic`));
      break;
    default:
      break;
  }
};

/**
 * Video actions that can be taken on the participant's end
 * when a broadcast message is received from the owner.
 */
export const changeCurrentUsersVideo = (
  message: OwnerBroadcastTypes['video'],
): AppThunkAction => (dispatch, getState) => {
  const state = getState();
  const { ownerId } = state.roomState;
  if (!ownerId) {
    logger.warn('changeCurrentUsersVideo: Could not change current user\'s video. ownerId = ', { ownerId });
    return;
  }
  const owner = state.roomState.room.participants[ownerId];
  const ownerName = owner?.displayName;
  switch (message) {
    case 'hide':
      dispatch(setEncoderVideoPaused(true));
      dispatch(setRoomAlert(`${ownerName} has hidden your video`));
      break;
    default:
      break;
  }
};

/** Makes POST request to room publish endpoint */
export async function publishToRoom<T extends keyof RoomPublishAction>(roomId: string, action: T, body: RoomPublishAction[T]) {
  logger.info('Owner broadcast', { roomId, action, body } as any);
  const req = await request({
    method: 'POST',
    url: `/rooms/${roomId}/publish`,
    data: { action, body },
  });
  return req;
}

/**
 * Allows the owner of a room to broadcast a message to the entire room/
 * do certain actions for a user like muting/unmuting or hiding their video.
 *
 * Pass wholeRoom: true to send to all users. Cannot provide users and wholeRoom: true at the same time.
 */
export const sendOwnerBroadcast = <Type extends keyof OwnerBroadcastTypes = keyof OwnerBroadcastTypes>({
  type,
  message,
  users = [],
  wholeRoom,
}: {
  type: Type,
  message: OwnerBroadcastTypes[Type],
  users: string[],
  wholeRoom?: false,
} | {
  type: Type,
  message: OwnerBroadcastTypes[Type],
  users?: undefined,
  wholeRoom: true
}): AppThunkAction => async (dispatch, getState): Promise<boolean> => {
    const { roomState: { room: { id: roomId, participants }, ownerId } } = getState();

    if (!roomId) {
      logger.warn('Invalid state to sendOwnerBroadcast', {
        reason: 'No roomId', roomId, type, message, users,
      });
      return false;
    }

    if (wholeRoom) {
      users = Object.keys(participants).filter((id) => id !== ownerId);
    }

    let success = true;

    try {
    /* Each user and message type receives a separate loading state.
Each button/component can consume it's own hash from the loading state. */
      const loadingState: OwnerBroadcastLoading = {};
      users.forEach((userId) => {
        const hash = `${userId}${type}`;
        loadingState[hash] = true;
      });
      dispatch(setOwnerBroadcastLoading(loadingState));

      const body = {
        type,
        message,
        users,
      };

      // if (type === 'ownerJoinAudio') {
      //   mixpanel.track('Listen in Group');
      // }

      await retry(async () => {
        publishToRoom(
          roomId,
          'owner-broadcast',
          body,
        );
      }, { retries: 10 });
    } catch (error) {
      success = false;
      dispatch(handleError('Owner-broadcast message error', {
        error,
        body: {
          type,
          message,
          users,
        },
      }));
    } finally {
      const loadingState: OwnerBroadcastLoading = {};
      users.forEach((userId) => {
        const hash = `${userId}${type}`;
        loadingState[hash] = false;
      });
      dispatch(setOwnerBroadcastLoading(loadingState));
    }
    return success;
  };

/**
 * Starts the room websocket connection
 */
export const startRoomWebsocket = (roomId: string): AppThunkAction => (dispatch, getState) => {
  const { livelyRoomToken: { token: roomToken } } = getState().roomState;

  if (!roomId) {
    logger.warn('Can\'`t initialize room websocket without a valid roomId');
    return;
  }

  if (!roomToken) {
    logger.warn('Can\'`t initialize room websocket without a valid roomToken');
    return;
  }

  logger.info('Starting room web socket');

  const ws = new WS({
    token: roomToken,
    roomId,
    websocketUri: appConfig.ccServiceWsUri,
    plugin: 'chalkcast',
    version: 'v2',
    logger,
  }, undefined, {
    maxReconnectInterval: 15000, // 15 sec
    reconnectDecay: 1.2, // 1s, 1.44s, 1.73s, 2.07s, ...
    debug: true,
  });

  dispatch(setWebsocket(ws));

  /** When socket disconnects mid-session, this code lives until valid room state received after reconnection */
  let prevDisconnectCode: number | null = null;
  let isRejoining = false;

  ws.on('connect', async () => {
    dispatch(setWSReady(true));
    const { roomState: { isDisabled, heartbeatFailure, roomJoinedTimestamp }, router: { location: { pathname } } } = getState();
    logger.info('Room websocket connected', { prevDisconnectCode, heartbeatFailure });

    if (prevDisconnectCode) {
      logger.info('Room websocket reconnected', { prevDisconnectCode });
      prevDisconnectCode = null;

      if (RouteEnum.ROOM.matchPath(pathname)) {
        isRejoining = true;
        await sendWebsocketMessage(ws, 'join');
        isRejoining = false;
      }
    }

    if (isDisabled) {
      // Websocket has successfully reconnected so isDisabled can be switched to false
      logger.info('Room was previously disabled because of 502 or 503 error');
      dispatch(setDisableRoomActions(false));
    }

    if (heartbeatFailure && roomJoinedTimestamp) {
      logger.info('Attempting to join session after heartbeat failure');
      try {
        await sendWebsocketMessage(ws, 'join');
        dispatch(stopChatClient());
      } catch (error) {
        logger.error('Error rejoining session after heartbeat failure');
        dispatch(push(RouteEnum.ROOM_MANAGER__JOIN.encodePath({ urlParams: { roomId } })));
        // Wrapping in setTimeout to force this to happen on the next tick.
        // When the user is sent to /room-manager/join and the Room unmounts, resetRoomState()
        // is called which will clear the error message. The setTimeout ensures
        // that the message is set after the room is reset
        setTimeout(() => {
          dispatch(setJoinError('We are sorry. Something went wrong'));
        }, 0);
      }
    }
  });

  ws.on('chalkcast', (msg) => {
    logger.debug('Received room websocket action', { action: msg.action });
    try {
      if (!msg || !msg.action || typeof msg.action !== 'string') {
        logger.warn('Malformed websocket message', { msg, detail: 'Expected msg to be object with "action" string' });
        return;
      }

      const { action, body, diff } = msg;

      const state = getState();
      const {
        layout, ownerId, ownerName, room, prevUserSelectedLayout, presenterId, initialRoomStateReceived,
        isLockedFromJoining,
      } = state.roomState;
      const {
        participants, callId, isLocked, isLoginRequired, knockers, roomRoster, recordingStart,
        calledOnId,
      } = room;
      const { user: currentUser, joinedRoom } = state.loginState;
      const { pathname } = state.router.location;
      const { soundPlayer } = state.audioState;

      // only play add-user and remove-user events inside room
      const isRoom = RouteEnum.ROOM.matchPath(pathname);

      const logInvalidBody = (detail: string) => logger.warn('Malformed websocket message body', { ...msg, detail });

      const shouldUseLegacyState = !appConfig.enableKafkaNatsRoomState || !joinedRoom;

      switch (action) {
        case 'kafka-nats-diff': {
          if (!initialRoomStateReceived) {
            dispatch(setInitialRoomStateReceived());
          }
          if (isRejoining) {
            logger.info('Skipping room state update', {
              reason: 'User is still rejoining the room after websocket reconnect', roomState: body,
            });
            return;
          }

          if (!diff || typeof diff !== 'object') {
            logInvalidBody('kafka-nats-diff payload is not an object');
          } else {
            const knatsDiff = diff as KNRoomState;
            const roomDiff = { ...knatsDiff.rooms?.[roomId] };
            if (roomDiff) {
              //! KNATS WORKAROUND These fields needed to be overridden by the legacy room-events only at the time being
              delete roomDiff?.isLocked;
              delete roomDiff?.isLoginRequired;
              delete roomDiff?.knockers;

              const roomState = sanitizeRoomState(mergeRoomStates(state.roomState.room, roomDiff));
              const { breakoutRooms } = roomState;

              if (roomState.breakoutRooms) {
                /**
                 *  The legacy breakout group shape used `users: string[]`, but the knats
                 *  shape uses an object shape with dummy values, so only the keys are needed for "users".
                 *  This was done to keep the existing breakout groups logic the same.
                 */
                dispatch(setGroupsFromWS(Object.entries(breakoutRooms).reduce<LaunchedGroups>((obj, [groupId, group]) => {
                  obj[groupId] = {
                    ...(group as KNBreakoutRoom),
                    users: Object.keys((group as KNBreakoutRoom).users),
                  };
                  return obj;
                }, {})));
              } else if (roomDiff.breakoutRooms === null) {
                dispatch(setGroupsFromWS({}));
              }

              if (knatsDiff.lastEventType === 'join') {
                const diffUser = Object.values(roomDiff?.participants || {})
                  .find((p) => !!p && p.userId !== currentUser?.userId && !participants[p.userId]);

                // only display message if not current user
                if (diffUser) {
                  logger.info('User joined the session', { user: diffUser } as any);

                  const joinSoundEnabled = state.preferencesState
                    .soundPreferences[CCServiceSoundPreferencesEnum.JOIN]?.enabled;
                  if (isRoom && callId && joinSoundEnabled) soundPlayer.play(SoundFileEnum.JOIN);
                  dispatch(setRoomNotification(`${diffUser.displayName} has joined the session`));

                  // Adds a participant to the unassigned column of un-launched breakout groups
                  dispatch(addNewParticipant(diffUser.userId));
                }
              } else if (knatsDiff.lastEventType === 'leave') {
                const diffUserId = Object.keys(roomDiff?.participants || {})
                  .find((userId) => userId !== currentUser?.userId && roomDiff?.participants?.[userId] === null);

                const diffUser = participants[diffUserId || ''];

                // only display message if not current user
                if (diffUser) {
                  logger.info('User left the session', { user: diffUser } as any);

                  const joinSoundEnabled = state.preferencesState
                    .soundPreferences[CCServiceSoundPreferencesEnum.LEAVE]?.enabled;
                  if (isRoom && callId && joinSoundEnabled) soundPlayer.play(SoundFileEnum.LEAVE);
                  dispatch(setRoomNotification(`${diffUser.displayName} has left the session`));

                  // Adds a participant to the unassigned column of un-launched breakout groups
                  dispatch(removeLeavingParticipant(diffUser.userId));
                }
              } else if (knatsDiff.lastEventType === 'end-call-hand' && calledOnId) {
                logger.info('Called on user is done speaking', { user: calledOnId });
                dispatch(endCalledOnMessage(calledOnId));
              } else if (knatsDiff.lastEventType === 'start-screen-share') {
                const screenSharerId = Object.keys(roomDiff.participants ?? {})[0];

                if (!screenSharerId || typeof screenSharerId !== 'string') {
                  logInvalidBody('Expected non-empty string body.usrPayload.userId');
                  return;
                }

                logger.info('User started screenshare', { user: screenSharerId });

                const currentUserId = currentUser?.userId ?? '';
                const breakoutGroups = state.breakoutGroupsState.groups;
                const breakoutGroupIds = Object.keys(breakoutGroups);

                let currentGroupId = '';
                let screenSharerGroupId = '';
                let ownerGroupId = '';

                /* Only look for the breakout ids groups Ids if breakout groups is launched */
                if (state.breakoutGroupsState.isLaunched) {
                  breakoutGroupIds.forEach((groupId) => {
                    const groupParticipants = breakoutGroups[groupId];
                    groupParticipants.forEach((groupUserId) => {
                      if (groupUserId === currentUserId) currentGroupId = groupId;
                      if (groupUserId === screenSharerId) screenSharerGroupId = groupId;
                      if (groupUserId === roomState.ownerId) ownerGroupId = groupId;
                    });
                  });
                }

                if (currentGroupId === screenSharerGroupId || (screenSharerId === roomState.ownerId && ownerGroupId === '')) {
                  /* Only set new main presenter if no current main presenter,
                    current presenter undefined, or current presenter is
                    not screensharing -- otherwise add to the end of the presenters array. */
                  const mainPresenter = state.roomState.presenterId;
                  const roomScreenSharers = getRoomScreenSharers(state);
                  const noCurrentScreenPresenter = !mainPresenter || !participants[mainPresenter] || !roomScreenSharers.includes(mainPresenter);

                  if (noCurrentScreenPresenter) {
                    dispatch(setPresenterId(screenSharerId));
                  }

                  if (layout !== RoomLayoutEnum.PRESENTER) {
                    dispatch(toggleLayout(RoomLayoutEnum.PRESENTER, false));
                  }
                }
              } else if (knatsDiff.lastEventType === 'stop-screen-share') {
                const screenSharerId = Object.keys(roomDiff.participants ?? {})[0];
                if (!screenSharerId || typeof screenSharerId !== 'string') {
                  logInvalidBody('Expected non-empty string body.usrPayload.userId');
                  return;
                }

                logger.info('User ended screenshare', { user: screenSharerId });

                const isPresenter = presenterId === screenSharerId;
                if (isPresenter && (layout !== prevUserSelectedLayout)) {
                  dispatch(toggleLayout(prevUserSelectedLayout, false));
                  dispatch(setPresenterId(null));
                }
                break;
              }

              logger.debug('Updating room state via knats', { roomState } as any);
              dispatch(updateRoomState(roomState));
            } else {
              logger.error('Did not find expected room in KNRoomState', { diff, roomId });
            }
          }
          break;
        }
        case 'call-hand': {
          if (!body?.userId || typeof body?.userId !== 'string') {
            logInvalidBody('Expected non-empty string body.usrPayload.userId');
            return;
          }

          logger.info('User was called on', { user: body.userId });
          const { user } = state.loginState;
          const userId = user?.userId;
          const calledOnSoundEnabled = state.preferencesState
            .soundPreferences[CCServiceSoundPreferencesEnum.CALLED_ON]?.enabled;
          if (body.userId === userId && calledOnSoundEnabled) soundPlayer.play(SoundFileEnum.CALLED_ON);
          dispatch(pickRaiseHandMessage(body.userId));
          break;
        }
        case 'auth-user-error': {
          logger.info('Session expired');
          dispatch(logout({ redirect: RouteEnum.SESSION_EXPIRED }));
          break;
        }
        case 'room-event': {
          logger.info('room-event', { msg });

          if (!initialRoomStateReceived) dispatch(setInitialRoomStateReceived());

          if (shouldUseLegacyState) {
            // ! KNATS WORKAROUND: We must use the legacy state before the user has joined the room
            const roomState = sanitizeRoomState({ ...convertLegacyRoomStateToKnats(msg.body), roomRoster });
            logger.debug('Updating room state via room-event', { roomState } as any);
            dispatch(updateRoomState(roomState));
          } else {
            //! KNATS WORKAROUND: When the knats pattern was introduced, these fields were not able to be pulled accurately yet
            const legacyIsLocked = !!body?.isLocked;
            const legacyIsLoginRequired = !!body?.requireLogin;
            const legacyKnockers = body?.knockers || {};
            const legacyRecordingStart = body?.recordingStart || 0;

            // the existing state checks aren't necessary but cut down on redux logger noise
            if (legacyIsLocked !== isLocked) dispatch(setIsLocked(legacyIsLocked));
            if (legacyIsLoginRequired !== isLoginRequired) dispatch(setIsLoginRequired(legacyIsLoginRequired));
            if (!isEqual(knockers, legacyKnockers)) dispatch(setKnockers(legacyKnockers));
            if (recordingStart !== legacyRecordingStart) dispatch(setRecordingStart(legacyRecordingStart));
          }
          break;
        }
        case 'room-permissions-update': {
          if (!Array.isArray(body?.roomPermissions)) {
            logInvalidBody('Expected body.roomPermissions array');
            return;
          }
          const { roomPermissions }: { roomPermissions: RoomPermission[] } = msg.body;
          const permissions = {} as RoomPermissions;
          roomPermissions.forEach((item) => {
            permissions[item.permission] = item;
          });

          // if current user is screen sharing, stop share
          batch(() => {
            if (!permissions.screenShare.permissionGranted) {
              const { isScreenCapture } = state.screenShareState;
              if (isScreenCapture && currentUser?.userId !== ownerId) {
                dispatch(endScreenShare(false, 'Screen share permission was revoked'));
                dispatch(setRoomAlert(`${ownerName || 'The owner'} has disabled screen share`));
              }
            }
            logger.info('Room permission have been updated', { permissions } as any);
            dispatch(setRoomPermissions(permissions));
          });
          break;
        }
        case 'owner-broadcast': {
          if ( // body.message may be null
            !body?.type || typeof body?.type !== 'string' || !Array.isArray(body?.users)
          ) {
            logInvalidBody('Received malformed owner broadcast body');
            return;
          }

          logger.info('Received broadcast from owner', { body });

          /** `typedBody` must remain as a single object to get type inference below */
          const typedBody = body as OwnerBroadcastDataUnion;

          // apply owner-broadcast message to current user, if applicable
          const userId = state.loginState.user?.userId;
          if (userId && !typedBody.users.includes(userId)) return;

          switch (typedBody.type) {
            case 'audio':
              dispatch(changeCurrentUsersAudio(typedBody.message));
              break;
            case 'video':
              dispatch(changeCurrentUsersVideo(typedBody.message));
              break;
            case 'ownerJoinAudio':
              /** Check to see if the owner joined audio of the current users breakout group.
               * If so unmute the the owner's player. */
              dispatch(ownerJoinAudio(typedBody.message));
              break;
            default:
              break;
          }
          break;
        }
        case 'call-joinable':
          logger.info('Call is joinable');
          /* sent when call is created after owner joins room:
          indicates that the room is now joinable */
          dispatch(setRoomJoinable(true));
          break;
        case 'room-locked':
          if (!initialRoomStateReceived) {
            dispatch(setInitialRoomStateReceived());
          }
          if (msg?.requireLogin) {
            dispatch(setIsLoginRequired(true));
          }
          logger.info('Room is locked');
          dispatch(setIsLockedFromJoining(true));
          break;
        case 'admitKnocker':
          if (isLockedFromJoining) {
            logger.info('User was admitted after knocking', { body });
            sendWebsocketMessage(ws, 'join');
            dispatch(setPreviouslyAdmitted(true));
            dispatch(setIsLockedFromJoining(false));
          }
          break;
        case 'denyKnocker':
          logger.info('User was denied after knocking', { body });
          dispatch(push(RouteEnum.ROOM_MANAGER__JOIN.encodePath({ urlParams: { roomId }, queryParams: { denied: true } })));
          break;
        case 'kick-user-by-owner': {
          logger.info('Removing user from room', { user: body?.userInfo });
          const roomName = getState().dashboardState.roomSettingsRoomName;
          if (!roomName) {
            logger.warn('Can\'t display RemovedModal with an invalid room name');
          } else {
            dispatch(setRoomNameForRemovedModal(roomName));
          }
          break;
        }
        case 'nudge':
          if (!body?.userId || typeof body.userId !== 'string') {
            logInvalidBody('Expected non-empty body.userId string');
            return;
          }
          if (!body.nudge || typeof body.nudge.message !== 'string'
            || typeof body.nudge.gifId !== 'string' || typeof body.nudge.emoji !== 'string') {
            logInvalidBody('Expected body.nudge to have string message, string gifId, and string emoji');
            return;
          }
          if (body.userId === currentUser?.userId) { // sender will have already activated optimistically on their side
            if (participants[body.nudge.fromUserId]) {
              dispatch(setRoomAlert(`${participants[body.nudge.fromUserId]?.displayName || '(No display name)'} nudged you`));
            }
            dispatch(activateNudge(body.userId, body.nudge));
          }
          break;
        case 'nudge-private': /** @todo dry logic with nudge case if refactored out of this switch statement  */
          if (!body?.from || typeof body.from !== 'string') {
            logInvalidBody('Expected non-empty body.from string');
            return;
          }
          if (!body?.to || typeof body.to !== 'string') {
            logInvalidBody('Expected non-empty body.to string');
            return;
          }
          if (!body.nudge || typeof body.nudge.message !== 'string'
            || typeof body.nudge.gifId !== 'string' || typeof body.nudge.emoji !== 'string') {
            logInvalidBody('Expected body.nudge to have string message, string gifId, and string emoji');
            return;
          }
          if (body.to === currentUser?.userId) { // sender will have already activated optimistically on their side
            if (participants[body.from]) {
              dispatch(setRoomAlert(`${participants[body.from]?.displayName || '(No display name)'} nudged you`));
            }
            dispatch(activateNudge(body.to, body.nudge));
          }
          break;
        case 'nudge-ack':
          if (!body.userId || typeof body.userId !== 'string') {
            logInvalidBody('Expected non-empty body.userId string');
            return;
          }
          // either owner or user can acknowledge the nudge (owner unsends via nudge-ack)
          if (ownerId === currentUser?.userId || currentUser?.userId === body.userId) {
            dispatch(deactivateNudge(body.userId));
          }
          break;
        case 'nudge-ack-private':
          if (!body?.from || typeof body.from !== 'string') {
            logInvalidBody('Expected non-empty body.from string');
            return;
          }
          if (!body?.to || typeof body.to !== 'string') {
            logInvalidBody('Expected non-empty body.to string');
            return;
          }

          dispatch(deactivateNudge(body.to));

          break;
        case 'room-roster': {
          if (shouldUseLegacyState) {
            dispatch(setRoomRoster(convertLegacyRoomRosterToKnats(msg.body)));
          }
          break;
        }
        case 'icebreaker-participant-response':
          logger.debug('Recieved icebreaker response', { reponse: msg.body });
          dispatch(addActiveResult(msg.body));
          break;
        default:
          break;
      }
    } catch (error) {
      logger.warn('Error in websocket handler switch', { error: error as any, msg });
      dispatch(setRoomError(getMessageFromError(error)));
    }
  });

  ws.on('error', (error) => {
    logger.error('Websocket Error', { error });
    dispatch(setRoomError(getMessageFromError(error)));
  });

  ws.on('disconnect', (code, message) => {
    logger.info('Room websocket disconnected', { code, message, codeInfo: getWsCloseCodeInfo(code) });
    dispatch(setWSReady(false));
    prevDisconnectCode = code;
    const { room: { callId } } = getState().roomState;
    switch (code) {
      case WSCloseCodeEnum.INTERNAL_ERROR: // received when owner is not present
        if (callId !== null) {
          dispatch({ type: ROOM_WEBSOCKET_DISCONNECTED });
        }
        break;
      case WSCloseCodeEnum.CHALKCAST_LOGIN_REQUIRED:
        // This should be unexpected fallback behavior that only occurs if a client manages to send a 'join' message
        // over a connection for a room that requires login. FE should be forcing login before this point.
        logger.warn('Received WS closure due to login requirement. Pushing to /');
        dispatch(push(RouteEnum.HOME.encodePath()));
        break;
      default:
        break;
    }
  });

  /**
   * When a user joins the same session from two different browsers/tabs, the user in the
   * first window receives this event, which allows us to kick them out of the room.
   */
  ws.on('leave', () => {
    logger.info('User joined session from a different window triggering websocket leave event');
    dispatch(push(RouteEnum.ROOM_MANAGER__JOIN.encodePath({ urlParams: { roomId } })));
  });

  ws.on('heartbeat-failed', () => {
    logger.warn('Heartbeat failed');
    dispatch(setHeartbeatFailure(true));
  });
};
/**
 * Closes the socket, resets state
 */
export const closeRoomWebsocket = (): AppThunkAction => (dispatch, getState) => {
  const { socket } = getState().roomState;

  // without this if check, causes even larger crash if error occurs
  if (socket) {
    logger.info('Closing websocket with code 1000');

    socket.close(
      1000,
      'Resetting room',
      true, // finalClose true so it does not attempt reconnect
    );

    dispatch({ type: CLOSE_WEBSOCKET });
  }
};

/**
 * Disposes all processes and services in the room, and sets them to null.
 * Resets room state to initial state.
 * Sets joinedRoom ID to null, which kicks the user out of the room.
 */
export const resetRoomState = (debugString: string): AppThunkAction => (dispatch) => {
  batch(() => {
    dispatch(closeRoomWebsocket());
    dispatch(cleanUpAllMedia(true, debugString));
    dispatch(stopChatClient());
    dispatch(setJoinedRoom(null));
    dispatch({ type: RESET_ROOM });
  });
};

interface WebsocketMessages {
  join: undefined,
  knock: undefined,
  'owner-join-unassigned': { status: boolean },
  'nudge-ack': { userId: string, roomId: string },
  'nudge-private': { body: { to: string, from: string, nudge: Nudge } },
  'nudge-ack-private': { body: { to: string, from: string } },
}

/**
 * Sends a message via websocket. Resolves on success or rejects if the websocket is not ready.
 */
export function sendWebsocketMessage<T extends keyof WebsocketMessages>(
  socket: WS,
  action: T,
  ...args: WebsocketMessages[T] extends undefined ? never[] : [addMetadata: WebsocketMessages[T]]
): Promise<string> {
  const [addMetadata] = args;
  return new Promise((res, rej) => {
    logger.info('Sending message via websocket', { action, ...addMetadata } as any);

    const sent = socket.send('chalkcast', { action, kndebug_ws_fe_id: uuidv4(), ...addMetadata });
    if (sent) res(`Websocket: "${action}" message successfully sent`);
    else {
      rej(new Error('Websocket: not ready for sending messages. It could be closed or still initializing. '
        + 'This behavior is expected when first joining a room after restarting the websocket.'));
    }
  });
}

/**
 * Causes the user to the leave the current room session.
 * Tracks event in mixpanel and redirects the user based on their login type.
 * Listed as a redux action in order to unify the logic & any cleanup around leaving a room.
 */
export const leaveRoom = (loginType?: LoginTypesEnum): AppThunkAction => (dispatch, getState) => {
  const {
    roomState: { room: { id: roomId } },
    // encoderState: { isVideoPaused, isAudioMuted },
  } = getState();

  // mixpanel.track('Leave Session', {
  //   Microphone: asOnOff(!isAudioMuted),
  //   Video: asOnOff(!isVideoPaused),
  //   'Leave Type': 'Call End',
  // });

  logger.flush();
  if (loginType === LoginTypesEnum.AUTH_USER) {
    const path = RouteEnum.DASHBOARD.encodePath();
    logger.info('User left the room', { pushTo: path });
    dispatch(push(path));
  } else {
    const newLocation = roomId ? (
      RouteEnum.ROOM_MANAGER__JOIN.encodePath({ urlParams: { roomId } })
    ) : RouteEnum.HOME.encodePath();
    logger.info('User left the room', { pushTo: newLocation });
    dispatch(push(newLocation));
  }
};

/**
 * Expected payloads for the updateRoom function.
 */
export type UpdateRoomNoPayload = 'endcallhand' | 'raisehand' | 'lowerhand';
interface UpdateWithPayload {
  hide: {
    hideAll: boolean,
  },
  mute: {
    muteAll: boolean,
  },
  callhand: {
    userId: string,
  },
}

/**
 * Updates the state of a room.
 *
 * This function can either take 2 parameters or it can take 3. If the second parameter is
 * 'endcallhand' | 'raisehand' | 'lowerhand', it does not expect a payload, but if the 2nd
 * parameter is any other acceptable string, it expects the appropriate payload for that string.
 *
 * Examples:
 * if 'endcallhand' is given for the type, then there must NOT be a 3rd parameter.
 *
 * if 'hide' is given for the type, then the next parameter MUST
 * be of type { hideAll: boolean }
 *
 * See: https://www.typescriptlang.org/docs/handbook/2/functions.html#function-overloads
 */
export function updateRoom(
  roomId: string,
  type: UpdateRoomNoPayload,
): AppThunkAction;
export function updateRoom<Type extends keyof UpdateWithPayload>(
  roomId: string,
  type: Type,
  payload: UpdateWithPayload[Type],
): AppThunkAction
export function updateRoom<Type extends UpdateRoomNoPayload | keyof UpdateWithPayload>(
  roomId: string,
  type: Type,
  payload?: Type extends keyof UpdateWithPayload ? UpdateWithPayload[Type] : never,
): AppThunkAction {
  return async (dispatch) => {
    try {
      const options: AxiosRequestConfig = {
        method: 'POST',
        url: `/rooms/${roomId}/${type}`,
      };
      // if (type === 'raisehand') {
      //   mixpanel.track('Raises Hand');
      // }
      if (payload) options.data = payload;
      await dispatch(roomRequest(options));
    } catch (error) {
      dispatch(handleError('Error updating room', { error }));
      dispatch(setRoomError(getMessageFromError(error)));
    }
  };
}

/**
 * Fetch room permissions and set to state
 */
export const fetchRoomPermissions = (
  roomId: string,
): AppThunkAction => async (dispatch, getState) => {
  const state = getState();
  const { loginType } = state.loginState;
  const isUserValid = [LoginTypesEnum.AUTH_USER, LoginTypesEnum.TEMP_USER].includes(loginType);

  if (!roomId) {
    logger.warn('fetchRoomPermissions: Can\'t fetch room permissions for an invalid roomId', { roomId });
    return;
  }

  if (!isUserValid) {
    logger.warn('fetchRoomPermissions: Can\'t fetch room permissions for an invalid user');
    return;
  }

  dispatch(setPermissionsLoading(true));
  try {
    const { data } = await request<GetRoomPermissions>({ method: 'GET', url: `/rooms/${roomId}/permissions/` });

    const permissions = {} as RoomPermissions;
    (data.permissions as RoomPermission[]).forEach((item) => {
      permissions[item.permission] = item;
    });

    dispatch(setRoomPermissions(permissions));
    if (data.owner) {
      const { user } = getState().loginState;
      const userId = user?.userId || '';
      const displayName = user?.displayName || '';
      dispatch(setOwnerId({ ownerId: userId, ownerName: displayName }));
    }
  } catch (error) {
    dispatch(handleError('Failed to fetch and set room permissions to state', { error }));
  } finally {
    dispatch(setPermissionsLoading(false));
  }
};

interface RoomPermissionUpdate {
  permissionId: number,
  permissionGranted: boolean
}

export const toggleRoomPermission = (
  permissionName: RoomPermissionName,
  roomId: string,
  _trackingSource: 'Room Settings' | 'Session',
): AppThunkAction => (dispatch, getState) => {
  const { roomState: { permissions } } = getState();

  const permission = permissions[permissionName] || DEFAULT_ROOM_PERMISSIONS[permissionName];

  if (!(permission)) {
    logger.warn('Permission name does not exist in permissions or default permissions', {
      permissionName,
      permissions,
      DEFAULT_ROOM_PERMISSIONS,
    } as any);
    return;
  }

  const { permissionId, permissionGranted } = permission;
  const permissionsUpdate = {
    permissionId,
    permissionGranted: !permissionGranted,
  };

  // const newPermissions = {
  //   ...permissions,
  //   [permissionName]: permissionsUpdate,
  // };

  // mixpanel.track('Guest Feature Change', {
  //   Source: trackingSource,
  //   'Screen Share': asOnOff(!!newPermissions.screenShare.permissionGranted),
  //   'Chat with Everyone': asOnOff(!!newPermissions.broadcastMessage.permissionGranted),
  //   'Direct Message': asOnOff(!!newPermissions.directMessage.permissionGranted),
  //   'Private Nudge': asOnOff(!!newPermissions.privateNudge.permissionGranted),
  // });

  dispatch(updateRoomPermissions(permissionsUpdate, permissionName, roomId));
};

/**
 * Sends PUT request to save room permissions
 */
export const updateRoomPermissions = (
  permissionsUpdate: RoomPermissionUpdate,
  permissionName: RoomPermissionName,
  roomId: string,
): AppThunkAction => async (dispatch, getState) => {
  logger.info('Updating room permissions', { permissionsUpdate, permissionName } as any);

  const { permissions: prevPermissions, permissionsUpdating } = getState().roomState;

  if (permissionsUpdating) {
    logger.warn('updateRoomPermissions: Can\'t update a room permission while an update is already being processed');
    return;
  }

  const nextPermissions = {
    ...prevPermissions,
    [permissionName]: {
      ...(prevPermissions[permissionName] || DEFAULT_ROOM_PERMISSIONS[permissionName]),
      permissionGranted: permissionsUpdate.permissionGranted ? 1 : 0,
    },
  };

  const ccServicePermissions = (Object.keys(nextPermissions) as RoomPermissionName[])
    .reduce((result: RoomPermissionUpdate[], name) => {
      const { permissionId, permissionGranted } = nextPermissions[name];
      result.push({ permissionId, permissionGranted: !!permissionGranted });
      return result;
    }, []);

  try {
    batch(() => {
      // assume the request will succeed
      dispatch(setRoomPermissions(nextPermissions));
      dispatch(setRoomPermissionsUpdating(true));
    });
    await dispatch(roomRequest({
      method: 'PUT',
      url: `/rooms/${roomId}/permissions`,
      data: { permissions: ccServicePermissions },
    }));
  } catch (error) {
    batch(() => {
      dispatch(handleError('Error updating permissions', { error }));
      dispatch(setRoomPermissions(prevPermissions));
      dispatch(setRoomError(getMessageFromError(error)));
    });
  } finally {
    dispatch(setRoomPermissionsUpdating(false));
  }
};

export const emptyRoomToken: LivelyRoomToken = {
  data: { displayName: '' },
  expire: '',
  scopes: ['conference-participant', 'chat'],
  token: '',
};

/**
 * Fetch a room-specific token for the user.
 * The user must be a valid user to fetch a room token (i.e. not ANON_USER)
 * The room token is not persisted, and is reset along with the room state,
 * so there is little danger of getting a stale room token value on the Join.js page.
 *
 * Requires a JWT in the headers.
*/
export const fetchLivelyRoomToken = (roomId: string): AppThunkAction<Promise<boolean>> => async (dispatch, getState) => {
  const state = getState();
  const { token } = state.loginState;

  if (!token) {
    logger.warn('Can\'t fetch a lively room token without a valid JWT');
    return false;
  }

  logger.info('Fetching livelyRoomToken');

  dispatch(setLivelyRoomToken(emptyRoomToken));
  try {
    const res = await request<FetchLivelyTokenResponse>({
      method: 'GET',
      url: `/rooms/${roomId}/token`,
    });
    if (typeof res?.data?.livelyToken?.token === 'string') {
      logger.info('Setting livelyRoomToken', { livelyRoomToken: res.data.livelyToken } as any);
      dispatch(setLivelyRoomToken(res.data.livelyToken));
      return true;
    }
    dispatch(setJoinError('Room token was not received (bad response)'));
    logger.error('Room token response data.livelyToken.token was not received or is wrong type (expected: string).');
  } catch (error) {
    let mess = 'Error fetching room token';
    const statusCode = getStatusCodeFromError(error);
    if (statusCode && statusCode >= 400) {
      // These errors happen if the user's token is expired or their temp account was deleted
      // We will have to clear their account from local storage and make them start over to
      // create a new temp user account
      mess = 'Logging user out because of 4xx error fetching room token';
      dispatch(logout({ redirect: RouteEnum.SESSION_EXPIRED }));
    }

    logger.error(mess, { error, statusCode, message: getMessageFromError(error) } as any);
    dispatch(setJoinError(`${mess}. ${getMessageFromError(error)}`));
  }
  return false;
};

export const fetchRoomName = (roomId: string): AppThunkAction => async (dispatch) => {
  logger.info('Fetching room name');
  try {
    const { data } = await request<FetchRoomNameResponse>({
      method: 'GET',
      url: `/rooms/${roomId}/name`,
    });
    logger.info('Setting room name', { roomName: data?.data?.name || '' });
    dispatch(setRoomSettingsRoomName(data?.data?.name || ''));
  } catch (error) {
    if (getStatusCodeFromError(error) === 404) {
      dispatch(setRedirectAfterLoginPath(''));
      dispatch(push(RouteEnum.HOME.encodePath()));
    }
    dispatch(handleError('Error fetching room name', { error }));
  }
};

/** Sends WS message to toggle room  */
export const toggleOwnerJoinUnassignedGroup = (join: boolean): AppThunkAction => (_, getState) => {
  const { roomState: { room: { id: roomId }, ownerId, socket }, loginState: { user } } = getState();
  const currentUserId = user?.userId;

  if (!currentUserId || !ownerId || !roomId || !socket || currentUserId !== ownerId) {
    logger.warn('Invalid state to request owner join/leave unassigned breakout group', {
      roomId, currentUserId, ownerId, wsPresent: !!socket,
    });
    return;
  }

  logger.info(`Attempting to ${join ? 'join' : 'leave'} unassigned group as owner`);

  sendWebsocketMessage(socket, 'owner-join-unassigned', { status: join });
};

/** Returns true request completed successfully, for optimistic toggling */
export const toggleLockRoom = (roomId: string, lock: boolean): AppThunkAction<Promise<boolean>> => async (dispatch, getState): Promise<boolean> => {
  const { roomState: { isRoomLockLoading } } = getState();
  if (isRoomLockLoading) return false;

  if (!roomId || typeof roomId !== 'string') {
    logger.warn('toggleLockRoom called with invalid room ID', { roomId, lock });
    return false;
  }

  logger.info(`Sending request to ${lock ? 'lock' : 'unlock'}`);

  dispatch(setIsRoomLockLoading(true));

  let success = true;
  try {
    await dispatch(roomRequest({
      method: lock ? 'PUT' : 'DELETE',
      url: `/rooms/${roomId}/lock`,
    }));
  } catch (error) {
    const message = `Error ${lock ? 'locking' : 'unlocking'} room`;
    dispatch(handleError(message, { error }));
    dispatch(setRoomError(`${message}. ${getMessageFromError(error)}`));
    success = false;
  } finally {
    dispatch(setIsRoomLockLoading(false));
  }

  logger.info(`Successfully ${lock ? 'locked' : 'unlocked'} room`);

  return success;
};

/** Returns true request completed successfully, for optimistic toggling */
export const toggleRequireLoginForRoom = (roomId: string, loginRequired: boolean): AppThunkAction => async (dispatch, getState): Promise<boolean> => {
  const { roomState: { isRoomLockLoading } } = getState();
  if (isRoomLockLoading) return false;

  if (!roomId || typeof roomId !== 'string') {
    logger.warn('toggleRequireLoginForRoom called with invalid room ID', { roomId, lock: loginRequired });
    return false;
  }

  logger.info(`Sending request to ${loginRequired ? 'set' : 'remove'} login requirement for room`);

  dispatch(setIsRequireLoginLoading(true));

  let success = true;
  try {
    await dispatch(roomRequest({
      method: loginRequired ? 'PUT' : 'DELETE',
      url: `/rooms/${roomId}/require-login`,
    }));
  } catch (error: any) {
    const message = `Error ${loginRequired ? 'setting' : 'removing'} login requirement for room`;
    dispatch(handleError(message, { error }));
    dispatch(setRoomError(`${message}: ${error.message}`));
    success = false;
  } finally {
    dispatch(setIsRequireLoginLoading(false));
  }

  logger.info(`Successfully ${loginRequired ? 'set' : 'removed'} login requirement for room`);

  return success;
};

/** Ways owner can respond to a knocker */
export type KnockAnswer = 'admit' | 'deny'

/** Returns true request completed successfully, for optimistic toggling */
export const answerKnockers = (roomId: string, userIds: string[], action: KnockAnswer): AppThunkAction => async (dispatch) => {
  logger.info('Sending answer to knockers', { userIds, action });
  try {
    await dispatch(roomRequest({
      method: 'POST',
      url: `/rooms/${roomId}/${action}`,
      data: { knockers: userIds },
    }));
    logger.info('Successfully answered knockers', { userIds, action });
  } catch (error) {
    const message = `Could not ${action} user(s) to room`;
    dispatch(handleError(message, { error }));
    dispatch(setRoomError(`${message}. ${getMessageFromError(error)}`));
    return false;
  }
  return true;
};

export const removeParticipant = (participant: StoredKNParticipant): AppThunkAction<Promise<void>> => async (dispatch, getState) => {
  const roomId = getState().roomState.room.id;
  if (!roomId) {
    logger.warn('Cannot remove participant with an invalid roomId');
    return;
  }

  if (!participant) {
    logger.warn('Cannot remove invalid participant');
    return;
  }

  const { userId } = participant;

  logger.info('Sending request to remove participant', { userId });

  try {
    // lock the room, regardless of whether removing the participant succeeds
    dispatch(toggleLockRoom(roomId, true));
    dispatch(selectConversation(CONVO_ID_NOT_SELECTED));
    await dispatch(roomRequest({
      method: 'POST',
      url: `rooms/${roomId}/kick-user`,
      data: { userId },
    }));

    logger.info('Successfully removed participant', { userId });

    dispatch(setRoomNotification({
      component: NotificationComponentEnum.REMOVE_PARTICIPANT,
      props: {
        displayName: participant?.displayName || 'The participant',
      },
    }));
  } catch (error) {
    const message = 'Error removing participant';
    batch(() => {
      logger.error(message, { error: error as any, userId });
      dispatch(setRoomError(`${message}. ${getMessageFromError(error)}`));
    });
  }
};

export const trackLeaveSessionOnClose = (): AppThunkAction => (_, _getState) => {
  // const { encoderState: { isVideoPaused, isAudioMuted } } = getState();

  // mixpanel.track('Leave Session', {
  //   'Leave Type': 'Close Tab',
  //   Microphone: asOnOff(!isAudioMuted),
  //   Video: asOnOff(!isVideoPaused),
  // });
};

/** Returns true if request completed successfully for optimistic toggling */
export const toggleChaperoneRequired = (
  roomId: string,
  newChaperoneRequiredValue: boolean,
): AppThunkAction<Promise<boolean>> => async (dispatch, getState): Promise<boolean> => {
  const {
    roomState: {
      isChaperoneUpdateLoading,
      livelyRoomToken: { token: livelyToken },
      initialRoomStateReceived,
      room: { openroom },
    },
  } = getState();

  let success = false;

  if (isChaperoneUpdateLoading) return success;

  if (!roomId || typeof roomId !== 'string') {
    logger.warn('toggleChaperoneRequired called with invalid room ID', { roomId, newChaperoneRequiredValue });
    return success;
  }

  if (!initialRoomStateReceived) {
    logger.warn('toggleChaperoneRequired called before initial room state was received', { roomId, initialRoomStateReceived });
    return success;
  }

  if (!livelyToken) {
    logger.warn('toggleChaperoneRequired called with invalid livelyRoomToken', { roomId, livelyRoomToken: livelyToken });
    return success;
  }

  logger.info(`Sending request to turn chaperoneRequired status to ${newChaperoneRequiredValue ? 'on' : 'off'}`);

  dispatch(setChaperoneLoading(true));

  try {
    const method = openroom ? 'DELETE' : 'PUT';
    const resp = await dispatch(roomRequest({
      method,
      url: `/rooms/${roomId}/openroom`,
    }));

    // can be null if cc-service is down
    if (!resp) {
      dispatch(setChaperoneLoading(false));
      return success;
    }

    success = true;
    batch(() => {
      dispatch(setOpenRoom(!newChaperoneRequiredValue));
      dispatch(setChaperoneLoading(false));
    });
  } catch (error) {
    const message = `Error setting chaperoneRequired status to ${newChaperoneRequiredValue ? 'on' : 'off'}`;
    batch(() => {
      dispatch(handleError(message, { error }));
      dispatch(setRoomError(`${message}. ${getMessageFromError(error)}`));
      dispatch(setChaperoneLoading(false));
    });
  }

  logger.info(`Successfully set chaperoneRequired status to ${newChaperoneRequiredValue ? 'on' : 'off'}`);
  return success;
};
