import { createSelector } from 'reselect';
import {
  ConversationId, LivelyChatMessage, StoreState, CONVO_ID_EVERYONE, CHAT_DM_PREFIX, CHAT_BG_PREFIX,
} from 'store/types';
import dropDuplicates from 'utils/dropDuplicates';

export const getIsChatVisible = (state: StoreState) => state.chatState.isChatVisible;

export const getChatStateEmojis = (state: StoreState) => state.chatState.emojis;

export const getChatMessages = (state: StoreState) => state.chatState.messages;

export const getChatClient = (state: StoreState) => state.chatState.chatClient;

export const getSelectedConversationId = (state: StoreState) => state.chatState.selectedConversationId;

export const getChatInput = (state: StoreState) => state.chatState.chatInput;

export const getChatShowEmojis = (state: StoreState) => state.chatState.showEmojis;

export const getChatParsedLimitReached = (state: StoreState) => state.chatState.parsedLimitReached;

export const getChatSubmitDisabled = (state: StoreState) => state.chatState.submitDisabled;

export const getUnseenMessages = (state: StoreState) => state.chatState.unseenMessages;

export const getRecentMessage = (state: StoreState) => state.chatState.recentMessage;

/**
 * returns all messages for a conversation
 * @param {string} convoId
 */
export const getConversationMessages = (convoId: ConversationId) => createSelector(
  getChatMessages,
  (messages): LivelyChatMessage[] => {
    let conversationMessages = [];
    if (convoId === CONVO_ID_EVERYONE) {
      conversationMessages = messages.filter((msg) => !msg.whisper
        && !(msg.metadata && msg.metadata.breakoutRoomId));
    } else {
      // get whispers from the other user and the user's whispers to them
      conversationMessages = messages.filter(
        (msg) => {
          if (!msg.metadata) return false;
          if (`${CHAT_DM_PREFIX}${msg.metadata.senderUserId}` === convoId) return true;
          if (`${CHAT_DM_PREFIX}${msg.metadata.recipientUserId}` === convoId) return true;
          if (`${CHAT_BG_PREFIX}${msg.metadata.breakoutRoomId}` === convoId) return true;
          return false;
        },
      );
    }
    return dropDuplicates(conversationMessages, { mapping: (msg) => msg?.id });
  },
);

/**
 * Returns the most recent message from the given userId
 * If fromSide is true, returns most recent message from any user
 */
export const getRecentMessageFromUser = (userId: string | null, fromSide: boolean) => createSelector(
  getChatMessages,
  (messages) => {
    if (fromSide) {
      return messages.length ? messages.slice(-1)[0] : null;
    }
    let recentMessage = null;
    if (messages.length) {
      const lastIndex = messages.length - 1;
      for (let i = lastIndex; i >= 0; i--) {
        if (messages[i].actor.id === userId) {
          recentMessage = messages[i];
          break;
        }
      }
    }
    return recentMessage;
  },
);
