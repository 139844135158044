/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import { useSelector } from 'react-redux';
import { getEncoderRenderId } from 'selectors';
import { EncoderRenderIdsEnum } from 'store/types';
import RegularVideo from './RegularVideo';

interface FilterVideoConditionalProps {
  encoderRenderId: EncoderRenderIdsEnum,
}

/** Renders the Regular, unfiltered encoder video if the current encoderRenderId matches this component's encoderRenderId */
export default function RegularVideoConditional({ encoderRenderId }: FilterVideoConditionalProps) {
  const selectedEncoderRenderId = useSelector(getEncoderRenderId);
  return selectedEncoderRenderId === encoderRenderId ? <RegularVideo /> : null;
}
