import { OwnerStatusEnum } from 'hooks/useIsOwner';
import { createContext, useContext } from 'react';

const RoomManagerContext = createContext<{ ownerStatus: OwnerStatusEnum, roomId: string }>({ ownerStatus: OwnerStatusEnum.UNKNOWN, roomId: '' });

export const RoomManagerProvider = RoomManagerContext.Provider;

export const useRoomManagerContext = () => {
  const ctx = useContext(RoomManagerContext);

  if (ctx === undefined) throw new Error('useRoomManagerContext called outside of OwnerStatusContext provider');

  return ctx;
};
