/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import useStateIfMounted from 'hooks/useStateIfMounted';
import {
  Component, ReactNode, useEffect,
} from 'react';
import logger from 'utils/logger';

interface Props {
  children?: ReactNode;
  fallback?: ReactNode;
}

interface State {
  error: unknown;
}

/**
 * Generic component for rendering fallback components on error
 */
class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error: unknown) {
    return { error };
  }

  componentDidCatch(error: unknown) {
    this.setState({ error });
    logger.error('ErrorBoundary', { error } as any);
  }

  render() {
    const { state, props } = this;
    if (state.error) {
      return props.fallback || <div />;
    }
    return props.children;
  }
}

const INITIAL_ERROR_STATE = Symbol('Initial error state');

/**
 * Wraps error logging around functions in components
 * @param {string} componentName
 * @param {function} functionToBeHandled
 */
const useHandleError = <F extends (...args: any) => any>(componentName: string, functionToBeHandled: F) => {
  const [error, setError] = useStateIfMounted<unknown>(INITIAL_ERROR_STATE);

  const errorHandledFunction = (...fnArgs: Parameters<F>): (ReturnType<F> | undefined) => {
    try {
      return functionToBeHandled(...fnArgs);
    } catch (e) {
      logger.warn('Error handler', { error: e } as any);
      setError(e);
      return undefined;
    }
  };

  useEffect(() => {
    if (error !== INITIAL_ERROR_STATE) {
      const functionName = functionToBeHandled.name || 'anonymous function';
      if (typeof error === 'string') throw new Error(`[ChalkCast]: ${error} in ${functionName} at ${componentName}.`);
      else if (error instanceof Error) throw new Error(`[ChalkCast]: ${error.name} ${error.message} in ${functionName} at ${componentName}.`);
      else throw new Error(`[ChalkCast]: Error in ${functionName} at ${componentName}.`);
    }
  }, [componentName, error, functionToBeHandled]);

  return errorHandledFunction;
};

export default ErrorBoundary;
export { useHandleError };
