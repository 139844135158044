import { ErrorMessageState } from 'store/types';
import { MakeActionType } from 'utils/typeUtils';

export const reducerName = 'errorMessageReducer' as const;
export const RESET_ERROR_MESSAGE = `${reducerName}/RESET_ERROR_MESSAGE` as const;
export const SET_INCOMPATIBLE_ERROR = `${reducerName}/SET_INCOMPATIBLE_ERROR` as const;

/**
 * Reset the error back to default empty string
 */
export const resetErrorMessage = (type: keyof ErrorMessageState = 'errorPage') => ({
  type: RESET_ERROR_MESSAGE,
  payload: {
    type,
  },
});

export const setIncompatibleError = (incompatibleError:string) => ({
  type: SET_INCOMPATIBLE_ERROR,
  payload: {
    incompatibleError,
  },
});

export type ErrorAction = MakeActionType<[
  typeof resetErrorMessage,
  typeof setIncompatibleError,
]>
