import logger from 'utils/logger';

/**
 * Condenses consecutive spaces into a single space.
 */
export const fixMultiWhitespace = (value: string) => value && value.replace(/\s\s+/g, ' ');

/**
 * If any spaces, returns the first name.
 * If no spaces, returns the original name.
 */
export const getFirstName = (displayName: string) => (displayName ? (fixMultiWhitespace(displayName).trim().split(' ').shift() || '') : '');

/**
 * If any spaces, returns the last name.
 * If no spaces, returns the original name.
 */
export const getLastName = (displayName: string) => (displayName ? (fixMultiWhitespace(displayName).trim().split(' ').pop() || '') : '');

export const hasLastName = (displayName: string) => !!displayName && (fixMultiWhitespace(displayName).trim().split(' ').length > 1);

/**
 * Formats "Firstname Lastname" as "Lastname, Firstname"
 * "Firstname MiddleName1 MiddleName2 Lastname" --> "Lastname, Firstname MiddleName1 MiddleName2"
 */
export const formatLastnameFirstname = (displayName: string) => {
  displayName = fixMultiWhitespace(displayName).trim();

  if (!displayName) {
    logger.warn('Trying to format displayName as lastName, firstName, but displayName is not defined', { displayName });
  }

  if (!hasLastName(displayName)) return displayName;
  const splitName = displayName.split(' ');
  return `${splitName.pop()}, ${splitName.join(' ')}`;
};

/** If firstName & lastName are both defined, then use those, else default
 * back to formatting the displayName as if it was lastName, firstName */
export const formatNameData = (firstName: string, lastName: string, displayName: string) => {
  if (firstName && lastName) {
    return `${lastName}, ${firstName}`;
  }
  return formatLastnameFirstname(displayName);
};

/**
 * Generates a character code for A-Z
 */
const makeRandomLetterCharCode = () => Math.floor(Math.random() * 26) + 65;

export const getRandomLetterString = (length: number) => String.fromCharCode(...new Array(length).fill(null).map(() => makeRandomLetterCharCode()));

export const formatTextAsId = (label: string) => fixMultiWhitespace(label)
  .trim().toLowerCase().split(' ')
  .join('-');
