export const DISPLAY_NAME_MAX_LEN = 50;

export const ERROR_REQUIRED = 'This field is required';
export const ERROR_MAX_LENGTH = `${DISPLAY_NAME_MAX_LEN} character limit reached`;

/**
 * Validates display name and returns any error messages, if any.
 */
export default function validateDisplayName(displayName: string, required = true) {
  let errorMessage = '';
  if (required && displayName.trim().length === 0) {
    errorMessage = ERROR_REQUIRED;
  } else if (displayName.length > DISPLAY_NAME_MAX_LEN) {
    errorMessage = ERROR_MAX_LENGTH;
  }
  return errorMessage;
}
