export type WithUnknownString<T = string> = T | 'UNKNOWN'

/**
 *  An abstract class meant to allow for more a more advanced enum
 *  feature than the normal TS enum.
 *
 *  Inherit this class and use a protected constructor to define the type,
 *  adding members as static variables.
 *
 *
 *  Providing types for asArray and asObject is a little hacky (see example below).
 *
 *  @example
 *  interface MyType {
 *    keyname: string;
 *    name: string;
 *  }
 *
 *  class MyEnum extends EnumBase implements MyType {
 *    public static readonly MEMBER_A = new MyEnum('A')
 *
 *    public static readonly MEMBER_B = new MyEnum('B')
 *
 *    protected constructor(public keyname: string, public name: string) { super(keyname); }
 *
 *    public static asArray(): Array<MyType> {
 *      return super.toArray() as Array<MyType>;
 *    }
 *
 *    public static asObject(): { [key: string]: MyType } {
 *      return super.toObject() as { [key: string]: MyType };
 *    }
 *  }
 *
 */
export abstract class EnumBase {
  /**
   * Get an array of the enum's members.
   *
   * When inheriting EnumBase, it's a bit of a hack,
   * but you should override this method to typecast the
   * array to the specific type you have enumerated.
   *
   * @returns Array<EnumBase>
   */
  public static asArray(): Array<EnumBase> {
    return Object.values(this).filter((member) => member instanceof EnumBase);
  }

  /**
   * Get a plain object of the enum,
   * with the member name being the key and the member being the value
   *
   * When inheriting EnumBase, it's a bit of a hack,
   * but you should override this method to typecast the
   * array to the specific type you have enumerated.
   *
   * @returns { [key: string]: EnumBase }
   */
  public static asObject(): { [key: string]: EnumBase | undefined } {
    return Object.entries(this)
      .reduce(
        (obj, [key, value]) => (value instanceof EnumBase ? ({ ...obj, [key]: value }) : obj),
        {} as { [key: string]: EnumBase | undefined },
      );
  }
}
