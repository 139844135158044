import {
  AppThunkAction, NotificationMessage, NotificationType,
} from 'store/types';
import { MakeActionType } from 'utils/typeUtils';

export const reducerName = 'notificationReducer' as const;
export const SET_NOTIFICATION = `${reducerName}/SET_NOTIFICATION` as const;
export const REMOVE_INVITE_LINK_NOTIFICATION = `${reducerName}/REMOVE_INVITE_LINK_NOTIFICATION` as const;

/**
 * When called without a message, it resets the message to it's initial state.
 */
export const setNotification = (type: NotificationType, message?: NotificationMessage) => ({
  type: SET_NOTIFICATION,
  payload: {
    type,
    message,
  },
});

export type NotificationAction = MakeActionType<[
  typeof setNotification,
  typeof REMOVE_INVITE_LINK_NOTIFICATION
]>

/**
 * Exported for more convenient use in roomActions.
 */
export const setRoomNotification = (message: NotificationMessage): AppThunkAction => (dispatch) => {
  dispatch(setNotification('room', message));
};

export const removeInviteLinkNotification = () => ({
  type: REMOVE_INVITE_LINK_NOTIFICATION,
});
