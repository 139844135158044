/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import { allViewFetchPrevSessions, resetAttendanceState } from 'actions/attendanceActions';
import ErrorRetry from 'components/ErrorRetry/ErrorRetry';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CSS } from 'types/css';
import { useRoomManagerContext } from 'views/RoomManager/roomManagerContext';

const containerStyles: CSS = {
  width: '100%',
  minHeight: '60rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export default function AttendanceAllViewError() {
  const dispatch = useDispatch();
  const { roomId } = useRoomManagerContext();
  const retry = useCallback(() => {
    dispatch(resetAttendanceState());
    dispatch(allViewFetchPrevSessions(roomId));
  }, [dispatch, roomId]);

  return (
    <div sx={containerStyles}>
      <ErrorRetry onClick={retry} />
    </div>
  );
}
