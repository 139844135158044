import { AlertAction, SET_ALERT } from 'actions/alertActions';
import { RESET_ROOM, SharedAction } from 'actions/sharedActions';
import produce from 'immer';
import { AlertState } from 'store/types';

export const initialState: AlertState = {
  room: '',
  join: '',
};

const reducer = produce((draft: AlertState, action: AlertAction | SharedAction) => {
  switch (action.type) {
    case SET_ALERT:
      draft[action.payload.type] = action.payload.message ?? initialState[action.payload.type];
      break;
    case RESET_ROOM:
      draft.room = initialState.room;
      break;
  }
}, initialState);

export default reducer;
