import { createSelector } from 'reselect';
import { StoreState, LaunchedGroup } from 'store/types';

export const getRecallTimer = (state: StoreState) => state.breakoutGroupsState.recallTimer;

export const getJoinAudioId = (state: StoreState) => state.breakoutGroupsState.joinAudioId;

export const getLaunchComplete = (state: StoreState) => state.breakoutGroupsState.launchComplete;

export const getBreakoutLoadingMessage = (state: StoreState) => state.breakoutGroupsState.loadingMessage;

export const getBreakoutLoading = (state: StoreState) => state.breakoutGroupsState.loading;

export const getRecentGroupsLoading = (state: StoreState) => state.breakoutGroupsState.recentGroupsLoading;

export const getIsGroupsVisible = (state: StoreState) => state.breakoutGroupsState.isGroupsVisible;

export const getGroupsType = (state: StoreState) => state.breakoutGroupsState.groupSelectionType;

export const getGroupSettings = (state: StoreState) => state.breakoutGroupsState.settings;

export const getIsLaunched = (state: StoreState) => state.breakoutGroupsState.isLaunched;

export const getGroups = (state: StoreState) => state.breakoutGroupsState.groups;

export const getGroupNames = (state: StoreState) => state.breakoutGroupsState.groupNames;

export const getIsRecallingGroups = (state: StoreState) => state.breakoutGroupsState.isRecallingGroups;

export const getLaunchedGroupIds = (
  state: StoreState,
) => Object.keys(state.breakoutGroupsState.launchedGroups);

export const getLaunchedGroups = (state: StoreState) => state.breakoutGroupsState.launchedGroups;

export const getLaunchedGroupById = (groupId: string) => createSelector(
  getLaunchedGroups,
  (launchedGroups): LaunchedGroup | null => launchedGroups[groupId] ?? null,
);

export const getGroupNamesById = (id: string) => createSelector(
  getGroupNames,
  (names) => names[id],
);

export const getDraftGroupsCount = createSelector(
  getGroups,
  (groups) => Object.keys(groups).length,
);

export const getJoinAudioGroup = createSelector(
  getJoinAudioId,
  getLaunchedGroups,
  (joinAudioId, launchedGroups) => launchedGroups[joinAudioId || ''] ?? null,
);
