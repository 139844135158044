import { LaunchedGroups } from 'store/types';

export const BG_NAME_CHAR_MAX = 35;

/**
 * Given launched groups and at least 2 user IDs, return boolean
 * for whether all those users are in the same group
 *
 * Note: If you're trying to spread userIds as a string aray into the second argument and are getting a type error, like so...:
 * ```getAreUsersInSameGroup(launchedGroups, ...userIds)```
 * , the two main things to look for are:
 * 1. Does the array definitely have at least two IDs?
 * 2. ^ If so, you may need to make sure the array is a const type, with "as const"
 */
export const getAreUsersInSameGroup = (launchedGroups: LaunchedGroups, ...userIds: [string, string, ...string[]]): boolean => {
  const groups = Object.values(launchedGroups);
  for (let i = 0; i < groups.length; i++) {
    const groupParticipants = groups[i].users;

    let nUsersFound = 0;
    const userFoundMap = new Map<string, boolean>();
    for (let j = 0; j < groupParticipants.length; j++) {
      const groupUserId = groupParticipants[j];
      if (!userFoundMap.get(groupUserId) && userIds.includes(groupUserId)) {
        userFoundMap.set(groupUserId, true);
        nUsersFound += 1;
      }
      // return here allows bailing out of loops if confirmed early
      if (nUsersFound === userIds.length) {
        return true;
      }
    }
  }
  return false;
};

/**
 * Returns the groupId of a user's breakout group
 * Returns null if no group
 */
export function findUserGroupId(launchedGroups: LaunchedGroups, userId: string): string | null {
  let groupId = null;

  Object.keys(launchedGroups).forEach((id) => {
    const groupParticipants = launchedGroups[id].users;
    if (groupParticipants.includes(userId)) {
      groupId = id;
    }
  });

  return groupId;
}
