/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import SignupForm from 'components/AuthForms/SignupForm';
import AuthFormWrapper from 'components/AuthFormWrapper/AuthFormWrapper';
import { LoginProvider } from 'hooks/useLoginContext';
// import { useEffect } from 'react';
// import mixpanel from 'utils/mixpanel';

function SignUp() {
  // useEffect(() => {
  //   mixpanel.trackPageView('Sign Up');
  // }, []);

  return (
    <LoginProvider>
      <AuthFormWrapper title="Welcome to ChalkCast!">
        <SignupForm />
      </AuthFormWrapper>
    </LoginProvider>
  );
}

export default SignUp;
