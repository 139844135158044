import { useEffect } from 'react';
import logger from 'utils/logger';
import useDetectBrowser from './useDetectBrowser';

function useDetectPageRefresh() {
  const browser = useDetectBrowser();

  useEffect(() => {
    /**
     * Detects if the user refreshes the page and logs to kibana
     * Page refreshes generally mean something has gone wrong and
     * the user is trying to fix it. We can use the number of refreshes
     * as a metric to know how buggy ChalkCast is.
     *
     * The goal is for there to be no refreshes
     *
     * This uses an experimental feature which is not supported by safari
     */
    if (window.performance && browser && browser.name !== 'safari') {
      const perfEntries = performance.getEntriesByType('navigation');
      const types = perfEntries.map((nav) => (nav as PerformanceNavigationTiming).type);

      if (types[0] === 'reload') {
        logger.info('The user refreshed the page');
      }
    }
  }, []);
}

export default useDetectPageRefresh;
