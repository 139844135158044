/**
 * Types of Grafana Dashboards that ChalkCast can link to.
 * We will probably be adding more than just the single dashboard to this list,
 * so keep as enum for now.
 */
export enum GrafanaDashboardsEnum {
  PRODUCER_CONSUMER_STATS = 'two-user-producer-consumer-stats',
}

/**
 * These are the query parameters are supported by all Grafana dashboards.
 */
export interface SharedQueryParams {
  orgId: '1',
  refresh: string,
  from: string,
  to: string,
}

/**
 * These are the query parameters are specific to each Grafana dashboard.
 */
export interface DashboardUniqueParams {
  [GrafanaDashboardsEnum.PRODUCER_CONSUMER_STATS]: {
    'var-streamId': string,
     'var-producingUserId': string,
     'var-producingDisplayName': string,
     'var-consumingUserId': string,
     'var-consumingDisplayName': string,
     'var-callId': string,
  }
}

/**
 * These are the combined params that each dashboard accepts.
 */
export type GrafanaDashboardParams<D extends GrafanaDashboardsEnum> = {
  [key in keyof Partial<SharedQueryParams & DashboardUniqueParams[D]>]: string;
}

/**
 * Param-specific arguments that `formatGrafanaLink` accepts for each dashboard,
 * formatted into normal JavaScript-style variables for ease of use.
 * Also differentiates between required and optional parameters.
 */
export interface GrafanaLinkOptionParamProps {
  [GrafanaDashboardsEnum.PRODUCER_CONSUMER_STATS]: {
    roomId: string,
    producingDisplayName: string,
    producingUserId?: string,
    consumingUserId?: string,
    consumingDisplayName?: string,
    refresh?: string,
    callId?: string,
    from?: string,
    to?: string,
  }
}

/**
 * All arguments that `formatGrafanaLink` accepts,
 * depending on which dashboard is being linked to.
 */
export type GrafanaLinkOptions<D extends GrafanaDashboardsEnum> = {
  includeFromTo?: boolean,
  includeRefresh?: boolean,
} & GrafanaLinkOptionParamProps[D];

/**
 * The UID that is associated with each Grafana dashboard.
 * These should not change very frequently.
 */
const grafanaDashboardUIDs = <const>{
  [GrafanaDashboardsEnum.PRODUCER_CONSUMER_STATS]: 'iI0zNCndz',
};

/**
 * Limits search params to acceptable query parameter combinations, depending on the Grafana dashboard.
 */
const formatSearchParams = <
  D extends GrafanaDashboardsEnum
>(searchParamsObject: GrafanaDashboardParams<D>) => (
    new URLSearchParams(searchParamsObject).toString()
  );

/* default display date range in Grafana is 24h, which causes pretty
significant load times (~1 minute) or 502 errors from query timeouts.
Limit the default link to the last hour by default */
export const DEFAULT_FROM = 'now-30m';
export const DEFAULT_TO = 'now';
export const DEFAULT_REFRESH = '30s';
export const ALL = 'All';
export const ORG_ID = '1';

/**
 * Creates a link to one of the Grafana Dashboards from the CC app.
 */
export default function formatGrafanaLink<D extends GrafanaDashboardsEnum>(
  dashboard: D,
  options: GrafanaLinkOptions<D>,
) {
  const grafanaBaseUrl = appConfig.grafana;
  switch (dashboard) {
    case GrafanaDashboardsEnum.PRODUCER_CONSUMER_STATS: {
      // TypeScript should be able to infer this options type, but it doesn't (yet):
      const {
        includeRefresh = true, includeFromTo = true, roomId, from, to, consumingUserId = ALL,
        producingUserId = ALL, callId = ALL, refresh, consumingDisplayName = ALL, producingDisplayName,
      } = options as GrafanaLinkOptions<GrafanaDashboardsEnum.PRODUCER_CONSUMER_STATS>;
      const formattedSearchParams = formatSearchParams<GrafanaDashboardsEnum.PRODUCER_CONSUMER_STATS>({
        // required
        'var-streamId': roomId,
        'var-producingDisplayName': producingDisplayName,

        // optional, default to All
        'var-consumingDisplayName': consumingDisplayName || ALL,
        'var-producingUserId': producingUserId || ALL,
        'var-consumingUserId': consumingUserId || ALL,
        'var-callId': callId || ALL,

        // only include if specified
        ...((from || includeFromTo) && { from: from || DEFAULT_FROM }),
        ...((to || includeFromTo) && { to: to || DEFAULT_TO }),
        ...((refresh || includeRefresh) && { refresh: refresh || DEFAULT_REFRESH }),

        // always specified
        orgId: ORG_ID,
      });
      return `${grafanaBaseUrl}/d/${grafanaDashboardUIDs[dashboard]}/${dashboard}?${formattedSearchParams}`;
    }

    default:
      return grafanaBaseUrl;
  }
}
