import SoundFileEnum from './soundFileEnum';

export const MIN_GAIN_RATIO = 0;
export const MAX_GAIN_RATIO = 2;

/** Options specific to a particular sound file */
export interface FilePlaybackConfig {
  throttleMs: number,
  gain: number,
}
/** Config for SoundPlayer */
export interface SoundPlayerOptions {
  overallGainRatio: number;
  filePlaybackConfigs: {
    [soundKeyName: string]: Partial<FilePlaybackConfig> | undefined
  }
}

export const defaultFilePlaybackConfig = {
  throttleMs: 0,
  gain: 1,
};

export const defaultSoundPlayerOptions: SoundPlayerOptions = {
  overallGainRatio: 1,
  filePlaybackConfigs: {
    ...SoundFileEnum
      .asArray()
      .reduce<SoundPlayerOptions['filePlaybackConfigs']>((configs, sound) => {
        configs[sound.keyName] = defaultFilePlaybackConfig;
        return configs;
      }, {}),
    [SoundFileEnum.NUDGE.keyName]: {
      ...defaultFilePlaybackConfig,
      throttleMs: 30 * 1000, // 30 seconds
    },
    [SoundFileEnum.SNAKE_APPLE.keyName]: {
      ...defaultFilePlaybackConfig,
      gain: 0.1, // this file is normalized up to -1dB (aka as loud as possible)
    },
    [SoundFileEnum.SNAKE_GAME_OVER.keyName]: {
      ...defaultFilePlaybackConfig,
      gain: 0.1, // this file is normalized up to -1dB (aka as loud as possible)
    },
  },
};
