import {
  SET_ONLINE,
  NetworkAction,
} from 'actions/networkActions';
import produce from 'immer';
import { NetworkState } from 'store/types';

export const initialState: NetworkState = {
  online: window.navigator.onLine,
};

const networkReducer = produce((draft: NetworkState, action: NetworkAction) => {
  switch (action.type) {
    case SET_ONLINE:
      draft.online = action.payload.online;
      break;
    default:
      break;
  }
}, initialState);

export default networkReducer;
