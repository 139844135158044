import { AlertType, AppThunkAction } from 'store/types';
import { MakeActionType } from 'utils/typeUtils';

export const reducerName = 'alertReducer' as const;
export const SET_ALERT = `${reducerName}/SET_ALERT` as const;

/**
 * When called without message argument, resets alert to initialState.
 */
export const setAlert = (type: AlertType, message?: string) => ({
  type: SET_ALERT,
  payload: {
    type,
    message,
  },
});

export type AlertAction = MakeActionType<[
  typeof setAlert
]>

/**
 * Exported for more convenient use in roomActions.
 */
export const setRoomAlert = (alert?: string): AppThunkAction => (dispatch) => {
  dispatch(setAlert('room', alert));
};
