import produce from 'immer';
import {
  SET_BITRATE_OPTIONS,
  SET_MAX_BITRATE_OPTION,
  SET_DTX_OPTION,
  SET_CODEC_ENABLED,
  DebugAction,
  SET_AUDIO_DEBUG_MODAL_OPEN,
  TOGGLE_SHOW_BITRATE_LEVELS,
  TOGGLE_DEBUG_MODAL,
  TOGGLE_BITRATE_OPTIONS_MODAL,
  TOGGLE_GRAFANA_OPTIONS_MODAL,
  TOGGLE_ENABLE_GRAFANA_LINKS,
  TOGGLE_ENABLE_MOCK_PARTICIPANTS,
  TOGGLE_ENABLE_SANDBOX,
  TOGGLE_ENABLE_DELETE_ALL_ROOMS,
  TOGGLE_ENABLE_ARCADE,
  TOGGLE_ENABLE_CHALKBOARD_STREAM,
  TOGGLE_EMIT_CLIENT_STATS,
  TOGGLE_FILTERS_MODAL,
  TOGGLE_SMART_SHAPE_TOOL,
} from 'actions/debugActions';
import { DebugState } from 'store/types';
import { persistReducer, persistConfig } from 'store/localStoragePersistMiddleware';
import { LOGOUT, SharedAction } from 'actions/sharedActions';

export enum PlayerMaxBitrateEnum {
  Low = 50_000,
  Medium = 400_000,
  High = 1_000_000,
}

/** It is not possible to simulcast with screenshare */
export const SCREEN_SHARE_MAX_BITRATE = 1_000_000;

export const initialState: DebugState = {
  encoderBitrateOptions: {
    Low: {
      maxBitrate: PlayerMaxBitrateEnum.Low,
      dtx: true,
    },
    Medium: {
      maxBitrate: PlayerMaxBitrateEnum.Medium,
      dtx: true,
    },
    High: {
      maxBitrate: PlayerMaxBitrateEnum.High,
      dtx: true,
    },
  },
  codecEnabled: false,
  isAudioDebugModalOpen: false,
  enableBitrateLevels: false,
  isBitrateOptionsModalOpen: false,
  isDebugModalOpen: false,
  isGrafanaOptionsModalOpen: false,
  isFiltersModalOpen: false,
  enableGrafanaLinks: false,
  enableSandbox: false,
  enableMockParticipants: false,
  enableDeleteAllRooms: false,
  enableArcade: false,
  enableChalkboardStream: true,
  enableSmartTool: false,
  enableEmitVideoClientStats: appConfig.emitVideoClientStats,
};

const reducer = produce((draft: DebugState, action: DebugAction | SharedAction) => {
  switch (action.type) {
    case SET_BITRATE_OPTIONS:
      draft.encoderBitrateOptions = action.payload.bitrateOptions;
      break;
    case SET_MAX_BITRATE_OPTION:
      draft.encoderBitrateOptions[action.payload.option].maxBitrate = action.payload.bitrate;
      break;
    case SET_DTX_OPTION:
      draft.encoderBitrateOptions[action.payload.option].dtx = action.payload.dtx;
      break;
    case SET_CODEC_ENABLED:
      draft.codecEnabled = action.payload.codecEnabled;
      break;
    case SET_AUDIO_DEBUG_MODAL_OPEN:
      draft.isAudioDebugModalOpen = action.payload.open;
      break;
    case TOGGLE_BITRATE_OPTIONS_MODAL:
      draft.isBitrateOptionsModalOpen = action.payload.open ?? !draft.isBitrateOptionsModalOpen;
      break;
    case TOGGLE_DEBUG_MODAL:
      draft.isDebugModalOpen = action.payload.open ?? !draft.isDebugModalOpen;
      break;
    case TOGGLE_GRAFANA_OPTIONS_MODAL:
      draft.isGrafanaOptionsModalOpen = action.payload.open ?? !draft.isGrafanaOptionsModalOpen;
      break;
    case TOGGLE_SHOW_BITRATE_LEVELS:
      draft.enableBitrateLevels = action.payload.show ?? !draft.enableBitrateLevels;
      break;
    case TOGGLE_ENABLE_GRAFANA_LINKS:
      draft.enableGrafanaLinks = action.payload.enable ?? !draft.enableGrafanaLinks;
      break;
    case TOGGLE_ENABLE_MOCK_PARTICIPANTS:
      draft.enableMockParticipants = action.payload.enable ?? !draft.enableMockParticipants;
      break;
    case TOGGLE_ENABLE_SANDBOX:
      draft.enableSandbox = action.payload.enable ?? !draft.enableSandbox;
      break;
    case TOGGLE_ENABLE_DELETE_ALL_ROOMS:
      draft.enableDeleteAllRooms = action.payload.enable ?? !draft.enableDeleteAllRooms;
      break;
    case TOGGLE_ENABLE_ARCADE:
      draft.enableArcade = action.payload.enable ?? !draft.enableArcade;
      break;
    case TOGGLE_ENABLE_CHALKBOARD_STREAM:
      draft.enableChalkboardStream = action.payload.enable ?? !draft.enableChalkboardStream;
      break;
    case TOGGLE_SMART_SHAPE_TOOL:
      draft.enableSmartTool = action.payload.enable ?? !draft.enableSmartTool;
      break;
    case TOGGLE_EMIT_CLIENT_STATS:
      draft.enableEmitVideoClientStats = action.payload.enable;
      break;
    case TOGGLE_FILTERS_MODAL:
      draft.isFiltersModalOpen = action.payload.show ?? !draft.isFiltersModalOpen;
      break;
    case LOGOUT:
      Object.assign(draft, initialState);
      break;
    default:
      break;
  }
}, initialState);

export default persistReducer<DebugState>(reducer, persistConfig.debugState);
