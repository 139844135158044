import { ReactElement } from 'react';
import { CSS } from 'types/css';

interface ClassNamePasserProps {
  sx: CSS,
  children: (className: string) => ReactElement
}

/**
 *  When the className generated by Theme UI style object is required in a separate component,
 * this can be used to pass it.
 *
 * * Only use this in situations where using the `sx` prop is not possible,
 * which is usually only when styling third party components.
 *
 * @example
 * ```typescript
 * <ClassNamePasser sx={{ background: 'blue' }}>
 *  {className => (
 *    <ThirdPartyComponent className={className} />
 *  )}
 * </ClassNamePasser>
 *```
 */
export default function ClassNamePasser(props: ClassNamePasserProps) {
  return props.children((props as ClassNamePasserProps & { className: string }).className);
}
