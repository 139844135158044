import { createSelector } from 'reselect';
import { ChalkboardTip, StoreState } from 'store/types';

// eslint-disable-next-line import/prefer-default-export
export const getIsChalkboardOpen = (store: StoreState) => store.chalkboardState.isChalkboardOpen;

const getChalkboardTips = (store: StoreState) => store.chalkboardState.tips;

export const makeGetChalkboardTip = (tip: ChalkboardTip) => createSelector(
  getChalkboardTips,
  (tips) => tips[tip],
);

export const getChalkboardTheme = (store: StoreState) => store.chalkboardState.theme;

export const getChalkboardTemplate = (store: StoreState) => store.chalkboardState.template;

export const getChalkboardTool = (store: StoreState) => store.chalkboardState.tool;

export const getIsAwaitingClear = (store: StoreState) => store.chalkboardState.isAwaitingClear;

export const getCommittedItems = (store: StoreState) => store.chalkboardState.committedItems;

export const getIsChalkboardDownloading = (store: StoreState) => store.chalkboardState.isDownloading;
