import { ReactComponent as A } from 'assets/icons/icebreaker/circleA.svg';
import { ReactComponent as B } from 'assets/icons/icebreaker/circleB.svg';
import { ReactComponent as C } from 'assets/icons/icebreaker/circleC.svg';
import { ReactComponent as D } from 'assets/icons/icebreaker/circleD.svg';
import {
  UnlabeledSvgComponent,
  IcebreakerPublishAnswer, IcebreakerParticipantResult, IcebreakerParticipantResponseNotification,
  IcebreakerCalculatedResults, IcebreakerQuestionType,
} from 'store/types';
import {
  BLUE_TRUE, PURPLE_TRUE, ORANGE_DARK, RED_DARK, ColorsEnum,
} from 'theme/ui/colors';
import logger from 'utils/logger';

export interface Letters {
  [index: number]: {
    component: UnlabeledSvgComponent,
    title: string
    color: ColorsEnum,
    variant: string,
    tile: ColorsEnum
  }
}

export const letters:Letters = {
  0: {
    component: A,
    title: 'A',
    color: ColorsEnum.BLUE_TRUE,
    variant: 'blue',
    tile: ColorsEnum.BLUE_TRUE,
  },
  1: {
    component: B,
    title: 'B',
    color: ColorsEnum.PURPLE_TRUE,
    variant: 'purple',
    tile: ColorsEnum.PURPLE_TRUE,
  },
  2: {
    component: C,
    title: 'C',
    color: ColorsEnum.ORANGE_DARK,
    variant: 'orange',
    tile: ColorsEnum.ORANGE_DARK,
  },
  3: {
    component: D,
    title: 'D',
    color: ColorsEnum.RED_DARK,
    variant: 'red',
    tile: ColorsEnum.RED_DARK,
  },
};

export const getAnswerLabelColor = (
  answer: IcebreakerPublishAnswer,
  answerIndex: number,
  questionType: IcebreakerQuestionType,
  isCorrect?: boolean,
) => {
  if (isCorrect) {
    return ColorsEnum.TEAL_DARK;
  }
  if (questionType === 'trueFalse') {
    return answer.label === 'True' ? ColorsEnum.BLUE_DARK : ColorsEnum.RED_DARK;
  }
  return letters[answerIndex].color;
};

const GRADIENT_CONFIG = {
  [ColorsEnum.BLUE_DARK.toString()]: ColorsEnum.BLUE_MEDIUM,
  [ColorsEnum.RED_DARK.toString()]: ColorsEnum.RED_MEDIUM,
  [ColorsEnum.TEAL_DARK.toString()]: ColorsEnum.TEAL_MEDIUM,
  [ColorsEnum.BLUE_TRUE.toString()]: ColorsEnum.BLUE_MEDIUM,
  [ColorsEnum.PURPLE_TRUE.toString()]: ColorsEnum.PURPLE_MEDIUM,
  [ColorsEnum.ORANGE_DARK.toString()]: ColorsEnum.ORANGE_MEDIUM,
};

export const getAnswerLabelGradient = (
  color: ColorsEnum,
) => {
  const gradientColor = GRADIENT_CONFIG[color.toString()] || color;

  return `linear-gradient(117.46deg, ${color} 11.44%, ${gradientColor} 74.61%)`;
};

export const calculateResults = (
  answers: { [id: string]: IcebreakerPublishAnswer },
  results: IcebreakerParticipantResult[] | IcebreakerParticipantResponseNotification[],
):IcebreakerCalculatedResults => {
  const calculated: IcebreakerCalculatedResults = {};

  Object.keys(answers).forEach((id) => {
    calculated[id] = {
      id,
      count: 0,
    };
  });

  results.forEach((res) => {
    if (calculated[res.answerId]) {
      calculated[res.answerId].count += 1;
    } else {
      logger.warn('Icebreaker answers is missing ID', { id: res.answerId });
    }
  });

  return calculated;
};

export const mockPieChartData = [
  { title: 'north', value: 0, color: BLUE_TRUE },
  { title: 'south', value: 12, color: PURPLE_TRUE },
  { title: 'east', value: 27, color: ORANGE_DARK },
  { title: 'west', value: 21, color: RED_DARK },
];

export const mockQuestion = {
  active: true,
  created: new Date(),
  id: '1',
  questionSessionId: '1',
  label: 'What is the largest planet in our solar system',
  questionType: 'multipleChoice',
  anonymousAnswers: true,
  answerCharLimit: 0,
  answers: {
    0: {
      label: 'SaturnSaturnSaturnSa',
      value: 'A',
      id: 0,
      correct: false,
    },
    1: {
      label: 'JupiterJupiterJupite',
      value: 'B',
      id: 1,
      correct: true,
    },
    2: {
      label: 'NeptuneNeptuneNeptun',
      value: 'C',
      id: 2,
      correct: false,
    },
    3: {
      label: 'MarsMarsMarsMarsMars',
      value: 'D',
      id: 3,
      correct: false,
    },
  },
};

export const QUESTION_CHAR_LIMIT = 80;
export const ANSWER_OPTION_CHAR_LIMIT = 20;
export const ANSWER_CHAR_LIMIT = 40;
