/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import { getIsAudioDebugModalOpen } from 'selectors';
import Modal from 'components/ui/Modal/Modal';
import ModalBody from 'components/ui/Modal/ModalBody';
import ModalTitle from 'components/ui/Modal/ModalTitle';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAudioDebugModalOpen, toggleDebugModal } from 'actions/debugActions';
import { SoundFileEnum } from 'utils/soundPlayer';
import useStateIfMounted from 'hooks/useStateIfMounted';
import ModalFooter from 'components/ui/Modal/ModalFooter';
import { useCallback } from 'react';
import Button from 'components/ui/Button';
import AudioDebugTestTone from './AudioDebugTestTone';
import AudioDebugNotification from './AudioDebugNotification';
import AudioDebugOverallGain from './AudioDebugOverallGain';

export default function AudioDebugModal() {
  const dispatch = useDispatch();
  const isOpen = useSelector(getIsAudioDebugModalOpen);
  const [playingDisabled, setPlayingDisabled] = useStateIfMounted(false);

  const close = () => dispatch(setIsAudioDebugModalOpen(false));

  const testTones = SoundFileEnum.getAllByType('test-tone');
  const notificationTones = SoundFileEnum.getAllByType('notification');

  const goBack = useCallback(() => {
    dispatch(setIsAudioDebugModalOpen(false));
    dispatch(toggleDebugModal(true));
  }, [dispatch]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={close}
      addFooterSpacing
    >
      <ModalTitle title="Audio Configuration" toggle={close}>Audio Tools</ModalTitle>
      <ModalBody>
        <p sx={{ mb: '2rem' }}>
          Tip: START WITH YOUR VOLUME AT ITS LOWEST LEVEL, especially when testing Rapid Fire. Wear headphones, and then use the test tone
          {testTones.length > 1 && 's'}
          {' '}
          as a reference for volume and to set your computer volume to a comfortable level.
        </p>
        <AudioDebugOverallGain />
        {testTones.map((sf) => (
          <AudioDebugTestTone
            key={`test-tone-${sf.keyName}`}
            sound={sf}
            playingDisabled={playingDisabled}
            setPlayingDisabled={setPlayingDisabled}
          />
        ))}
        {notificationTones.map((sf) => (
          <AudioDebugNotification
            key={`notification-${sf.keyName}`}
            sound={sf}
            playingDisabled={playingDisabled}
            setPlayingDisabled={setPlayingDisabled}
          />
        ))}
      </ModalBody>
      <ModalFooter>
        <Button variant="secondaryLight" onClick={goBack}>Back</Button>
        <Button onClick={close}>Close</Button>
      </ModalFooter>
    </Modal>
  );
}
