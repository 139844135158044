import { StoredKNRoom } from 'store/types';
import mapValues from 'lodash/mapValues';
import isEqual from 'lodash/isEqual';
import logger from 'utils/logger';

const sanitizeRoomState = (roomState: StoredKNRoom): StoredKNRoom => {
  const sanitized = {
    id: roomState.id || '',
    roomName: roomState.roomName || '',
    callId: roomState.callId || null,
    isJoinable: roomState.isJoinable || false,
    participants: mapValues(roomState.participants || {}, (p) => ({
      userId: p?.userId || '',
      roles: p?.roles || {},
      sessionParticipantId: p?.sessionParticipantId || '',
      reqId: p?.reqId || '',
      displayName: p?.displayName || '',
      peerId: p?.peerId || '',
      callBaseUrl: p?.callBaseUrl || '',
      callToken: p?.callToken || '',
      isHandRaised: !!p?.isHandRaised,
      handRaisedTimeStampMillis: p?.handRaisedTimeStampMillis || -1, // -1 if hand is down, epoch millis, chalkcast-service clock
      isScreenShared: !!p?.isScreenShared,
      nudged: !!p?.nudged,
      nudgeAcknowledged: !!p?.nudgeAcknowledged,
      reserved: p?.reserved || {},
      profileUrl: p?.profileUrl || '',
    })),
    ownerPresent: !!roomState.ownerPresent,
    calledOnId: roomState.calledOnId || null,
    isLocked: !!roomState.isLocked,
    knockers: mapValues(roomState.knockers || {}, (k) => ({
      displayName: k?.displayName || '',
      reqId: k?.reqId || '',
    })),
    roomRoster: mapValues(roomState.roomRoster || {}, (r) => ({
      displayName: r?.displayName || '',
      id: r?.id || '',
    })),
    ownerJoinUnassigned: !!roomState.ownerJoinUnassigned,
    ownerBroadcastData: roomState.ownerBroadcastData || {},
    breakoutRooms: mapValues(roomState.breakoutRooms || {}, (b) => ({
      id: b.id || '',
      name: b.name || '',
      active: b.active || true,
      users: b.users || {},
    })),
    ownerId: roomState.ownerId || '',
    isLoginRequired: !!roomState.isLoginRequired,
    reserved: roomState.reserved || {},
    roomPermissions: roomState.roomPermissions || {},
    questions: mapValues(roomState.questions || {}, (q) => ({
      active: !!q.active,
      created: q.created || new Date(),
      id: q.id || -1,
      questionSessionId: q.questionSessionId || '',
      label: q.label || '',
      questionType: q.questionType || 'multipleChoice',
      anonymousAnswers: !!q.anonymousAnswers,
      answerCharLimit: q.answerCharLimit || 30,
      answers: q.answers || {},
      results: q.results || {},
    })),
    openroom: !!roomState.openroom,
    recordingStart: roomState.recordingStart || 0,
  };

  if (!isEqual(roomState, sanitized)) {
    logger.debug('Sanitized room state does not match raw room state', { roomState, sanitized } as any);
  }

  return sanitized;
};

export default sanitizeRoomState;
