/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import {
  allViewRecalibrateSessionId, allViewGoToNextPage, allViewGoToPrevPage, allViewFetchAndDownloadAttendanceCSV,
} from 'actions/attendanceActions';
import Loading from 'components/Loading/Loading';
import Button from 'components/ui/Button';
import { OwnerStatusEnum } from 'hooks/useIsOwner';
import {
  Fragment,
  memo, useCallback, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dateToClockTime } from 'utils/dateUtils';
import {
  getAllViewCanGoToPrevPage, getAllViewCanGoToNextPage, getAllViewSessionsLoading,
  getAllViewSessionsFetched, getAllViewGridDimensions, getAllViewShowingTooFewCols, getAllViewSessionsError,
  getAllViewCanRecalibrateSessionId, getAllViewSessionIdsForView, getAllViewAllUserIdLabels, getAllViewSessions,
  getAllViewAllSessionIdLabels,
  getAllViewCSVDataLoading,
} from 'selectors';
import { CSS } from 'types/css';
import { ReactComponent as Chevron } from 'assets/icons/chevron.svg';
import Svg from 'components/ui/Svg';
import { Redirect } from 'react-router';
import { CCServiceAllViewAttendanceSession, CCServiceAttendanceRosterUser } from 'utils/ccService/types';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { AllViewSession } from 'store/types';
import { textEllipsis } from 'styles/styles';
import ErrorMessage from 'components/ui/Messages/ErrorMessage';
import { useRoomManagerContext } from 'views/RoomManager/roomManagerContext';
import AttendanceNameLabel from './AttendanceNameLabel';
import AttendanceDateLabel from '../AttendanceDateLabel';
import {
  GRID_PADDING, attendanceCardBaseStyles, mainHeadingStylesBase, attendanceContainerStyles,
  darkerRowStyles, spacerStyles, bottomBorderRadius, TABLE_ROW_HEIGHT, noSessionsStyles, headerStyles,
} from '../common';
import AttendanceStatusIcon from '../AttendanceStatusIcon/AttendanceStatusIcon';
import AttendanceAllViewError from './AttendanceAllViewError';
import AttendanceHoverDetail from './AttendanceHoverDetail';
import AttendanceDownloadCSVButton from '../AttendanceDownloadCSVButton';

export const COLS_FOR_DISPLAY_NAME_LABELS = 1;
export const ROWS_FOR_DATE_HEADING_LABELS = 1;
export const COLS_FOR_BACK_BUTTON = 1;
export const BUTTON_SIZE = '4.4rem';
export const BUTTON_MARGIN = '0.5rem';

const h1Styles: CSS = {
  ...mainHeadingStylesBase,
  maxWidth: '100%',
  ...textEllipsis,
};

const tableStyles: CSS = {
  ...attendanceCardBaseStyles,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  placeItems: 'center',
};

const navigationButtonStyles: CSS = {
  height: BUTTON_SIZE,
  width: BUTTON_SIZE,
  p: 0,
};

const navigationButtonTdStyles: CSS = {
  mt: GRID_PADDING,
  alignSelf: 'start',
  height: BUTTON_SIZE,
  width: BUTTON_SIZE,
  p: 0,
};

const backButtonTdStyles: CSS = {
  ...navigationButtonTdStyles,
  gridArea: '1 / 1 / 2 / 2',
  justifySelf: 'end',
  mr: BUTTON_MARGIN,
};

const nextButtonTdDefaultStyles: CSS = {
  ...navigationButtonTdStyles,
  '& button > svg': {
    transform: 'rotate(180deg)',
  },
  ml: BUTTON_MARGIN,
  mr: GRID_PADDING,
};

const TABLE_TITLE_ID = 'attendance__all-guests__title';

/** Displays attendance reports for the last 5 sessions (or fewer on smaller displays) */
function AttendanceAllView() {
  const dispatch = useDispatch();
  const canGoToPrevPage = useSelector(getAllViewCanGoToPrevPage);
  const canGoToNextPage = useSelector(getAllViewCanGoToNextPage);
  const loading = useSelector(getAllViewSessionsLoading);
  const error = useSelector(getAllViewSessionsError);
  const sessionsFetched = useSelector(getAllViewSessionsFetched);
  const [cols, rows] = useSelector(getAllViewGridDimensions);
  const showingTooFewCols = useSelector(getAllViewShowingTooFewCols);
  const canRecalibrateCols = useSelector(getAllViewCanRecalibrateSessionId);
  const allUserIdLabels = useSelector(getAllViewAllUserIdLabels);
  const allSessionIdLabels = useSelector(getAllViewAllSessionIdLabels);
  const sessionIdsForView = useSelector(getAllViewSessionIdsForView);
  const sessionMap = useSelector(getAllViewSessions);
  const lastSessionId = allSessionIdLabels[allSessionIdLabels.length - 1];
  const arbitrarySession = sessionMap[lastSessionId] as CCServiceAllViewAttendanceSession | undefined;
  const csvDataLoading = useSelector(getAllViewCSVDataLoading);
  const { roomId, ownerStatus } = useRoomManagerContext();

  // recalibrate num of cols displayed when resizing across breakpoints
  useEffect(() => {
    if (showingTooFewCols && canRecalibrateCols) dispatch(allViewRecalibrateSessionId());
  }, [showingTooFewCols, canRecalibrateCols, dispatch]);

  const handleDownloadCSVClick = useCallback(() => dispatch(allViewFetchAndDownloadAttendanceCSV(roomId)), [roomId, dispatch]);
  const handleBack = useCallback(() => dispatch(allViewGoToPrevPage(roomId)), [roomId, dispatch]);
  const handleNext = useCallback(() => dispatch(allViewGoToNextPage()), [dispatch]);

  const gridTemplateColumns = useMemo(() => `minmax(12rem, 20rem) 
  repeat(${cols - COLS_FOR_BACK_BUTTON - COLS_FOR_DISPLAY_NAME_LABELS}, minmax(5rem, 18rem))
  calc(${BUTTON_SIZE} + ${GRID_PADDING} + ${BUTTON_MARGIN})`, [cols]);

  const topRowStyles: CSS = useMemo(() => ({
    gridArea: `1 / 1 / 2 / ${cols + 1}`,
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns,
  }), [gridTemplateColumns, cols]);

  const tableBodyStyles: CSS = useMemo(() => ({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: `repeat(${rows - ROWS_FOR_DATE_HEADING_LABELS}, 5.3rem)`,
    ...bottomBorderRadius,
  }), [rows]);

  const bodyRowStyles: CSS = useMemo(() => ({
    display: 'grid',
    height: TABLE_ROW_HEIGHT,
    maxHeight: TABLE_ROW_HEIGHT,
    gridTemplateRows: '1fr',
    gridTemplateColumns,
    justifyContent: 'center',
    placeItems: 'center',
  }), [gridTemplateColumns]);

  const nextButtonTdStyles: CSS = useMemo(() => ({
    ...nextButtonTdDefaultStyles,
    gridArea: `1 / ${cols} / 2 / ${cols + 1}`,
  }), [cols]);

  if (roomId && ownerStatus === OwnerStatusEnum.NOT_OWNER) {
    return <Redirect to={`../join/${roomId}`} />;
  }

  if (error) {
    return (
      <Fragment>
        <ErrorMessage type="attendance" useTimer />
        <AttendanceAllViewError />
      </Fragment>
    );
  }

  if (!sessionsFetched) {
    return <Loading />;
  }

  if (sessionsFetched && sessionIdsForView.length === 0) {
    return <p sx={noSessionsStyles}>There are no attendance reports to show for this room.</p>;
  }

  return (
    <ErrorBoundary fallback={<AttendanceAllViewError />}>
      <ErrorMessage type="attendance" useTimer />
      <main sx={attendanceContainerStyles}>
        <header sx={headerStyles}>
          <h1 sx={h1Styles} id={TABLE_TITLE_ID}>Attendance</h1>
          <AttendanceDownloadCSVButton
            csvDataLoading={csvDataLoading}
            handleDownloadCSVClick={handleDownloadCSVClick}
          />
        </header>

        <table sx={tableStyles} aria-labelledby={TABLE_TITLE_ID} summary="Attendance data for all guests that have visited the room">
          <thead>
            <tr sx={topRowStyles}>
              <td sx={backButtonTdStyles}>
                {/* Back Button */}
                <Button
                  disabled={!canGoToPrevPage}
                  type="button"
                  sx={navigationButtonStyles}
                  iconComponent={<Svg svg={Chevron} title="See previous page" />}
                  onClick={handleBack}
                />
              </td>

              {/* Session Date Labels */}
              {sessionIdsForView.map((sessionId) => {
                const session: AllViewSession | undefined = sessionMap[sessionId];
                const startTimeDate = session?.startTimeDate;
                return (
                  <AttendanceDateLabel
                    scope="col"
                    id={sessionId}
                    key={sessionId}
                    startTime={session.startTime}
                    startTimeDate={startTimeDate}
                    loading={loading}
                    sessionId={sessionId}

                    // for debugging / testing
                    {...(process.env.NODE_ENV !== 'production' && {
                      'data-test-id': 'session-date-label',
                      'data-session-id': sessionId,
                      'data-start-time': session?.startTime,
                    })}
                  />
                );
              })}

              {/* Forward Button */}
              <td sx={nextButtonTdStyles}>
                <Button
                  disabled={!canGoToNextPage}
                  type="button"
                  sx={navigationButtonStyles}
                  iconComponent={<Svg svg={Chevron} title="See next page" />}
                  onClick={handleNext}
                />
              </td>
            </tr>
          </thead>

          <tbody sx={tableBodyStyles}>
            {allUserIdLabels.map((userId, i) => {
              const displayNameLabel = arbitrarySession?.roster[userId]?.displayName || '';
              const firstNameLabel = arbitrarySession?.roster[userId]?.firstName || '';
              const lastNameLabel = arbitrarySession?.roster[userId]?.lastName || '';
              const rowIsDark = i % 2 === 0;
              return (
                <tr
                  key={userId}
                  sx={{
                    ...bodyRowStyles,
                    ...(rowIsDark && darkerRowStyles),
                    ...(i === allUserIdLabels.length - 1 && bottomBorderRadius), // curved bottom corners of last row
                  }}
                >
                  {/* Display Name Labels */}
                  <AttendanceNameLabel
                    scope="row"
                    id={userId}
                    displayName={displayNameLabel}
                    firstName={firstNameLabel}
                    lastName={lastNameLabel}
                    userId={userId}
                    // for debugging / testing
                    {...(process.env.NODE_ENV !== 'production' && {
                      'data-test-id': 'display-name-label',
                      'data-display-name': displayNameLabel,
                      'data-user-id': userId,
                      'data-first-name': firstNameLabel,
                      'data-last-name': lastNameLabel,
                    })
                    }
                  />

                  {/* Attendance Statuses */}
                  {sessionIdsForView.map((sessionId) => {
                    const user: CCServiceAttendanceRosterUser | undefined = sessionMap[sessionId]?.roster[userId];
                    const displayName = user?.displayName || '';
                    const firstName = user?.firstName || '';
                    const lastName = user?.lastName || '';
                    const attendanceStatus = user?.attendanceStatus || '';
                    const session: AllViewSession | undefined = sessionMap[sessionId];
                    const startTimeDate = session?.startTimeDate;
                    const startTimeDateString = startTimeDate.toDateString();
                    const startTimeClockTimeString = dateToClockTime(startTimeDate);
                    return (
                      <AttendanceStatusIcon
                        key={`${userId}${sessionId}`}
                        headers={`${sessionId} ${userId}`}
                        loading={loading}
                        attendanceStatus={attendanceStatus || ''}
                        aria-label={`${displayName} was ${attendanceStatus} on ${startTimeDateString} at ${startTimeClockTimeString}`}
                        userId={userId}
                        sessionId={sessionId}
                        rowIsDark={rowIsDark}
                        enableHoverForDetail

                        // for debugging / testing
                        {...(process.env.NODE_ENV !== 'production' && {
                          'data-test-id': 'attendance-status',
                          'data-display-name': displayName,
                          'data-first-name': firstName,
                          'data-last-name': lastName,
                          'data-user-id': userId,
                          'data-session-id': sessionId,
                          'data-attendance-status': attendanceStatus,
                          'data-start-time-date': session?.startTime,
                          'data-start-time-clock-time': startTimeClockTimeString,
                        })}
                      >
                        <AttendanceHoverDetail userId={userId} sessionId={sessionId} />
                      </AttendanceStatusIcon>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>

        </table>
        <div sx={spacerStyles} />
      </main>
    </ErrorBoundary>
  );
}

export default memo(AttendanceAllView);
