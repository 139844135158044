/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import { ComponentProps, ReactNode } from 'react';
import { loginCardPx, roomBackdropFilter } from 'styles/styles';
import { ColorsEnum, ColorsEnumValue } from 'theme/ui/colors';

interface CardPropTypes extends ComponentProps<'div'> {
  children?: ReactNode;
  color?: ColorsEnumValue;
}

function Card({ children, color = ColorsEnum.WHITE.withOpacity(0.8), ...rest }: CardPropTypes) {
  const styles = {
    bg: color,
    backdropFilter: roomBackdropFilter,
    borderRadius: '2.4rem',
    py: '6rem',
    px: loginCardPx,
    transition: '0.2s ease',
  };

  return (
    <div sx={styles} {...rest}>
      {children}
    </div>
  );
}

export default Card;
