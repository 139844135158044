import { useEffect, useRef } from 'react';
import { isHTMLInputElement, isHTMLTextAreaElement } from 'utils/typePredicates';

const initKeysPressed = (keys: string[], prevPresses: { [key: string]: boolean }) => keys.reduce<{ [key: string]: boolean }>((obj, key) => {
  obj[key] = !!prevPresses[key] || false;
  return obj;
}, {});

const getIsActiveElementInput = () => isHTMLInputElement(document.activeElement) || isHTMLTextAreaElement(document.activeElement);

/**
 * Calls callback when all keys (KeyboardEvent.key) are pressed simultaneously.
 *
 * You can supply a string of multiple keys separated by `|` (`'Shift|Control|Tab'`)
 * in order to provide a union of keys treated as the same key.
 *
 * The callback is passed the last keydown event received.
 *
 * Note: This ignores "metaKey" keydown events, since keyup is not fired in macOS while Command is pressed,
 * and there is no existing workaround.
*/
export default function useKeyCombo(keys: string[], cb: (ev: KeyboardEvent) => void) {
  const keysPressedRef = useRef<{ [key: string]: boolean }>({});
  const prevKeydownEvRef = useRef<KeyboardEvent>();

  useEffect(() => {
    const keysPressed = initKeysPressed(keys, keysPressedRef.current); // preserve pressed keys between effects

    const onKeyDown = (ev: KeyboardEvent) => {
      // Skip keys pressed while macOS Command pressed since keyup cannot be fired while it is pressed
      if (keys.length === 0 || ev.metaKey || prevKeydownEvRef.current?.key === ev.key) return;
      let allPressed = true;
      for (const expectedKey of keys) {
        if (expectedKey === ev.key || expectedKey.split('|').includes(ev.key)) {
          keysPressed[expectedKey] = true;
        } else if (!keysPressed[expectedKey]) {
          allPressed = false;
        }
      }
      // does not fire if the user is focused on input/textarea
      if (allPressed && !getIsActiveElementInput()) {
        cb(ev);
      }
      keysPressedRef.current = keysPressed;
      prevKeydownEvRef.current = ev;
    };

    const onKeyUp = ({ key }: KeyboardEvent) => {
      if (keys.length === 0) return;
      for (const expectedKey of keys) {
        if (expectedKey.split('|').includes(key)) {
          keysPressed[expectedKey] = false;
          break;
        }
      }
      keysPressedRef.current = keysPressed;
      prevKeydownEvRef.current = undefined;
    };

    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('keyup', onKeyUp);
    };
  }, [keys, cb]);
}
