/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import {
  ComponentProps, memo, useEffect, useMemo, useRef,
} from 'react';
import { useStore } from 'react-redux';
import { makeGetAllViewSessionTimeLabelWeight } from 'selectors';
import { textEllipsis } from 'styles/styles';
import {
  GREY_100, GREY_50, GREY_80, RED_TRUE,
} from 'theme/ui/colors';
import { CSS } from 'types/css';
import { CCServiceUserViewAttendanceSession } from 'utils/ccService/types';
import { dateToMonthString, dateToNumericDateString, dateToWeekdayString } from 'utils/dateUtils';
import { GRID_PADDING, attendanceLoaderStyles, ALL_VIEW_FADE_TRANSITION_TIME } from './common';

interface VariantStyles {
  all: CSS,
  user: CSS,
}

const containerVariantStyles: VariantStyles = {
  all: {
    my: GRID_PADDING,
    mx: '0.5rem',
  },
  user: {
    mx: GRID_PADDING,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    justifySelf: 'start',
  },
};

const loaderVariantStyles: VariantStyles = {
  all: {
    ...attendanceLoaderStyles,
    height: '4.5rem',
    width: '4.5rem',
    mx: 'auto',
  },
  user: {
    ...attendanceLoaderStyles,
    height: '4.5rem',
    width: '4.5rem',
  },
};

const monthVariantStyles: VariantStyles = {
  all: {
    transition: `color ${ALL_VIEW_FADE_TRANSITION_TIME}`,
    display: 'block',
    fontFamily: 'open',
    fontWeight: 'bold',
    fontSize: '2rem',
    textAlign: 'center',
    alignSelf: 'start',
    lineHeight: '2.25rem',
  },
  user: {
    variant: 'text.body_small',
    fontWeight: 'bold',
    display: 'block',
    ...textEllipsis,
  },
};

const weekdayVariantStyles: VariantStyles = {
  all: {
    display: 'block',
    variant: 'text.body_small',
    textAlign: 'center',
    lineHeight: '2.25rem',
  },
  user: {
    variant: 'text.caption',
    display: 'block',
    ...textEllipsis,
  },
};

type AttendanceDateLabelProps = ComponentProps<'th'> & {
  loading: boolean,
  startTime: string,
  startTimeDate: Date,
  sessionId: string,
} & ({
  variant?: 'all',
  attendanceStatus?: never,
} | {
  variant?: 'user',
  attendanceStatus: CCServiceUserViewAttendanceSession['attendanceStatus'],
});

/** Month Date & Day label */
function AttendanceDateLabel({
  startTimeDate, variant = 'all', attendanceStatus, startTime, loading, sessionId, ...rest
}: AttendanceDateLabelProps) {
  const formattedDate = useMemo(() => {
    const monthAbbreviation = dateToMonthString(startTimeDate).slice(0, 3);
    const formattedMonth = variant === 'all' ? monthAbbreviation.toUpperCase() : monthAbbreviation;
    return `${formattedMonth} ${dateToNumericDateString(startTimeDate)}`;
  }, [startTimeDate, variant]);
  const formattedWeekday = useMemo(() => dateToWeekdayString(startTimeDate).slice(0, 3), [startTimeDate]);
  const getSessionTimeLabelWeight = useMemo(() => makeGetAllViewSessionTimeLabelWeight(sessionId), [sessionId]);
  const store = useStore();
  const thRef = useRef<HTMLTableCellElement | null>(null);
  const absentColorStyles = useMemo(() => attendanceStatus === 'absent' && { color: RED_TRUE }, [attendanceStatus]);

  const {
    containerStyles, monthStyles, loaderStyles, weekdayStyles,
  } = useMemo(() => ({
    containerStyles: containerVariantStyles[variant],
    loaderStyles: loaderVariantStyles[variant],
    monthStyles: { ...monthVariantStyles[variant], ...(variant === 'user' && absentColorStyles) },
    weekdayStyles: { ...weekdayVariantStyles[variant], ...(variant === 'user' && absentColorStyles) },
  }), [variant, absentColorStyles]);

  // Imperatively update styles to change color (prevents 100ms+ on hover/click)
  // (Only necessary in All view)
  useEffect(() => {
    const unsubscribe = variant === 'all' ? (store.subscribe(() => {
      if (thRef.current) {
        const sessionTimeLabelWeight = getSessionTimeLabelWeight(store.getState());
        let color: CSS['color'];
        switch (sessionTimeLabelWeight) {
          case 'light':
            color = GREY_50;
            break;
          case 'bold':
            color = GREY_100;
            break;
          default:
            color = GREY_80;
            break;
        }
        thRef.current.style.color = color;
      }
    })) : (() => { });

    return () => unsubscribe();
  }, [variant, getSessionTimeLabelWeight, store]);

  return (
    <th sx={containerStyles} ref={thRef} {...rest}>
      {loading ? <div sx={loaderStyles} /> : (
        <time dateTime={startTime}>
          <span sx={monthStyles}>{formattedDate}</span>
          <span sx={weekdayStyles}>{formattedWeekday}</span>
        </time>
      )}
    </th>
  );
}

export default memo(AttendanceDateLabel);
