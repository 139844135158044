import { MakeActionType } from 'utils/typeUtils';

export const reducerName = 'mobileState' as const;
export const DISMISS_MOBILE_REDIRECT = `${reducerName}/DISMISS_MOBILE_REDIRECT` as const;

export const dismissMobileRedirect = () => ({
  type: DISMISS_MOBILE_REDIRECT,
});

export type MobileAction = MakeActionType<[
  typeof dismissMobileRedirect
]>
