/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import {
  GREY_70, WHITE, GREY_50, RED_LIGHT, GREY_20, GREY_80, GREY_40, GREY_60, ColorsEnum,
} from 'theme/ui/colors';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import { ReactComponent as ShowPasswordSVG } from 'assets/icons/dontShow.svg';
import { ReactComponent as HidePasswordSVG } from 'assets/icons/show.svg';
import { ReactComponent as SearchSVG } from 'assets/icons/search.svg';
import {
  useState, useEffect, memo, MutableRefObject, ComponentPropsWithoutRef, ReactNode,
} from 'react';
import useIsKeyboardUser from 'hooks/useIsKeyboardUser';
import FocusRing from 'components/FocusRing/FocusRing';
import { CSS } from 'types/css';
import { Overwrite } from 'utils/typeUtils';
import Svg from './Svg';

type InputVariants = 'light' | 'dark';

export const SHOW_PASSWORD_LABEL = 'Show/Hide Password';
export const DEFAULT_REQUIRED_ERROR = 'This field is required';

const warningIconStyles: CSS = {
  fill: RED_LIGHT,
  ml: '0.8rem',
  mr: '0rem',
  mt: '0.2rem',
  width: '1.5rem',
  minWidth: '1.5rem',
  height: '1.5rem',
  minHeight: '1.5rem',
};

const showPasswordButtonStyles: CSS = {
  position: 'absolute',
  right: '1rem',
  top: '0.8rem',
  p: '0.2rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  bg: 'transparent',
  border: 'none',
  '&:focus': {
    outline: 'none',
  },
  height: '2.75rem',
  width: '2.75rem',
};

/**
 * Encapsulates the input for styling the focus
 * ring and positioning the hide/show password button.
*/
const focusContainerStyles: CSS = {
  position: 'relative',
};

const searchSvgStyles: CSS = {
  position: 'absolute',
  top: 11,
  right: 14,
};

const showPasswordSVGStyles: CSS = {
  fill: GREY_40,
  'button:hover &': {
    fill: GREY_60,
  },
  'button:active:not(:disabled) &': {
    fill: GREY_80,
  },
};

export interface InputProps extends Overwrite<ComponentPropsWithoutRef<'input'>, {
  // overwrite value (limit it to a string only)
  value?: string,
}> {
  // add in the rest
  label?: ReactNode,
  id: string,
  variant?: InputVariants,
  onChangeSanitized?: ((onChangeSanitized: string) => void) | null,
  onBlurSanitized?: ((sanitizedInputValue: string) => void) | null,
  overrideError?: string | null,
  validate?: ((value: string) => string) | null,
  caption?: string,
  wide?: boolean,
  inputRef?: MutableRefObject<HTMLInputElement | null>,
  sanitizeOnChange?: ((inputValue: string) => string) | null,
  sanitizeOnBlur?: ((inputValue: string) => string) | null,
  componentStyles?: CSS,
  hasErrorCallback?: (hasError: boolean, error: string | null) => void,
  validateOnChange?: boolean,
  name?: string,
  showSearchIcon?: boolean,
  searchIconTitle?: string,
  labelStyles?: CSS,
}

/**
 * Component for receiving text-based user input.
 * Uses the normal onChange, onBlur, and onFocus handler props.
 * Optionally provides sanitizeOnChange & sanitizeOnBlur to clean up the user's input
 * before calling the corresponding onChangeSanitized and onBlurSanitized handler props.
 *
 * Order of precedence for determining input errors / captions:
 * 1. Any overrideError provided
 * 2. Any errors from custom input validation provided
 * 3. "This field is required" error (for required inputs)
 * 4. "Minimum {number} characters required"
 * 5. "{number} character limit reached" caption (for inputs with max length)
 * 6. Default caption provided
 */
function Input({
  id,
  label,
  value = '',
  type = 'text',
  variant = 'light',
  required = false,
  wide = false,
  maxLength,
  minLength,
  overrideError = null,
  caption = '',
  inputRef = { current: null },
  onChange,
  onBlur,
  onFocus,
  onChangeSanitized = null,
  onBlurSanitized = null,
  validate = null,
  sanitizeOnChange = null,
  sanitizeOnBlur = null,
  autoComplete = 'off',
  componentStyles = {},
  hasErrorCallback,
  validateOnChange = false,
  showSearchIcon = false,
  searchIconTitle,
  labelStyles = {},
  ...rest
}: InputProps) {
  const [error, setError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const isKeyboardUser = useIsKeyboardUser();

  caption = (maxLength && value.length === maxLength) ? `${maxLength} character limit reached` : caption;
  caption = (minLength && value.length < minLength) ? `Minimum ${minLength} characters required` : caption;
  const inputError = overrideError || error;
  const showCaption = inputError || caption;

  useEffect(() => {
    if (hasErrorCallback) hasErrorCallback(!!inputError, inputError);
  }, [inputError]);

  const inputContainerStyles: CSS = {
    position: 'relative',
    minWidth: '20rem',
    width: '100%',
    maxWidth: wide ? '100%' : '29.5rem',
  };

  const inputLabelStyles: CSS = {
    variant: 'text.label',
    color: variant === 'light' ? GREY_70 : GREY_20,
    ...labelStyles,
  };

  const inputStyles: CSS = {
    p: '0.9rem 2.3rem 0.7rem',
    // prevent password button from obscuring text
    pr: type === 'password' ? '4.25rem' : null,
    border: 0,
    borderRadius: '2.2rem',
    minHeight: '4.4rem',
    width: '100%',
    transition: 'box-shadow 0.1s',
    fontSize: '1.6rem',
    lineHeight: '2rem',
    bg: variant === 'light' ? WHITE : ColorsEnum.BLACK.withOpacity(0.6),
    color: variant === 'light' ? GREY_80 : GREY_20,
    '&::placeholder': {
      variant: 'text.body_italic',
      color: GREY_50,
      overflow: 'visible',
    } as CSS,
    '&:hover, &:active, &:focus': {
      ':not(:disabled)': {
        boxShadow: `inset 0px 4px 16px ${ColorsEnum.BLACK.withOpacity(0.1)}`,
      },
    },
    '&:focus': {
      outline: 0,
    },
    // no gap between input and focus ring
    '&:focus + span': {
      width: '100% !important',
      height: '100% !important',
      top: '0 !important',
      left: '0 !important',
    },
  };

  if (showSearchIcon) {
    inputStyles.pr = '3.4rem';
  }

  const captionContainerStyles: CSS = {
    display: 'flex',
    opacity: caption || inputError ? 1 : 0,
    height: '1.7rem',
    mt: '0.5rem',
  };

  let captionColor: CSS['color'] = variant === 'light' ? GREY_70 : GREY_20;
  if (inputError) captionColor = RED_LIGHT;
  const captionStyles: CSS = {
    variant: 'text.caption',
    color: captionColor,
    ml: '0.6rem',
  };

  return (
    <div sx={{ ...inputContainerStyles, ...componentStyles }}>
      {label && <label htmlFor={id} sx={inputLabelStyles}>{label}</label>}
      <div className="input-wrapper" sx={focusContainerStyles}>
        <input
          id={id}
          type={showPassword ? 'text' : type}
          sx={inputStyles}
          value={value}
          required={required}
          ref={inputRef}
          minLength={minLength}
          maxLength={maxLength}
          autoComplete={autoComplete}
          onChange={(e) => {
            // sanitize input
            const inputValue = e.target.value;
            const sanitizedInputValue = sanitizeOnChange ? sanitizeOnChange(inputValue) : inputValue;

            // call any onChange functions
            if (onChangeSanitized) onChangeSanitized(sanitizedInputValue);
            if (onChange) onChange(e);
            if (validate && validateOnChange) {
              setError(validate(sanitizedInputValue));
            }
          }}
          onBlur={(e) => {
            // sanitize input
            const inputValue = e.target.value || value;
            const sanitizedInputValue = sanitizeOnBlur ? sanitizeOnBlur(inputValue) : inputValue;
            if (sanitizedInputValue !== inputValue && onChangeSanitized) onChangeSanitized(sanitizedInputValue);

            // validate with sanitized value
            let newError = error;
            if (sanitizedInputValue.length < 1 && required) newError = DEFAULT_REQUIRED_ERROR;
            if (validate && validate(sanitizedInputValue)) newError = validate(sanitizedInputValue);
            if (newError !== error) setError(newError);

            // call any onBlur functions
            if (onBlurSanitized) onBlurSanitized(sanitizedInputValue);
            if (onBlur) onBlur(e);
          }}
          onFocus={(e) => {
            if (error) setError(null);
            if (onFocus) onFocus(e);
          }}
          {...rest}
        />
        <FocusRing rounded as="input" sibling />
        {type === 'password' && (
          <button
            sx={showPasswordButtonStyles}
            onClick={() => setShowPassword(!showPassword)}
            type="button"
            tabIndex={isKeyboardUser ? 0 : -1}
            aria-label={SHOW_PASSWORD_LABEL}
          >
            {!showPassword ? <Svg svg={ShowPasswordSVG} title="Show password" sx={showPasswordSVGStyles} />
              : <Svg svg={HidePasswordSVG} title="Hide password" sx={showPasswordSVGStyles} />}
            <FocusRing />
          </button>
        )}
        {showSearchIcon && <Svg svg={SearchSVG} title={searchIconTitle || 'Search'} sx={searchSvgStyles} />}
      </div>
      {showCaption && (
        <div role={inputError ? 'alert' : undefined} sx={captionContainerStyles}>
          {inputError && <Svg svg={Warning} title="Input error occurred" sx={warningIconStyles} />}
          <p sx={captionStyles}>{inputError || caption}</p>
        </div>
      )}
    </div>
  );
}

export default memo(Input);
