import produce from 'immer';
import { GiphyAction, SET_PRESET_GIFS, UPDATE_GIF_DATA } from 'actions/giphyActions';
import { GiphyState } from 'store/types';
import { SharedAction } from 'actions/sharedActions';

export const initialState: GiphyState = {
  gifData: {},
  presetGifs: null,
};

const reducer = produce((draft: GiphyState, action: GiphyAction | SharedAction) => {
  switch (action.type) {
    case UPDATE_GIF_DATA:
      draft.gifData[action.payload.id] = action.payload.data;
      break;
    case SET_PRESET_GIFS:
      draft.presetGifs = action.payload.presetGifs;
      break;
    default:
      break;
  }
}, initialState);

export default reducer;
