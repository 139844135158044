import { StoreState } from 'store/types';

export const getScreenshareBroadcast = (state: StoreState) => state.screenShareState.broadcast;

export const getScreenshareUiState = (state: StoreState) => state.screenShareState.screenShareUiState;

export const getScreenShareMediaStreamController = (state: StoreState) => state.screenShareState.screenShareMediaStreamController;

export const getIsScreenCapture = (state: StoreState) => state.screenShareState.isScreenCapture;

export const getSwappedScreenSharePreview = (state: StoreState) => state.screenShareState.swappedScreenSharePreview;

export const getDisplayCaptureSurface = (state: StoreState) => state.screenShareState.displayCaptureSurface;
