import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { StoreState } from 'store/types';

/**
 * Provides a typed version of the plain Redux useSelector for use in .js files.
 * This provides autocomplete for any parts of the store that have been given types
 * when writing selector functions by hand.
 */
const useTypedSelector: TypedUseSelectorHook<StoreState> = useSelector;

export default useTypedSelector;
