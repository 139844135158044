import { setIncompatibleError } from 'actions/errorMessageActions';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import logger from 'utils/logger';
import useDetectBrowser from './useDetectBrowser';
import useFeatureFlags from './useFeatureFlags';

const iosMessage = 'To use ChalkCast on an iOS mobile device, we recommend using the latest version of Safari';
const androidMessage = 'To use ChalkCast on a mobile Android device, we recommend using the latest version of Chrome';
const notChromeMessage = 'To use ChalkCast, we recommend using the latest version of Chrome';

function useDetectIncompatibleBrowser() {
  const browser = useDetectBrowser();
  const dispatch = useDispatch();
  const { enableIncompatibleBrowsers } = useFeatureFlags();
  const [incompatibleError, setError] = useState(false);

  useEffect(() => {
    if (enableIncompatibleBrowsers) return;

    if (browser && browser.type === 'browser') {
      const { name, os } = browser;
      logger.debug('Browser information', { ...browser });

      if (os === 'iOS' && !(name === 'ios' || name === 'safari')) {
        logger.warn('User is not using safari on iOS', { ...browser });
        dispatch(setIncompatibleError(iosMessage));
        setError(true);
      } else if (os === 'Android OS' && name !== 'chrome') {
        logger.warn('User is not using chrome on Android', { ...browser });
        dispatch(setIncompatibleError(androidMessage));
        setError(true);
      } else if (os !== 'iOS' && os !== 'Android OS' && name !== 'chrome') {
        logger.warn('User is not using chrome', { ...browser });
        dispatch(setIncompatibleError(notChromeMessage));
        setError(true);
      }
    }
  }, [browser, enableIncompatibleBrowsers, dispatch]);

  return incompatibleError;
}

export default useDetectIncompatibleBrowser;
