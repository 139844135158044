import { EnumBase } from 'utils/enum';

export type FaceDetectionEventKeys = Exclude<keyof typeof FaceDetectionEvent, keyof typeof EnumBase>;

export class FaceDetectionEvent<S extends string = string> extends EnumBase {
  public static readonly PREDICTION_COMPLETE = new FaceDetectionEvent('PREDICTION_COMPLETE');

  public static readonly INITIALIZING_MODEL = new FaceDetectionEvent('INITIALIZING_MODEL');

  public static readonly MODEL_READY = new FaceDetectionEvent('MODEL_READY');

  public static readonly MODEL_CHOSEN = new FaceDetectionEvent('MODEL_CHOSEN');

  constructor(public id: S) {
    super();
  }

  /** Identical ordering is guaranteed on successive calls */
  public static asArray() {
    const array = super.asArray();
    array.sort();
    return super.asArray() as Array<typeof FaceDetectionEvent[FaceDetectionEventKeys]>;
  }

  public static asObject() {
    return super.asObject() as { [key in FaceDetectionEventKeys]: typeof FaceDetectionEvent[FaceDetectionEventKeys] };
  }
}
