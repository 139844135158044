/** @jsx jsx */
import { jsx } from '@theme-ui/core';
import Input, { InputProps } from 'components/ui/Input';
import useTypedSelector from 'hooks/useTypedSelector';
import { getUserProfile } from 'selectors';
import { isAuthUser } from 'utils/typePredicates';
import { CSS } from 'types/css';
import { GREY_50 } from 'theme/ui/colors';

const inputStyles: CSS = {
  '& input:disabled': {
    color: GREY_50,
  },
};

/* Make some InputProps optional, since if they are omitted,
these props are defined here in the component */
export interface HiddenEmailInputProps extends Omit<InputProps, 'id' | 'label' | 'value' | 'type'> {
  id?: InputProps['id'],
  label?: InputProps['label'],
  value?: InputProps['value'],
}

export default function HiddenEmailInput({
  id = 'hidden-email-input', label = 'Email', value, disabled = true, componentStyles, ...rest
}: HiddenEmailInputProps) {
  const currentUser = useTypedSelector(getUserProfile);
  let hiddenEmail = '****************';
  if (isAuthUser(currentUser) && currentUser?.sanitizedEmail) {
    hiddenEmail = currentUser.sanitizedEmail;
  }

  return (
    <Input
      type="email"
      id={id}
      label={label}
      /* do not replace with hiddenEmail if value is '' */
      value={value ?? hiddenEmail}
      disabled={disabled}
      componentStyles={{ ...componentStyles, ...inputStyles }}
      {...rest}
    />
  );
}
