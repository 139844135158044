import { SET_NOTIFICATION, REMOVE_INVITE_LINK_NOTIFICATION, NotificationAction } from 'actions/notificationActions';
import { RESET_ROOM, SharedAction } from 'actions/sharedActions';
import produce from 'immer';
import { NotificationComponentEnum, NotificationState } from 'store/types';

export const initialState: NotificationState = {
  room: '',
};

const reducer = produce((draft: NotificationState, action: NotificationAction | SharedAction) => {
  switch (action.type) {
    case SET_NOTIFICATION:
      draft[action.payload.type] = action.payload.message ?? initialState[action.payload.type];
      break;
    case REMOVE_INVITE_LINK_NOTIFICATION:
      if (typeof draft.room === 'object' && draft.room.component === NotificationComponentEnum.INVITE_LINK) {
        draft.room = '';
      }
      break;
    case RESET_ROOM:
      draft.room = initialState.room;
      break;
  }
}, initialState);

export default reducer;
