import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import logger from 'utils/logger';
import { getItemFromPersistedStore } from 'store/localStoragePersistMiddleware';

const ccServiceInstance = axios.create({
  baseURL: `${appConfig.ccServiceApi}/api/chalkcast/v1`,
  timeout: 30000,
});

const chatInstance = axios.create({
  baseURL: `https://${appConfig.chatHost}/chat/v1`,
});

const ccServiceRequestInterceptor = (options: AxiosRequestConfig) => {
  const token = getItemFromPersistedStore('token');
  const livelyToken = getItemFromPersistedStore('livelyToken');

  if (token && !options.headers.token) {
    options.headers.token = `${token}`;
  }

  if (livelyToken && !options.headers.livelyToken) {
    options.headers.livelyToken = `${livelyToken}`;
  }

  logger.info('Sending request to ccService: ', { options: JSON.stringify(options) });
  return options;
};

const chatRequestInterceptor = (options: AxiosRequestConfig) => {
  const livelyToken = getItemFromPersistedStore('livelyToken');

  if (livelyToken) {
    options.headers.authorization = `Bearer ${livelyToken}`;
  }

  logger.info('Sending request to chat: ', { options: JSON.stringify(options) });
  return options;
};

interface Reason {
  text?: string;
  field?: string;
}

interface Message {
  level: string;
  message: string;
}

const formatReason = (reason?: Reason, num?: number) => (
  `Reason${num ? ` ${num}` : ''}: ${reason?.text ? reason.text : reason}${reason?.field ? ` (Field: ${reason.field})` : ''}`
);

const formatMessage = (message?: Message, num?: number) => (
  `Message${num ? ` ${num}` : ''}: ${message?.message || 'N/A'}${message?.level ? ` (Level: ${message.level})` : ''}`
);

/**
 * Non-2xx responses should almost always have the "reasons" field in the response data, but
 * some responses have "messages" intead.
 *
 * This interceptor simply logs to the console so that error metadata
 * can be seen even when the network tab is not active.
 */
const formatError = (error: AxiosError) => {
  const reasons: Reason[] | undefined = error.response?.data?.reasons;
  const messages: Message[] | undefined = error.response?.data?.messages;

  const hasReasons = Array.isArray(reasons);
  const hasMessages = Array.isArray(messages);

  if (!hasReasons && !hasMessages) {
    return error.response?.data || error;
  }

  // Intersect types so that formatter can work with either
  // Thanks to the return after !hasReasons && !hasMessages, we know at least one is not undefined
  const metadataArr = (hasReasons ? reasons : messages) as Array<Reason & Message>;
  const format = hasReasons ? formatReason : formatMessage;
  const areMultiple = metadataArr.length > 1;

  const err = metadataArr?.reduce((str, meta, i) => (
    `${str}\n${format(meta, areMultiple ? i + 1 : undefined)}`
  ), 'Error metadata:');

  return err;
};

export const errorResponseInterceptor = (error: AxiosError) => {
  try {
    console.error(formatError(error));
  } catch (e) {
    console.error('Unexpected error in formatting error response: ', e);
    console.error(error.response?.data || error);
  }
  return Promise.reject(error);
};

ccServiceInstance.interceptors.request.use(ccServiceRequestInterceptor);
ccServiceInstance.interceptors.response.use(undefined, errorResponseInterceptor);
chatInstance.interceptors.request.use(chatRequestInterceptor);
chatInstance.interceptors.response.use(undefined, errorResponseInterceptor);

/**
 * The timeout is 30 seconds but we still want to log an error if the request
 * takes longer than 5 seconds
 */
const request = async <T>(options: AxiosRequestConfig) => {
  const timeout = setTimeout(() => {
    logger.error('Request took longer than 5 seconds', options as any);
  }, 5000);

  try {
    const response: AxiosResponse<T> = await ccServiceInstance(options);
    return response;
  } finally {
    clearTimeout(timeout);
  }
};

export default request;
export { chatInstance as chatRequest };
