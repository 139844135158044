export const lowEncodings = [{ maxBitrate: 50_000, dtx: false }];
export const mediumEncodings = [{ maxBitrate: 50_000, dtx: true }, { maxBitrate: 400_000, dtx: true }];
export const normalEncodings = [{ maxBitrate: 50_000, dtx: true }, { maxBitrate: 400_000, dtx: true }, { maxBitrate: 1_000_000, dtx: true }];

/**
 * Create encoding options object.
 * Optionally pass in normal values, usually from redux debugger state
 */
function createEncodingOptions(normal = normalEncodings) {
  return {
    1: lowEncodings,
    2: mediumEncodings,
    3: normal,
  };
}

export default createEncodingOptions;
